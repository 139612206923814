import {Dispatch, SetStateAction, useEffect, useState} from "react";

export default function useRemoveExperienceModalState(
  uuid: string | undefined,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Reset state if uuid changes since experiences can change without unmounting components
    setOpen(false);
  }, [uuid]);

  return [open, setOpen];
}
