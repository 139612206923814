import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import ExperienceAddButton from "../components/ExperienceAddButton";
import {ApplicantContext} from "../context/ApplicantContext";
import {getPrevUuid} from "../helpers/entries";
import {useSharedStyles} from "../hooks/useSharedStyles";

export default function EndProjects() {
  const {projects, setUIState} = useContext(ApplicantContext);
  const sharedClasses = useSharedStyles();

  const onProceed = () => {
    setUIState({step: "finalReview"});
  };

  const onBack = () => {
    const prevUuid = getPrevUuid(projects);
    if (prevUuid) {
      setUIState({step: "validateProject", uuid: prevUuid});
    } else {
      setUIState({step: "educationEnd"});
    }
  };

  return (
    <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
      <Typography color="primary" variant="h2">
        Projects
      </Typography>
      {projects.length ? (
        <Typography variant="body1">
          You have completed the validation for your project entries! You can
          now add any entries we might have missed. Otherwise, please continue
          on to the final review step.
        </Typography>
      ) : (
        <Typography variant="body1">
          There are currently no project entries associated with your portfolio.
          Feel free to add any that we might have missed. Otherwise, please
          continue on to the final review step.
        </Typography>
      )}
      <ActionsWrapper>
        <ExperienceAddButton category="project" />
        <Button onClick={onBack}>Back</Button>
        <Button color="primary" onClick={onProceed} variant="contained">
          Proceed
        </Button>
      </ActionsWrapper>
    </ApplicantPaper>
  );
}
