import {Flags} from "./types";
import {FlagsAction} from "../actions/actionTypes";

const defaultState: Flags = {
  cloudshellEnabled: true,
  enableBBDoc: false,
};

export function flags<F>(state: F, action: FlagsAction<F>): F {
  state = state || (defaultState as unknown as F);
  switch (action.type) {
    case "SET_FLAGS":
      return {
        ...state,
        ...action.flags,
        ...getFlagOverridesFromUrl(action.possibleValues),
      };
    default:
      return state;
  }
}

/**
 * Gets the flag overrides from the current url. For example:
 *
 * /interview/abc?flags=enableRed,-enableGreen
 *
 * This example url would turn on the enableRed flag and turn off the
 * enableGreen flag.
 *
 * See flags/spec.ts for more examples.
 */
function getFlagOverridesFromUrl<F>(possibleValues: (keyof F)[]): F {
  const params = new URLSearchParams(window.location.search);
  const urlFlags = (params.get("flags") || "").split(",");

  return (
    urlFlags
      // Only include valid flags (ignoring the possible "-").
      .filter((flag) => possibleValues.includes(flag.replace("-", "") as any))
      .reduce((flagsObj, flag) => {
        const flagValue = !flag.startsWith("-");
        if (flag.startsWith("-")) {
          flag = flag.replace("-", "");
        }
        return {
          ...flagsObj,
          [flag]: flagValue,
        };
      }, {}) as F
  );
}
