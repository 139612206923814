import * as React from "react";
import {AppState, Interview} from "../reducers/types";
import {ExternalLink} from "./Link";
import {connect} from "react-redux";

const byteboardFAQUrl =
  "https://docs.google.com/document/d/1lQ0hepX4J-xsJ8PGJSFUvd9xmXfU0uCYkHQbeCadT5Y/preview";

interface StateProps {
  interview: Interview;
}
interface Props extends StateProps {
  children: React.ReactNode;
}

class FAQLinkBase extends React.Component<Props> {
  render() {
    return (
      <ExternalLink href={byteboardFAQUrl} target="_blank">
        {this.props.children}
      </ExternalLink>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  interview: state.interview,
});

export const FAQLink = connect(mapStateToProps)(FAQLinkBase);
