import * as React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {
  AppState,
  Interview,
  User,
  CandidateBBHCStatus,
} from "../reducers/types";
import {BBHCScreenAction} from "../actions/actionTypes";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {termsUrl} from "../helpers/urls/routerUrls";
import {withInterview, RedirectRule} from "../components/InterviewLoader";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {saveBBHCOptin, saveBBHCOptout} from "../actions/bbhcScreenActions";
import Spinner from "../components/Spinner";
import {ExternalLink} from "../components/Link";

interface Props extends StyledComponentProps {
  atsId: string;
  interview: Interview;
  user: User;
  saveBBHCOptin: (interview: Interview, user: User) => any;
  saveBBHCOptout: (interview: Interview, user: User) => any;
}

const styles = () =>
  createStyles({
    box: {
      border: "solid 1px #ccc",
      borderRadius: 10,
      maxHeight: 350,
      minHeight: 500,
      maxWidth: 700,
      padding: 40,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    p: {
      color: "black",
      fontSize: 12,
    },
    elbowRoom: {
      margin: 7,
    },
  });

class BBHCConsentScreen extends React.Component<
  Props,
  {
    agreed: boolean;
    optingIn: boolean;
    optingOut: boolean;
    bbhcStatus: CandidateBBHCStatus;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agreed: false,
      optingIn: false,
      optingOut: false,
      // @ts-ignore FIXME: strictNullChecks
      bbhcStatus: props.interview.bbhcStatus,
    };
  }

  @autobind
  onBBHCAgreementChange() {
    this.setState({agreed: !this.state.agreed});
  }

  canProceed(): boolean {
    return this.state.agreed;
  }

  @autobind
  isLoading(): boolean {
    return this.state.optingIn || this.state.optingOut;
  }

  @autobind
  onOptin() {
    this.setState({optingIn: true});
    this.props.saveBBHCOptin(this.props.interview, this.props.user);
  }

  @autobind
  onOptout() {
    this.setState({optingOut: true});
    this.props.saveBBHCOptout(this.props.interview, this.props.user);
  }

  renderAgreementInputs(): JSX.Element {
    return (
      <FormControlLabel
        key="bbhcAgreement"
        control={
          <Checkbox
            checked={this.state.agreed}
            onChange={this.onBBHCAgreementChange}
            color="primary"
            disabled={this.isLoading()}
          />
        }
        label={
          <div>
            I have read and agree to the Byteboard Hiring Consortium Terms.
          </div>
        }
      />
    );
  }

  render() {
    const {classes, interview, atsId} = this.props;
    return (
      <CenteredScreenContent size="tall">
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.box}>
          {this.isLoading() ? (
            <>
              {this.state.optingIn && (
                <Typography variant="h2">
                  Sharing interview materials with {interview.companyName}...
                </Typography>
              )}
              {this.state.optingOut && (
                <Typography variant="h2">
                  Opting-out of Byteboard Hiring Consortium...
                </Typography>
              )}
              <Spinner />
            </>
          ) : (
            <>
              <Typography variant="h2">
                Ready to skip the interview and share your past interview
                materials with {interview.companyName}?
              </Typography>
              <Typography paragraph={true}>
                You recently took a Byteboard interview with a company in the
                Byteboard Hiring Consortium. Your materials from that interview
                are valid for 3 months and can be shared with any company in the
                Consortium with your permission.
              </Typography>
              <Typography paragraph={true}>
                Note: Your interview will be assessed based on{" "}
                {interview.companyName}’s hiring requirements, so you may see
                different outcomes across companies.
              </Typography>
              <Typography paragraph={true}>
                Please review the Byteboard{" "}
                <ExternalLink
                  target="_blank"
                  href="https://byteboard.dev/terms"
                >
                  Terms of Service
                </ExternalLink>
                .
              </Typography>
            </>
          )}
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.elbowRoom}>
            {this.renderAgreementInputs()}
          </div>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.elbowRoom}>
            <Button
              color="primary"
              disabled={!this.canProceed() || this.isLoading()}
              onClick={this.onOptin}
              variant="contained"
            >
              Share Materials!
            </Button>
          </div>
          {/* <Typography paragraph={true}><a onClick={this.onOptout} href="">Re-take the interview</a></Typography> */}
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.elbowRoom}>
            <Typography paragraph={true}>Or</Typography>
          </div>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.elbowRoom}>
            <Button
              color="primary"
              disabled={this.isLoading()}
              onClick={this.onOptout}
              variant="text"
            >
              Re-take the interview
            </Button>
          </div>
        </div>
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  interview: state.interview,
  user: state.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, BBHCScreenAction>,
) => ({
  saveBBHCOptin: (interview: Interview, user: User) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(saveBBHCOptin(interview, user));
  },
  saveBBHCOptout: (interview: Interview, user: User) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(saveBBHCOptout(interview, user));
  },
});

const redirectRules: RedirectRule[] = [
  {
    /* If the candidate is not BBHC eligible, then they should not be able to see the BBHC opt-in screen.
       Instead they should be redirected to the regular interview UX where the terms are the first step. */
    match: (interview: Interview) =>
      interview.bbhcStatus !== CandidateBBHCStatus.eligible,
    redirect: (interview) => termsUrl(interview.id),
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview(redirectRules, {ignoreGlobalRules: ["terms", "getStarted"]})(
      BBHCConsentScreen,
    ),
  ),
);
