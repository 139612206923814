import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import ApplicantPaper from "../components/ApplicantPaper";
import portfolioIcon from "../icons/portfolio-icon.png";

const useStyles = makeStyles({
  container: {
    columnGap: 16,
    display: "grid",
    gridTemplateAreas: `
      "icon title"
      "icon body"
    `,
    rowGap: 24,
  },
});

export default function ThankYou() {
  const classes = useStyles();
  return (
    <ApplicantPaper className={classes.container}>
      <img src={portfolioIcon} style={{gridArea: "icon"}} />
      <Typography variant="h1" style={{gridArea: "title"}}>
        Validation complete!
      </Typography>
      <Typography variant="body1" style={{gridArea: "body"}}>
        Thank you for validating your information! We appreciate your effort in
        ensuring that your professional journey is accurately represented. You
        can now safely close the page. Best of luck!
      </Typography>
    </ApplicantPaper>
  );
}
