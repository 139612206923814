import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import copyIcon from "../img/copy-icon.png";
import {useDispatch} from "react-redux";
import {omit} from "lodash";
import {toast} from "../actions/toastActions";
import {makeStyles} from "@material-ui/styles";
import {toggleExpandLanguageStep} from "../actions/getStartedScreenActions";

interface Props {
  className?: string;
  text?: string;
}

const useStyles = makeStyles(() => ({
  icon: {
    height: 21,
    padding: "0 2px",
    width: 17,
  },
}));

const CopyButton = React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onCopy = () => {
      try {
        // @ts-ignore FIXME: strictNullChecks
        navigator.clipboard.writeText(props.text);
        dispatch(toast("Copied to clipboard!"));
      } catch (err) {
        console.error("Error copying text");
      }
    };

    const {text} = props;
    const icon = <img src={copyIcon} className={classes.icon} />;
    if (!text) {
      return React.cloneElement(icon, {style: {transform: "translateY(5px)"}});
    }
    return (
      <IconButton
        {...omit(props, "toast", "classes")}
        onClick={onCopy}
        aria-label="Click to copy to clipboard"
        ref={ref}
      >
        {icon}
      </IconButton>
    );
  },
);

export default CopyButton;
