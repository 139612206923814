import {createTheme} from "@material-ui/core/styles";
import {primaryColor} from "../../../branding";

const applicantTheme = createTheme({
  overrides: {
    MuiTypography: {
      caption: {
        fontSize: "0.875rem",
        letterSpacing: "0.025rem",
        lineHeight: "1rem",
      },
      body1: {
        fontSize: "1rem",
        letterSpacing: "0.016rem",
        lineHeight: "1.375rem",
      },
      h1: {
        fontSize: "1.625rem",
        letterSpacing: "0.038rem",
        lineHeight: "2rem",
        marginBottom: "unset",
      },
      h2: {
        fontSize: "1rem",
        letterSpacing: "0.006rem",
        lineHeight: "1.5rem",
        marginBottom: "unset",
        textTransform: "uppercase",
      },
      h3: {
        fontSize: "1rem",
        fontWeight: 500,
        letterSpacing: "0.016rem",
        lineHeight: "1.5rem",
        marginBottom: "unset",
      },
    },
  },
  palette: {
    primary: primaryColor,
  },
});

export default applicantTheme;
