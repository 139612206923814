import * as React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Typography from "@material-ui/core/Typography";
import {brand} from "../branding";
import Button from "@material-ui/core/Button";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import {AppState, CandidateBBHCStatus, Interview} from "../reducers/types";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {interviewHubUrl} from "../helpers/urls/routerUrls";
import {ExternalLink} from "../components/Link";

interface Props extends StyledComponentProps, RouteComponentProps {
  atsId: string;
  interview: Interview;
}

const styles = () =>
  createStyles({
    root: {
      textAlign: "center",
    },
    logo: {
      height: brand.logoMarkDimensions.height,
      marginBottom: 40,
      width: brand.logoMarkDimensions.width,
    },
    elbowRoom: {
      margin: 15,
    },
  });

class PostSurveyScreen extends React.Component<Props> {
  render() {
    const {classes, atsId, interview} = this.props;
    let message = (
      <Typography style={{textAlign: "center"}}>
        You can close this window at any time.
      </Typography>
    );

    if (
      interview.bbhcStatus === CandidateBBHCStatus.optout &&
      !interview.m1OpenTime
    ) {
      /* If the candidate has opted-out of BBHC but not started the interview, then they just submitted feedback on why they opted-out of BBHC.
         So we want to inform them that they will receive an email confirmation and/or can begin the interview by clicking the prep guide button. */
      message = (
        <Typography style={{textAlign: "center"}}>
          You should receive an updated interview invitation by email shortly
          with direct links to the interview or you can get started by clicking
          below.
        </Typography>
      );
    } else if (interview.survey_stars && interview.survey_stars >= 4) {
      message = (
        <Typography style={{textAlign: "center"}}>
          We're glad your experience with Byteboard was enjoyable! Help us
          spread the word by{" "}
          <ExternalLink
            href="https://www.g2.com/products/byteboard/reviews/start?return_to=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fbyteboard%2Ftake_survey"
            target="_blank"
          >
            sharing your feedback on G2.
          </ExternalLink>{" "}
          Leaving a review only takes about 5 minutes and will help other
          candidates know what to expect during the interview.
        </Typography>
      );
    }
    return (
      <CenteredScreenContent>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <img className={classes.logo} src={brand.logoMark} />
          <Typography
            variant="h2"
            paragraph={true}
            style={{textAlign: "center"}}
          >
            Thank you for your feedback!
          </Typography>
          {message}
          {
            /* If candidate is presented the post survey screen but they have not yet started their interview,
            and opted-out of BBHC, then display a button that links to the interview prep center.
            The interview prep center page provides general preparation information about the interview and has
            a button to begin the assessment. */
            interview.bbhcStatus === CandidateBBHCStatus.optout &&
              !interview.m1OpenTime && (
                <Button
                  // @ts-ignore FIXME: strictNullChecks
                  className={classes.elbowRoom}
                  color="primary"
                  variant="contained"
                  href={interviewHubUrl(atsId)}
                >
                  Go to Interview Prep Center
                </Button>
              )
          }
        </div>
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  interview: state.interview,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(PostSurveyScreen)));
