import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import ExperienceAddButton from "../components/ExperienceAddButton";
import {ApplicantContext} from "../context/ApplicantContext";
import {getNextUuid, getPrevUuid} from "../helpers/entries";
import {useSharedStyles} from "../hooks/useSharedStyles";

export default function EndRoles() {
  const {roles, education, setUIState} = useContext(ApplicantContext);
  const sharedClasses = useSharedStyles();

  const onProceed = () => {
    const nextUuid = getNextUuid(education);
    if (nextUuid) {
      setUIState({step: "validateEducation", uuid: nextUuid});
    } else {
      setUIState({step: "educationEnd"});
    }
  };

  const onBack = () => {
    const prevUuid = getPrevUuid(roles);
    if (prevUuid) {
      setUIState({step: "validateRole", uuid: prevUuid});
    } else {
      setUIState({step: "getStarted"});
    }
  };

  return (
    <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
      <Typography color="primary" variant="h2">
        Work Experience
      </Typography>
      {roles.length ? (
        <Typography variant="body1">
          You have completed the validation for your role entries! You can now
          add any entries we might have missed. Otherwise, please continue on
          with validating your education entries.
        </Typography>
      ) : (
        <Typography variant="body1">
          There are currently no role entries associated with your portfolio.
          Feel free to add any that we might have missed. Otherwise, please
          continue on with validating your education entries.
        </Typography>
      )}
      <ActionsWrapper>
        <ExperienceAddButton category="role" />
        <Button onClick={onBack}>Back</Button>
        <Button color="primary" onClick={onProceed} variant="contained">
          Proceed
        </Button>
      </ActionsWrapper>
    </ApplicantPaper>
  );
}
