import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import React, {Fragment, useContext} from "react";
import {getAPIBaseUrl} from "../../../helpers/api";
import getAtsIdFromUrl from "../../../helpers/urls/getAtsIdFromUrl";
import {post} from "../../../util/fetch";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import ExperienceAddButton from "../components/ExperienceAddButton";
import ExperienceEducation from "../components/ExperienceEducation";
import ExperienceProject from "../components/ExperienceProject";
import ExperienceRole from "../components/ExperienceRole";
import {ApplicantContext} from "../context/ApplicantContext";
import {createValidatedPortfolio} from "../helpers/entries";
import {useSharedStyles} from "../hooks/useSharedStyles";
import validateIcon from "../icons/validate-icon.png";

const useStyles = makeStyles({
  headerContainer: {
    display: "grid",
    gridColumnGap: 16,
    gridRowGap: 24,
    gridTemplateAreas: `
      "icon title"
      "icon body"
    `,
  },
  submitButton: {
    margin: "0 auto",
    width: 200,
  },
});

export default function FinalReview() {
  const {
    roles,
    education,
    projects,
    skills,
    setUIState,
    responsePortfolio: unvalidatedPortfolio,
  } = useContext(ApplicantContext);
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const onSubmit = async () => {
    // Shouldn't happen, just appeasing TS
    if (unvalidatedPortfolio === null) return;

    const validatedPortfolio = createValidatedPortfolio({
      bio: unvalidatedPortfolio.bio,
      roles,
      education,
      projects,
      skills,
    });

    const applicantId = getAtsIdFromUrl();
    await post(`${getAPIBaseUrl()}/applicant/${applicantId}/validated`, {
      portfolio: validatedPortfolio,
    });

    setUIState({step: "thankYou"});
    // TODO: Custom error page
  };

  return (
    <Box className={sharedClasses.gridBoxRowGap24}>
      <ApplicantPaper className={classes.headerContainer}>
        <img src={validateIcon} style={{gridArea: "icon"}} />
        <Typography variant="h1" style={{gridArea: "title"}}>
          Review Your Portfolio
        </Typography>
        <Typography variant="body1" style={{gridArea: "body"}}>
          Please take a moment to do one final review of your portfolio. Feel
          free to make any necessary edits or additions. Once you're satisfied,
          simply submit your portfolio at the bottom of the page. Keep in mind
          that once submitted, you won't be able to make any further changes. So
          please review your portfolio carefully and make sure it showcases your
          best work!
        </Typography>
      </ApplicantPaper>
      <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
        <Typography color="primary" variant="h2">
          Roles
        </Typography>
        {roles.length ? (
          roles.map((role, idx) => (
            <Fragment key={role.uuid}>
              <ExperienceRole role={role} />
              {idx < roles.length - 1 && <Divider />}
            </Fragment>
          ))
        ) : (
          <>
            <Typography variant="body1">
              There are no roles associated with your portfolio. Please add any
              that may have been missed!
            </Typography>
            <ExperienceAddButton category="role" />
          </>
        )}
        <ActionsWrapper>
          <ExperienceAddButton category="role" />
        </ActionsWrapper>
      </ApplicantPaper>
      <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
        <Typography color="primary" variant="h2">
          Education
        </Typography>
        {education.length ? (
          education.map((edu, idx) => (
            <Fragment key={edu.uuid}>
              <ExperienceEducation education={edu} />
              {idx < education.length - 1 && <Divider />}
            </Fragment>
          ))
        ) : (
          <>
            <Typography variant="body1">
              There are no education entries associated with your portfolio.
              Please add any that may have been missed!
            </Typography>
            <ExperienceAddButton category="education" />
          </>
        )}
        <ActionsWrapper>
          <ExperienceAddButton category="education" />
        </ActionsWrapper>
      </ApplicantPaper>
      <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
        <Typography color="primary" variant="h2">
          Projects
        </Typography>
        {projects.length ? (
          projects.map((proj, idx) => (
            <Fragment>
              <ExperienceProject key={proj.uuid} project={proj} />
              {idx < projects.length - 1 && <Divider />}
            </Fragment>
          ))
        ) : (
          <Typography variant="body1">
            There are no projects associated with your portfolio. Please add any
            projects that may have been missed!
          </Typography>
        )}
        <ActionsWrapper>
          <ExperienceAddButton category="project" />
        </ActionsWrapper>
      </ApplicantPaper>
      <ActionsWrapper>
        <Button
          className={classes.submitButton}
          color="primary"
          onClick={onSubmit}
          size="large"
          variant="contained"
        >
          Submit Portfolio
        </Button>
      </ActionsWrapper>
    </Box>
  );
}
