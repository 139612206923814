import * as React from "react";
import {Link as ReactRouterLink, LinkProps} from "react-router-dom";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {createStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core/styles";

interface Props extends LinkProps {
  classes: any;
}

interface ExternalLinkProps {
  children: any;
  classes: any;
  href: string;
  target?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    link: {
      // @ts-ignore FIXME: strictNullChecks
      ...theme.overrides.MuiLink.root,
    },
  });

class Link extends React.Component<Props> {
  render() {
    return (
      <ReactRouterLink className={this.props.classes.link} {...this.props} />
    );
  }
}

function ExternalLinkBase(props: ExternalLinkProps) {
  return (
    <a className={props.classes.link} href={props.href} target={props.target}>
      {props.children}
    </a>
  );
}

export const ExternalLink = withStyles(styles)(ExternalLinkBase);

export default withStyles(styles)(Link);
