import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";

interface IconProps {
  className?: string;
  color?: string;
}

export const CoffeeAndClockIcon = (props: IconProps) => {
  return (
    <SvgIcon
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M5.21739 0.869568C7.60869 0.869568 9.56522 2.82609 9.56522 5.21739C9.56522 7.6087 7.60869 9.56522 5.21739 9.56522C2.82609 9.56522 0.869563 7.6087 0.869563 5.21739C0.869563 2.82609 2.82609 0.869568 5.21739 0.869568ZM5.21739 8.69566C7.13478 8.69566 8.69565 7.13479 8.69565 5.21739C8.69565 3.3 7.13478 1.73913 5.21739 1.73913C3.3 1.73913 1.73913 3.3 1.73913 5.21739C1.73913 7.13479 3.3 8.69566 5.21739 8.69566ZM5 3.04348H5.65217V5.65218L3.3913 7.04348L3.04348 6.47826L5 5.30435V3.04348Z"
        fill={props.color}
      />
      <rect
        x="6.26086"
        y="6.26086"
        width="5.21739"
        height="5.21739"
        fill="white"
      />
      <path
        d="M19.8261 20.087H5.73914V21.6522H19.8261V20.087Z"
        fill={props.color}
      />
      <path
        d="M19.8261 7.56525H7.30435V15.3913C7.30435 17.1209 8.70522 18.5218 10.4348 18.5218H15.1304C16.86 18.5218 18.2609 17.1209 18.2609 15.3913V13.0435H19.8261C20.6948 13.0435 21.3913 12.347 21.3913 11.4783V9.13046C21.3913 8.26177 20.6948 7.56525 19.8261 7.56525ZM16.6957 15.3913C16.6957 16.2522 15.9913 16.9566 15.1304 16.9566H10.4348C9.57392 16.9566 8.86957 16.2522 8.86957 15.3913V9.13046H11.2174V10.2261L9.80087 11.3609C9.70696 11.4313 9.65218 11.5487 9.65218 11.6661V15C9.65218 15.2192 9.82435 15.3913 10.0435 15.3913H13.1739C13.393 15.3913 13.5652 15.2192 13.5652 15V11.6661C13.5652 11.5487 13.5104 11.4313 13.4165 11.3609L12 10.2261V9.13046H16.6957V15.3913ZM11.6087 10.9148L12.7826 11.8539V14.6087H10.4348V11.8539L11.6087 10.9148ZM19.8261 11.4783H18.2609V9.13046H19.8261V11.4783Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export const WavingHandIcon = (props: IconProps) => {
  return (
    <SvgIcon width="24" height="24" fill="none" className={props.className}>
      <path
        d="M7.03 4.95L3.49 8.49C0.17 11.81 0.17 17.19 3.49 20.51C6.81 23.83 12.19 23.83 15.51 20.51L21.52 14.5C22.49 13.53 22.49 11.94 21.52 10.96C21.4 10.84 21.27 10.73 21.13 10.64L21.52 10.25C22.49 9.28 22.49 7.69 21.52 6.71C21.36 6.55 21.17 6.41 20.98 6.3C21.38 5.38 21.21 4.28 20.46 3.53C19.59 2.66 18.24 2.57 17.26 3.25C17.16 3.1 17.05 2.96 16.92 2.83C15.95 1.86 14.36 1.86 13.38 2.83L10.87 5.34C10.78 5.2 10.67 5.07 10.55 4.95C9.58 3.98 8 3.98 7.03 4.95ZM8.44 6.37C8.64 6.17 8.95 6.17 9.15 6.37C9.35 6.57 9.35 6.88 9.15 7.08L5.97 10.26C7.14 11.43 7.14 13.33 5.97 14.5L7.38 15.91C8.83 14.46 9.2 12.34 8.5 10.55L14.8 4.25C15 4.05 15.31 4.05 15.51 4.25C15.71 4.45 15.71 4.76 15.51 4.96L10.91 9.56L12.32 10.97L18.33 4.96C18.53 4.76 18.84 4.76 19.04 4.96C19.24 5.16 19.24 5.47 19.04 5.67L13.03 11.68L14.44 13.09L19.39 8.14C19.59 7.94 19.9 7.94 20.1 8.14C20.3 8.34 20.3 8.65 20.1 8.85L14.44 14.51L15.85 15.92L19.39 12.38C19.59 12.18 19.9 12.18 20.1 12.38C20.3 12.58 20.3 12.89 20.1 13.09L14.1 19.1C11.56 21.64 7.45 21.64 4.91 19.1C2.37 16.56 2.37 12.45 4.91 9.91L8.44 6.37ZM23 17C23 20.31 20.31 23 17 23V21.5C19.48 21.5 21.5 19.48 21.5 17H23ZM1 7C1 3.69 3.69 1 7 1V2.5C4.52 2.5 2.5 4.52 2.5 7H1Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};
