export function preloadImages(...srcs: string[]): Promise<void[]> {
  return Promise.all(srcs.map((src) => preloadImage(src)));
}

export function preloadImage(src: string): Promise<void> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve();
    img.src = src;
  });
}
