import * as React from "react";
import classNames from "classnames";
import {createStyles} from "@material-ui/core/styles";
import {getEnvironment} from "../branding";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface Props {
  className?: string;
}

const isByteboardEnv = getEnvironment() === "default";

const styles = () =>
  createStyles({
    note: {
      background: isByteboardEnv ? "#F8F5FF" : "#F0FAFF",
      border: `solid 1px ${isByteboardEnv ? "#DCCAFF" : "#CDE6F2"}`,
      borderRadius: 10,
      marginBottom: 20,
      padding: 15,
    },
  });

class Note extends React.Component<StyledComponentProps & Props> {
  render(): React.ReactNode {
    const {classes, children, className} = this.props;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classNames(classes.note, className)}>{children}</div>
    );
  }
}

export default withStyles(styles)(Note);
