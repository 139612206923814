import * as React from "react";
import * as firebase from "firebase/app";
import config from "../config.js";
import {ThunkAction, ThunkDispatch, User} from "../reducers/types";
import {
  UserNotSignedInError,
  NoEmailError,
  userNotSignedIn,
  UserNotAdminError,
} from "./errorActions";
import {rejectLongRunningPromise} from "../util/async";
import {setErrorMessage} from "./errorActions";
import {setMostRecentUrl} from "../util/auth";
import {signInWithCredential, getAuth, GoogleAuthProvider} from "firebase/auth";
import {isLocalhost} from "../util/env";

const {redirectUrl, gapi: gapiConfig} = config;

export const SET_USER = "SET_USER";

const loadUserMaxTime = 10000;

// @ts-ignore
const gapi: any = window.gapi;

interface Tokens {
  accessToken: string;
  idToken: string;
}

export function fetchUser(): ThunkAction {
  return async (dispatch: ThunkDispatch) => {
    try {
      const user = await rejectLongRunningPromise(
        getUser(),
        loadUserMaxTime,
        new Error(`User not loaded after ${loadUserMaxTime / 1000}s.`),
      );
      dispatch({
        type: SET_USER,
        user,
      });
    } catch (err) {
      if (err instanceof UserNotSignedInError) {
        // Gapi sometimes doesn't work in Safari. As a temporary solution, tell
        // the candidate to use a different browser.
        // See https://github.com/google/google-api-javascript-client/issues/503
        // @ts-ignore
        if (window.safari && location.href.endsWith("redirect")) {
          dispatch(
            setErrorMessage(
              "Try using Google Chrome or update your Safari settings.",
              {
                tips: [
                  <span>
                    Currently Safari is only supported if cross-site tracking is
                    enabled. To do this open Safari preferences, click on the
                    Privacy tab, and uncheck "Prevent cross-site tracking".
                  </span>,
                ],
              },
            ),
          );
        }
        dispatch(userNotSignedIn());
      } else if (err instanceof UserNotAdminError) {
        dispatch(
          setErrorMessage("You do not have permission to view this content."),
        );
      } else {
        dispatch(setErrorMessage("Failed to load user."));
        console.error(err);
      }
    }
  };
}

async function getUser(): Promise<User> {
  let user = null;
  const isOnsiteHub = window.location.pathname.includes("/codecollab/");

  if (!isLocalhost() && !isOnsiteHub) {
    const tokens = await getTokens();
    const {accessToken, idToken} = tokens;
    const credential = GoogleAuthProvider.credential(idToken, accessToken);
    const auth = getAuth(firebase.getApp());
    const {user: firebaseUser} = await signInWithCredential(auth, credential);
    // @ts-ignore FIXME: strictNullChecks
    const {email, photoURL} = firebaseUser;
    if (!email) {
      throw new NoEmailError();
    }
    user = {
      id: email.split("@")[0],
      avatar: `${photoURL}?sz=64`,
      email,
      accessToken,
      idToken,
    };
  } else {
    user = {
      id: "noauth",
      email: "noauth@example.com",
      accessToken: "accessToken",
      idToken: "idToken",
    };
  }
  if (!user) {
    throw new Error("Failed to load user");
  }
  return user;
}

async function getTokens(): Promise<Tokens> {
  await initClient();
  const tokens = gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .getAuthResponse();
  return {
    accessToken: tokens.access_token,
    idToken: tokens.id_token,
  };
}

export async function getFreshIdToken(): Promise<string> {
  try {
    const res = await gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .reloadAuthResponse();
    return res.id_token;
  } catch (err) {
    if (err.type === "noSessionBound") {
      // Attempting to refresh the id token in the Cypress environment will
      // throw this error. Just return empty string and let the caller handle.
      return "";
    } else {
      throw err;
    }
  }
}

async function initClient(): Promise<void> {
  await new Promise((resolve) => gapi.load("client:auth2", resolve));
  const {protocol, host} = location;
  await gapi.client.init({
    apiKey: gapiConfig.apiKey,
    clientId: gapiConfig.clientId,
    discoveryDocs: gapiConfig.discoveryDocs,
    scope: gapiConfig.scopes.join(" "),
    uxMode: "redirect",
    redirectUri: `${protocol}//${host}${redirectUrl}`,
  });
  const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
  if (!isSignedIn) {
    throw new UserNotSignedInError();
  }
}

export function signIn(currentUrl = location.pathname) {
  // Google auth doesn't allow dynamic redirect urls, so we need to store the
  // current URL before the sign in redirect.
  setMostRecentUrl(currentUrl);
  gapi.auth2.getAuthInstance().signIn();
}

export function signOut() {
  gapi.auth2.getAuthInstance().signOut();
}

// @ts-ignore
window.signOut = () => {
  signOut();
  console.log("Signed out!");
};
