import {alpha, darken, makeStyles} from "@material-ui/core/styles";

export const useSharedStyles = makeStyles((theme) => ({
  gridBoxRowGap24: {
    display: "grid",
    gridAutoFlow: "row",
    rowGap: "24px",
  },
  marginRightAuto: {
    marginRight: "auto",
  },
  outlinedButtonWarningColor: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.main, 0.1),
    },
  },
  containedButtonWarningColor: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: darken(theme.palette.error.main, 0.1),
    },
  },
  experinceHeaderContainer: {
    columnGap: 8,
    display: "grid",
    gridTemplateAreas: `
      "title editButton"
      "line1 editButton"
      "line2 editButton"
    `,
    gridTemplateColumns: "1fr auto",
  },
  experienceTitle: {
    marginBottom: 4,
  },
}));
