import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import * as React from "react";
import {renderToStaticMarkup} from "react-dom/server";
import {ErrorBoundary} from "react-error-boundary";
import ReactMarkdown from "react-markdown";
import {PluggableList} from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

const useStyles = makeStyles({
  root: {
    "& h1": {
      lineHeight: "1.15em",
    },
  },
});

interface Props {
  children: string;
  className?: string;
  remarkPlugins?: PluggableList;
}

export const Markdown: React.FC<Props> = ({
  children,
  className,
  remarkPlugins,
}) => {
  const classes = useStyles();
  return (
    <ErrorBoundary
      fallback={
        <Typography color="error">
          An error occurred while rendering markdown.
        </Typography>
      }
    >
      <ReactMarkdown
        className={clsx(classes.root, className)}
        children={children}
        remarkPlugins={remarkPlugins}
        rehypePlugins={[rehypeRaw]}
      />
    </ErrorBoundary>
  );
};

/** Converts a markdown string into a DOM tree. */
export function parseMarkdown(
  markdownStr: string,
  remarkPlugins?: PluggableList,
): HTMLDivElement {
  const rootEl = document.createElement("div");
  const renderedMarkdownStr = renderToStaticMarkup(
    <Markdown remarkPlugins={remarkPlugins || []}>{markdownStr}</Markdown>,
  );
  rootEl.innerHTML = renderedMarkdownStr;
  return rootEl;
}
