import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import autobind from "autobind-decorator";
import classNames from "classnames";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface Props extends StyledComponentProps {
  numStars: number; // 1-based
  onChange: (num: number) => any;
}

interface State {
  numStarsHover: number;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginLeft: -8,
    },
    starButton: {
      padding: 4,
      "&:hover": {
        background: "none",
      },
    },
    star: {
      color: "#aaa",
      fontSize: 44,
    },
    starFilled: {
      color: theme.palette.primary.main,
    },
  });

class Stars extends React.Component<Props, State> {
  static defaultProps = {
    numStars: 0,
  };

  state = {
    numStarsHover: 0,
  };

  isStarFilled(num: number): boolean {
    const {numStars} = this.props;
    const {numStarsHover} = this.state;
    if (numStarsHover > 0) {
      return numStarsHover >= num;
    }
    return numStars >= num;
  }

  onStarClick(num: number) {
    this.props.onChange(num);
  }

  onStarHover(numStarsHover: number) {
    this.setState({numStarsHover});
  }

  @autobind
  onEndHover() {
    this.setState({numStarsHover: 0});
  }

  renderEmptyStar(curNum: number) {
    const {classes} = this.props;
    const isStarFilled = this.isStarFilled(curNum);
    const Icon = isStarFilled ? StarIcon : StarBorderIcon;
    return (
      <IconButton
        aria-label={`${curNum} star${curNum > 1 ? "s" : ""}`}
        key={curNum}
        // @ts-ignore FIXME: strictNullChecks
        className={classes.starButton}
        onClick={() => this.onStarClick(curNum)}
        onMouseEnter={() => this.onStarHover(curNum)}
        disableRipple={false}
      >
        <Icon
          // @ts-ignore FIXME: strictNullChecks
          className={classNames(classes.star, {
            // @ts-ignore FIXME: strictNullChecks
            [classes.starFilled]: isStarFilled,
          })}
        />
      </IconButton>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.root} onMouseLeave={this.onEndHover}>
        {[1, 2, 3, 4, 5].map((num) => this.renderEmptyStar(num))}
      </div>
    );
  }
}

export default withStyles(styles)(Stars);
