import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AppState, Interview, User} from "../reducers/types";
import {BBHCScreenAction} from "./actionTypes";
import {optinToBBHC, optoutOfBBHC} from "./bbhcActions";

export function saveBBHCOptin(
  interview: Interview,
  user: User,
): ThunkAction<Promise<void>, {}, {}, BBHCScreenAction> {
  // @ts-ignore FIXME: No overload matches this call
  return async (
    dispatch: ThunkDispatch<AppState, null, BBHCScreenAction>,
  ): Promise<void> => {
    // @ts-ignore FIXME: strictNullChecks
    await dispatch(optinToBBHC(interview, user));
  };
}

export function saveBBHCOptout(
  interview: Interview,
  user: User,
): ThunkAction<Promise<void>, {}, {}, BBHCScreenAction> {
  // @ts-ignore FIXME: No overload matches this call
  return async (
    dispatch: ThunkDispatch<AppState, null, BBHCScreenAction>,
  ): Promise<void> => {
    // @ts-ignore FIXME: strictNullChecks
    await dispatch(optoutOfBBHC(interview, user));
  };
}
