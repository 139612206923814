import {getItem, setItem, removeItem} from "./localStorage";

const KEY = "MOST_RECENT_URL";

export function setMostRecentUrl(currentUrl = location.pathname) {
  setItem(KEY, currentUrl);
}

export function getMostRecentUrl(): string {
  return getItem(KEY) || "/";
}

export function clearMostRecentUrl() {
  removeItem(KEY);
}
