import * as React from "react";
import Button from "@material-ui/core/Button";
import TimerIcon from "@material-ui/icons/Timer";
import Tooltip from "@material-ui/core/Tooltip";
import autobind from "autobind-decorator";
import classNames from "classnames";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {createStyles} from "@material-ui/core/styles";
import {say} from "../util/announcer";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

const transitionDelay = 300;
const autoExpandWindow = 1000;
const autoExpandTimeDefault = 1000 * 60 * 2; // 2 min.
export const beginShowingSecondsAtDefault = 1000 * 60 * 2; // 2 min.
// Times (in minutes) that we want to announce to screen readers.
const announceTimeRemainingMins = [30, 15, 5, 1];

interface Props extends StyledComponentProps {
  /** ms */
  endTime?: number;
  label?: string;
  /** ms */
  beginShowingSecondsAt?: number;
  /** ms */
  autoExpandTime?: number;
  started: boolean;
  getServerTime: () => number;
  onTimeExpired?: () => any;
}

interface State {
  timeRemainingStr: string;
  isCollapsed: boolean;
  isTransitioning: boolean;
  didAutoExpand: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    "@keyframes timerPop": {
      from: {
        opacity: 0,
        transform: "scale(0.95)",
      },
      "50%": {
        transform: "scale(1.05)",
      },
      to: {
        opacity: 1,
        transform: "none",
      },
    },
    timer: {
      animation: "$timerPop 0.25s 0.1s forwards",
      background: "white",
      fontWeight: 600,
      opacity: 0,
      padding: "5px 30px",
    },
    timerCollapsed: {
      padding: "5px 0px",
      minWidth: 64,
    },
    timeStr: {
      fontFamily: '"Lucida Console", Monaco, monospace',
      fontSize: 11,
      fontWeight: 400,
    },
  });

class Timer extends React.Component<Props, State> {
  static defaultProps = {
    beginShowingSecondsAt: beginShowingSecondsAtDefault,
    autoExpandTime: autoExpandTimeDefault,
  };

  state = {
    timeRemainingStr: "",
    isCollapsed: false,
    isTransitioning: false,
    didAutoExpand: false,
  };
  animationFrameId: any;
  transitionTimeoutId: any;
  // We need to store the total seconds remaining so that we can periodically
  // announce the time to screen readers.
  totalSecondsRemaining: number;

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.timeRemainingStr === "0 s" &&
      !this.state.timeRemainingStr &&
      this.props.onTimeExpired
    ) {
      this.props.onTimeExpired();
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrameId);
    clearTimeout(this.transitionTimeoutId);
  }

  shouldShowSeconds(): boolean {
    const {endTime, beginShowingSecondsAt, getServerTime} = this.props;
    // @ts-ignore FIXME: strictNullChecks
    const timeRemaining = endTime - getServerTime();
    // @ts-ignore FIXME: strictNullChecks
    return timeRemaining < beginShowingSecondsAt;
  }

  @autobind
  onClick() {
    if (this.state.isTransitioning) return;
    this.setState({
      isCollapsed: !this.state.isCollapsed,
      isTransitioning: true,
    });
    this.transitionTimeoutId = setTimeout(() => {
      this.setState({
        isTransitioning: false,
      });
    }, transitionDelay);
  }

  @autobind
  async update(): Promise<void> {
    const timeRemainingStr = this.getTimeRemainingStr();
    if (this.totalSecondsRemaining !== this.getTotalSecondsRemaining()) {
      this.totalSecondsRemaining = this.getTotalSecondsRemaining();
      this.maybeAnnounceTime();
    }
    if (timeRemainingStr !== this.state.timeRemainingStr) {
      await new Promise((resolve) =>
        // @ts-ignore FIXME: strictNullChecks
        this.setState({timeRemainingStr}, resolve),
      );
      await this.maybeAutoExpand();
    }
    this.animationFrameId = requestAnimationFrame(this.update);
  }

  async maybeAutoExpand() {
    // If the timer is collapsed when there is `autoExpandTime` remaining then
    // we want the timer to expand. Only do this if the current time remaining
    // is within `autoExpandWindow` of `autoExpandTime` so that the timer
    // doesn't immediately expand if someone collapses it.
    // @ts-ignore FIXME: strictNullChecks
    const deltaTime = this.props.autoExpandTime - this.getTotalMsRemaining();
    if (
      this.state.isCollapsed &&
      !this.state.didAutoExpand &&
      deltaTime > 0 &&
      deltaTime < autoExpandWindow
    ) {
      await new Promise((resolve) =>
        this.setState(
          {
            didAutoExpand: true,
            isCollapsed: false,
          },
          // @ts-ignore FIXME: strictNullChecks
          resolve,
        ),
      );
    }
  }

  /** Announce to screen readers when the timer is at a predetermined value. */
  maybeAnnounceTime() {
    if (
      announceTimeRemainingMins.includes(this.getTotalMinutesRemaining()) &&
      this.getSecondsRemaining() === 0
    ) {
      say(`${this.getTotalMinutesRemaining()} minutes remaining.`);
    }
  }

  getTotalMsRemaining(): number {
    // @ts-ignore FIXME: strictNullChecks
    return this.props.endTime - this.props.getServerTime();
  }

  /** Returns a whole number of the total time in seconds. */
  getTotalSecondsRemaining(): number {
    return Math.floor(this.getTotalMsRemaining() / 1000);
  }

  /** Returns a whole number of the total time in minutes. */
  getTotalMinutesRemaining(): number {
    return Math.floor(this.getTotalSecondsRemaining() / 60);
  }

  /**
   * Returns a whole number of the seconds in the current minute, between 0 and
   * 60.
   */
  getSecondsRemaining(): number {
    return this.getTotalSecondsRemaining() % 60;
  }

  getTimeRemainingStr(): string {
    const {endTime, getServerTime} = this.props;
    if (!endTime || endTime <= getServerTime()) return "";
    const minutesRemaining = this.getTotalMinutesRemaining();
    const secondsRemaining = this.getSecondsRemaining();
    if (this.shouldShowSeconds()) {
      if (minutesRemaining < 1) {
        return `${secondsRemaining} s`;
      }
      return `${minutesRemaining} m ${secondsRemaining} s`;
    }
    return `${minutesRemaining} min`;
  }

  renderLabel() {
    const {endTime, label, classes, started, getServerTime} = this.props;
    if (!started) return "Time has not begun";
    // @ts-ignore FIXME: strictNullChecks
    if (endTime <= getServerTime()) return null;
    return (
      <span>
        {label}:&nbsp; {/* @ts-ignore FIXME: strictNullChecks*/}
        <span className={classes.timeStr}>{this.state.timeRemainingStr}</span>
      </span>
    );
  }

  render() {
    const {classes} = this.props;
    const {isCollapsed} = this.state;
    const label = isCollapsed ? <TimerIcon /> : this.renderLabel();
    const title = this.state.isTransitioning
      ? ""
      : this.state.isCollapsed
      ? "Show timer"
      : "Hide timer";
    if (!label) return null;
    return (
      <Tooltip title={title}>
        <Button
          variant="outlined"
          // @ts-ignore FIXME: strictNullChecks
          className={classNames(classes.timer, {
            // @ts-ignore FIXME: strictNullChecks
            [classes.timerCollapsed]: isCollapsed,
          })}
          onClick={this.onClick}
          size="small"
        >
          {label}
        </Button>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(Timer);
