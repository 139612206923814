import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import classNames from "classnames";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LogAction, logFEInfo} from "../../actions/serverInfoActions";
import CenteredScreenContent from "../../components/CenteredScreenContent";
import {withInterview} from "../../components/InterviewLoader";
import MinimalHeader from "../../components/MinimalHeader";
import {
  interviewGuidePlatformUrl,
  interviewHubUrl,
} from "../../helpers/urls/routerUrls";
import timerImage from "../../img/interview-center/timers-and-time-reminders.png";
import {Interview} from "../../reducers/types";
import {AccordionItemInterface, AccordionItems} from "./components/Accordion";
import {useStyles} from "./InterviewHubScreen.styles";

interface Props extends RouteComponentProps {
  interview: Interview;
}

const InterviewHubFAQScreen = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logFEInfo(LogAction.INTERVIEW_HUB_FAQ_PAGE_VIEWED));
  }, []);

  const planningYourInterviewData: AccordionItemInterface[] = [
    {
      title: "What do I need to take this interview?",
      content: (
        <>
          <Typography>You will need:</Typography>
          <ul>
            <li>A Gmail or Google Workspace account</li>
            <li>Access to a computer</li>
            <li>Reliable internet connection</li>
            <li>
              For Part 2, for most languages, you will have the option to either
              work in our online editor (Google Cloud Shell) or download a zip
              file containing the code base to work in an IDE of your choosing.
              You will have the opportunity to test out these options before you
              begin your interview.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Should I prepare for the Byteboard Interview?",
      content: (
        <>
          <Typography paragraph={true}>
            The Byteboard interview is designed to let you showcase a wide
            variety of skills. During the interview you will be asked to provide
            your reasoning on how a team should move forward with a technical
            project, navigate a small codebase, and implement functions to make
            the feature work. Be comfortable coding in your language of choosing
            (including commenting best practices, code syntax, common data
            structures).
          </Typography>
          <Typography>
            For more information on how to prepare, who to contact if you
            experience technical difficulties, how to request accommodations if
            you have a disability, and more, please carefully read through the
            topics in this guide.
          </Typography>
        </>
      ),
    },
    {
      title: "When can I take this interview?",
      content: (
        <>
          <Typography>
            The Byteboard interview can be taken at any time of day, whenever is
            most convenient for you.
          </Typography>
        </>
      ),
    },
    {
      title:
        "What if I need to make an accommodation request for my interview?",
      content: (
        <>
          <Typography>
            Reach out to your recruiter with your request. We can accommodate
            requests sent to us by your recruiter. This includes requesting
            additional time (e.g. 2x, 1.5x, etc.).
          </Typography>
        </>
      ),
    },
  ];

  const interviewFormatData: AccordionItemInterface[] = [
    {
      title: "What if I run out of time to finish?",
      content: (
        <>
          <Typography paragraph={true}>
            The Byteboard interview is designed to be open-ended, so there will
            always be parts you won't be able to get to. The duration on each
            part is deliberately to be able to showcase a little bit of your
            skills in a variety of tasks. So focus on the tasks that showcase
            your strengths.
          </Typography>
          <Typography>
            At the end of the interview, you will have the chance to share a
            short description of what you would do if you had more time to
            tackle the tasks.
          </Typography>
        </>
      ),
    },
    {
      title: "What coding languages are offered?",
      content: (
        <>
          <Typography paragraph={true}>
            <strong>Software Engineering (Backend):</strong> Java, Python, PHP,
            Ruby, Rust, C++, JavaScript (Node.js), TypeScript, C#, Go, Kotlin
          </Typography>
          <Typography paragraph={true}>
            <strong>Staff Software Engineering (Backend):</strong> Python,
            JavaScript, TypeScript
          </Typography>
          <Typography paragraph={true}>
            <strong>Web:</strong> Vanilla JS, TypeScript, React + JS, React + TS
          </Typography>
          <Typography paragraph={true}>
            <strong>Mobile:</strong> Swift (iOS), Kotlin (Android), Flutter
          </Typography>
          <Typography paragraph={true}>
            <strong>Data Eng:</strong> Java, Python
          </Typography>
          <Typography paragraph={true}>
            <strong>Data Analysis:</strong> Python, R, SQL
          </Typography>
          <Typography paragraph={true}>
            <strong>Security Eng:</strong> Python, JavaScript
          </Typography>
          <Typography>
            If you are not comfortable taking the interview in one of these
            languages, or if you would prefer to take an interview in a
            different domain (e.g. backend, web, mobile), please let your
            recruiter know. Note that some companies you might interview with
            might not provide all the languages listed above.
          </Typography>
        </>
      ),
    },
    {
      title: "What platform does the interview use? Will I need my own IDE?",
      content: (
        <>
          <Typography paragraph={true}>
            For Part 1, you will be working on a document similar to a Google
            Document when shared by multiple people.
          </Typography>
          <Typography paragraph={true}>
            For Part 2, you will have the option to either work in our online
            editor CloudShell or download a zip file containing the code base to
            work in an IDE of your choosing. You will have the opportunity to
            test out these options before you begin your interview.
          </Typography>
          <Typography>
            We recommend you check out the{" "}
            <Link href={interviewGuidePlatformUrl(props.interview.id)}>
              Interview Platform
            </Link>{" "}
            section of the guide.
          </Typography>
        </>
      ),
    },
    {
      title: "Can I use external resources during the interview?",
      content: (
        <>
          <Typography>
            You can use the internet or books as a resource as you work through
            the interview, particularly if you encounter terminology or syntax
            that you aren't familiar with or would like to look at built-in
            documentation for your language. However, all submissions must be
            original and personally authored. Submissions flagged with cheating,
            including copying code from the internet, will be disqualified.
          </Typography>
        </>
      ),
    },
  ];

  const interviewDesignData: AccordionItemInterface[] = [
    {
      title: "Why is the interview timed?",
      content: (
        <>
          <Typography paragraph={true}>
            We know the process of interviewing is hard and time-consuming. You
            might be trying to find time to interview while balancing other
            obligations or work schedules, and we appreciate the time you are
            dedicating to take the Byteboard interview. We also want to make
            sure that you are able to demonstrate as much of your skill set as
            candidates who might have fewer obligations.
          </Typography>
          <Typography paragraph={true}>
            Timing the interview ensures that every candidate has a consistent
            and fair interview. The duration of each part of the Byteboard
            interview is set such that you have enough time to showcase your
            strength in a range of skills.
          </Typography>
          <Typography>
            Each part of the interview is purposefully designed to be open-ended
            just like tasks and responsibilities would be on a real job. So
            there will always be parts you won't be able to get to. Try not to
            focus on finishing the interview and focus on doing your best on
            tasks where you get to show off your skills.
          </Typography>
          <div
            className={classNames(
              classes.callout,
              classes.calloutWithIcon,
              classes.calloutWithInline,
            )}
          >
            <TipsAndUpdatesOutlinedIcon />
            <Typography>
              <strong>Remember: </strong>You are not expected to complete high
              quality work that is ready for production. Leave as many comments
              and pseudocode as you would like to describe your thinking and
              showcase how you approach tasks as an engineer on the job.
            </Typography>
          </div>
        </>
      ),
    },
    {
      title: "What time reminders will I have during the interview?",
      content: (
        <>
          <Typography>
            A visual timer is present in the top right corner of the screen on
            your interview platform. You may click to toggle showing/hiding the
            timer at any time. Regardless of whether your timer is hidden, in
            addition to the visual timer there will be an audio reminder to
            signal when you have 10 minutes left on the interview. Check the
            volume of your system to ensure you receive these alerts.
          </Typography>
          <img src={timerImage} alt="Indicating where timer is on the screen" />
        </>
      ),
    },
    {
      title:
        "What are technical design docs, and how are they used on the job?",
      content: (
        <>
          <Typography paragraph={true}>
            Technical design documents are a common tool for scoping projects on
            software development teams. They describe the goals, plans, and
            context for a project, as well as any relevant considerations that
            might impact project work. They are helpful for understanding
            potential problems early on in a project because they communicate
            the problems and potential solutions at a higher level than code.
          </Typography>
          <Typography>
            At this stage of the project lifecycle, changes to the goals, scope
            and technical design are bound to evolve, so the purpose of this
            document is to be a single place where the team can discuss and
            document these moving parts. Because these documents are informal
            and evolving, they don't follow a strict guideline and can cover
            topics such as Context, Scope, Goals and Non-Goals, System Diagrams,
            API Design, and more.
          </Typography>
        </>
      ),
    },
    {
      title: "What makes the Byteboard interview different?",
      content: (
        <>
          <Typography paragraph={true}>
            The Byteboard interview was designed by engineers who know how
            stressful interviewing is. They found that candidates spent most of
            their interviewing time working out obscure algorithms on a
            whiteboard instead of solving real day-to-day problems like most
            engineers do on their job. More importantly, they found that
            candidates spend hundreds of hours studying for interviews because
            the questions asked do not map to the skill set used on the job.
          </Typography>
          <Typography>
            We believe the best way to assess real on-the-job skills is to
            replicate a day-in-the-life experience, so you can demonstrate what
            you can do when handed real work tasks and responsibilities. The
            various parts of the Byteboard interview replicate tasks you might
            be expected to complete on a small contained project. Work samples
            like the Byteboard interview have shown benefits for promoting
            diversity in the hiring process, predicting future performance, and
            most importantly creating an interview experience that is relatable
            and enjoyable.
          </Typography>
        </>
      ),
    },
    {
      title: "How does the Byteboard interview minimize bias?",
      content: (
        <>
          <Typography>
            The entire interview is self proctored, so you can forget all about
            how it feels to be watched during an interview. After you complete
            the interview, all of the materials are completely anonymized before
            being graded and reviewed by trained and calibrated graders. Each
            part of the interview is also reviewed separately by individual
            graders to further reduce familiarity or recency bias. Each grader
            is guided by a set of structured questions and rubrics to ensure
            that the results are fair and equitable across all candidates. The
            report that we then generate is designed to focus on your strengths
            and achievements in the interview.
          </Typography>
        </>
      ),
    },
  ];

  const onBack = () => {
    props.history.replace(interviewHubUrl(props.interview.id));
  };

  return (
    <React.Fragment>
      <MinimalHeader title="Frequently Asked Questions" onBack={onBack} />
      <main className={classes.content}>
        <CenteredScreenContent size="normal">
          <main className={classNames(classes.root, classes.withAccordion)}>
            <Typography variant="h2">Planning Your Interview</Typography>
            <AccordionItems data={planningYourInterviewData} />
            <Typography variant="h2">Interview Format</Typography>
            <AccordionItems data={interviewFormatData} />
            <Typography variant="h2">Byteboard Interview design</Typography>
            <AccordionItems data={interviewDesignData} />
          </main>
        </CenteredScreenContent>
      </main>
    </React.Fragment>
  );
};

export default withInterview([], {ignoreGlobalRules: ["terms", "getStarted"]})(
  withRouter(InterviewHubFAQScreen),
);
