import * as React from "react";
import * as f from "../util/fetch";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AppState} from "../reducers/types";
import {ErrorAction} from "./actionTypes";
import {ExternalLink} from "../components/Link";
import {setErrorMessage} from "./errorActions";
import {toast} from "./toastActions";

/** Generic Async Action type */
export type AsyncAction<T, A extends {type: string}> = ThunkAction<
  Promise<T>,
  AppState,
  Record<string, never>,
  A
>;
/** Generic Dispatch type for a generic Async Action */
export type Dispatch<A extends {type: string}> = ThunkDispatch<
  AppState,
  Record<string, never>,
  A
>;

export type ErrorAsyncAction<T = any> = AsyncAction<T, ErrorAction>;
type ErrorDispatch = Dispatch<ErrorAction>;

export const errorTips: React.ReactNode[] = [
  <span>
    Sometimes browser plugins can interfere with our platform. Disabling your
    plugins temporarily may help. Additionally, it might be worth checking if
    you use any systems that may interfere with .dev domains (ex.{" "}
    <ExternalLink
      href="https://andycroll.com/ruby/clean-up-broken-dev-domains-after-puma-dns_probe_finished_nxdomain/"
      target="_blank"
    >
      puma-dev
    </ExternalLink>
    ,{" "}
    <ExternalLink href="http://pow.cx/manual.html#section_3.1" target="_blank">
      pow
    </ExternalLink>
    ).
  </span>,
];

interface FetchOptions extends f.FetchOptions {
  errorMessage?: string;
  errorType?: "full" | "toast" | "silent";
  handleError?: boolean;
}
type GetOptions = f.GetOptions & FetchOptions;
type PostOptions = f.PostOptions & FetchOptions;
type PutOptions = f.PutOptions & FetchOptions;
type DeleteOptions = f.DeleteOptions & FetchOptions;

const GENERIC_ERROR = "An unknown error occurred";
const UNABLE_TO_PERFORM_ACTION = "Unable to perform the requested action.";

const defaultGetOptions: GetOptions = {
  token: "",
  errorMessage: UNABLE_TO_PERFORM_ACTION,
  errorType: "full",
  handleError: true,
};
const defaultPostOptions: PostOptions = {
  token: "",
  contentType: "application/json",
  noContentType: false,
  errorMessage: UNABLE_TO_PERFORM_ACTION,
  errorType: "full",
  handleError: true,
};
const defaultPutOptions: PutOptions = {
  token: "",
  errorMessage: UNABLE_TO_PERFORM_ACTION,
  errorType: "full",
  handleError: true,
};
const defaultDeleteOptions: DeleteOptions = {
  token: "",
  errorMessage: UNABLE_TO_PERFORM_ACTION,
  errorType: "full",
  handleError: true,
};

export function get<T = any>(
  url: string,
  options: GetOptions = defaultGetOptions,
): ErrorAsyncAction<T> {
  return async (dispatch: ErrorDispatch): Promise<any> => {
    const optsWithDefaults = {...defaultGetOptions, ...options};
    try {
      const response = await f.get(url, optsWithDefaults);
      return await response.json();
    } catch (error) {
      // @ts-ignore FIXME: strictNullChecks
      dispatch(handleError(error, optsWithDefaults));
      return {
        failed: true,
        error,
      };
    }
  };
}

export function post(
  url: string,
  data: {},
  options: PostOptions = defaultPostOptions,
): ErrorAsyncAction {
  return async (dispatch: ErrorDispatch): Promise<any> => {
    const optsWithDefaults = {...defaultPostOptions, ...options};
    try {
      const response = await f.post(url, data, optsWithDefaults);
      return await response.json();
    } catch (error) {
      // @ts-ignore FIXME: strictNullChecks
      dispatch(handleError(error, optsWithDefaults));
    }
  };
}

export function put(
  url: string,
  data: {},
  options: PutOptions = defaultPutOptions,
): ErrorAsyncAction {
  return async (dispatch: ErrorDispatch): Promise<any> => {
    const optsWithDefaults = {...defaultPutOptions, ...options};
    try {
      const response = await f.put(url, data, optsWithDefaults);
      return await response.json();
    } catch (error) {
      // @ts-ignore FIXME: strictNullChecks
      dispatch(handleError(error, optsWithDefaults));
    }
  };
}

export function deleteRequest(
  url: string,
  options: DeleteOptions = defaultDeleteOptions,
): ErrorAsyncAction {
  return async (dispatch: ErrorDispatch): Promise<void> => {
    const optsWithDefaults = {...defaultDeleteOptions, ...options};
    try {
      const response = await f.deleteRequest(url, optsWithDefaults);
      return await response.json();
    } catch (error) {
      // @ts-ignore FIXME: strictNullChecks
      dispatch(handleError(error, optsWithDefaults));
    }
  };
}

function handleError(error: Error, opts: FetchOptions): ErrorAsyncAction {
  return async (dispatch: ErrorDispatch): Promise<void> => {
    if (!opts.handleError) throw error;
    console.error(error);
    let message = GENERIC_ERROR;
    if (opts.errorMessage) {
      message = opts.errorMessage;
    } else if (
      error instanceof f.PermissionError ||
      error instanceof f.NetworkError
    ) {
      message = error.message;
    }
    if (opts.errorType === "toast") {
      dispatch(toast(message));
    } else {
      dispatch(setErrorMessage(message));
    }
  };
}
