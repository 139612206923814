import {Interview, Language, M2Type, ModuleId} from "../../reducers/types";
import {isLocalhost, isStaging} from "../../util/env";
export const interviewPlatformTab = "#interview-platform";
export const technicalReasoningTab = "#technical-reasoning-overview";
export const codingExerciseTab = "#coding-exercise-overview";
export const accessibilityTab = "#accessibility";
export const calmAnxietyTab = "#calm-anxiety";
export const breatherTab = "#breather";

const WEB_DOMAIN_NAME = isStaging()
  ? "code-staging.byteboard.dev"
  : isLocalhost()
  ? "code-qa.byteboard.dev"
  : "code.byteboard.dev";

function appendCurrentUrlQuery(url: string): string {
  // Take the query string from the current url in the browser and append it to
  // the end of the provided url.
  const urlpath = url + window.location.search;
  return url + window.location.search;
}

// Client URLs
export function termsUrl(atsId: string) {
  return appendCurrentUrlQuery(`/terms/${atsId}`);
}

export function bbhcUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/bbhc/${atsId}`);
}

export function getStartedUrl(atsId: string) {
  return appendCurrentUrlQuery(`/get-started/${atsId}`);
}

export function testerUrl(atsId: string, m2Type?: M2Type, language?: Language) {
  const url = `/tester/${atsId}/${m2Type}/${language}`;
  return appendCurrentUrlQuery(url);
}

export function overviewUrl(atsId: string) {
  return appendCurrentUrlQuery(`/overview/${atsId}`);
}

export function interviewModuleUrl(
  interview: Interview,
  moduleId: string,
): string {
  let url = "";
  if (moduleId === "m1") {
    url = `/part1/${interview.id}`;
  } else if (moduleId === "m2") {
    url = `/part2/${interview.id}`;
  }
  if (!url) {
    throw new Error(`Unable to generate url for module with id="${moduleId}"`);
  }
  return appendCurrentUrlQuery(url);
}

export function surveyUrl(atsId: string) {
  return appendCurrentUrlQuery(`/survey/${atsId}`);
}

export function liveCodingSurveyUrl(atsId: string) {
  return appendCurrentUrlQuery(`/codecollab/survey/${atsId}`);
}

export function thankYouUrl(atsId: string) {
  return appendCurrentUrlQuery(`/thank-you/${atsId}`);
}

export function liveCodingThankYouUrl(liveCodingInterviewId: string) {
  return appendCurrentUrlQuery(
    `/codecollab/thank-you/${liveCodingInterviewId}`,
  );
}

export function docViewUrl(atsId: string, moduleId: ModuleId) {
  return appendCurrentUrlQuery(`/doc/${atsId}/${moduleId}`);
}

export function interviewUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/${atsId}`);
}

export function onsiteUrl(interviewId: string): string {
  return `https://code-server-${interviewId}.${WEB_DOMAIN_NAME}/?folder=/home/coder/interview`;
}

export function onsiteWebUrl(interviewId: string): string {
  return `https://code-server-${interviewId}.8080.${WEB_DOMAIN_NAME}`;
}

export function interviewHubUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/${atsId}`);
}

export function interviewGuideUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/guide/${atsId}`);
}

export function interviewGuidePlatformUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/guide/${atsId}${interviewPlatformTab}`);
}

export function interviewGuideAccessibilityUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/guide/${atsId}${accessibilityTab}`);
}

export function interviewGuideBreatherUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/guide/${atsId}${breatherTab}`);
}

export function interviewFAQUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/faq/${atsId}`);
}

export function interviewHelpUrl(atsId: string): string {
  return appendCurrentUrlQuery(`/hub/help/${atsId}`);
}
