import * as React from "react";
import {brand} from "../branding";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

type Props = StyledComponentProps;

const styles = () =>
  createStyles({
    "@keyframes embedCheckBounce": {
      from: {
        opacity: 0,
        transform: "scale(0.5)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      to: {
        opacity: 1,
        transform: "scale(1)",
      },
    },
    checkOutlineContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
    },
    checkOutlineIcon: {
      animation: "$embedCheckBounce 0.5s 0.2s forwards",
      height: 36,
      opacity: 0,
      width: 36,
    },
  });

class CheckIcon extends React.Component<Props> {
  render(): React.ReactNode {
    const {classes} = this.props;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.checkOutlineContainer}>
        <svg
          // @ts-ignore FIXME: strictNullChecks
          className={classes.checkOutlineIcon}
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 38.3429L30.5652 48L52 22"
            stroke={brand.primaryColor.main}
            strokeWidth="7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="36"
            cy="36"
            r="32"
            stroke={brand.primaryColor.main}
            strokeWidth="7"
          />
        </svg>
      </div>
    );
  }
}

export default withStyles(styles)(CheckIcon);
