import * as React from "react";
import HelpDialogButton from "./HelpDialogButton";
import Note from "./Note";
import UnorderedList from "./UnorderedList";
import classNames from "classnames";
import {ExternalLink} from "./Link";
import {Interview} from "../reducers/types";
import {ModuleId} from "../reducers/types";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {compact, isUndefined} from "lodash";
import {createStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  getModuleType,
  isCodeModuleType,
  isDocModuleType,
} from "../helpers/interview";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import autobind from "autobind-decorator";

interface Props extends StyledComponentProps, RouteComponentProps {
  interview: Interview;
  moduleId: ModuleId;
  className?: string;
  withoutNoteFormatting?: boolean;
  hideHelpDialogTip?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 0,
      marginTop: 15,
    },
    tipsList: {
      marginBottom: 0,
      paddingInlineStart: "16px",
      // Remove some of the normal vertical space in the list so the continue
      // button isn't too far down the page.
      "& p, & ul, & li": {
        marginBottom: 6,
      },
    },
    miniHelpButton: {
      marginBottom: -8,
      marginTop: -8,
      transform: "scale(0.7)",
    },
  });

class InterviewOverviewTips extends React.Component<Props> {
  renderTips(): React.ReactNode {
    const {interview, moduleId} = this.props;
    const moduleType = getModuleType(interview, moduleId);
    // @ts-ignore FIXME: strictNullChecks
    if (isCodeModuleType(moduleType)) {
      return this.renderCodeTips();
      // @ts-ignore FIXME: strictNullChecks
    } else if (isDocModuleType(moduleType)) {
      return this.renderDocTips();
    }
    return null;
  }

  @autobind
  getWalkthroughLink() {
    if (this.props.interview.enableBBDoc) {
      return "https://drive.google.com/file/d/1HefDc4KY7nS7AQOEXobuIhHAkJRYRk8s/preview";
    } else {
      return "https://drive.google.com/file/d/1LMxAREUf78fjBF0zS-RxE8cCAN-FIlyF/preview";
    }
  }

  renderDocTips(): React.ReactNode {
    const {classes, interview, hideHelpDialogTip} = this.props;
    return (
      <UnorderedList
        // @ts-ignore FIXME: strictNullChecks
        className={classes.tipsList}
        items={compact([
          <Typography>
            Please watch a walkthrough of the Byteboard Document UI{" "}
            <ExternalLink href={this.getWalkthroughLink()} target="_blank">
              here
            </ExternalLink>
            .
          </Typography>,
          <>
            <Typography paragraph={true}>
              Your interview will be evaluated by a real person. To ensure they
              understand your thought process:
            </Typography>
            <UnorderedList
              items={[
                <Typography>Fully state your assumptions.</Typography>,

                <Typography>
                  Add comments in the document for any clarifications or
                  questions you have about the design.
                </Typography>,
                <Typography>
                  Do not spend too much time responding to any one question.
                </Typography>,
                <Typography>
                  Complete the exercise to the best of your ability and come
                  back to expand on answers if you have more time.
                </Typography>,
              ]}
            />
          </>,
          <Typography>
            If there’s a concept you are not familiar with, Google it!
          </Typography>,
          hideHelpDialogTip ? null : (
            <Typography>
              These tips will always be available if you click the{" "}
              {/* @ts-ignore FIXME: strictNullChecks */}
              <HelpDialogButton className={classes.miniHelpButton} /> at the top
              of your screen.
            </Typography>
          ),
        ])}
      />
    );
  }

  renderCodeTips(): React.ReactNode {
    const {classes, hideHelpDialogTip} = this.props;
    return (
      <UnorderedList
        // @ts-ignore FIXME: strictNullChecks
        className={classes.tipsList}
        items={compact([
          <Typography>
            Spend some time reading through the codebase and building a mental
            model of how the code fits together.
          </Typography>,
          <>
            <Typography paragraph={true}>
              Run your code often to detect bugs early on.
            </Typography>
            <UnorderedList
              items={[
                <Typography>Pay attention to edge cases.</Typography>,
                <Typography>
                  Try to submit code that does not crash.
                </Typography>,
                <Typography>
                  Consider adding test cases to catch edge cases or a future
                  regression.
                </Typography>,
              ]}
            />
          </>,
          <>
            <Typography paragraph={true}>
              Your interview will be evaluated by a real engineer meaning all of
              your progress (including comments!) will be taken into account. To
              ensure they understand your thought process:
            </Typography>
            <UnorderedList
              items={[
                <Typography>
                  Always state your assumptions, questions, or thoughts in clear
                  comments.
                </Typography>,
                <Typography>
                  If you do not finish all the tasks or have additional ideas
                  for optimizations, describe what you would do with more time
                  by adding comments.
                </Typography>,
              ]}
            />
          </>,
          <Typography>
            If you forget syntax or encounter a concept you are not familiar
            with, Google it!
          </Typography>,
          hideHelpDialogTip ? null : (
            <Typography>
              These tips will always be available if you click the{" "}
              {/* @ts-ignore FIXME: strictNullChecks */}
              <HelpDialogButton className={classes.miniHelpButton} /> at the top
              of your screen.
            </Typography>
          ),
        ])}
      />
    );
  }

  render(): React.ReactNode {
    const {classes, className, withoutNoteFormatting} = this.props;
    const tips = this.renderTips();
    if (!tips || isUndefined(this.props.interview.enableBBDoc)) return null;
    if (withoutNoteFormatting) return tips;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <Note className={classNames(classes.root, className)}>
        <Typography paragraph={true}>
          <strong>Tips:</strong>
        </Typography>
        {tips}
      </Note>
    );
  }
}

export default withStyles(styles)(withRouter(InterviewOverviewTips));
