import {
  StyledComponentProps,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import type {Theme} from "@material-ui/core/styles/createTheme";
import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {activeScreenNotFound, getActiveScreen} from "../Routes";
import {fetchFlags} from "../actions/flagsActions";
import {fetchServerTimeOffset} from "../actions/serverInfoActions";
import {fetchUser} from "../actions/userActions";
import Spinner from "../components/Spinner";
import Toast from "../components/Toast";
import getAtsIdFromUrl from "../helpers/urls/getAtsIdFromUrl";
import {AppError, AppState, Flags, ServerInfo, User} from "../reducers/types";
import ErrorScreen from "../screens/ErrorScreen";
import PageNotFoundScreen from "../screens/PageNotFoundScreen";
import SignInScreen from "../screens/SignInScreen";
import Header from "./Header";

interface Props extends StyledComponentProps, RouteComponentProps {
  error: AppError;
  fetchUser: () => any;
  fetchFlags: () => any;
  fetchServerTimeOffset: () => any;
  flags: Flags;
  serverInfo: ServerInfo;
  user: User;
}

const styles = (theme: Theme) =>
  createStyles({
    shell: {
      color: "#505050",
      display: "flex",
      flexDirection: "column",
      fontFamily: "roboto, arial",
      fontSize: "14px",
      height: "100%",
      lineHeight: "1.5em",
      overflow: "hidden",
    },
    content: {
      flex: 1,
      position: "relative",
    },
  });

class Shell extends React.Component<Props> {
  async componentDidMount() {
    if (activeScreenNotFound() || this.isApplicantScreen()) return;
    await Promise.all([
      this.props.fetchUser(),
      this.props.fetchFlags(),
      this.props.fetchServerTimeOffset(),
    ]);
  }

  isUserSet(): boolean {
    return Boolean(this.props.user.id);
  }

  isInitialized(): boolean {
    const {flags, serverInfo} = this.props;
    return Boolean(
      this.isUserSet() &&
        Object.keys(flags).length &&
        serverInfo.serverTimeOffset,
    );
  }

  isAccommodationScreen(): boolean {
    return getActiveScreen()?.name === "accommodation";
  }

  isApplicantScreen(): boolean {
    return getActiveScreen()?.name === "applicant";
  }

  hideNavBar(): boolean {
    return getActiveScreen()?.hideNavBar === true;
  }

  isUserNotSignedIn(): boolean {
    return this.props.error.userNotSignedIn && !this.isUserSet();
  }

  renderHeader(): React.ReactNode {
    if (
      !this.isInitialized() ||
      this.isUserNotSignedIn() ||
      this.hideNavBar()
    ) {
      return null;
    }

    return <Header />;
  }

  renderContent(): React.ReactNode {
    const {children, error} = this.props;
    if (activeScreenNotFound()) {
      return <PageNotFoundScreen />;
    } else if (error.errorMessage) {
      return <ErrorScreen />;
    } else if (this.isAccommodationScreen() || this.isApplicantScreen()) {
      // The accommodation screen is a special case because the user is
      // not signed in but we want to show something other than the sign-in
      // page.
    } else if (this.isUserNotSignedIn()) {
      return <SignInScreen atsId={getAtsIdFromUrl()} />;
    } else if (!this.isInitialized()) {
      return <Spinner variant="logo" />;
    }
    return children;
  }

  render(): React.ReactNode {
    const {classes} = this.props;
    if (this.hideNavBar()) {
      return (
        // @ts-ignore FIXME: strictNullChecks
        <div className={classes.shell}>
          {this.renderContent()}
          <Toast />
        </div>
      );
    } else {
      return (
        // @ts-ignore FIXME: strictNullChecks
        <div className={classes.shell}>
          {this.renderHeader()}
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <main className={classes.content}>{this.renderContent()}</main>
          <Toast />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error,
  flags: state.flags,
  serverInfo: state.serverInfo,
  user: state.user,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, AnyAction>) => ({
  // @ts-ignore FIXME: strictNullChecks
  fetchUser: () => dispatch(fetchUser()),
  // @ts-ignore FIXME: strictNullChecks
  fetchFlags: () => dispatch(fetchFlags()),
  // @ts-ignore FIXME: strictNullChecks
  fetchServerTimeOffset: () => dispatch(fetchServerTimeOffset()),
});

// Need `withRouter` to fix a weird react-router bug.
// See https://github.com/ReactTraining/react-router/issues/4671
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Shell)),
);
