import {AssessmentConfigAction} from "../actions/actionTypes";
import {AssessmentConfig} from "../reducers/types";

const defaultState: AssessmentConfig = {
  isLoading: false,
  isLoaded: false,
  config: {
    taskList: "",
  },
};

export function assessmentConfig(
  state: AssessmentConfig = defaultState,
  action: AssessmentConfigAction,
): AssessmentConfig {
  switch (action.type) {
    case "ASSESSMENT_CONFIG.IS_LOADING": {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case "ASSESSMENT_CONFIG.IS_LOADED": {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    case "ASSESSMENT_CONFIG.SET_ASSESSMENT_CONFIG": {
      return {
        ...state,
        config: action.value,
      };
    }
    default:
      return state;
  }
}
