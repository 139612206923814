import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  StyledComponentProps,
  Theme,
} from "@material-ui/core/styles";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {gutterWidth} from "./Header";
import HelpDialog from "./HelpDialog";
import HelpDialogButton from "./HelpDialogButton";
import {headerHeight} from "./Theme";

interface Props extends StyledComponentProps {
  title: string;
  onBack: () => any;
}

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes header": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  header: {
    alignItems: "center",
    animation: "$header 0.2s forwards",
    borderBottom: "solid 1px #ddd",
    display: "flex",
    flexShrink: 0,
    height: headerHeight,
    opacity: 0,
    paddingLeft: gutterWidth,
    paddingRight: gutterWidth,
  },
  logo: {
    height: 35,
    marginRight: 30,
  },
  right: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
  },
  helpButton: {
    marginLeft: 15,
  },
  title: {
    fontFamily: "Roboto, Arial",
    fontSize: 25,
    margin: 0,
    fontWeight: 700,
    marginLeft: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
}));

const MinimalHeader = (props: Props) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <IconButton onClick={props.onBack}>
        <ArrowBack />
      </IconButton>
      <Typography component="h1" className={classes.title}>
        {props.title}
      </Typography>
      <nav className={classes.right}>
        <HelpDialogButton className={classes.helpButton} />
        <HelpDialog />
      </nav>
    </header>
  );
};

export default MinimalHeader;
