import React, {useEffect} from "react";
import Spinner from "../components/Spinner";
import {BBDoc} from "../components/BBDoc";
import {Interview, AppState, ModuleId} from "../reducers/types";
import {withInterview} from "../components/InterviewLoader";
import Typography from "@material-ui/core/Typography";
import {getServerTime} from "../util/time";
import {isBBDocEnabled, isBBDocForModuleViewable} from "../helpers/bbdoc";
import {loadBBDoc} from "../actions/bbdocActions";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useDispatch} from "react-redux";

interface Props {
  atsId: string;
  moduleId: ModuleId;
  interview: Interview;
}

const useStyles = makeStyles(
  () => ({
    noCanView: {
      margin: "100px 30px",
      textAlign: "center",
    },
  }),
  {
    name: "BBDocViewScreen",
  },
);

const BBDocViewScreen: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  const dispatch = useDispatch();

  const {flags, bbdocState, serverInfo} = useSelector(
    (state: AppState) => state,
  );
  const {isLoading, isLoaded, interviewDoc} = bbdocState.current;
  const {serverTimeOffset} = serverInfo;
  const serverTime = getServerTime(serverTimeOffset);

  const bbdocViewable = isBBDocForModuleViewable(
    props.interview,
    props.moduleId,
    serverTime,
  );
  const bbdocEnabled = isBBDocEnabled(props.interview, flags);

  useEffect(() => {
    if (bbdocViewable && bbdocEnabled && !isLoading && !isLoaded) {
      dispatch(loadBBDoc(props.moduleId));
    }
  });

  if (!bbdocViewable) {
    return (
      <Typography className={classes.noCanView}>
        This document is no longer active. You may close this window&nbsp;now.
      </Typography>
    );
  }

  return isLoaded ? (
    <BBDoc
      readonly={true}
      // @ts-ignore FIXME: strictNullChecks
      interviewDoc={interviewDoc}
      candidateName={props.interview.candidateName}
    />
  ) : (
    <Spinner />
  );
};

export default withInterview([])(BBDocViewScreen);
