export enum Gender {
  man = "man",
  woman = "woman",
  nonBinary = "nonBinary",
  noneApply = "noneApply",
  preferNotToSay = "preferNotToSay",
}

export enum Race {
  asian = "asian",
  black = "black",
  latinx = "latinx",
  indigenous = "indigenous",
  middleEastern = "middleEastern",
  white = "white",
}

export type FirestoreTimestamp = {
  _seconds: number;
  _nanoseconds: number;
};
