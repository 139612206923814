import {AppState, Interview, User} from "../reducers/types";
import {AnyAction} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {saveTerms as saveInterviewTerms} from "./interviewActions";

export function saveTerms(
  interview: Interview,
  user: User,
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  // @ts-ignore FIXME: No overload matches this call
  return async (
    dispatch: ThunkDispatch<AppState, null, AnyAction>,
  ): Promise<void> => {
    // Wait before changing the state so the button doesn't flash.
    await new Promise((resolve) => setTimeout(resolve, 100));
    // @ts-ignore FIXME: strictNullChecks
    await dispatch(saveInterviewTerms(interview, user));
  };
}
