import {AppState} from "../reducers/types";
import {AccommodationScreenAction, ErrorAction} from "./actionTypes";
import {ThunkDispatch, ThunkAction} from "redux-thunk";
import {helpEmailUrl} from "../helpers/urls/interviewUrls";
import {post} from "../util/fetch";
import {setErrorMessage} from "./errorActions";

type Action = AccommodationScreenAction | ErrorAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

export function isSubmitting(): AccommodationScreenAction {
  return {
    type: "ACCOMMODATION_SCREEN.IS_SUBMITTING",
  };
}

export function didSubmit(): AccommodationScreenAction {
  return {
    type: "ACCOMMODATION_SCREEN.DID_SUBMIT",
  };
}

export function toggleScreenReader(): AccommodationScreenAction {
  return {
    type: "ACCOMMODATION_SCREEN.TOGGLE_SCREEN_READER",
  };
}

export function submit(text: string): AsyncAction {
  // @ts-ignore FIXME: strictNullChecks
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview, accommodationScreenState} = getState();
      const {screenReader} = accommodationScreenState;
      const message =
        (screenReader ? "I require the usage of a screen reader. " : "") +
        `I require the following accommodation: ${text} (ATS id: ${interview.id})`;
      dispatch(isSubmitting());
      const data = {
        atsId: interview.id,
        message,
      };
      const response = await post(helpEmailUrl(), data);
      const {error} = await response.json();
      if (error) {
        dispatch(setErrorMessage("Failed to submit accommodation request."));
      } else {
        dispatch(didSubmit());
      }
    } catch (err) {
      dispatch(setErrorMessage("Failed to submit request."));
    }
  };
}
