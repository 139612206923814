import * as React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Typography from "@material-ui/core/Typography";
import googleSignIn from "../img/google-sign-in.png";
import googleSignInFocused from "../img/google-sign-in-focused.png";
import googleSignInActive from "../img/google-sign-in-active.png";
import {AppState, Interview} from "../reducers/types";
import {brand} from "../branding";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import {preloadImages} from "../util/image";
import {signIn} from "../actions/userActions";
import {withInterview} from "../components/InterviewLoader";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import autobind from "autobind-decorator";

interface Props extends StyledComponentProps {
  interview: Interview;
}

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 480,
    },
    logo: {
      height: 33,
      marginBottom: 30,
      marginRight: "auto",
      marginLeft: "auto",
    },
    box: {
      alignItems: "center",
      border: "solid 1px #c4c2c2",
      borderRadius: 20,
      display: "flex",
      flexDirection: "column",
      padding: "50px 30px",
    },
    headerText: {
      lineHeight: 1.3,
    },
    text: {
      margin: "0 20px 40px 20px",
      textAlign: "center",
    },
    signIn: {
      "&:focus": {
        backgroundImage: `url(${googleSignInFocused})`,
      },
      "&:active": {
        backgroundImage: `url(${googleSignInActive})`,
      },
      backgroundImage: `url(${googleSignIn})`,
      backgroundSize: "191px 46px",
      border: "none",
      cursor: "pointer",
      height: 46,
      marginBottom: 35,
      outline: "none",
      width: 191,
    },
  });

class SignInScreen extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    preloadImages(googleSignInFocused, googleSignInActive);
  }

  @autobind
  onSignIn() {
    signIn();
  }

  render() {
    const {classes, interview} = this.props;
    return (
      <CenteredScreenContent>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <img className={classes.logo} src={brand.logo} />
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.box}>
            <Typography
              variant="h3"
              align="center"
              // @ts-ignore FIXME: strictNullChecks
              className={classes.headerText}
            >
              Welcome to your {interview.companyName} Interview!
            </Typography>
            <br />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.text}>
              In order to offer you an online interview experience, this
              interview is integrated with tools offered by the Google Cloud
              Platform. To ensure you have permissions to access these tools, we
              ask that you sign into a Google account.
            </Typography>
            <button
              // @ts-ignore FIXME: strictNullChecks
              className={classes.signIn}
              onClick={this.onSignIn}
              aria-label="Sign in with Google"
            />
            <Typography align="center">
              This account does not need to match the email you were invited
              with, and will not be shared with the company that sent you the
              invitation.
            </Typography>
          </div>
        </div>
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  interview: state.interview,
});

export default connect(mapStateToProps)(
  withStyles(styles)(
    withInterview([], {ignoreGlobalRules: ["terms", "getStarted"]})(
      SignInScreen,
    ),
  ),
);
