import * as React from "react";
import Typography from "@material-ui/core/Typography";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {createStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
    },
    message: {
      color: "black",
      fontSize: 240,
      fontWeight: 500,
      lineHeight: 1,
      marginBottom: 20,
    },
    subMessage: {
      color: theme.palette.primary.main,
      fontSize: 54,
      fontWeight: 600,
      lineHeight: 1,
    },
  });

interface Props {
  classes: any;
}

class PageNotFoundScreen extends React.Component<Props> {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.message}>404!</Typography>
        <Typography className={classes.subMessage}>
          Page not found :(
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(PageNotFoundScreen);
