import * as React from "react";
import Button from "@material-ui/core/Button";
import {OpenCloudShellButton} from "../components/CloudShell";
import CenteredScreenContent from "../components/CenteredScreenContent";
import CodeSnippet from "../components/CodeSnippet";
import CopyButton from "../components/CopyButton";
import InlineCode from "../components/InlineCode";
import Instructions, {
  InstructionItem,
  instructionsTextStyle,
} from "../components/Instructions";
import Link from "../components/Link";
import Note from "../components/Note";
import Typography from "@material-ui/core/Typography";
import UnorderedList from "../components/UnorderedList";
import autobind from "autobind-decorator";
import commandLineIcon from "../img/command-line-icon.png";
import downloadIcon from "../img/download-icon.png";
import editorIcon from "../img/editor-icon.png";
import webPreviewIcon from "../img/web-preview-icon.png"; // TODO make the correct img
import {
  AppState,
  Language,
  M2Type,
  TesterScreenState,
  Flags,
  User,
} from "../reducers/types";
import {ExternalLink} from "../components/Link";
import {FAQLink} from "../components/FAQ";
import {HelpDialogAction, TesterScreenAction} from "../actions/actionTypes";
import {HelpTopicId} from "../components/HelpDialog";
import {Interview} from "../reducers/types";
import {ThunkDispatch} from "redux-thunk";
import {brand} from "../branding";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import MUILink from "@material-ui/core/Link";
import {
  isInterviewGenerated,
  getTesterZipCommand,
  isWebInterview,
  isMobileInterview,
  hasM2CloudShellOption,
  isStaffEngInterview,
} from "../helpers/interview";
import {
  languageNameMap,
  languageDownloadUrlMap,
  languageInstallCommandMap,
  webInteviewPort,
} from "../config.js";
import {overviewUrl, testerUrl} from "../helpers/urls/routerUrls";
import {showHelpDialog} from "../actions/helpDialogActions";
import {
  submit,
  downloadZip,
  toggleInstructions,
} from "../actions/testerScreenActions";
import {withInterview, RedirectRule} from "../components/InterviewLoader";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import Spinner from "../components/Spinner";

interface StateProps {
  interview: Interview;
  flags: Flags;
  testerScreenState: TesterScreenState;
  user: User;
}

interface DispatchProps {
  submit: (atsId: string, language?: Language, m2Type?: M2Type) => any;
  downloadZip: (language: Language | "csv") => Promise<void>;
  toggleInstructions: () => void;
  showHelpDialog: (activeTopicId: string) => void;
}

interface Props extends StyledComponentProps, StateProps, DispatchProps {
  atsId: string;
  language: Language;
  m2Type: M2Type;
}

type Dispatch = ThunkDispatch<
  AppState,
  null,
  TesterScreenAction | HelpDialogAction
>;

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 480,
    },
    downloadIcon: {
      height: 16,
      marginRight: 15,
      width: 16.5,
    },
    box: {
      border: "solid 1px #c4c2c2",
      borderRadius: 20,
      padding: "50px 30px",
    },
    headerText: {
      lineHeight: 1.3,
    },
    bottom: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    cloudshellRoot: {
      bottom: 0,
      display: "flex",
      left: 0,
      overflow: "hidden",
      position: "absolute",
      right: 0,
      top: 0,
    },
    cloudshellContentContainer: {
      borderRight: "solid 1px #ddd",
      flex: 1,
      height: "100%",
      overflow: "hidden",
      position: "relative",
    },
    fullScreen: {
      height: "100%",
      width: "100%",
    },
    instructionsText: instructionsTextStyle,
    list: {
      paddingLeft: 15,
    },
    shellIcon: {
      margin: "0 2px",
      transform: "translateY(2px)",
      width: 18,
    },
    commandLineIcon: {
      height: 18,
      margin: "0 2px",
      transform: "translateY(2px)",
      width: 18,
    },
  });

class TesterScreen extends React.Component<Props> {
  getCloudShellTesterBucketPath(): string {
    const {interview, language} = this.props;
    let bucketDir: string = language;
    if (isWebInterview(interview)) {
      // JavaScript and TypeScript are used for both genSwe and web assessments,
      // so each language has two separate hello world folders, e.g. "js" and "js_web"
      // This makes sure we're using the js_web/ts_web folder for the web assessment,
      // but makes no change if the language is React
      if (language === "js") {
        bucketDir = "js_web";
      } else if (language === "ts") {
        bucketDir = "ts_web";
      }
    } else if (isMobileInterview(interview)) {
      // Similar to the above, Kotlin is used for both genSwe and mobile assessments.
      // The mobile assessment tester code is an Android Studio project.
      if (language === "kotlin") {
        bucketDir = "kotlin_android";
      }
    }
    return `gs://byteboard-testers/${bucketDir}/*`;
  }

  getMkDirCode(): string {
    const {language} = this.props;
    return (
      "mkdir ~/editor_walkthrough && gsutil cp -r " +
      `${this.getCloudShellTesterBucketPath()} ~/editor_walkthrough ` +
      ((languageInstallCommandMap as any)[language] ?? "") +
      "&& cloudshell ws ~/editor_walkthrough"
    );
  }

  getSwitchToCloudShellLabel(): string {
    return `I'd like to use Google Cloud Shell instead`;
  }

  willUseOnlineEditor(): boolean {
    return this.props.m2Type === "cloudshell";
  }

  @autobind
  onContinueZip(): Promise<void> {
    return this.continue("zip");
  }

  @autobind
  onContinueCloudshell(): Promise<void> {
    return this.continue("cloudshell");
  }

  @autobind
  async onDownloadZip(language: Language | "csv") {
    await this.props.downloadZip(language);
  }

  @autobind
  async onSubmitHelpRequest() {
    this.props.showHelpDialog(HelpTopicId.CONTACT_FORM);
  }

  async continue(m2Type?: M2Type): Promise<void> {
    const {atsId, language, testerScreenState} = this.props;
    if (testerScreenState.isSubmitting) return;
    await this.props.submit(atsId, language, m2Type);
    // Once the interview is generated then the redirect rules will kick in
    // and send the user over to the interview overview screen.
  }

  renderZipDownloadText(): React.ReactNode {
    const {interview, language} = this.props;
    const languageDownloadUrl = (languageDownloadUrlMap as any)[language];
    const languageName = (languageNameMap as any)[language];
    switch (language) {
      case "php":
      case "python2":
      case "python3":
      case "ruby":
      case "rust":
        return (
          <React.Fragment>
            <li>
              You can install the newest version of {languageName}{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "java":
        return (
          <React.Fragment>
            <li>
              <strong>You will need at least Java 11 for the interview.</strong>
            </li>
            <li>
              You can install the newest {languageName} JDK{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "golang":
        return (
          <React.Fragment>
            <li>You will need at least Go 1.7 for the interview.</li>
            <li>
              You can install the newest version of {languageName}{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "reactjs":
      case "reactts":
      case "js":
      case "ts":
        return (
          <React.Fragment>
            <li>
              <strong>
                You will need node.js (version &gt;= v14.21.0) for the
                interview.
              </strong>
            </li>
            <li>
              You can install Node{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "cpp":
        return (
          <React.Fragment>
            <li>You will need at least C++14 and CMake for the interview.</li>
            <li>
              You can install the latest version of CMake{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "csharp":
        return (
          <React.Fragment>
            <li>
              You will need version 8.* of the .NET SDK for the interview.
            </li>
            <li>
              You can install the latest SDK{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "swift":
      case "swiftui":
        return (
          <React.Fragment>
            <li>
              You will need Xcode 12 or later (and Swift 5.5 support) for the
              interview.
            </li>
            <li>
              You can install the latest version of Swift{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "kotlin":
        if (isMobileInterview(interview)) {
          return (
            <React.Fragment>
              <li>
                You will need the following in order to load your code in
                Android Studio for the interview:
                <ul>
                  <li>
                    Android Studio version 3.5 or later (download instructions{" "}
                    <ExternalLink
                      href="https://developer.android.com/studio"
                      target="_blank"
                    >
                      here
                    </ExternalLink>
                    ).
                  </li>
                  <li>
                    Android SDK Build-Tools version 31 (download instructions{" "}
                    <ExternalLink
                      href="https://developer.android.com/about/versions/12/setup-sdk#get-sdk"
                      target="_blank"
                    >
                      here
                    </ExternalLink>
                    ).
                  </li>
                </ul>
              </li>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <li>
                You can use either the IntelliJ IDE or the Kotlin command-line
                compiler for the interview.
                <ul>
                  <li>
                    You can install the IntelliJ IDE{" "}
                    <ExternalLink
                      href="https://www.jetbrains.com/idea/download"
                      target="_blank"
                    >
                      here
                    </ExternalLink>
                    .
                  </li>
                  <li>
                    You can install the newest version of the {languageName}{" "}
                    command-line compiler{" "}
                    <ExternalLink href={languageDownloadUrl} target="_blank">
                      here
                    </ExternalLink>
                    .
                  </li>
                </ul>
              </li>
            </React.Fragment>
          );
        }
      case "r":
        return (
          <React.Fragment>
            <li>You will need R for this interview.</li>
            <li>
              You can install the latest version of R{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      case "sql":
        return (
          <React.Fragment>
            <li>
              You will need to be able to process a SQLite database for the
              interview. You may use whatever tools you are most comfortable
              with. If you have never interacted with a SQLite database, we
              recommend DB Browser for SQLite.
            </li>
            <li>
              You can install the latest version of DB Browser for SQLite{" "}
              <ExternalLink href={languageDownloadUrl} target="_blank">
                here
              </ExternalLink>
              .
            </li>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  renderZipTesterText(): React.ReactNode {
    const {language, interview, atsId} = this.props;
    if (isMobileInterview(interview)) {
      return null;
    } else if (isWebInterview(interview)) {
      return (
        <React.Fragment>
          <Typography paragraph={true}>and run the command</Typography>
          <CodeSnippet>npm install</CodeSnippet>
          <Typography paragraph={true}>then run</Typography>
          <CodeSnippet>npm start</CodeSnippet>
          <Typography paragraph={true}>
            Once the local web server is running, open{" "}
            <strong>
              <ExternalLink
                target="_blank"
                href={`http://localhost:${webInteviewPort}`}
              >
                localhost:{webInteviewPort}
              </ExternalLink>
            </strong>{" "}
            (or 127.0.0.1:{webInteviewPort}) in your browser to verify that the
            tester project is running. To stop the local web server, run{" "}
            <InlineCode>ctrl + c</InlineCode>.
          </Typography>
          <Typography paragraph={true}>
            If you are having issues running the tester project please{" "}
            <MUILink onClick={this.onSubmitHelpRequest}>
              submit a help request
            </MUILink>{" "}
            or switch to the Online Editor below.
          </Typography>
        </React.Fragment>
      );
    } else if (language === "sql") {
      return (
        <React.Fragment>
          <Typography paragraph={true}>
            and ensure you can open and query the included SQLite database.
          </Typography>
          <Typography paragraph={true}>
            If you are having issues opening the tester file please{" "}
            <MUILink onClick={this.onSubmitHelpRequest}>
              submit a help request
            </MUILink>{" "}
            .
          </Typography>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Typography paragraph={true}>and run the command</Typography>
          <CodeSnippet>{getTesterZipCommand(language)}</CodeSnippet>
          <Typography paragraph={true}>
            <strong>Expected output: "Hello World! 25"</strong>, if not see{" "}
            <FAQLink>our FAQ</FAQLink>.
          </Typography>
        </React.Fragment>
      );
    }
  }

  renderZipTester(): React.ReactNode {
    const {classes, language, testerScreenState, atsId, interview, flags} =
      this.props;
    const continueButtonLabel = testerScreenState.isSubmitting ? (
      <>
        {/* Generating interview materials takes ~20 seconds, so show a spinner
            to let candidates know we are working on something and not just frozen */}
        <Spinner />
        Generating Interview Materials...
      </>
    ) : (
      "Continue"
    );
    const zipDownloadText = this.renderZipDownloadText();
    const languageName = (languageNameMap as any)[language];
    const zipInstructions = isWebInterview(interview) ? (
      <>
        To zip up your project files (and exclude the node_modules directory)
        you will run <InlineCode>npm run zip</InlineCode>.
      </>
    ) : (
      <>
        Instructions for creating a zip file can be found{" "}
        <ExternalLink
          target="_blank"
          href="https://www.wikihow.com/Make-a-Zip-File"
        >
          here
        </ExternalLink>
        .
      </>
    );
    return (
      <CenteredScreenContent size="tall">
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.box}>
            <Typography variant="h3" align="center">
              Personal IDE Setup
            </Typography>
            <Typography>
              You have decided to use your own IDE for Part 2 of the interview.
              Let's make sure your coding environment is configured properly.
            </Typography>
            <ul>
              {zipDownloadText ? zipDownloadText : null}
              <li>
                At the end of your interview, you’ll need to upload a zip file
                with your code. {zipInstructions}
              </li>
              {isStaffEngInterview(interview) && (
                <li>
                  You will also need SQLite installed on your computer to
                  complete this interview. If you don't already have SQLite
                  installed, you can download it from{" "}
                  <ExternalLink
                    target="_blank"
                    href="https://www.sqlite.org/download.html"
                  >
                    the SQLite website
                  </ExternalLink>
                  .
                </li>
              )}
            </ul>
            <Typography paragraph={true}>
              To ensure your environment works as expected before you begin the
              interview, you can download, run, and practice zipping the{" "}
              {languageName} tester below.
            </Typography>
            <Button
              onClick={(e) => this.onDownloadZip(language)}
              disabled={testerScreenState.isDownloadingZip}
              variant="outlined"
            >
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <img src={downloadIcon} className={classes.downloadIcon} />{" "}
              Download Tester
            </Button>
            <div style={{height: "14px"}} />
            {this.renderZipTesterText()}
            <br />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <div className={classes.bottom}>
              <Button
                onClick={this.onContinueZip}
                disabled={testerScreenState.isSubmitting}
                color="primary"
                variant="contained"
              >
                {continueButtonLabel}
              </Button>
              {hasM2CloudShellOption(interview, flags) ? (
                <React.Fragment>
                  <br />
                  <Typography>Or</Typography>
                  <br />
                  <Link to={testerUrl(atsId, "cloudshell", language)}>
                    {this.getSwitchToCloudShellLabel()}
                  </Link>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </CenteredScreenContent>
    );
  }

  renderCloudshellInstructions(): React.ReactNode {
    const {classes, testerScreenState, atsId, language} = this.props;
    const instructions: InstructionItem[] = [
      {
        enabled: true,
        content: (
          <React.Fragment>
            <Typography variant="h3" align="center">
              Online Editor Walkthrough
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              You have decided to use Google Cloud Shell, for Part 2 of the
              interview. Let's make sure the editor works in your browser.
            </Typography>
            <Note>
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <Typography className={classes.instructionsText}>
                <strong>Note:</strong> If you have issues completing the
                walkthrough then you can{" "}
                <Link to={testerUrl(atsId, "zip", language)}>
                  switch to the personal IDE experience
                </Link>{" "}
                for Part 2. You will be able to switch between these experiences
                during your interview.
              </Typography>
            </Note>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              To get started, push
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <CopyButton className={classes.copyIconButton} /> to copy the
              command below and paste it into the terminal. When prompted to
              authorize Cloud Shell, press the "Authorize" button.
            </Typography>
            <CodeSnippet>{this.getMkDirCode()}</CodeSnippet>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              The <strong>Terminal</strong> can be opened/closed by clicking the{" "}
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <img src={commandLineIcon} className={classes.shellIcon} /> icon
              at the top-right of the IDE.
            </Typography>
            <UnorderedList
              // @ts-ignore FIXME: strictNullChecks
              className={classes.list}
              items={[
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  If you are unfamiliar with the command line{" "}
                  <ExternalLink
                    target="_blank"
                    href="https://maker.pro/linux/tutorial/basic-linux-commands-for-beginners"
                  >
                    here are some key Linux commands
                  </ExternalLink>
                  .
                </Typography>,
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  If you would like additional room to work, you can collapse
                  this side panel and resize the terminal panel at any time.
                </Typography>,
              ]}
            />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              The <strong>Editor</strong> can be opened/closed by clicking the{" "}
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <img src={editorIcon} className={classes.shellIcon} /> icon at the
              top-right of your IDE. The editor will auto-save any changes you
              make. Please take some time to familiarize yourself with the
              editor.
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              For your interview, you will be given commands to run your code.
              Try it now by pasting into the terminal:
            </Typography>
            {this.renderCloudShellRunInstructions()}
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              Take a moment to explore a little more. There are several features
              not covered here that you may find interesting.
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              <Link to={testerUrl(atsId, "zip", language)}>
                I'd like to use the personal IDE experience instead
              </Link>
            </Typography>
          </React.Fragment>
        ),
      },
    ];
    const continueButtonLabel = testerScreenState.isSubmitting ? (
      <>
        {/* Generating interview materials takes ~20 seconds, so show a spinner
            to let candidates know we are working on something and not just frozen */}
        <Spinner />
        Generating Interview Materials...
      </>
    ) : (
      "Continue"
    );
    return (
      <Instructions
        instructions={instructions}
        instructionsIndex={0}
        isExpanded={testerScreenState.showInstructions}
        isSubmitEnabled={!testerScreenState.isSubmitting}
        submitButtonLabel={continueButtonLabel}
        onToggleExpand={this.props.toggleInstructions}
        onSubmit={this.onContinueCloudshell}
        onNext={() => {
          /* */
        }}
        onPrev={() => {
          /* */
        }}
      />
    );
  }

  renderCloudShellRunInstructions(): React.ReactNode {
    const {classes, interview, language} = this.props;
    if (isWebInterview(interview)) {
      return (
        <>
          <CodeSnippet>{"cd editor_walkthrough"}</CodeSnippet>
          <CodeSnippet>{"npm install"}</CodeSnippet>
          <CodeSnippet>{"npm start"}</CodeSnippet>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <Typography className={classes.instructionsText} paragraph={true}>
            Click the <strong>Web Preview</strong> button
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <img src={webPreviewIcon} className={classes.shellIcon} /> and
            select "Preview on port {webInteviewPort}" to see your app, and
            refresh whenever you make a change.
          </Typography>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <Typography className={classes.instructionsText} paragraph={true}>
            To stop the local web server, run <InlineCode>ctrl + c</InlineCode>.
          </Typography>
        </>
      );
    }
    let runCode = "cd ~/editor_walkthrough && sh run_hello.sh";
    if (language === "csharp") {
      runCode = "cd ~/editor_walkthrough && dotnet run";
    } else if (language === "ts") {
      runCode = "cd ~/editor_walkthrough && npm install && sh run_hello.sh";
    }
    return <CodeSnippet>{runCode}</CodeSnippet>;
  }

  renderCloudshellInterviewTester(): React.ReactNode {
    const {classes, language, testerScreenState, atsId, user} = this.props;
    const continueButtonLabel = testerScreenState.isSubmitting ? (
      <>
        {/* Generating interview materials takes ~20 seconds, so show a spinner
            to let candidates know we are working on something and not just frozen */}
        <Spinner />
        Generating Interview Materials...
      </>
    ) : (
      "Continue"
    );
    return (
      <CenteredScreenContent size="tall">
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.box}>
            <Typography variant="h3" align="center">
              Google Cloud Shell Testing
            </Typography>
            <Typography paragraph={true}>
              You have decided to leverage Byteboard's integration with Google
              Cloud Shell for Part 2 of the interview. Let's test the Google
              Cloud Shell environment to ensure that it works for you:
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <OpenCloudShellButton authUserEmail={user.email} />
            <br />
            <br />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              To get started, push {/* @ts-ignore FIXME: strictNullChecks*/}
              <CopyButton className={classes.copyIconButton} /> to copy the
              command below. Paste the command into the terminal and press
              Enter. When prompted to authorize Cloud Shell, press the
              "Authorize" button.
            </Typography>
            <CodeSnippet>{this.getMkDirCode()}</CodeSnippet>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText} paragraph={true}>
              For your interview, you will be given commands to run your code.
              Try running the tester script now by pasting the command below
              into the terminal and pressing Enter:
            </Typography>
            {this.renderCloudShellRunInstructions()}
            <Typography paragraph={true}>
              Some Additional Tips (these will also be available during the
              interview):
            </Typography>
            <UnorderedList
              // @ts-ignore FIXME: strictNullChecks
              className={classes.list}
              items={[
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  The <strong>Terminal</strong> can be opened/closed by clicking
                  the
                  <img
                    src={commandLineIcon}
                    // @ts-ignore FIXME: strictNullChecks
                    className={classes.shellIcon}
                  />{" "}
                  icon at the top-right of the IDE.
                </Typography>,
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  The <strong>Editor</strong> can be opened/closed by clicking
                  the
                  {/* @ts-ignore FIXME: strictNullChecks*/}
                  <img src={editorIcon} className={classes.shellIcon} /> icon at
                  the top-right of your IDE.
                </Typography>,
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  The editor will auto-save any changes you make.
                </Typography>,
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  You can open multiple files side-by-side by dragging and
                  dropping files into your desired configuration.
                </Typography>,
                // @ts-ignore FIXME: strictNullChecks
                <Typography className={classes.instructionsText}>
                  If you are unfamiliar with the command line{" "}
                  <ExternalLink
                    target="_blank"
                    href="https://maker.pro/linux/tutorial/basic-linux-commands-for-beginners"
                  >
                    here are some key Linux commands
                  </ExternalLink>
                  .
                </Typography>,
              ]}
            />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <div className={classes.bottom}>
              <Button
                onClick={this.onContinueCloudshell}
                disabled={testerScreenState.isSubmitting}
                color="primary"
                variant="contained"
              >
                {continueButtonLabel}
              </Button>
              <br />
              <Typography>Or</Typography>
              <br />
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <Typography className={classes.instructionsText} paragraph={true}>
                <Link to={testerUrl(atsId, "zip", language)}>
                  I'd like to use the personal IDE experience instead
                </Link>
              </Typography>
            </div>
          </div>
        </div>
      </CenteredScreenContent>
    );
  }

  render(): React.ReactNode {
    if (this.willUseOnlineEditor()) {
      return this.renderCloudshellInterviewTester();
    }
    return this.renderZipTester();
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  interview: state.interview,
  flags: state.flags,
  testerScreenState: state.testerScreenState,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  submit: (atsId: string, language: Language, m2Type: M2Type) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(submit(atsId, language, m2Type));
  },
  // @ts-ignore FIXME: strictNullChecks
  downloadZip: (language: Language) => dispatch(downloadZip(language)),
  toggleInstructions: () => dispatch(toggleInstructions()),
  showHelpDialog: (activeTopicId: string) => {
    return dispatch(showHelpDialog(activeTopicId));
  },
});

const redirectRules: RedirectRule[] = [
  {
    match: (interview: Interview) => isInterviewGenerated(interview),
    redirect: (interview) => overviewUrl(interview.id),
  },
];

// Make sure this is in sync with GetStartedScreen.
const redirectOptions = {
  ignoreGlobalRules: ["getStarted"],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview(redirectRules, redirectOptions)(TesterScreen),
  ),
);
