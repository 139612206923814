import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import React, {ChangeEvent} from "react";
import {useDispatch} from "react-redux";
import {
  downloadZip,
  setZipDownloadType,
  startM2,
} from "../../../actions/m2InterviewScreenActions";
import {toggleZipUploadDialog} from "../../../actions/zipUploadActions";
import {brand} from "../../../branding";
import CenteredScreenContent from "../../../components/CenteredScreenContent";
import CodeSnippet from "../../../components/CodeSnippet";
import InlineCode from "../../../components/InlineCode";
import {instructionsTextStyle} from "../../../components/Instructions";
import {ExternalLink} from "../../../components/Link";
import M2SubmittedDialog from "../../../components/M2SubmittedDialog";
import ZipUploadDialog from "../../../components/ZipUploadDialog";
import {webInteviewPort} from "../../../config";
import {
  ctrlC,
  getM2DurationInMinutes,
  getRunShFileNameCode,
  isWebInterview,
  npmInstallCommand,
  npmStartCommand,
  requiresNpmInstall,
  shouldShowServerInstructions,
  willPlayTenMinutesRemainingAudio,
} from "../../../helpers/interview";
import downloadIcon from "../../../img/download-icon.png";
import {
  Flags,
  Interview,
  M2InterviewScreenState,
  ZipDownloadType,
} from "../../../reducers/types";
import Expectations from "../Expectations";

const zipUploadTimeMin = 5;

const useStyles = makeStyles(() => ({
  downloadIcon: {
    height: 16,
    marginRight: 15,
    width: 16.5,
  },
  instructionsText: instructionsTextStyle,
  radioForm: {
    display: "flex",
    flexDirection: "row",
  },
  radioGroup: {
    flexDirection: "row",
  },
  radioLabel: {
    marginRight: 30,
  },
}));

type Props = {
  atsId: string;
  flags: Flags;
  interview: Interview;
  m2InterviewScreenState: M2InterviewScreenState;
};

export default function ZipInterviewInstructions({
  atsId,
  flags,
  interview,
  m2InterviewScreenState,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    hasModifiedM2Code,
    isDownloadingZip,
    showSubmittedDialog,
    zipDownloadType,
  } = m2InterviewScreenState;

  const handleSetZipDownloadType = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as ZipDownloadType;
    dispatch(setZipDownloadType(value));
  };

  const handleStartDownloadZip = async () => {
    if (!interview.m2OpenTime) {
      await dispatch(startM2());
    }
    dispatch(downloadZip());
  };

  const handleShowZipUploadDialog = () => {
    dispatch(toggleZipUploadDialog(true));
  };

  return (
    <CenteredScreenContent size="tall">
      <Typography variant="h3">Part 2 Instructions</Typography>
      <Typography paragraph={true}>
        Your Part 2 timer will not begin until you download your code. When your
        timer begins, you will have {getM2DurationInMinutes(interview)} minutes
        to work on the implementation.
      </Typography>
      <Typography paragraph={true}>
        You will hear a notification{" "}
        {willPlayTenMinutesRemainingAudio(interview)
          ? "when 10 minutes remain, and again "
          : ""}
        when the implementation time expires. After that, the timer will update
        to reflect that you have {zipUploadTimeMin} additional minutes to upload
        your code. Turn on your sound now to ensure you hear the notification
        {willPlayTenMinutesRemainingAudio(interview) ? "s" : ""}.
      </Typography>
      <Expectations flags={flags} interview={interview} />
      <Typography variant="h4">Step 1</Typography>
      <Typography paragraph={!hasModifiedM2Code}>
        {hasModifiedM2Code
          ? `Download your modified code from the ${brand.company} editor or the original starter code.`
          : "Clicking the button below will start your Part 2 timer and download your starter code."}{" "}
        Once your code is downloaded, you should open it in the IDE of your
        choice.
      </Typography>
      {hasModifiedM2Code && (
        <FormControl className={classes.radioForm}>
          <RadioGroup
            name="zipDownloadType"
            className={classes.radioGroup}
            aria-label="Zip download type"
            value={zipDownloadType}
            onChange={handleSetZipDownloadType}
          >
            <FormControlLabel
              value="modified"
              className={classes.radioLabel}
              control={<Radio inputProps={{["aria-label"]: "modified"}} />}
              label="My Code"
            />
            <FormControlLabel
              value="base"
              className={classes.radioLabel}
              control={<Radio inputProps={{["aria-label"]: "base"}} />}
              label="Starter Code"
            />
          </RadioGroup>
        </FormControl>
      )}
      <Button
        onClick={handleStartDownloadZip}
        disabled={isDownloadingZip}
        variant="outlined"
      >
        <img src={downloadIcon} className={classes.downloadIcon} /> Download
        Code
      </Button>
      <br />
      <br />
      {shouldShowServerInstructions(interview) && (
        <>
          <Typography variant="h4">Step 2</Typography>
          {isWebInterview(interview) ? (
            <>
              <Typography paragraph={true}>
                Run the command below to install the dependencies.
              </Typography>
              <CodeSnippet>{npmInstallCommand}</CodeSnippet>
              <Typography paragraph={true}>
                Next, run the following command to start a local web server.
              </Typography>
              <CodeSnippet>{npmStartCommand}</CodeSnippet>
              <Typography paragraph={true}>
                Once the local web server is running, open{" "}
                <strong>
                  <ExternalLink
                    target="_blank"
                    href={`http://localhost:${webInteviewPort}`}
                  >
                    localhost:{webInteviewPort}
                  </ExternalLink>
                </strong>{" "}
                (or 127.0.0.1:{webInteviewPort}) in your browser to test your
                code. To stop the local web server, run{" "}
                <InlineCode>{ctrlC}</InlineCode>.
              </Typography>
            </>
          ) : (
            <>
              {requiresNpmInstall(interview) && (
                <>
                  <Typography
                    className={classes.instructionsText}
                    paragraph={true}
                  >
                    Install the dependencies by running
                  </Typography>
                  <CodeSnippet>{npmInstallCommand}</CodeSnippet>
                </>
              )}
              <Typography paragraph={true}>
                Run the command below in a terminal. You should see output that
                confirms the server runs. You can rerun the script each time you
                want to verify your code.
              </Typography>
              <CodeSnippet>
                {getRunShFileNameCode(m2InterviewScreenState)}
              </CodeSnippet>
            </>
          )}
        </>
      )}
      <Typography variant="h4">
        Step {shouldShowServerInstructions(interview) ? 3 : 2}
      </Typography>
      <Typography paragraph={true}>
        Go through the list of tasks in the README file and complete them.
      </Typography>
      {interview.role === "mobile" && interview.language === "kotlin" && (
        <Typography paragraph={true}>
          For a fast and successful upload, please zip only the "src" directory
          in your project, not the "build" directory, the "gradle" directory, or
          any of the other files in the root directory.
        </Typography>
      )}
      <br />
      <Button
        color="primary"
        disabled={!interview.m2OpenTime}
        onClick={handleShowZipUploadDialog}
        variant="contained"
      >
        Upload Code
      </Button>
      <ZipUploadDialog atsId={atsId} />
      <M2SubmittedDialog show={showSubmittedDialog} />
    </CenteredScreenContent>
  );
}
