import {createStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MUILink from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {StyledComponentProps, withStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ChevronRight from "@material-ui/icons/ChevronRight";

import {compact} from "lodash";
import * as React from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {HelpDialogAction} from "../actions/actionTypes";
import {
  hideHelpDialog,
  setActiveTopicId,
  setContactFormText,
  submitHelpForm,
} from "../actions/helpDialogActions";
import {getSupportEmail} from "../actions/interviewActions";
import {disableBBDoc} from "../actions/interviewModuleScreenActions";
import {unresolveComments} from "../actions/m1InterviewScreenActions";
import {updateM2Type} from "../actions/m2InterviewScreenActions";
import {LogAction, logFEInfo} from "../actions/serverInfoActions";
import {downloadZip} from "../actions/testerScreenActions";
import {brand} from "../branding";
import {languageNameMap, webInteviewPort} from "../config.js";
import {isBBDocEnabled} from "../helpers/bbdoc";
import {
  canSwitchM2Type,
  getActiveModuleId,
  getTesterZipCommand,
  isActiveDocModuleWithComments,
  isCloudShellM2,
  isCodeModuleActive,
  isCodeModuleActiveOrNextAfterBreak,
  isDocModuleActive,
  isInterviewStarted,
  isMobileInterview,
  isDataAnalysisInterview,
  isOnlineEditorM2,
  isWebInterview,
  isZipM2,
} from "../helpers/interview";
import docComment from "../img/doc-comment.png";
import downloadIcon from "../img/download-icon.png";
import {
  AppState,
  Flags,
  HelpDialogState,
  Interview,
  Language,
  M2InterviewScreenState,
  ModuleId,
  User,
} from "../reducers/types";
import {isChromeBrowser} from "../util/browser";
import {getServerTime} from "../util/time";
import BBDocAccessibilityDocumentKeyboardShortcuts from "./BBDocAccessibilityDocumentKeyboardShortcuts";
import CodeSnippet from "./CodeSnippet";
import {FAQLink} from "./FAQ";
import InlineCode from "./InlineCode";
import InterviewOverviewTips from "./InterviewOverviewTips";
import Link, {ExternalLink} from "./Link";
import Note from "./Note";
import UnorderedList from "./UnorderedList";
import {interviewGuideUrl, interviewHubUrl} from "../helpers/urls/routerUrls";

interface Props extends StyledComponentProps {
  helpDialog: HelpDialogState;
  interview: Interview;
  flags: Flags;
  m2InterviewScreenState: M2InterviewScreenState;
  isUnresolvingComments: boolean;
  isBBDocDisabledOrDisabling: boolean;
  serverTime: number;
  user: User;
  hideHelpDialog: () => Promise<void>;
  setActiveTopicId: (id: string) => void;
  submitHelpForm: (message: string) => Promise<void>;
  downloadTesterZip: (language: Language) => Promise<void>;
  switchM2ToZip: () => Promise<void>;
  switchM2ToCloudshell: () => Promise<void>;
  setContactFormText: (text: string) => void;
  unresolveComments: () => void;
  disableBBDoc: () => void;
  logFEInfo: (action: LogAction, data?: any) => Promise<void>;
}
export enum HelpTopicId {
  ACCOMMODATION_REQUEST = "ACCOMMODATION_REQUEST",
  ADD_COMMENTS = "ADD_COMMENTS",
  AUTHORIZE_CLOUDSHELL = "AUTHORIZE_CLOUDSHELL",
  CONFIRM_SUBMISSION = "CONFIRM_SUBMISSION",
  CONTACT_FORM = "CONTACT_FORM",
  DOC_COMMENT_DISAPPEARED = "DOC_COMMENT_DISAPPEARED",
  DOC_NOT_LOADING = "DOC_NOT_LOADING",
  EDITOR_DISAPPEARED = "EDITOR_DISAPPEARED",
  EDITOR_ISSUES = "EDITOR_ISSUES",
  FE_DEBUG_CODE = "FE_DEBUG_CODE",
  KEYBOARD = "KEYBOARD",
  NO_DOC_COMMENTS = "NO_DOC_COMMENTS",
  PREPARE_FOR_INTERVIEW = "PREPARE_FOR_INTERVIEW",
  RESPOND_TO_DOC_COMMENTS = "RESPOND_TO_DOC_COMMENTS",
  RUNNING_CODE = "RUNNING_CODE",
  SUBMIT_ISSUES = "SUBMIT_ISSUES",
  SWITCH_TO_ZIP = "SWITCH_TO_ZIP",
  SWITCH_TO_CLOUDSHELL = "SWITCH_TO_CLOUDSHELL",
  TIPS = "TIPS",
  VIEW_MULTIPLE_FILES = "VIEW_MULTIPLE_FILES",
  WEB_SCREEN_FROZEN = "WEB_SCREEN_FROZEN",
  WHAT_DO_I_NEED = "WHAT_DO_I_NEED",
  WHEN_CAN_I_TAKE = "WHEN_CAN_I_TAKE",
}
interface HelpTopic {
  id: keyof typeof HelpTopicId;
  title: string | ((props: Props) => string);
  render: (props: Props) => React.ReactNode;
  show: boolean | ((props: Props) => boolean);
}

const helpTopics: HelpTopic[] = [
  {
    id: HelpTopicId.PREPARE_FOR_INTERVIEW,
    title: `Should I prepare for the ${brand.company} Interview?`,
    show: (props) => !isInterviewStarted(props.interview),
    render: (props) => {
      return (
        <Typography>
          Here’s a{" "}
          <Link to={interviewGuideUrl(props.interview.id)} target="_blank">
            brief interview guide
          </Link>{" "}
          with resources and handy tips for feeling prepared for the{" "}
          {brand.company} interview.
        </Typography>
      );
    },
  },
  {
    id: HelpTopicId.WHEN_CAN_I_TAKE,
    title: "When can I take this interview?",
    show: (props) => !isInterviewStarted(props.interview),
    render: () => (
      <Typography>
        The interview can be taken at any time, including outside of work hours
        and on weekends. Once you start the interview you must finish it in one
        sitting.
      </Typography>
    ),
  },
  {
    id: HelpTopicId.WHAT_DO_I_NEED,
    title: "What do I need to take this interview?",
    show: (props) => !isInterviewStarted(props.interview),
    render: (props) => (
      <React.Fragment>
        <Typography>You will need:</Typography>
        <UnorderedList
          items={compact([
            <Typography>
              One of the following supported browsers: Google Chrome, Mozilla
              Firefox, Microsoft Edge, or Microsoft Internet Explorer 11+.
              Safari is not supported.
            </Typography>,
            <Typography>A Gmail or G Suite account</Typography>,
            <Typography>Access to a computer</Typography>,
            <Typography>Reliable internet connection</Typography>,
            canSwitchM2Type(props.interview, props.flags) ? (
              <Typography>
                For Part 2 you will have the option to either work in our online
                editor or download a zip file containing the codebase to work in
                an IDE of your choosing. You will have the opportunity to test
                out these options before you begin your interview.
              </Typography>
            ) : null,
            isMobileInterview(props.interview) ||
            isDataAnalysisInterview(props.interview) ? (
              <Typography>
                For Part 2 you will download a zip file containing the codebase
                to work in an IDE of your choosing.
              </Typography>
            ) : null,
          ])}
        />
      </React.Fragment>
    ),
  },
  {
    id: HelpTopicId.ACCOMMODATION_REQUEST,
    title: "What if I need to make an accommodation request for my interview?",
    show: (props) => !isInterviewStarted(props.interview),
    render: (props) => {
      return (
        <>
          <Typography paragraph={true}>
            Reach out to your recruiter to make accomodation requests, such as a
            request for extra time.
          </Typography>
        </>
      );
    },
  },
  {
    id: HelpTopicId.TIPS,
    title: "What were the tips for this section of the interview?",
    show: (props) => {
      const activeModuleId = getActiveModuleId(
        props.interview,
        props.serverTime,
      );
      return (
        isDocModuleActive(props.interview, props.serverTime) ||
        isCodeModuleActive(props.interview, props.serverTime)
      );
    },
    render: (props) => {
      const activeModuleId = getActiveModuleId(
        props.interview,
        props.serverTime,
      );
      return (
        <InterviewOverviewTips
          interview={props.interview}
          withoutNoteFormatting={true}
          hideHelpDialogTip={true}
          moduleId={activeModuleId as ModuleId}
        />
      );
    },
  },
  {
    id: HelpTopicId.KEYBOARD,
    title:
      "How do I use my keyboard or screen reader to navigate the design document?",
    show: (props) => {
      return true;
    },
    render: (props) => {
      return (
        <>
          <Note>
            <Typography>
              If any of the assessment's accessibility features are
              malfunctioning, please{" "}
              <MUILink
                onClick={() => props.setActiveTopicId(HelpTopicId.CONTACT_FORM)}
              >
                submit a help request
              </MUILink>
              , and a {brand.company} team member will pause your interview and
              assist you.
            </Typography>
          </Note>
          <BBDocAccessibilityDocumentKeyboardShortcuts />
        </>
      );
    },
  },
  {
    id: HelpTopicId.DOC_NOT_LOADING,
    title:
      "My timer has started and all I can see is the sidebar. What do I do?",
    show: (props) =>
      isDocModuleActive(props.interview, props.serverTime) &&
      !isBBDocEnabled(props.interview, props.flags),
    render: (props) => (
      <>
        {isChromeBrowser() ? (
          <Typography paragraph={true}>
            Please open <strong>chrome://settings/cookies</strong> in another
            tab and select "Allow all cookies". Then refresh this window.
          </Typography>
        ) : (
          <Typography paragraph={true}>
            Please clear your cookies. Then refresh this window.
          </Typography>
        )}
        <Typography paragraph={true}>
          If you are still unable to see anything besides the sidebar, please{" "}
          <MUILink
            onClick={() => props.setActiveTopicId(HelpTopicId.CONTACT_FORM)}
          >
            submit a help request
          </MUILink>{" "}
          and a {brand.company} team member will assist you.
        </Typography>
      </>
    ),
  },
  {
    id: HelpTopicId.RESPOND_TO_DOC_COMMENTS,
    title: "How do I respond to comments in the document?",
    show: (props) => {
      return isActiveDocModuleWithComments(props.interview, props.serverTime);
    },
    render: (props) => {
      const bbdocEnabled = isBBDocEnabled(props.interview, props.flags);
      return (
        <UnorderedList
          items={[
            <Typography>Click the comment you want to respond to.</Typography>,
            ...(bbdocEnabled
              ? [
                  <Typography>
                    Add your answer in the text box that says "Write your answer
                    here".
                  </Typography>,
                  <Typography>Your answer will be auto-saved.</Typography>,
                ]
              : [
                  <Typography>Click Reply and type your reply.</Typography>,
                  <Typography>To save, click Reply.</Typography>,
                ]),
          ]}
        />
      );
    },
  },
  {
    id: HelpTopicId.NO_DOC_COMMENTS,
    title:
      "I can’t see any comments on the right side of the document. What should I do?",
    show: (props) => {
      return (
        isActiveDocModuleWithComments(props.interview, props.serverTime) &&
        !isBBDocEnabled(props.interview, props.flags)
      );
    },
    render: () => (
      <Typography>
        If you are having trouble viewing or accessing comments on the right
        side of the document, you can find the comment prompts at the end of the
        document for you to answer inline. Comments are hyperlinked (blue
        underlined text), and you can click to see the associated prompt.
      </Typography>
    ),
  },
  {
    id: HelpTopicId.DOC_COMMENT_DISAPPEARED,
    title:
      "A comment question disappeared after I pushed a check mark. How do I get it back?",
    show: (props) => {
      return (
        isActiveDocModuleWithComments(props.interview, props.serverTime) &&
        !isBBDocEnabled(props.interview, props.flags)
      );
    },
    render: (props) => (
      <>
        <Typography paragraph={true}>
          Pushing the check mark results in a question being marked as closed.
          You do not need to close comments when you are finished answering them
          but you can if you choose. If you would like to re-open your comments,
          push the button below:
        </Typography>
        <Button
          color="default"
          variant="outlined"
          disabled={!props.interview.m1OpenTime || props.isUnresolvingComments}
          onClick={props.unresolveComments}
        >
          {props.isUnresolvingComments ? "Please wait" : "Re-open all comments"}
        </Button>
      </>
    ),
  },
  {
    id: HelpTopicId.ADD_COMMENTS,
    title: "How do I add my own comments to the document?",
    show: (props) => {
      return isActiveDocModuleWithComments(props.interview, props.serverTime);
    },
    render: (props) => {
      const bbdocEnabled = isBBDocEnabled(props.interview, props.flags);
      return (
        <UnorderedList
          items={[
            <Typography>
              Highlight the text, images, cells, or slides you want to comment
              on.
            </Typography>,
            ...(bbdocEnabled
              ? [
                  <Typography>
                    Click "Add Comment" on the right side of the document.
                  </Typography>,
                  <Typography>
                    Add your comment in the open text box.
                  </Typography>,
                ]
              : [
                  <Typography>
                    Click <img src={docComment} alt="Doc Comment" />
                  </Typography>,
                  <Typography>Type your comment.</Typography>,
                  <Typography>Click Comment.</Typography>,
                ]),
          ]}
        />
      );
    },
  },
  {
    id: HelpTopicId.SWITCH_TO_CLOUDSHELL,
    title: () =>
      `I’d like to use Google Cloud Shell (Byteboard's online editor) instead. What do I do?`,
    show: (props) => {
      return (
        isCodeModuleActiveOrNextAfterBreak(props.interview, props.serverTime) &&
        isZipM2(props.interview) &&
        canSwitchM2Type(props.interview, props.flags)
      );
    },
    render: (props) => (
      <React.Fragment>
        <Typography paragraph={true}>
          Switch over to the Google Cloud Shell online editor by pushing the
          button below and then follow the provided instructions. If you’ve made
          any modifications to your code, copy and paste them into the editor.
        </Typography>
        <Button
          variant="contained"
          disabled={props.m2InterviewScreenState.isUpdatingM2Type}
          onClick={props.switchM2ToCloudshell}
          color="primary"
        >
          Switch to editor
        </Button>
      </React.Fragment>
    ),
  },
  {
    id: HelpTopicId.AUTHORIZE_CLOUDSHELL,
    title:
      'I\'m seeing the following error "Service Exception: 401 Anonymous caller does not have permissions". How do I fix this?',
    show: (props) => {
      return (
        isCodeModuleActiveOrNextAfterBreak(props.interview, props.serverTime) &&
        isOnlineEditorM2(props.interview)
      );
    },
    render: () => (
      <React.Fragment>
        <Typography paragraph={true}>
          You need to authorize Cloud Shell to run the code. Run{" "}
          <InlineCode>gcloud auth login</InlineCode> in the command line and
          follow the instructions to authorize Cloud Shell.
        </Typography>
      </React.Fragment>
    ),
  },
  {
    id: HelpTopicId.RUNNING_CODE,
    title:
      "I am using a Windows machine and can’t run the provided code. What do I do?",
    show: (props) => {
      return (
        isCodeModuleActive(props.interview, props.serverTime) &&
        isZipM2(props.interview)
      );
    },
    render: (props) => (
      <React.Fragment>
        <Typography paragraph={true}>
          If you are running on a Windows machine, you can try renaming our
          provided *.sh files to *.bat files. If you are still unable to run
          your code or are running into any other issues, please{" "}
          <MUILink
            onClick={() => props.setActiveTopicId(HelpTopicId.CONTACT_FORM)}
          >
            submit a help request
          </MUILink>{" "}
          and a {brand.company} team member will assist you.
        </Typography>
      </React.Fragment>
    ),
  },
  {
    id: HelpTopicId.SWITCH_TO_ZIP,
    title:
      "I’d like to download a zip file and use my local IDE instead. What do I do?",
    show: (props) => {
      return (
        isCodeModuleActiveOrNextAfterBreak(props.interview, props.serverTime) &&
        isOnlineEditorM2(props.interview) &&
        canSwitchM2Type(props.interview, props.flags)
      );
    },
    render: (props) => (
      <React.Fragment>
        {props.interview.m2OpenTime ? (
          <Note>
            <Typography>
              Note: You will not have additional time to set up your
              environment. If you lost significant time due to issues with the
              platform, please reach out to the {brand.company} team immediately
              by{" "}
              <MUILink
                onClick={() => props.setActiveTopicId(HelpTopicId.CONTACT_FORM)}
              >
                submitting a help request
              </MUILink>
              .
            </Typography>
          </Note>
        ) : null}
        <Typography paragraph={true}>
          If you’d like to ensure your environment is properly set up, download
          the {/* @ts-ignore FIXME: strictNullChecks*/}
          {languageNameMap[props.interview.language]} tester below.
        </Typography>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={props.classes.downloadZipContainer}>
          <Button
            // @ts-ignore FIXME: strictNullChecks
            onClick={() => props.downloadTesterZip(props.interview.language)}
            variant="outlined"
          >
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <img
              src={downloadIcon}
              // @ts-ignore FIXME: strictNullChecks
              className={props.classes.downloadIcon}
            />{" "}
            Download Tester
          </Button>
          <Typography>and run</Typography>
          {isWebInterview(props.interview) ? (
            <div>
              <CodeSnippet style={{margin: 0, minWidth: 220}}>
                npm install
              </CodeSnippet>
              <div style={{height: 5}} />
              <CodeSnippet style={{margin: 0, minWidth: 220}}>
                npm start
              </CodeSnippet>
            </div>
          ) : (
            <CodeSnippet style={{margin: 0, minWidth: 220}}>
              {/* @ts-ignore FIXME: strictNullChecks*/}
              {getTesterZipCommand(props.interview.language)}
            </CodeSnippet>
          )}
        </div>
        {isWebInterview(props.interview) ? (
          <Typography paragraph={true}>
            Once the local web server is running, open{" "}
            <strong>
              <ExternalLink
                target="_blank"
                href={`http://localhost:${webInteviewPort}`}
              >
                localhost:{webInteviewPort}
              </ExternalLink>
            </strong>{" "}
            (or 127.0.0.1:{webInteviewPort}) in your browser to verify that the
            tester project is running. To stop the local web server, run{" "}
            <InlineCode>ctrl + c</InlineCode>.
          </Typography>
        ) : (
          <Typography paragraph={true}>
            <strong>Expected output: "Hello World! 25"</strong>
          </Typography>
        )}
        {props.interview.m2OpenTime ? (
          <React.Fragment>
            <Typography paragraph={true}>
              When you are ready to switch to the zip file experience, run the
              command below in the terminal at the bottom of the {brand.company}{" "}
              IDE.
            </Typography>
            <CodeSnippet>
              sh ~/coding-challenge/admin/switch_to_zip.sh
            </CodeSnippet>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography paragraph={true}>
              When you are ready to switch to the zip file experience, push the
              button below
              {props.interview.m2OpenTime
                ? ""
                : " and download the provided code to start your timer"}
              .
            </Typography>
            <Button
              variant="contained"
              disabled={props.m2InterviewScreenState.isUpdatingM2Type}
              onClick={props.switchM2ToZip}
              color="primary"
            >
              Switch to zip file
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    ),
  },
  {
    id: HelpTopicId.EDITOR_DISAPPEARED,
    title: "The editor file explorer seems to have disappeared. What do I do?",
    show: (props) => {
      return (
        isCodeModuleActiveOrNextAfterBreak(props.interview, props.serverTime) &&
        isCloudShellM2(props.interview, props.flags)
      );
    },
    render: (props) => (
      <Typography>
        Please try going to View &#x3E; Explorer in the top toolbar. Try this a
        few times if trying once does not fix the issue. If you continue running
        into issues, please{" "}
        <MUILink
          onClick={() => props.setActiveTopicId(HelpTopicId.CONTACT_FORM)}
        >
          submit a help request
        </MUILink>{" "}
        and a {brand.company} team member will assist you.
      </Typography>
    ),
  },
  {
    id: HelpTopicId.VIEW_MULTIPLE_FILES,
    title: "How do I view multiple files side by side?",
    show: (props) => {
      return (
        isCodeModuleActiveOrNextAfterBreak(props.interview, props.serverTime) &&
        isCloudShellM2(props.interview, props.flags)
      );
    },
    render: () => (
      <Typography>
        Open your files and drag them into your desired layout (side-by-side or
        stacked). Note, you can split the screen as many times as you prefer.
      </Typography>
    ),
  },
  {
    id: HelpTopicId.SUBMIT_ISSUES,
    title: "I’m running into issues submitting my code. What do I do?",
    show: (props) => isCodeModuleActive(props.interview, props.serverTime),
    render: (props) => {
      const supportEmail = getSupportEmail(props.interview);
      return (
        <Typography>
          If you for some reason cannot submit your code, please upload it to
          Google Drive and share it with{" "}
          <ExternalLink href={`mailto:${supportEmail}`} target="_blank">
            {supportEmail}
          </ExternalLink>
          .
        </Typography>
      );
    },
  },
  {
    id: HelpTopicId.FE_DEBUG_CODE,
    title: "How do I debug my code?",
    show: (props) => {
      return (
        isCodeModuleActiveOrNextAfterBreak(props.interview, props.serverTime) &&
        isWebInterview(props.interview)
      );
    },
    render: () => (
      <Typography>
        You can use your browser's development tools to set breakpoints, view
        output from console.log(), etc.
      </Typography>
    ),
  },
  {
    id: HelpTopicId.CONFIRM_SUBMISSION,
    title: "How do I confirm my submission was submitted?",
    show: (props) => isCodeModuleActive(props.interview, props.serverTime),
    render: (props) => (
      <Typography>
        You should see a {brand.company} pop-up that says “Part 2 has been
        submitted!” If you do not see that modal, please{" "}
        <MUILink
          onClick={() => props.setActiveTopicId(HelpTopicId.CONTACT_FORM)}
        >
          submit a help request
        </MUILink>{" "}
        and a {brand.company} team member will assist you.
      </Typography>
    ),
  },
  {
    id: HelpTopicId.CONTACT_FORM,
    title: "Still need help?",
    show: true,
    render: (props) => {
      const {interview} = props;
      const {contactFormText, isSubmitting, didSubmit} = props.helpDialog;
      const isSubmitEnabled = !isSubmitting && Boolean(contactFormText.length);
      if (didSubmit) {
        return (
          <React.Fragment>
            <br />
            <br />
            <br />
            <Typography paragraph={true}>
              Message sent! Please check your inbox for a confirmation email.
            </Typography>
            <br />
            <br />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <Typography paragraph={true}>
            If something is wrong with your environment, start by refreshing
            your page (don't worry, your work will be saved). If that does not
            work, check out <FAQLink>our FAQ</FAQLink>.
          </Typography>
          <Typography paragraph={true}>
            If you don't find an answer to your question, send us a message and
            a {brand.company} team member will assist you over email. Our team
            monitors requests 7 days a week, 8am to 8pm Pacific Time, and will
            usually get back to you within 15 minutes.
          </Typography>
          <TextField
            multiline={true}
            autoFocus={true}
            variant="outlined"
            fullWidth={true}
            onChange={(e) => props.setContactFormText(e.target.value)}
            value={contactFormText}
            placeholder="Oh no, our FAQ didn’t help you? Tell us what’s wrong."
            aria-label="Please describe the issue."
            rows={4}
            maxRows={15}
          />
          <br />
          <br />
          <Button
            color="primary"
            disabled={!isSubmitEnabled}
            variant="contained"
            onClick={(e) => props.submitHelpForm(contactFormText)}
          >
            {isSubmitting ? "Submitting" : "Submit"}
          </Button>
        </React.Fragment>
      );
    },
  },
];

const styles = () =>
  createStyles({
    root: {
      maxWidth: 600,
      width: "90%",
      "& .MuiLink-root": {
        color: brand.primaryColor.main,
        "&:hover": {
          color: brand.primaryColor.dark,
        },
      },
    },
    helpTopicRow: {
      borderRadius: 10,
      justifyContent: "flex-start",
      marginBottom: 15,
      padding: "10px 10px 10px 20px",
      width: "100%",
    },
    helpTopicRowText: {
      flex: 1,
      lineHeight: 1.3,
      marginRight: 20,
      textAlign: "left",
    },
    helpTopicTop: {
      alignItems: "center",
      display: "flex",
      padding: "15px 6px 10px 6px",
    },
    helpTopicTitle: {
      fontWeight: 500,
      marginLeft: 10,
    },
    helpTopicContent: {
      paddingLeft: 64,
    },
    buttonPadding: {
      paddingBottom: 15,
      paddingTop: 15,
    },
    downloadZipContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    downloadIcon: {
      height: 16,
      marginRight: 15,
      width: 16.5,
    },
  });

class HelpDialogBase extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (!prevProps.helpDialog.showDialog && this.props.helpDialog.showDialog) {
      this.props.logFEInfo(LogAction.HELP_DIALOG_OPENED);
    }
  }

  renderHelpTopics(): React.ReactNode[] {
    const {classes} = this.props;
    return helpTopics
      .filter((helpTopic) => {
        return typeof helpTopic.show === "function"
          ? helpTopic.show(this.props)
          : helpTopic.show;
      })
      .map((helpTopic) => (
        <Button
          variant="outlined"
          onClick={() => this.props.setActiveTopicId(helpTopic.id)}
          // @ts-ignore FIXME: strictNullChecks
          className={classes.helpTopicRow}
          key={helpTopic.id}
        >
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <Typography className={classes.helpTopicRowText}>
            {typeof helpTopic.title === "function"
              ? helpTopic.title(this.props)
              : helpTopic.title}
          </Typography>
          <ChevronRight />
        </Button>
      ));
  }

  renderHelpTopic(helpTopicId: string): React.ReactNode {
    const {classes} = this.props;
    const helpTopic = helpTopics.find((topic) => topic.id === helpTopicId);
    return (
      <React.Fragment>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.helpTopicTop}>
          <IconButton onClick={() => this.props.setActiveTopicId("")}>
            <ArrowBack />
          </IconButton>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <Typography className={classes.helpTopicTitle}>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            {typeof helpTopic.title === "function"
              ? // @ts-ignore FIXME: strictNullChecks
                helpTopic.title(this.props)
              : // @ts-ignore FIXME: strictNullChecks
                helpTopic.title}
          </Typography>
        </div>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <DialogContent className={classes.helpTopicContent}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          {helpTopic.render(this.props)}
        </DialogContent>
      </React.Fragment>
    );
  }

  renderMenu(): React.ReactNode {
    return (
      <React.Fragment>
        <DialogTitle>How can we help?</DialogTitle>
        <DialogContent>
          <Typography paragraph={true}>
            Refer to topics in the{" "}
            <MUILink
              color="primary"
              href={interviewHubUrl(this.props.interview.id)}
            >
              Interview Hub
            </MUILink>{" "}
            or the{" "}
            <MUILink
              color="primary"
              href={interviewGuideUrl(this.props.interview.id)}
            >
              Interview Guide
            </MUILink>{" "}
            at any time during the interview. Here’s a list of common questions
            for this part of your interview:
          </Typography>
          {this.renderHelpTopics()}
        </DialogContent>
      </React.Fragment>
    );
  }

  render(): React.ReactNode {
    const {helpDialog, classes, interview} = this.props;
    const {showDialog, activeTopicId} = helpDialog;
    if (!interview) return null;
    return (
      <Dialog
        open={showDialog}
        // @ts-ignore FIXME: strictNullChecks
        classes={{paper: classes.root}}
        onClose={this.props.hideHelpDialog}
      >
        {activeTopicId
          ? this.renderHelpTopic(activeTopicId)
          : this.renderMenu()}
        <DialogActions>
          <div />
          <Button onClick={this.props.hideHelpDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const serverTime = getServerTime(state.serverInfo.serverTimeOffset);
  return {
    helpDialog: state.helpDialogState,
    interview: state.interview,
    flags: state.flags,
    m2InterviewScreenState: state.m2InterviewScreenState,
    isUnresolvingComments: state.m1InterviewScreenState.isUnresolvingComments,
    isBBDocDisabledOrDisabling:
      state.interviewModuleScreenState.isBBDocDisabledOrDisabling,
    serverTime,
    user: state.user,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, HelpDialogAction>,
) => ({
  // @ts-ignore FIXME: No overload matches this call
  hideHelpDialog: () => dispatch(hideHelpDialog()),
  // @ts-ignore FIXME: strictNullChecks
  submitHelpForm: (message: string) => dispatch(submitHelpForm(message)),
  setActiveTopicId: (id: string) => dispatch(setActiveTopicId(id)),
  // @ts-ignore FIXME: strictNullChecks
  downloadTesterZip: (language: Language) => dispatch(downloadZip(language)),
  // @ts-ignore FIXME: strictNullChecks
  switchM2ToZip: () => dispatch(updateM2Type("zip")),
  // @ts-ignore FIXME: strictNullChecks
  switchM2ToCloudshell: () => dispatch(updateM2Type("cloudshell")),
  setContactFormText: (text: string) => dispatch(setContactFormText(text)),
  // @ts-ignore FIXME: strictNullChecks
  unresolveComments: () => dispatch(unresolveComments()),
  // @ts-ignore FIXME: strictNullChecks
  disableBBDoc: () => dispatch(disableBBDoc()),
  logFEInfo: (action: LogAction, data?: any) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(logFEInfo(action, data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore FIXME: strictNullChecks
)(withStyles(styles)(HelpDialogBase));
