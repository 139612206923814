import {ExternalLiveCodingInterview, Interview} from "../reducers/types";
import {getDisplayInterviewType} from "./interview";

export function getLiveCodingDisplayInterviewType(
  liveCodingInterview: ExternalLiveCodingInterview,
  interview: Interview,
): string {
  if (liveCodingInterview.configurationId === "core") {
    return getDisplayInterviewType(interview);
  } else {
    return "CodeCollab Interview";
  }
}
