import * as React from "react";
import Button from "@material-ui/core/Button";
import ExpandIcon from "./ExpandIcon";
import Spinner from "./Spinner";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import autobind from "autobind-decorator";
import classNames from "classnames";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

export interface InstructionItem {
  content: React.ReactNode;
  enabled: boolean;
}

interface Props extends StyledComponentProps {
  instructions: InstructionItem[];
  instructionsIndex: number;
  isExpanded: boolean;
  isSubmitEnabled: boolean;
  submitButtonLabel: string | JSX.Element;
  onSubmit?: () => any;
  onToggleExpand: () => any;
  onNext: () => any;
  onPrev: () => any;
}
interface State {
  /**
   * Keep track of visible instruction(s) so that off-screen instructions can't
   * be focused and so that unnecessary scrollbars don't appear.
   */
  visibleInstructionIndices: number[];
}

const instructionsExpandedWidth = 380;
const instructionsCollapsedWidth = 48;
const instructionsFooterHeight = 74;
const instructionsTransitionDuration = 300;

export const instructionsTextStyle = {
  color: "black",
};
const styles = (theme: Theme) =>
  createStyles({
    instructions: {
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      overflow: "hidden",
      position: "relative",
      transition: "width 0.3s",
    },
    instructionsTop: {
      bottom: instructionsFooterHeight,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    instructionsBottom: {
      alignItems: "flex-end",
      background: "#fff",
      bottom: 0,
      boxShadow: "0 0 5px rgba(0,0,0,0.1)",
      display: "flex",
      height: instructionsFooterHeight,
      justifyContent: "space-between",
      left: 0,
      minWidth: instructionsCollapsedWidth,
      position: "absolute",
      right: 0,
    },
    instructionsScroll: {
      bottom: 0,
      left: 0,
      overflowX: "hidden",
      overflowY: "auto",
      position: "absolute",
      right: 0,
      top: 0,
    },
    instructionsScrollHidden: {
      overflowY: "hidden",
    },
    instructionsOuterContainer: {
      display: "flex",
      transition:
        `opacity ${instructionsTransitionDuration}ms, ` +
        `transform ${instructionsTransitionDuration}ms`,
    },
    instructionsOuterContaierHidden: {
      height: `calc(100% - ${instructionsFooterHeight}px)`,
      opacity: 0,
    },
    instructionsContent: {
      boxSizing: "border-box",
      padding: "25px 20px 15px 15px",
      width: instructionsExpandedWidth,
    },
    instructionsContentHidden: {
      display: "none",
    },
    single: {},
    navButtons: {
      left: "50%",
      position: "absolute",
      top: 18,
      transform: "translateX(-50%)",
      // @ts-ignore
      width: theme.overrides.MuiToggleButton.root.minWidth * 2 + 2,
    },
    nextButton: {
      "&:not(:disabled)": {
        background: theme.palette.primary.main,
        color: "#fff",
      },
      "&:not(:disabled):hover": {
        background: theme.palette.primary.dark,
      },
      "&:disabled": {
        // @ts-ignore
        borderLeft: theme.overrides.MuiToggleButtonGroup.root.border,
      },
    },
    singleButton: {
      transform: "translateX(16px)",
    },
  });

class Instructions extends React.Component<Props, State> {
  state: State = {
    visibleInstructionIndices: [0],
  };
  private instructionsScrollEl?: HTMLElement;
  private transitionContainerEl?: HTMLElement;
  private addedTransitionEventListener = false;
  private windowResizeLoopId: any;
  private windowResizeLoopTimeoutId: any;

  componentDidMount() {
    this.maybeAddTransitionEventListener();
  }

  componentDidUpdate(prevProps: Props) {
    // Fixes a weird issue where the scroll position gets crazy if the
    // instructions are taller than the screen.
    const didExpand = !this.props.isExpanded && prevProps.isExpanded;
    const didNavigate =
      this.props.instructionsIndex !== prevProps.instructionsIndex;
    if (didExpand || didNavigate) {
      // @ts-ignore FIXME: strictNullChecks
      this.instructionsScrollEl.scrollTop = 0;
    }
    if (didNavigate) {
      this.transitionToInstructionsIndex(this.props.instructionsIndex);
    }
    this.maybeAddTransitionEventListener();
  }

  componentWillUnmount() {
    if (this.transitionContainerEl) {
      this.transitionContainerEl.removeEventListener(
        "transitionend",
        this.onExpandTransitionEnd,
      );
    }
    clearTimeout(this.windowResizeLoopTimeoutId);
    cancelAnimationFrame(this.windowResizeLoopId);
  }

  isTransitioningInstructions(): boolean {
    return this.state.visibleInstructionIndices.length > 1;
  }

  maybeAddTransitionEventListener() {
    if (this.transitionContainerEl && !this.addedTransitionEventListener) {
      this.transitionContainerEl.addEventListener(
        "transitionend",
        this.onExpandTransitionEnd,
      );
      this.addedTransitionEventListener = true;
    }
  }

  @autobind
  async toggleExpandInstructions() {
    this.props.onToggleExpand();
    this.emitWindowResizeEventLoop();
    await new Promise((resolve) => {
      this.windowResizeLoopTimeoutId = setTimeout(
        resolve,
        instructionsTransitionDuration,
      );
    });
    cancelAnimationFrame(this.windowResizeLoopId);
  }

  @autobind
  emitWindowResizeEventLoop() {
    this.emitWindowResizeEvent();
    this.windowResizeLoopId = requestAnimationFrame(
      this.emitWindowResizeEventLoop,
    );
  }

  @autobind
  emitWindowResizeEvent() {
    window.dispatchEvent(new Event("resize"));
  }

  @autobind
  onPrevInstructions() {
    if (this.isTransitioningInstructions()) return;
    this.props.onPrev();
  }

  @autobind
  onNextInstructions() {
    const {instructions, instructionsIndex, isSubmitEnabled} = this.props;
    if (this.isTransitioningInstructions()) return;
    if (instructionsIndex < instructions.length - 1) {
      this.props.onNext();
    } else if (isSubmitEnabled) {
      // @ts-ignore FIXME: strictNullChecks
      this.props.onSubmit();
    }
  }

  @autobind
  onExpandTransitionEnd() {
    // Make sure the timers are all canceled and emit resize one last time.
    clearTimeout(this.windowResizeLoopTimeoutId);
    cancelAnimationFrame(this.windowResizeLoopId);
    this.emitWindowResizeEvent();
  }

  /** Updates this.state.visibleInstructionIndices */
  async transitionToInstructionsIndex(index: number) {
    await new Promise<void>((resolve) =>
      this.setState(
        {
          visibleInstructionIndices: [
            ...this.state.visibleInstructionIndices,
            index,
          ],
        },
        resolve,
      ),
    );
    await new Promise((resolve) =>
      setTimeout(resolve, instructionsTransitionDuration),
    );
    await new Promise<void>((resolve) =>
      this.setState(
        {
          visibleInstructionIndices: [index],
        },
        resolve,
      ),
    );
  }

  renderInstructionsNavButtons(): React.ReactNode {
    const {
      classes,
      instructions,
      instructionsIndex,
      isExpanded,
      isSubmitEnabled,
      submitButtonLabel,
    } = this.props;
    if (!isExpanded) return null;
    const multiple = instructions.length > 1;
    const prevInstruction =
      instructionsIndex > 0 ? instructions[instructionsIndex - 1] : null;
    const isPrevEnabled = prevInstruction && prevInstruction.enabled;
    const nextInstruction =
      instructionsIndex < instructions.length - 1
        ? instructions[instructionsIndex + 1]
        : null;
    const isNextEnabled =
      (nextInstruction && nextInstruction.enabled) ||
      (!nextInstruction && isSubmitEnabled);
    const nextButtonLabel = nextInstruction ? "Next" : submitButtonLabel;
    return (
      <div
        // @ts-ignore FIXME: strictNullChecks
        className={classNames(classes.navButtons, {
          // @ts-ignore FIXME: strictNullChecks
          [classes.single]: instructions.length === 1,
        })}
      >
        {multiple ? (
          <ToggleButtonGroup>
            <ToggleButton
              value="prev"
              disabled={!isPrevEnabled}
              onClick={this.onPrevInstructions}
            >
              Prev
            </ToggleButton>
            <ToggleButton
              value="next"
              disabled={!isNextEnabled}
              // @ts-ignore FIXME: strictNullChecks
              className={classes.nextButton}
              onClick={this.onNextInstructions}
            >
              {nextButtonLabel}
            </ToggleButton>
          </ToggleButtonGroup>
        ) : (
          <Button
            color="primary"
            disabled={!isSubmitEnabled}
            // @ts-ignore FIXME: strictNullChecks
            className={classes.singleButton}
            key={`submit${instructionsIndex}`}
            variant="contained"
            onClick={this.props.onSubmit}
          >
            {submitButtonLabel}
          </Button>
        )}
      </div>
    );
  }

  render(): React.ReactNode {
    const {classes, instructions, instructionsIndex, isExpanded} = this.props;
    const {visibleInstructionIndices} = this.state;
    const width = isExpanded
      ? instructionsExpandedWidth
      : instructionsCollapsedWidth;
    const instructionsOuterContainerStyle = {
      transform: `translateX(-${
        instructionsIndex * instructionsExpandedWidth
      }px)`,
      width: instructions.length * instructionsExpandedWidth,
    };
    if (!instructions.length) {
      return (
        // @ts-ignore FIXME: strictNullChecks
        <div className={classes.instructions} style={{width}}>
          <Spinner />
        </div>
      );
    }
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.instructions} style={{width}}>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.instructionsTop}>
          <div
            // @ts-ignore FIXME: strictNullChecks
            className={classNames(classes.instructionsScroll, {
              // @ts-ignore FIXME: strictNullChecks
              [classes.instructionsScrollHidden]: !isExpanded,
            })}
            // @ts-ignore FIXME: strictNullChecks
            ref={(ref) => (this.instructionsScrollEl = ref)}
          >
            <div
              // @ts-ignore FIXME: strictNullChecks
              className={classNames(classes.instructionsOuterContainer, {
                // @ts-ignore FIXME: strictNullChecks
                [classes.instructionsOuterContaierHidden]: !isExpanded,
              })}
              style={instructionsOuterContainerStyle}
              // @ts-ignore FIXME: strictNullChecks
              ref={(ref) => (this.transitionContainerEl = ref)}
            >
              {instructions.map((curInstruction: InstructionItem, index) => (
                // @ts-ignore FIXME: strictNullChecks
                <div className={classes.instructionsContent} key={index}>
                  <div
                    className={
                      !visibleInstructionIndices.includes(index)
                        ? // @ts-ignore FIXME: strictNullChecks
                          classes.instructionsContentHidden
                        : ""
                    }
                  >
                    {curInstruction.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.instructionsBottom}>
          {this.renderInstructionsNavButtons()}
          <ExpandIcon
            isExpanded={isExpanded}
            expandLabel="Expand side panel"
            collapseLabel="Collapse side panel"
            onChange={this.toggleExpandInstructions}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Instructions);
