import Typography from "@material-ui/core/Typography";
import {WavingHandIcon} from "../../../components/CustomIcons";
import {brand} from "../../../branding";
import React from "react";

type Props = {
  title: string;
};
export const Title = ({title}: Props) => {
  return (
    <Typography variant="h1">
      <WavingHandIcon color={brand.primaryColor.main} /> {title}
    </Typography>
  );
};
