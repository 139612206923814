import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React, {useContext} from "react";
import {ApplicantContext} from "../context/ApplicantContext";
import {Skill} from "../helpers/types";
import {useSharedStyles} from "../hooks/useSharedStyles";

const useStyles = makeStyles((theme) => ({
  skillBox: {
    columnGap: "16px",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "12px",
  },
  chip: {
    fontWeight: "bold",
  },
  selectedChip: {
    color: "white",
    // MUI is making it difficult to override the background color, so we've go to bust out !important
    backgroundColor: `${theme.palette.primary.main} !important`,
    "&:hover, &:focus": {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

export default function Skills() {
  const {skills, setSkills, uiState} = useContext(ApplicantContext);
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const currentUuid = "uuid" in uiState ? uiState.uuid : undefined;
  if (!currentUuid) return null;

  const handleToggleSkill = (skillName: Skill["name"]) => {
    setSkills((oldSkills) =>
      oldSkills.map((skill) => {
        if (skill.name === skillName) {
          if (skill.experiences.includes(currentUuid)) {
            return {
              ...skill,
              experiences: skill.experiences.filter(
                (uuid) => uuid !== currentUuid,
              ),
            };
          } else {
            return {
              ...skill,
              experiences: [...skill.experiences, currentUuid],
            };
          }
        }
        return skill;
      }),
    );
  };

  return (
    <Box className={sharedClasses.gridBoxRowGap24}>
      <Typography variant="body2">
        To help the hiring team better understand your background, please select
        any skills that apply to this experience.
      </Typography>
      <Box className={classes.skillBox}>
        {skills.map(({name, experiences}) => {
          const selected = experiences.includes(currentUuid);
          return (
            <Chip
              className={clsx(classes.chip, selected && classes.selectedChip)}
              key={name}
              label={name}
              onClick={() => handleToggleSkill(name)}
              variant="outlined"
            />
          );
        })}
      </Box>
    </Box>
  );
}
