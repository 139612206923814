import Typography from "@material-ui/core/Typography";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import classNames from "classnames";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LogAction, logFEInfo} from "../../actions/serverInfoActions";
import CenteredScreenContent from "../../components/CenteredScreenContent";
import {withInterview} from "../../components/InterviewLoader";
import MinimalHeader from "../../components/MinimalHeader";
import SupportEmail from "../../components/SupportEmail";
import {interviewHubUrl} from "../../helpers/urls/routerUrls";
import part2SubmittedImage from "../../img/interview-center/part-2-submitted.png";
import viewExplorerImage from "../../img/interview-center/view-explorer.png";
import {Interview} from "../../reducers/types";
import {AccordionItemInterface, AccordionItems} from "./components/Accordion";
import {useStyles} from "./InterviewHubScreen.styles";

interface Props extends RouteComponentProps {
  interview: Interview;
}

const InterviewHubHelpScreen = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logFEInfo(LogAction.INTERVIEW_HUB_HELP_PAGE_VIEWED));
  }, []);

  const helpData: AccordionItemInterface[] = [
    {
      title: "Where can I find in-platform help?",
      content: (
        <>
          <Typography paragraph={true}>
            Click the{" "}
            <HelpOutlineOutlinedIcon
              className={classes.miniHelpButton}
              aria-label="help icon"
            />{" "}
            icon in the top right corner of your screen in the platform for a
            list of common Help topics.
          </Typography>
          <Typography paragraph={true}>
            If you still need help and would like to talk to someone from the
            Byteboard team, click on “Still need help?” and enter your question
            or request.
          </Typography>
          <Typography paragraph={true}>
            A member of the Byteboard team will assist you over email.
          </Typography>
          <Typography>
            Our team monitors requests 7 days a week, 8 AM to 10 PM Pacific
            Time, and will usually get back to you within 15 minutes.
          </Typography>
        </>
      ),
    },
    {
      title: "I can’t access my interview. What do I do?",
      content: (
        <>
          <ol>
            <li>
              Try refreshing the platform if it is stuck. Any work you have done
              will be saved.
            </li>
            <li>Read troubleshooting tips for Parts 1 and 2 below.</li>
            <li>
              If you still cannot access your interview materials, or have
              another pressing issue, please email <SupportEmail /> and a
              Byteboard team member will assist.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "How do I respond to comments in the document?",
      content: (
        <>
          <ol>
            <li>
              If you don’t see any comments, make sure your window is wide
              enough; they should appear floating on the right side of the
              document.
            </li>
            <li>Click the comment you want to respond to.</li>
            <li>Everything you type will automatically save.</li>
          </ol>
        </>
      ),
    },
    {
      title: "How do I add my own comments to the document?",
      content: (
        <>
          <ol>
            <li>Highlight the text you would like to comment on.</li>
            <li>
              You should see the “Add Comment” button appear on the right side
              of the document. Click that button.
            </li>
            <li>
              Write your comment. All changes will be saved automatically.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "I can’t run the provided code. What do I do?",
      content: (
        <>
          <Typography paragraph={true}>
            <strong>Your Personal IDE</strong>
          </Typography>
          <Typography paragraph={true}>
            If you are running on a Windows machine, you can try renaming our
            provided <code>*.sh</code> files to <code>*.bat</code> files.
          </Typography>
          <Typography paragraph={true}>
            If you are still unable to run your code or are running into any
            other issues, please submit a help request and a Byteboard team
            member will assist you.
          </Typography>
          <Typography paragraph={true}>
            <strong>Byteboard Editor</strong>
          </Typography>
          <Typography paragraph={true}>
            If you are having issues running the provided code, please submit a
            help request and a Byteboard team member will assist you.
          </Typography>
        </>
      ),
    },
    {
      title: "I’m running into issues submitting my code. What do I do?",
      content: (
        <>
          <Typography paragraph={true}>
            If you for some reason cannot submit your code, save your code, try
            refreshing and submitting again.
          </Typography>
          <Typography>
            If you’re still having trouble, please email a zip file containing
            your code to <SupportEmail />.
          </Typography>
        </>
      ),
    },
    {
      title: "How do I confirm my submission was submitted?",
      content: (
        <>
          <Typography paragraph={true}>
            You should see this dialog that says “Your Part 2 was submitted.”
          </Typography>
          <Typography>
            If you do not see that dialog, please submit a help request and a
            Byteboard team member will assist you.
          </Typography>
          <img
            src={part2SubmittedImage}
            alt="Part 2 has been submitted"
            width="410"
          />
        </>
      ),
    },
    {
      title:
        "I am having issues opening Byteboard’s online editor. What do I do?",
      content: (
        <>
          <Typography paragraph={true}>
            If you are having issues accessing the Byteboard editor try and
            refresh the page.
          </Typography>
          <Typography>
            If you continue running into issues please submit a help request and
            a Byteboard team member will assist you.
          </Typography>
        </>
      ),
    },
    {
      title:
        "The editor file explorer seems to have disappeared. What do I do?",
      content: (
        <>
          <Typography paragraph={true}>
            {"Please try going to View > Explorer in the top toolbar."}
          </Typography>
          <Typography paragraph={true}>
            Try this a few times if trying once does not fix the issue.
          </Typography>
          <Typography>
            If you continue running into issues, please submit a help request
            and a Byteboard team member will assist you.
          </Typography>
          <img
            src={viewExplorerImage}
            alt="View > Explorer in toolbar"
            width="263"
          />
        </>
      ),
    },
  ];

  const onBack = () => {
    props.history.replace(interviewHubUrl(props.interview.id));
  };

  return (
    <React.Fragment>
      <MinimalHeader title="Help & Troubleshooting" onBack={onBack} />
      <main className={classes.content}>
        <CenteredScreenContent size="normal">
          <main className={classNames(classes.root, classes.withAccordion)}>
            <AccordionItems data={helpData} />
          </main>
        </CenteredScreenContent>
      </main>
    </React.Fragment>
  );
};

export default withInterview([], {ignoreGlobalRules: ["terms", "getStarted"]})(
  withRouter(InterviewHubHelpScreen),
);
