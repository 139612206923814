import {createBrowserHistory} from "history";
import * as React from "react";
import {matchPath, Route} from "react-router";
import {Redirect, RouteComponentProps, Router, Switch} from "react-router-dom";
import Shell from "./components/Shell";
import {interviewBasePath, languages, redirectUrl} from "./config.js";
import {interviewHubUrl, termsUrl} from "./helpers/urls/routerUrls";
import {Language, M2Type} from "./reducers/types";
import AccommodationScreen from "./screens/AccommodationScreen";
import ApplicantScreen from "./screens/Applicant";
import BBDocViewScreen from "./screens/BBDocViewScreen";
import BBHCConsentScreen from "./screens/BBHCConsentScreen";
import GetStartedScreen from "./screens/GetStartedScreen";
import InterviewHubFAQScreen from "./screens/interviewHub/InterviewHubFAQScreen";
import InterviewHubGuideScreen from "./screens/interviewHub/InterviewHubGuideScreen";
import InterviewHubHelpScreen from "./screens/interviewHub/InterviewHubHelpScreen";
import {InterviewHubScreen} from "./screens/interviewHub/InterviewHubScreen";
import InterviewSurveyScreen from "./screens/InterviewSurveyScreen";
import LiveCodingSurveyScreen from "./screens/LiveCodingSurveyScreen";
import M1InterviewScreen from "./screens/M1InterviewScreen";
import M2InterviewScreen from "./screens/M2InterviewScreen";
import M2TesterScreen from "./screens/M2TesterScreen";
import OverviewScreen from "./screens/OverviewScreen";
import PostSurveyScreen from "./screens/PostSurveyScreen";
import TermsScreen from "./screens/TermsScreen";
import {getMostRecentUrl} from "./util/auth";
import {LiveCodingInterviewHubScreen} from "./screens/interviewHub/LiveCodingInterviewHubScreen";
import {PostLiveCodingSurveyScreen} from "./screens/PostLiveCodingSurveyScreen";

const history = createBrowserHistory();
const atsIdParam = ":atsId([a-zA-Z0-9]{20})";
const m2TypeParam = ":m2Type(cloudshell|zip)";
const liveCodingInterviewIdParam = ":liveCodingInterviewId([a-zA-Z0-9]{20})";

export type ScreenName =
  | "applicant"
  | "loginRedirect"
  | "terms"
  | "interview"
  | "getStarted"
  | "tester"
  | "overview"
  | "interviewModule"
  | "part1"
  | "part2"
  | "survey"
  | "liveCodingSurvey"
  | "thankYou"
  | "liveCodingThankYou"
  | "accommodation"
  | "bbdocView"
  | "bbhc"
  | "prepGuideRedirect"
  | "interviewHub"
  | "liveCodingInterviewHub"
  | "interviewHubGuide"
  | "interviewHubFAQ"
  | "interviewHubHelp";

interface ScreenRenderer {
  name: ScreenName;
  component: any;
  hideNavBar?: boolean;
  render?: (props: RouteComponentProps) => React.ReactNode;
}

const pathToScreenRendererMap: {[s: string]: ScreenRenderer} = {
  [`${interviewBasePath}/applicant/${atsIdParam}`]: {
    name: "applicant",
    component: ApplicantScreen,
    hideNavBar: true,
  },
  [`${redirectUrl}`]: {
    name: "loginRedirect",
    component: Redirect,
    render: () => <Redirect to={getMostRecentUrl()} />,
  },
  [`${interviewBasePath}/terms/${atsIdParam}`]: {
    name: "terms",
    component: TermsScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <TermsScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/${atsIdParam}`]: {
    name: "interview",
    component: Redirect,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <Redirect to={termsUrl(props.match.params.atsId)} />
    ),
  },
  [`${interviewBasePath}/bbhc/${atsIdParam}`]: {
    name: "bbhc",
    component: BBHCConsentScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <BBHCConsentScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/get-started/${atsIdParam}`]: {
    name: "getStarted",
    component: GetStartedScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <GetStartedScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/tester/${atsIdParam}/${m2TypeParam}/` +
  `:language(${languages.join("|")})`]: {
    name: "tester",
    component: M2TesterScreen,
    render: (
      props: RouteComponentProps<{
        atsId: string;
        m2Type: M2Type;
        language: Language;
      }>,
    ) => (
      <M2TesterScreen
        atsId={props.match.params.atsId}
        m2Type={props.match.params.m2Type}
        language={props.match.params.language}
      />
    ),
  },
  [`${interviewBasePath}/overview/${atsIdParam}`]: {
    name: "overview",
    component: OverviewScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <OverviewScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/part1/${atsIdParam}`]: {
    name: "part1",
    component: M1InterviewScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <M1InterviewScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/part2/${atsIdParam}`]: {
    name: "part2",
    component: M2InterviewScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <M2InterviewScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/survey/${atsIdParam}`]: {
    name: "survey",
    component: InterviewSurveyScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <InterviewSurveyScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/codecollab/survey/${liveCodingInterviewIdParam}`]: {
    name: "liveCodingSurvey",
    component: LiveCodingSurveyScreen,
    render: (props: RouteComponentProps<{liveCodingInterviewId: string}>) => (
      <LiveCodingSurveyScreen
        liveCodingInterviewId={props.match.params.liveCodingInterviewId}
      />
    ),
  },
  [`${interviewBasePath}/thank-you/${atsIdParam}`]: {
    name: "thankYou",
    component: PostSurveyScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <PostSurveyScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/codecollab/thank-you/${liveCodingInterviewIdParam}`]: {
    name: "liveCodingThankYou",
    component: PostLiveCodingSurveyScreen,
    render: (props: RouteComponentProps<{liveCodingInterviewId: string}>) => (
      <PostLiveCodingSurveyScreen
        liveCodingInterviewId={props.match.params.liveCodingInterviewId}
      />
    ),
  },
  [`${interviewBasePath}/accommodation/${atsIdParam}`]: {
    name: "accommodation",
    component: AccommodationScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <AccommodationScreen atsId={props.match.params.atsId} />
    ),
  },
  [`${interviewBasePath}/doc/${atsIdParam}/:moduleId`]: {
    name: "bbdocView",
    component: BBDocViewScreen,
    render: (props: RouteComponentProps<{atsId: string; moduleId: string}>) => (
      <BBDocViewScreen
        atsId={props.match.params.atsId}
        moduleId={props.match.params.moduleId}
      />
    ),
  },
  // Prep Guide Redirect (Old Version of Interview Hub)
  [`${interviewBasePath}/prep-guide/${atsIdParam}`]: {
    name: "prepGuideRedirect",
    component: Redirect,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <Redirect to={interviewHubUrl(props.match.params.atsId)} />
    ),
  },
  // Interview Hub
  [`${interviewBasePath}/hub/${atsIdParam}`]: {
    name: "interviewHub",
    component: InterviewHubScreen,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <InterviewHubScreen atsId={props.match.params.atsId} />
    ),
  },
  // Live Coding Interview Hub
  [`${interviewBasePath}/codecollab/${liveCodingInterviewIdParam}`]: {
    name: "liveCodingInterviewHub",
    component: LiveCodingInterviewHubScreen,
    render: (props: RouteComponentProps<{liveCodingInterviewId: string}>) => (
      <LiveCodingInterviewHubScreen
        liveCodingInterviewId={props.match.params.liveCodingInterviewId}
      />
    ),
  },

  // // Interview Hub - Guide
  [`${interviewBasePath}/hub/guide/${atsIdParam}`]: {
    name: "interviewHubGuide",
    component: InterviewHubGuideScreen,
    hideNavBar: true,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <InterviewHubGuideScreen atsId={props.match.params.atsId} />
    ),
  },
  // Interview Hub - FAQ
  [`${interviewBasePath}/hub/faq/${atsIdParam}`]: {
    name: "interviewHubFAQ",
    component: InterviewHubFAQScreen,
    hideNavBar: true,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <InterviewHubFAQScreen atsId={props.match.params.atsId} />
    ),
  },
  // Interview Hub - Help & Troubleshooting
  [`${interviewBasePath}/hub/help/${atsIdParam}`]: {
    name: "interviewHubHelp",
    component: InterviewHubHelpScreen,
    hideNavBar: true,
    render: (props: RouteComponentProps<{atsId: string}>) => (
      <InterviewHubHelpScreen atsId={props.match.params.atsId} />
    ),
  },
};

export function getActiveScreen(): ScreenRenderer | null {
  for (const path of Object.keys(pathToScreenRendererMap)) {
    const match = matchPath(location.pathname, {
      path,
      exact: true,
    });
    if (match) {
      return pathToScreenRendererMap[path];
    }
  }
  return null;
}

export function activeScreenNotFound(): boolean {
  const activeScreen = getActiveScreen();
  return !activeScreen;
}

export default class Routes extends React.Component<{}> {
  renderRoutes(): React.ReactNode {
    return Object.keys(pathToScreenRendererMap).map((path) => {
      const {render, component} = pathToScreenRendererMap[path];
      const props = {
        key: path,
        path,
        render,
        ...(!render ? {component} : {}),
      };
      return <Route exact {...props} />;
    });
  }

  render(): React.ReactNode {
    return (
      <Router history={history}>
        <Shell>
          <Switch>{this.renderRoutes()}</Switch>
        </Shell>
      </Router>
    );
  }
}
