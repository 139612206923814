import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import {getAPIBaseUrl} from "../../../helpers/api";
import getAtsIdFromUrl from "../../../helpers/urls/getAtsIdFromUrl";
import {get} from "../../../util/fetch";
import {
  normalizeEducation,
  normalizeProject,
  normalizeRole,
} from "../helpers/entries";
import {
  Education,
  GetPortfolioValidationStatusResponseBody,
  GetUnvalidatedPortfolioResponseBody,
  Project,
  ResponsePortfolio,
  Role,
  Skill,
  UIState,
} from "../helpers/types";

type ApplicantContextType = {
  education: Required<Education>[];
  hasReachedFinalReview: boolean;
  projects: Required<Project>[];
  responsePortfolio: ResponsePortfolio | null;
  roles: Required<Role>[];
  setEducation: React.Dispatch<React.SetStateAction<Required<Education>[]>>;
  setProjects: React.Dispatch<React.SetStateAction<Required<Project>[]>>;
  setRoles: React.Dispatch<React.SetStateAction<Required<Role>[]>>;
  setSkills: React.Dispatch<React.SetStateAction<Skill[]>>;
  setUIState: React.Dispatch<React.SetStateAction<UIState>>;
  skills: Skill[];
  uiState: UIState;
};

export const ApplicantContext = createContext<ApplicantContextType>({
  education: [],
  hasReachedFinalReview: false,
  projects: [],
  responsePortfolio: null,
  roles: [],
  setEducation: () => undefined,
  setProjects: () => undefined,
  setRoles: () => undefined,
  setSkills: () => undefined,
  setUIState: () => undefined,
  skills: [],
  uiState: {step: "loading"},
});

export function ApplicantContextProvider({children}: PropsWithChildren<{}>) {
  const [responsePortfolio, setResponsePortfolio] =
    useState<ResponsePortfolio | null>(null);
  const [roles, setRoles] = useState<Required<Role>[]>([]);
  const [education, setEducation] = useState<Required<Education>[]>([]);
  const [projects, setProjects] = useState<Required<Project>[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [uiState, setUIState] = useState<UIState>({
    step: "loading",
  });

  useEffect(() => {
    const applicantId = getAtsIdFromUrl();
    if (applicantId) {
      // useEffect doesn't like being given async functions directly, so we use an IIFE
      (async () => {
        const validationStatusRes = await get(
          `${getAPIBaseUrl()}/applicant/${applicantId}/validationStatus`,
        );
        const {validated}: GetPortfolioValidationStatusResponseBody =
          await validationStatusRes.json();

        if (validated) {
          setUIState({step: "thankYou"});
          return;
        }

        const unvalidatedRes = await get(
          `${getAPIBaseUrl()}/applicant/${applicantId}/unvalidated`,
        );
        const {portfolio: resPortfolio}: GetUnvalidatedPortfolioResponseBody =
          await unvalidatedRes.json();

        // Store this so we can access the `bio` later and potentially use in the future for
        // undoing changes.
        setResponsePortfolio(resPortfolio);
        // Response and request portfolios have slightly different shapes, so we transform them
        // all to the request version from the beginning since it's a little easier to work with.
        setRoles(resPortfolio.roles.map(normalizeRole));
        setEducation(resPortfolio.education.map(normalizeEducation));
        setProjects(resPortfolio.projects.map(normalizeProject));
        // We want the applicant to opt-in to skills, so we don't include any unvalidated matches
        setSkills(
          resPortfolio.skills.map(
            (skill): Skill => ({...skill, experiences: []}),
          ),
        );

        setUIState({step: "getStarted"});
      })();
    }
  }, []);

  // This lets us replace the usual progression buttons with a "return to final review"
  // button once the user has reached that step.
  const [hasReachedFinalReview, setHasReachedFinalReview] = useState(false);
  useEffect(() => {
    if (uiState.step === "finalReview") {
      setHasReachedFinalReview(true);
    }
  }, [uiState]);

  return (
    <ApplicantContext.Provider
      value={{
        education,
        hasReachedFinalReview,
        projects,
        responsePortfolio,
        roles,
        setEducation,
        setProjects,
        setRoles,
        setSkills,
        setUIState,
        skills,
        uiState,
      }}
    >
      {children}
    </ApplicantContext.Provider>
  );
}
