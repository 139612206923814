import {AppState, ThunkDispatch} from "../reducers/types";
import {AnyAction} from "redux";
import {GetStartedScreenAction} from "./actionTypes";
import {Language, M2Type} from "../reducers/types";
import {ThunkAction} from "redux-thunk";
import {createInterview} from "./interviewActions";
import {setErrorMessage} from "./errorActions";

export const OVERVIEW_STEP = 1;
export const LANGUAGE_STEP = 2;
export const M2_TYPE_STEP = 3;

export function setOverview(): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.SET_OVERVIEW",
  };
}

export function setLanguage(language: Language): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.SET_LANGUAGE",
    language,
  };
}

export function setM2Type(value: M2Type | ""): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.SET_M2_TYPE",
    value,
  };
}

export function toggleCompletedStep(value: number): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.TOGGLE_COMPLETED_STEP",
    value,
  };
}

export function toggleDocsWalkthroughDialog(): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.TOGGLE_DOCS_WALKTHROUGH_DIALOG",
  };
}

/**
 * @returns Whether or not the interview was created (or already existed).
 */
export function submit(
  atsId: string,
  language: Language,
  m2Type: M2Type,
): ThunkAction<Promise<boolean>, {}, {}, AnyAction> {
  return async (
    dispatch: ThunkDispatch,
    getState: () => AppState,
  ): Promise<boolean> => {
    dispatch({
      type: "GET_STARTED_SCREEN.IS_SUBMITTING",
      value: true,
    });
    const {user} = getState();
    try {
      await createInterview(atsId, user, language, m2Type);
      return true;
    } catch (err) {
      dispatch(
        setErrorMessage(
          "Your interview has failed to load. Look out for an email from our support team with next steps shortly.",
          {
            hideTips: true,
          },
        ),
      );
      return false;
    }
  };
}

export function toggleExpandOverviewStep(
  value?: boolean,
): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.TOGGLE_EXPAND_OVERVIEW_STEP",
    value,
  };
}

export function toggleExpandLanguageStep(
  value?: boolean,
): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.TOGGLE_EXPAND_LANGUAGE_STEP",
    value,
  };
}

export function toggleExpandM2TypeStep(
  value?: boolean,
): GetStartedScreenAction {
  return {
    type: "GET_STARTED_SCREEN.TOGGLE_EXPAND_M2_TYPE_STEP",
    value,
  };
}
