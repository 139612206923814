import {makeStyles, Theme} from "@material-ui/core/styles";
import {brand} from "../../branding";

const colors = {
  black: "#000000",
  white: "#FFFFFF",
  text: "#333333",
  border: "#DDDDDD",
  secondaryLight: "#F8F5FF",
  secondary: "#EBE3FF",
  secondaryDark: "#DCCAFF",
  primary: "#4038DE",
};

const borders = {
  secondaryDark: `1px solid ${colors.secondaryDark}`,
  secondaryLight: `3px solid ${colors.secondaryLight}`,
  default: `1px solid ${colors.border}`,
};

const spacing = {
  quarter: "4px",
  half: "8px",
  default: "16px",
  oneHalf: "24px",
  double: "32px",
  triple: "48px",
};

export const fontWeights = {
  bold: 700,
};

export const fontSizes = {
  heading: 26,
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    "& .MuiLink-root": {
      color: brand.primaryColor.main,
      "&:hover": {
        color: brand.primaryColor.dark,
      },
    },
    "& img": {
      maxWidth: "100%",
      margin: `${spacing.default} auto`,
      display: "block",
    },
    "& iframe": {
      margin: `${spacing.default} auto`,
    },
    "& iframe.video": {
      width: "875px",
      height: "auto",
      maxWidth: "100%",
      aspectRatio: "875 / 496",
    },
    "& iframe.slides": {
      width: "960px",
      height: "auto",
      maxWidth: "100%",
      aspectRatio: "960 / 569",
    },
    "& iframe.figma-prototype": {
      width: "960px",
      height: "auto",
      maxWidth: "100%",
      aspectRatio: "960 / 562",
      border: borders.secondaryDark,
    },
    "& .MuiTypography-body2": {
      fontSize: spacing.default,
    },
    "& .MuiTypography-h1": {
      fontSize: 26,
      lineHeight: spacing.double,
      letterSpacing: "0.6px",
    },
    "& .MuiTypography-h2": {
      fontFamily: "Roboto, Arial",
      fontWeight: 500,
      fontSize: spacing.default,
      lineHeight: spacing.oneHalf,
      letterSpacing: "0.1px",
      textTransform: "uppercase",
      color: brand.primaryColor.main,
      marginTop: spacing.double,
      marginBottom: spacing.default,
    },
    "& .MuiTypography-h3": {
      fontFamily: "Roboto, Arial",
      fontWeight: 500,
      fontSize: spacing.default,
      lineHeight: spacing.oneHalf,
      letterSpacing: "0.25px",
      color: colors.text,
      marginBottom: spacing.default,
    },
    "& .MuiTypography-h4": {
      fontFamily: "Roboto, Arial",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: colors.text,
      marginTop: spacing.oneHalf,
      marginBottom: "12px",
    },
    "& ol, ul": {
      paddingInlineStart: spacing.default,
      "& li": {
        marginBottom: spacing.quarter,
        color: colors.black,
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    "& ol ul": {
      marginTop: spacing.half,
    },
    "& .MuiAccordion-root": {
      border: "none",
      marginBottom: 0,
      "& .MuiAccordionSummary-root": {
        padding: 0,
        flexDirection: "row-reverse",
        minHeight: "unset",
        "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
          transform: "rotate(90deg)",
        },
        "& .MuiTypography-root": {
          marginBottom: 0,
        },
      },
      "& .MuiAccordionDetails-root": {
        paddingTop: 0,
        display: "block",
      },
    },
  },
  withAccordion: {
    "& .MuiTypography-h2": {
      marginLeft: "18px",
      marginBottom: spacing.quarter,
    },
  },
  callout: {
    backgroundColor: colors.secondaryLight,
    padding: 22,
    borderRadius: 4,
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: {
      padding: spacing.default,
      margin: "16px 0",
    },
  },
  calloutWithBorder: {
    border: borders.secondaryDark,
    color: brand.primaryColor.main,
    "& .MuiTypography-root": {
      color: brand.primaryColor.main,
      marginBottom: 0,
    },
    "&:hover": {
      backgroundColor: colors.secondaryDark,
      "& .MuiTypography-root": {
        color: brand.primaryColor.dark,
      },
      "& .MuiSvgIcon-root": {
        color: brand.primaryColor.dark,
      },
    },
  },
  calloutWithIcon: {
    display: "grid",
    gridTemplateColumns: "32px auto",
    justifyItems: "flex-start",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: brand.primaryColor.main,
    },
  },
  calloutWithInline: {
    margin: "16px 0",
  },
  separator: {
    border: "none",
    borderBottom: borders.default,
    marginBottom: "48px",
  },
  miniHelpButton: {
    marginTop: "-7px",
    marginBottom: "-7px",
    marginRight: "5px",
    color: "inherit",
  },
  interviewOverviewGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    marginTop: spacing.oneHalf,
    marginBottom: spacing.double,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  interviewOverviewGridActions: {
    display: "flex",
    flexWrap: "wrap",
    justifyItems: "center",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    alignContent: "space-evenly",
    rowGap: "8px",
    [theme.breakpoints.down("sm")]: {
      columnGap: "8px",
      marginTop: spacing.default,
    },
  },
  actionItemContainer: {
    width: "272px",
  },
  buttonLink: {
    borderRadius: spacing.half,
    backgroundColor: brand.primaryColor.main,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
    "&:hover": {
      backgroundColor: brand.primaryColor.dark,
    },
    "& .MuiButton-label": {
      textTransform: "uppercase",
      color: "#FFFFFF",
      fontFamily: "Roboto, Arial",
      letterSpacing: "0.25px",
    },
  },
  buttonLinkSecondary: {
    background: colors.secondaryLight,
    border: borders.secondaryDark,
    "& .MuiButton-label": {
      color: brand.primaryColor.main,
    },
    "&:hover": {
      backgroundColor: colors.secondaryDark,
    },
  },
  list: {
    paddingInlineStart: "20px",
  },
  interviewSection: {
    display: "grid",
    gridTemplateColumns: "20px auto",
    position: "relative",
    margin: "24px 0",
  },
  interviewSectionBorder: {
    width: spacing.half,
    borderRadius: spacing.half,
  },
  interviewSectionBorderSecondary: {
    background: colors.secondary,
  },
  interviewSectionBorderSecondaryLight: {
    background: colors.secondaryLight,
  },
  interviewSectionBorderPrimary: {
    background: brand.primaryColor.main,
  },
  interviewSectionInfo: {
    border: borders.secondaryDark,
    borderRadius: spacing.half,
    padding: spacing.oneHalf,
  },
  additionalReadingSection: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: spacing.double,
    margin: `${spacing.oneHalf} 0`,
    [theme.breakpoints.down("sm")]: {
      gridGap: spacing.oneHalf,
      gridTemplateColumns: "100%",
    },
  },
  additionalReadingBlock: {
    border: borders.secondaryDark,
    borderRadius: spacing.half,
    minHeight: "200px",
    display: "grid",
    gridTemplateRows: "64px auto",
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
  },
  blockAsLink: {
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      border: `1px solid ${brand.primaryColor.main}`,
      "& $additionalReadingBlockHeader": {
        backgroundColor: colors.secondaryDark,
        "& .MuiTypography-root": {
          color: brand.primaryColor.dark,
        },
        "& .MuiSvgIcon-root": {
          color: brand.primaryColor.dark,
        },
      },
    },
  },
  additionalReadingBlockHeader: {
    backgroundColor: colors.secondaryLight,
    padding: spacing.default,
    borderTopLeftRadius: spacing.half,
    borderTopRightRadius: spacing.half,
    "& .MuiTypography-root": {
      color: brand.primaryColor.main,
    },
  },
  additionalReadingBlockContent: {
    padding: spacing.default,
  },
  additionalReadingBlockCTA: {
    padding: spacing.default,
    textTransform: "uppercase",
    fontWeight: 500,
    textDecoration: "none",
    letterSpacing: "0.25px",
  },
  arrowForwardIcon: {
    verticalAlign: "bottom",
    marginLeft: spacing.default,
  },
  interviewSectionHeading: {
    display: "grid",
    alignItems: "center",
    marginBottom: spacing.default,
    gridTemplateColumns: "32px auto",
    gridGap: "12px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
  interviewSectionSubHeading: {
    marginTop: spacing.oneHalf,
  },
  interviewSectionIcon: {
    marginRight: spacing.default,
  },
  interviewSectionIconSubHeading: {
    marginRight: spacing.half,
    color: brand.primaryColor.main,
  },
  interviewSectionSubInfo: {
    marginLeft: spacing.oneHalf,
    color: colors.black,
  },
  noBorder: {
    border: "none",
  },
  mt0: {
    marginTop: 0,
  },
  mt8: {
    marginTop: spacing.half,
  },
  mb0: {
    marginBottom: 0,
  },
  removeTypographyMargin: {
    "& .MuiTypography-root": {
      margin: 0,
    },
  },
  content: {
    flex: 1,
    position: "relative",
  },
  contentWithNavBar: {
    alignItems: "stretch",
    display: "flex",
    flex: 1,
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tabs: {
    width: "256px",
    boxSizing: "border-box",
    paddingTop: 20,
    paddingRight: 20,
    borderRight: borders.default,
    "& .MuiTabs-indicator": {
      display: "none",
      backgroundColor: brand.primaryColor.main,
    },
    "& .MuiTab-root": {
      color: colors.text,
      alignItems: "flex-start",
      fontFamily: "Google Sans, Arial",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textTransform: "none",
    },
    "& .MuiTab-root.Mui-selected": {
      fontFamily: "Google Sans, Arial",
      background: colors.secondaryLight,
      borderRadius: "0px 34px 34px 0px",
      color: brand.primaryColor.main,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderRight: "none",
      padding: 0,
      "& .MuiTabs-indicator": {
        display: "unset",
      },
    },
  },
  tabPanel: {
    flex: 1,
    padding: 32,
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    "& .MuiTypography-h2:first-child": {
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  tabPanelContent: {
    margin: "0 auto",
    maxWidth: 900,
  },
  quote: {
    margin: `${spacing.oneHalf} 0`,
    display: "grid",
    gridTemplateColumns: "32px auto",
    alignItems: "center",
    "& .MuiTypography-body2": {
      fontStyle: "italic",
      fontWeight: 500,
    },
  },
  quoteIcon: {
    color: brand.primaryColor.main,
  },
  headerWithIcon: {
    display: "grid",
    gridTemplateColumns: "32px auto",
    "& .MuiSvgIcon-root": {
      color: brand.primaryColor.main,
    },
  },
  onsiteInstructionsBox: {
    display: "flex",
    flexDirection: "column",
  },
  instructionsLine: {
    width: "12px",
    marginRight: "8px",
    borderRadius: "4px",
    backgroundColor: colors.secondaryLight,
  },
  instructionsBox: {
    marginLeft: "8px",
    border: borders.secondaryLight,
    borderRadius: "8px",
    padding: spacing.default,
  },
  interviewStatus: {
    color: colors.primary,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes.heading,
    display: "flex",
    flexDirection: "column",
    border: borders.secondaryDark,
    boxSizing: "border-box",
    backgroundColor: colors.secondaryLight,
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: spacing.half,
    alignItems: "center",
  },
  codeTogetherSidePanelIcon: {
    width: 300,
    alignSelf: "flex-start",
  },
  codeTogetherInstructions: {
    width: "50%",
  },
  codeTogetherInstructionsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: spacing.default,
    padding: spacing.default,
    border: borders.secondaryLight,
    borderRadius: "8px",
    marginBottom: spacing.default,
    marginTop: spacing.default,
  },
}));
