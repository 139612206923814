import * as React from "react";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface Props extends StyledComponentProps {
  children?: React.ReactNode;
}

const styles = createStyles({
  base: {
    background: "#f3f3f3",
    border: "solid 1px #ddd",
    borderRadius: 5,
    color: "#d85262",
    fontFamily: '"Lucida Console", Monaco, monospace',
    fontSize: 12,
    padding: "1px 5px",
    whiteSpace: "nowrap",
  },
});

function InlineCodeBase(props: Props) {
  const {classes} = props;
  // @ts-ignore FIXME: strictNullChecks
  return <code className={classes.base}>{props.children}</code>;
}

export default withStyles(styles)(InlineCodeBase);
