import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {compact} from "lodash";
import React from "react";
import {instructionsTextStyle} from "../../components/Instructions";
import {ExternalLink} from "../../components/Link";
import UnorderedList from "../../components/UnorderedList";
import {languageNameMap} from "../../config";
import {isBBDocEnabled} from "../../helpers/bbdoc";
import {
  getIframeUrl,
  isDataAnalysisInterview,
  isSecurityInterview,
} from "../../helpers/interview";
import {docViewUrl} from "../../helpers/urls/routerUrls";
import {Flags, Interview} from "../../reducers/types";

type Props = {
  flags: Flags;
  interview: Interview;
};

const useStyles = makeStyles({
  instructionsText: instructionsTextStyle,
});

export default function Expectations({flags, interview}: Props) {
  const classes = useStyles();

  const p1DocumentUrl = isBBDocEnabled(interview, flags)
    ? docViewUrl(interview.id, "m1")
    : getIframeUrl(interview.m1DocId, interview.m1DocUrl, {rm: "minimal"});

  const languageDisplayName = interview.language
    ? languageNameMap[interview.language]
    : null;

  return (
    <>
      <Typography variant="h3">Expectations</Typography>
      <UnorderedList
        items={compact([
          <Typography className={classes.instructionsText}>
            You can reference your Part 1 document at any time using this{" "}
            <ExternalLink target="_blank" href={p1DocumentUrl}>
              view-only copy here
            </ExternalLink>
            .
          </Typography>,

          <Typography className={classes.instructionsText}>
            Your code should be clean and well-commented. Try to submit code
            that runs and functions as expected.
          </Typography>,

          !isDataAnalysisInterview(interview) &&
            !isSecurityInterview(interview) && (
              <Typography className={classes.instructionsText}>
                Do not use any third-party libraries, APIs, or code for this
                exercise (language built-ins only).
              </Typography>
            ),

          <Typography className={classes.instructionsText}>
            You may use resources available online (
            {languageDisplayName
              ? `${languageDisplayName} documentation`
              : "documentation"}
            , stackoverflow, etc) but all submissions should be entirely your
            own.
          </Typography>,

          <Typography className={classes.instructionsText}>
            You may not have enough time to complete all the tasks. Make as much
            progress as you can and write comments or pseudocode for tasks or
            improvements you did not complete.
          </Typography>,
        ])}
      />
    </>
  );
}
