import * as React from "react";
import {useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import Spinner from "../components/Spinner";
import Button from "@material-ui/core/Button";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import UnordererList from "../components/UnorderedList";
import {Interview, CandidateBBHCStatus} from "../reducers/types";
import {ExternalLink} from "../components/Link";
import {brand} from "../branding";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import {Redirect} from "react-router";
import {getStartedUrl, bbhcUrl, surveyUrl} from "../helpers/urls/routerUrls";
import {getSupportEmail} from "../actions/interviewActions";
import {saveTerms} from "../actions/termsScreenActions";
import {useUser} from "../hooks/user";
import {useInterview} from "../hooks/interview";

interface Props {
  atsId: string;
}

const useStyles = makeStyles({
  agreement: {
    border: "solid 1px #ccc",
    borderRadius: 6,
    marginBottom: 15,
    maxHeight: 350,
    maxWidth: 700,
    overflowY: "auto",
    padding: 15,
  },
  p: {
    color: "black",
    fontSize: 12,
  },
  inputContainer: {
    marginBottom: 15,
    marginLeft: 15,
  },
});

export default function TermsScreen({atsId}: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useUser();
  const interview = useInterview(atsId, user);

  if (!interview) {
    return <Spinner />;
  }

  const redirectUrl = checkForRedirect(interview);
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  const onContinue = async () => {
    try {
      await dispatch(saveTerms(interview, user));
    } catch (err) {
      console.error("Error while saving interview terms: ", err);
    }
  };

  const {candidateName} = interview;
  return (
    <CenteredScreenContent size="tall">
      <div>
        <Typography variant="h2">
          {candidateName ? `Hi ${candidateName}, welcome ` : "Welcome "}to your{" "}
          {brand.company} Interview!
        </Typography>
        <Typography variant="h4" paragraph={true}>
          Before we get started we would like for you to review the{" "}
          {brand.company} Candidate Agreement.
        </Typography>
        <div className={classes.agreement}>
          <CandidateAgreement supportEmail={getSupportEmail(interview)} />
        </div>
      </div>
      <div className={classes.inputContainer}>
        <AgreementForm onContinue={onContinue} />
      </div>
    </CenteredScreenContent>
  );
}

interface CandidateAgreementProps {
  supportEmail: string;
}

function CandidateAgreement({supportEmail}: CandidateAgreementProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography paragraph={true} className={classes.p}>
        Byteboard provides an interview service available at byteboard.dev that
        allows you to undertake technical recruitment interviews for potential
        employers (the “Service”). Byteboard provides the Service on behalf of
        potential employers.
      </Typography>
      <Typography paragraph={true} className={classes.p}>
        It is important that you read and understand this Candidate Agreement
        before using the Service. By using and accessing the Service you agree
        to this Candidate Agreement. If you do not agree to this Candidate
        Agreement, please do not access or use the Service.
      </Typography>
      <Typography paragraph={true} className={classes.p}>
        By using the Service, you hereby grant us a worldwide, non-exclusive,
        royalty-free, sublicensable, transferable and perpetual license to use,
        copy, reproduce, distribute, adapt, re-format, modify, publish,
        translate, license, sub-license, assign, and transfer the code, text and
        other data or information that you submit or create while using the
        Service ("User Content") for the purpose of providing the Service.
      </Typography>
      <Typography paragraph={true} className={classes.p}>
        We will share the information you provide to us when signing up to take
        your interview (for example, your name and email) as well as your User
        Content with the company you are interviewing with. We are not
        responsible for any hiring decisions made by your potential employer.
      </Typography>
      <Typography paragraph={true} className={classes.p}>
        You agree that your User Content will not:
      </Typography>
      <UnordererList
        items={[
          <Typography className={classes.p}>
            Be false or misleading;
          </Typography>,
          <Typography className={classes.p}>
            Use any code other than your own code when undertaking the interview
            or any tasks on the Service, or otherwise upload any User Content to
            which you do not have sufficient rights to grant us the rights and
            licenses specified herein;
          </Typography>,
          <Typography className={classes.p}>
            Permit another person to use the Service under your name or on your
            behalf;
          </Typography>,
          <Typography className={classes.p}>
            Modify, interfere, intercept, disrupt or hack the Service; or
          </Typography>,
          <Typography className={classes.p}>
            Misuse the Service by introducing viruses, Trojans, worms, logic
            bombs or other material which would harm the Service or any user of
            the Service.
          </Typography>,
        ]}
      />
      <Typography paragraph={true} className={classes.p}>
        You also agree that you will not share your code, text and other data or
        information that you create while using the Service with any other
        party.
      </Typography>
      <Typography paragraph={true} className={classes.p}>
        The interview materials provided to you are our confidential information
        and must not be shared with any other party.
      </Typography>
      <Typography paragraph={true} className={classes.p}>
        Failure to comply with this Candidate Agreement may result in the
        immediate withdrawal of your right to use our Service or any other
        action we reasonably deem appropriate. If you have any concerns or
        questions about the Service or this Candidate Agreement, please contact
        us at{" "}
        <ExternalLink href={`mailto:${supportEmail}`} target="_blank">
          {supportEmail}
        </ExternalLink>
        .
      </Typography>
    </React.Fragment>
  );
}

interface AgreementFormProps {
  onContinue: () => void;
}

interface Inputs {
  interviewAgreement: boolean;
  confidentialAgreement: boolean;
}

function AgreementForm({onContinue}: AgreementFormProps) {
  const {register, handleSubmit, watch} = useForm<Inputs>();
  const [isSavingTerms, setIsSavingTerms] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSavingTerms(true);
    await onContinue();
    // N.B. we never need to setIsSavingTerms(false)
  };

  const canProceed = () => {
    const values = watch();
    return values.interviewAgreement && values.confidentialAgreement;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControlLabel
          key="interviewAgreement"
          control={
            <Checkbox color="primary" {...register("interviewAgreement")} />
          }
          label={
            <div>
              I have read and agree to {brand.company}'s Candidate Agreement and
              agree to keep {brand.company} materials confidential.
            </div>
          }
        />
        <FormControlLabel
          key="confidential"
          control={
            <Checkbox color="primary" {...register("confidentialAgreement")} />
          }
          label={
            <div>
              I agree to the {brand.company}{" "}
              <ExternalLink
                href={`https://byteboard.dev/terms`}
                target="_blank"
              >
                Terms of Service
              </ExternalLink>{" "}
              and{" "}
              <ExternalLink
                href={`https://byteboard.dev/privacy`}
                target="_blank"
              >
                Privacy Policy
              </ExternalLink>
              .
            </div>
          }
        />
      </div>
      <Button
        color="primary"
        disabled={!canProceed() || isSavingTerms}
        variant="contained"
        type="submit"
      >
        {isSavingTerms ? "Loading" : "Continue"}
      </Button>
    </form>
  );
}

function checkForRedirect(interview: Interview): string | undefined {
  const bbhcStatus = interview.bbhcStatus;
  /* If the candidate opted-out of BBHC and they have not completed the survey, then they should be redirected to the survey screen. */
  if (bbhcStatus === CandidateBBHCStatus.optout && !interview.survey) {
    return surveyUrl(interview.id);
  }

  /* If the candidate is BBHC Eligible, then they should be redirected to the opt-in screen. */
  if (bbhcStatus === CandidateBBHCStatus.eligible) {
    return bbhcUrl(interview.id);
  }

  /* If the candidate has already accepted the terms, redirect to the getting started screen. */
  if (interview.terms) {
    return getStartedUrl(interview.id);
  }
}
