import Mark from "mark.js";
import React, {forwardRef, useEffect, useRef} from "react";
import {CreateCSSProperties} from "@material-ui/styles";
import {InterviewDoc, CandidateResponse} from "../reducers/types";
import {makeStyles, lighten} from "@material-ui/core/styles";
import {useCombinedRefs} from "../util/hooks";
import {useHotkeys} from "react-hotkeys-hook";
import {NEW_COMMENT} from "../helpers/bbdoc";

export const INLINE_QUESTION_DATA_ATTR = "data-inline-question-response-id";
const DOC_BODY_HIGHLIGHT_DATA_ATTR = "data-doc-body-highlight";

export interface ResponseIdToHighlightedElementsMap {
  [candidateResponseId: string]: HTMLElement[];
}

interface Props {
  interviewDoc: InterviewDoc;
  activeResponseId: string | null;
  readonly: boolean;
  commentBoxRefs: React.MutableRefObject<{
    [candidateResponseId: string]: HTMLElement | null;
  }>;
  /**
   * Function that is called when the question and comment ranges are
   * highlighted (at render time).
   */
  onHighlightRanges: (
    responseIdToHighlightedElementsMap: ResponseIdToHighlightedElementsMap,
  ) => any;
  /**
   * Function that is called when the user selects (or unselects) text in the
   * doc.
   */
  onSelectionChange: (selection: Selection | null) => any;
  /**
   * Function that is called when the user clicks on a highlighted range.
   */
  onHighlightedRangeClick: (responseId: string) => any;
}

const highlightCommonStyles: CreateCSSProperties = {
  paddingBottom: "0.2em",
  paddingTop: "0.2em",
  position: "relative",

  // https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
  "&::before": {
    content: '" Comment start. "',
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  },
  "&::after": {
    content: '" Comment end. "',
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  },

  "h1 &": {
    paddingBottom: 7,
  },
  "h2 &": {
    paddingTop: 4,
  },
  "h3 &": {
    paddingTop: 3,
  },
  "h4 &": {
    paddingBottom: 2,
    paddingTop: 2,
  },
  "h5 &": {
    paddingBottom: 3,
    paddingTop: 2,
  },
  "p &": {
    paddingBottom: 3,
    paddingTop: 2,
  },
  "li &": {
    paddingBottom: 3,
    paddingTop: 2,
  },
};
const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: "white",
      borderRadius: 8,
      boxShadow: "0 2px 9px rgba(0,0,0,0.3)",
      color: "black",
      flex: 1,
      marginBottom: 50,
      marginLeft: theme.bbdoc.gutterWidth,
      marginRight: theme.bbdoc.gutterWidth,
      marginTop: theme.bbdoc.gutterWidth,
      padding: 80,
      position: "relative",
      "& h1, & h2, & h3, & h4, & h5": {
        fontFamily: "Google Sans",
      },
      "& h1": {
        fontSize: "2em",
        lineHeight: "1.65em",
        marginTop: 0,
        textAlign: "center",
      },
      "& h2": {
        fontSize: "1.5em",
        lineHeight: "1.55em",
        marginTop: "1.5em",
      },
      "& h3": {
        fontSize: 17,
        lineHeight: "1.5em",
      },
      "& h4": {
        color: "#555",
        fontSize: "1.05em",
        lineHeight: "1.5em",
        marginTop: "3em",
      },
      "& h5": {
        fontSize: "1em",
        lineHeight: "1.5em",
        marginBottom: "1em",
      },
      "& p": {
        lineHeight: "1.5em",
      },
      "& li": {
        lineHeight: "1.5em",
      },
      "& img": {
        display: "block",
        margin: "30px auto",
      },
      "& figure": {
        "& figcaption": {
          marginTop: "-20",
          textAlign: "center",
        },
      },
      "& #table-of-contents": {
        // We don't want to show the toc header.
        display: "none",
        "& + ul": {
          listStyleType: "none",
          paddingInlineStart: 0,
        },
        "& + ul ul": {
          listStyleType: "none",
          paddingInlineStart: 25,
        },
        "& + ul > li": {
          marginBottom: 10,
        },
        "& + ul p": {
          margin: 0,
        },
        "& + ul a": {
          color: theme.link.color,
        },
      },
    },
    questionHighlight: {
      ...highlightCommonStyles,
      background: theme.bbdoc.palette.bb.light,
    },
    questionHighlightActive: {
      ...highlightCommonStyles,
      background: lighten(theme.bbdoc.palette.bb.main, 0.7),
    },
    commentHighlight: {
      ...highlightCommonStyles,
      background: lighten(theme.bbdoc.palette.candidate.main, 0.7),
    },
    commentHighlightActive: {
      ...highlightCommonStyles,
      background: lighten(theme.bbdoc.palette.candidate.main, 0.6),
    },
  }),
  {
    name: "BBDocBody",
  },
);

interface SelectionProps {
  anchorNode: Node | null;
  anchorOffset: number;
  focusNode: Node | null;
  focusOffset: number;
}

export const BBDocBody = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const combinedRef = useCombinedRefs(ref);
  const classes = useStyles({});
  const selectionRef = useRef<SelectionProps | null>(null);

  useEffect(() => {
    if (props.readonly) return;
    document.addEventListener("selectionchange", onSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", onSelectionChange);
    };
  }, []);

  const onMouseDown = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLElement) {
      maybeSetActiveResponseId(e.target);
    }
  };

  const preventCopyAction = (e: React.ClipboardEvent | React.MouseEvent) => {
    e.preventDefault();
    return false;
  };

  const maybeSetActiveResponseId = (clickedElement: HTMLElement) => {
    const highlightClassNamesSelector = [
      classes.commentHighlight,
      classes.questionHighlight,
    ]
      .map((className) => `.${className}`)
      .join(", ");
    const clickedHighlightElement = clickedElement.closest(
      highlightClassNamesSelector,
    );
    if (clickedHighlightElement instanceof HTMLElement) {
      const responseId = clickedHighlightElement.dataset.id;
      if (responseId) {
        props.onHighlightedRangeClick(responseId);
      }
    }
  };

  const onSelectionChange = () => {
    const selection = document.getSelection();
    const previousSelection = selectionRef.current;
    if (!selection) {
      if (previousSelection) {
        selectionRef.current = null;
        // @ts-ignore FIXME: strictNullChecks
        props.onHighlightedRangeClick(null);
      }
      return;
    }

    if (selection && previousSelection) {
      // If the selection hasn't changed, don't do anything.
      // This fixes an infinite re-render in Firefox becasuse the selection
      // object was constantly changing and React was re-rendering the
      // entire component.
      if (
        selection.anchorNode === previousSelection.anchorNode &&
        selection.anchorOffset === previousSelection.anchorOffset &&
        selection.focusNode === previousSelection.focusNode &&
        selection.focusOffset === previousSelection.focusOffset
      ) {
        return;
      }
    }

    selectionRef.current = {
      anchorNode: selection?.anchorNode,
      anchorOffset: selection?.anchorOffset,
      focusNode: selection?.focusNode,
      focusOffset: selection?.focusOffset,
    };

    const {anchorNode, type} = selection;
    const selectionInDocBody =
      // @ts-ignore FIXME: strictNullChecks
      combinedRef.current.contains(anchorNode) &&
      // @ts-ignore FIXME: strictNullChecks
      !anchorNode.parentElement.closest(`[${INLINE_QUESTION_DATA_ATTR}]`);
    const hasSelectionRange = type === "Range";
    // Ignore selections that are made outside of the doc body.
    if (hasSelectionRange && !selectionInDocBody) return;
    props.onSelectionChange(hasSelectionRange ? selection : null);
  };

  const getHighlightClassName = (
    candidateResponse: CandidateResponse,
  ): string => {
    const isActiveResponse = props.activeResponseId === candidateResponse.id;
    if (candidateResponse.questionId) {
      return isActiveResponse
        ? classes.questionHighlightActive
        : classes.questionHighlight;
    } else {
      return isActiveResponse
        ? classes.commentHighlightActive
        : classes.commentHighlight;
    }
  };

  // For questions that display the text field in the doc body, insert empty
  // parent elements in the DOM
  // todo: av club replace this
  const addQuestionInBodyIfNeeded = (
    response: CandidateResponse,
    highlightedElements: Element[],
  ) => {
    const {questionId} = response;
    // @ts-ignore FIXME: strictNullChecks
    const question = props.interviewDoc.questions[questionId];
    if (question?.inputType === "body") {
      const docEl = combinedRef.current;
      const lastHighlightedElement =
        highlightedElements[highlightedElements.length - 1];

      // Don't create a new element if it already exists.
      if (
        // @ts-ignore FIXME: strictNullChecks
        docEl.querySelector(`[${INLINE_QUESTION_DATA_ATTR}="${question.id}"]`)
      ) {
        return;
      }
      const parentElement = document.createElement("div");
      parentElement.setAttribute(INLINE_QUESTION_DATA_ATTR, question.id);

      // Walk up the tree until we get to the top-level doc child (inside the root
      // element, which is a div) and insert the text box afterwards. We do this so
      // that the inline response box doesn't end up inside a text or list element.
      // @ts-ignore FIXME: strictNullChecks
      lastHighlightedElement.closest("div > *").after(parentElement);

      // BBDocCommentList will find this element by its data attribute and
      // stick a text field in it.
    }
  };

  const responseIdToHighlightedElementsMapRef = useRef({});
  useEffect(() => {
    const rootEl = combinedRef.current;
    const allResponseIds = new Set();
    const responseIdToHighlightedElementsMap: ResponseIdToHighlightedElementsMap =
      responseIdToHighlightedElementsMapRef.current;

    // For each candidate response, highlight it in the document if it's newly added.
    props.interviewDoc.candidateResponses.forEach((response) => {
      allResponseIds.add(response.id);
      if (responseIdToHighlightedElementsMap[response.id]) {
        // Already highlighted
        return;
      }
      const [startIndex, endIndex] = response.selectionRange;
      const highlightedElements = highlightRangeInElement({
        // @ts-ignore FIXME: strictNullChecks
        element: rootEl,
        startIndex,
        endIndex,
        className: getHighlightClassName(response),
        responseId: response.id,
      });
      responseIdToHighlightedElementsMap[response.id] = highlightedElements;
      addQuestionInBodyIfNeeded(response, highlightedElements);
    });

    // Check for candidate responses that have been removed
    for (const responseId in responseIdToHighlightedElementsMap) {
      if (!allResponseIds.has(responseId)) {
        // Remove wrapping <mark> elements
        for (const element of responseIdToHighlightedElementsMap[responseId]) {
          element.replaceWith(...element.childNodes);
        }
      }
    }

    props.onHighlightRanges(responseIdToHighlightedElementsMap);
  }, [props.interviewDoc]);

  /**
   * Add <mark> tags at the start/end character indices. Returns a list of the
   * <mark> elements.
   * mark.js has an async API because it has a feature where it will wait for
   * any iframes to load before adding marks, but we don't need this and
   * asynchrony makes code hard to deal with so we will just wrap it in a sync
   * API here.
   */
  const highlightRangeInElement = ({
    element,
    startIndex,
    endIndex,
    className,
    responseId,
  }: {
    element: HTMLElement;
    startIndex: number;
    endIndex: number;
    className: string;
    responseId: string;
  }): HTMLElement[] => {
    const marker = new Mark(element);
    const highlightedElements: HTMLElement[] = [];
    let done = false;
    marker.markRanges([{start: startIndex, length: endIndex - startIndex}], {
      className,
      each: (markEl) => {
        if (markEl instanceof HTMLElement) {
          markEl.dataset.id = responseId;
          markEl.setAttribute(DOC_BODY_HIGHLIGHT_DATA_ATTR, "");
          markEl.setAttribute("tabindex", "0");
          // Link the highlighted <mark> to the corresponding candidate response comment
          markEl.setAttribute("aria-details", responseId);
          markEl.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
              props.onHighlightedRangeClick(responseId);
            }
          });
          highlightedElements.push(markEl);
        }
      },
      done: () => {
        done = true;
      },
      exclude: [
        // Skip over inline doc response text elements.
        `[${INLINE_QUESTION_DATA_ATTR}] *`,
      ],
    });
    if (!done) {
      // This will only happen if we add iframes to bbdocs
      throw new Error("markjs did not return synchronously");
    }
    return highlightedElements;
  };

  useHotkeys("ctrl+alt+m, cmd+option+m", (e) => {
    // Add a new comment
    e.preventDefault();
    // @ts-ignore FIXME: strictNullChecks
    props.commentBoxRefs.current[NEW_COMMENT].click();
  });

  return (
    <div
      className={classes.root}
      // @ts-ignore FIXME: strictNullChecks
      ref={combinedRef}
      onMouseDown={onMouseDown}
      onCopy={preventCopyAction}
      onCut={preventCopyAction}
      onContextMenu={preventCopyAction}
    >
      {/* At this point, the doc body string has already gone through the
          markdown parser and is now a string with html tags. It doesn't
          contain any user input, so it's safe to just use as inner html. */}
      <div dangerouslySetInnerHTML={{__html: props.interviewDoc.docHTML}} />
    </div>
  );
});

export function isDocBodyHighlightElement(element: HTMLElement): boolean {
  return element.matches(`[${DOC_BODY_HIGHLIGHT_DATA_ATTR}]`);
}
