import * as React from "react";
import Typography from "@material-ui/core/Typography";

const BBDocAccessibilityDocumentKeyboardShortcuts = () => {
  return (
    <>
      <Typography component="h4" variant="h4">
        Caret Browsing
      </Typography>
      <Typography paragraph={true}>
        Caret browsing can be toggled on and off in Chrome and Firefox using the
        keyboard shortcut F7. Caret browsing will allow you to select text in
        the document using your keyboard so you can leave comments.
      </Typography>
      <Typography component="h4" variant="h4">
        Add a new comment
      </Typography>
      <Typography paragraph={true}>
        Select the relevant text and use “Command+Option+M” or “Control+Alt+M”
        to add a new comment. After typing your comment, pressing Esc transfers
        focus back to the highlighted text.
      </Typography>
      <Typography component="h4" variant="h4">
        Move between comment boxes
      </Typography>
      <Typography paragraph={true}>
        When your focus is on a comment box, you can use the up and down arrow
        keys to navigate between comment boxes. When you are at the end of a
        comment text field, pressing down will take you to the next comment.
        When you are at the beginning of a comment text field, pressing up will
        take you to the previous comment.
      </Typography>
      <Typography component="h4" variant="h4">
        Find the next comment
      </Typography>
      <Typography paragraph={true}>
        When the focus is on the main page, use the Tab key to find the next
        highlighted area. Now the focus is on the highlight and you can use the
        “enter” key to focus on the associated comment and “esc” key to focus
        back to the highlight.
      </Typography>
      <Typography component="h4" variant="h4">
        Editing and deleting comments
      </Typography>
      <Typography paragraph={true}>
        Changes in the comment boxes are saved by default. To edit or delete a
        comment, simply move the focus to the comment text field and edit or
        delete text.
      </Typography>
    </>
  );
};

export default BBDocAccessibilityDocumentKeyboardShortcuts;
