import {
  AppState,
  CandidateResponse,
  InterviewDoc,
  InterviewDocJSON,
  InterviewDocQuestion,
  ModuleId,
} from "../reducers/types";
import {BBDocAction, ErrorAction} from "./actionTypes";
import {BEGIN, COMMIT, REVERT, OptimisticAction} from "redux-optimistic-ui";
import {ThunkDispatch, ThunkAction} from "redux-thunk";
import {
  bbDocCandidateResponseUrl,
  bbDocUrl,
} from "../helpers/urls/interviewUrls";
import {get, post, deleteRequest} from "./fetchActions";
import {getEmptyCandidateResponseForQuestion} from "../helpers/bbdoc";
import {getRangeForElement, trimChildNodes} from "../util/selection";
import {parseMarkdown} from "../components/Markdown";
import {setErrorMessage} from "./errorActions";
import {uniqueId} from "lodash";

type Action = BBDocAction | ErrorAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

function isLoading(): BBDocAction {
  return {
    type: "BBDOC.IS_LOADING",
  };
}

function isLoaded(): BBDocAction {
  return {
    type: "BBDOC.IS_LOADED",
  };
}

function setInterviewDoc(value: InterviewDoc): BBDocAction {
  return {
    type: "BBDOC.SET_INTERVIEW_DOC",
    value,
  };
}

function beginSaveCandidateResponse(
  candidateResponse: CandidateResponse,
): BBDocAction {
  return {
    type: "BBDOC.BEGIN_SAVE_RESPONSE",
    candidateResponse,
  };
}

function failedToSaveCandidateResponse(
  candidateResponse: CandidateResponse,
): BBDocAction {
  return {
    type: "BBDOC.FAILED_TO_SAVE_RESPONSE",
    candidateResponse,
  };
}

export function clear(): BBDocAction {
  return {
    type: "BBDOC.CLEAR",
  };
}

export function loadBBDoc(moduleId: ModuleId): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview} = getState();
    dispatch(isLoading());
    const request = get<{interviewDoc: InterviewDocJSON; failed: boolean}>(
      bbDocUrl(interview.id, moduleId),
      {
        errorMessage: "Failed to load interview document.",
      },
    );
    const response = await dispatch(request);
    if (response && response.interviewDoc) {
      try {
        const interviewDoc = toInterviewDoc(response.interviewDoc, moduleId);
        dispatch(setInterviewDoc(interviewDoc));
        dispatch(isLoaded());
      } catch (err) {
        dispatch(setErrorMessage("Unable to parse interview document"));
        console.error(err);
      }
    }
  };
}

function toInterviewDoc(
  interviewDocJSON: InterviewDocJSON,
  moduleId: ModuleId,
): InterviewDoc {
  const docBodyWithFullAssetUrls = Object.entries(
    interviewDocJSON.assets || {},
  ).reduce((curDocBody, [assetPath, fullAssetUrl]) => {
    return curDocBody.replace(
      new RegExp(assetPath, "g"),
      fullAssetUrl as string,
    );
  }, interviewDocJSON.docBody as string);
  const interviewDocEl = parseMarkdown(docBodyWithFullAssetUrls);
  const candidateResponses = Object.values(
    addIdToObjectsInMap<CandidateResponse>(interviewDocJSON.candidateResponses),
  ).map(toCandidateResponse);
  const sectionsToInclude = interviewDocJSON.sections;
  if (sectionsToInclude != null) {
    // Get the section elements
    const sectionEls = Array.from(
      interviewDocEl.querySelectorAll("section"),
    ).map(trimChildNodes) as HTMLElement[];
    sectionEls.forEach((sectionEl) => {
      const contentMarker: string | undefined = sectionEl.dataset.id;
      if (contentMarker == null) {
        // Malformed data
        throw new Error("Unable to parse interview document");
      }
      // If the section element's data-id is one of the sections in interviewDocJSON, keep it and remove the section tag.
      if (
        sectionsToInclude.findIndex((section) => section === contentMarker) !==
        -1
      ) {
        if (sectionEl.children) {
          sectionEl.before(...sectionEl.children);
          sectionEl.remove();
        } else {
          sectionEl.replaceWith(sectionEl.textContent || "");
        }
      } else {
        // Otherwise, remove the section element.
        sectionEl.remove();
      }
    });
  }
  const questionEls = Array.from(
    interviewDocEl.querySelectorAll("question"),
  ).map(trimChildNodes) as HTMLElement[];
  const questions: {[questionId: string]: InterviewDocQuestion} = {};
  for (const questionEl of questionEls) {
    const id = questionEl.dataset.id;
    if (!id) {
      // If there isn't an id on this question, the data is malformed.
      // This should never happen, but if it does, we'll just skip it.
      // TODO: check that bbdocs are in the correct format when they
      // are uploaded: https://app.asana.com/0/1202409350597401/1203203265840384/f
      continue;
    }
    const selectionRange = getRangeForElement(interviewDocEl, questionEl);
    const {placeholder, questioner, text, inputRequired, inputType} =
      interviewDocJSON.questions[id] || {};
    if (interviewDocJSON.questions[id]) {
      // If we have an associated question for this question element, we'll
      // add that data to the questions array. If it doesn't exist, we just
      // skip it.
      if (!questioner || !text) {
        throw new Error("Unable to parse interview document");
      }
      const question: InterviewDocQuestion = {
        id,
        text,
        questioner,
        selectionRange,
        inputRequired,
        inputType,
        placeholder,
      };
      questions[id] = question;
      if (!candidateResponses.some((response) => response.id === id)) {
        // Add an empty candidate response if there isn't one for this question.
        candidateResponses.push(getEmptyCandidateResponseForQuestion(question));
      }
    }
    // Strip the <question> tag off of the selected text.
    questionEl.replaceWith(questionEl.children[0] || questionEl.textContent);
  }
  return {
    moduleId,
    docHTML: interviewDocEl.innerHTML,
    questions,
    candidateResponses,
  };
}

function toCandidateResponse(json: Record<string, any>): CandidateResponse {
  return {
    id: json.id,
    type: json.type,
    responseText: json.responseText,
    // @ts-ignore FIXME: strictNullChecks
    firstEditTime: json.firstEditTime ? new Date(json.firstEditTime) : null,
    // @ts-ignore FIXME: strictNullChecks
    lastEditTime: json.lastEditTime ? new Date(json.lastEditTime) : null,
    selectionRange: json.selectionRange,
    questionId: json.questionId,
  };
}

/**
 * Adds an id field to all of the objects in a map. For example:
 * {a: {num: 1}, b: {num: 2}} => {a: {id: 'a', num: 1}, b: {id: 'b', num: 2}}
 */
function addIdToObjectsInMap<T>(mapOfObjects: {[id: string]: T}): {
  [id: string]: T;
} {
  return Object.entries(mapOfObjects).reduce(
    (mapOfObjectsWithIds, [id, obj]) => {
      return {
        ...mapOfObjectsWithIds,
        [id]: {
          id,
          ...obj,
        },
      };
    },
    {},
  );
}

async function asyncTimeout(callback: () => any, wait: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(callback()), wait);
  });
}

function getCandidateResponseById(id: string, interviewDoc: InterviewDoc) {
  return interviewDoc.candidateResponses.find((response) => response.id === id);
}

async function waitForInFlightCandidateResponse(
  id: string,
  getState: GetState,
): Promise<any> {
  const response = getCandidateResponseById(
    id,
    // @ts-ignore FIXME: strictNullChecks
    getState().bbdocState.current.interviewDoc,
  );

  // @ts-ignore FIXME: strictNullChecks
  if (response.inFlightRequest) {
    return await asyncTimeout(
      () => waitForInFlightCandidateResponse(id, getState),
      50,
    );
  }

  return;
}

export function createComment(
  candidateResponse: CandidateResponse,
  moduleId: ModuleId,
): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview} = getState();
    const transactionId = parseInt(uniqueId(), 10);
    // Make an optimistic update to prevent lag while adding the new comment.
    dispatch(createCommentBegin(candidateResponse, transactionId));
    const candidateResponseWithModuleId = {
      ...candidateResponse,
      moduleId,
    };
    const request = post(
      bbDocCandidateResponseUrl(interview.id, candidateResponse.id),
      candidateResponseWithModuleId,
      {
        errorMessage: "Failed to create comment.",
        errorType: "toast",
      },
    );
    // @ts-ignore FIXME: strictNullChecks
    const json = await dispatch(request);
    if (json) {
      const updatedCandidateResponse = toCandidateResponse(json);
      dispatch(createCommentSuccess(updatedCandidateResponse, transactionId));
    } else {
      dispatch(createCommentError(transactionId));
    }
  };
}

export function deleteComment(
  candidateResponse: CandidateResponse,
): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview} = getState();
    const transactionId = parseInt(uniqueId(), 10);
    await waitForInFlightCandidateResponse(candidateResponse.id, getState);
    // Make an optimistic update to prevent lag while deleting the comment.
    dispatch(deleteCommentBegin(candidateResponse, transactionId));
    const request = deleteRequest(
      bbDocCandidateResponseUrl(interview.id, candidateResponse.id),
      {
        errorMessage: "Failed to delete comment.",
        errorType: "toast",
      },
    );
    // @ts-ignore FIXME: strictNullChecks
    const json = await dispatch(request);
    if (json) {
      dispatch(deleteCommentSuccess(transactionId));
    } else {
      dispatch(deleteCommentError(transactionId));
    }
  };
}

export function updateCandidateResponse(
  candidateResponse: CandidateResponse,
  moduleId: ModuleId,
): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview} = getState();
    const candidateResponseWithModuleId = {
      ...candidateResponse,
      moduleId,
    };

    await waitForInFlightCandidateResponse(candidateResponse.id, getState);
    dispatch(beginSaveCandidateResponse(candidateResponse));
    const request = post(
      bbDocCandidateResponseUrl(interview.id, candidateResponse.id),
      candidateResponseWithModuleId,
      {
        errorMessage: "Failed to save comment.",
        errorType: "toast",
      },
    );
    // @ts-ignore FIXME: strictNullChecks
    const json = await dispatch(request);
    if (json) {
      const updatedCandidateResponse = toCandidateResponse(json);
      dispatch({
        type: "BBDOC.UPDATE_RESPONSE",
        candidateResponse: updatedCandidateResponse,
      });
    } else {
      dispatch(failedToSaveCandidateResponse(candidateResponse));
    }
  };
}

function createCommentBegin(
  value: CandidateResponse,
  transactionId: number,
): BBDocAction & OptimisticAction {
  return {
    type: "BBDOC.CREATE_COMMENT",
    value,
    meta: {
      optimistic: {
        type: BEGIN,
        id: transactionId,
      },
    },
  };
}

function createCommentSuccess(
  candidateResponse: CandidateResponse,
  transactionId: number,
): BBDocAction & OptimisticAction {
  return {
    type: "BBDOC.CREATE_COMMENT_SUCCESS",
    candidateResponse,
    meta: {
      optimistic: {
        type: COMMIT,
        id: transactionId,
      },
    },
  };
}

function createCommentError(
  transactionId: number,
): BBDocAction & OptimisticAction {
  return {
    type: "BBDOC.CREATE_COMMENT_ERROR",
    meta: {
      optimistic: {
        type: REVERT,
        id: transactionId,
      },
    },
  };
}

function deleteCommentBegin(
  value: CandidateResponse,
  transactionId: number,
): BBDocAction & OptimisticAction {
  return {
    type: "BBDOC.DELETE_COMMENT",
    value,
    meta: {
      optimistic: {
        type: BEGIN,
        id: transactionId,
      },
    },
  };
}

function deleteCommentSuccess(
  transactionId: number,
): BBDocAction & OptimisticAction {
  return {
    type: "BBDOC.DELETE_COMMENT_SUCCESS",
    meta: {
      optimistic: {
        type: COMMIT,
        id: transactionId,
      },
    },
  };
}

function deleteCommentError(
  transactionId: number,
): BBDocAction & OptimisticAction {
  return {
    type: "BBDOC.DELETE_COMMENT_ERROR",
    meta: {
      optimistic: {
        type: REVERT,
        id: transactionId,
      },
    },
  };
}
