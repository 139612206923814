import * as React from "react";
import {makeStyles} from "@material-ui/styles";
import {Dialog} from "@headlessui/react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: -99999,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    padding: "8px 24px",
    borderRadius: "4px",
  },
  dialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999999,
  },
  dialogPanel: {
    background: "white",
    border: `1px solid #DDDDDD`,
    padding: "24px 42px",
    borderRadius: "8px",
    boxShadow:
      "0px 1px 3px 0px rgba(60, 64, 67, 0.30), 0px 4px 8px 3px rgba(60, 64, 67, 0.15)",
    maxWidth: "580px",
  },
  text: {
    marginBottom: "12px",
  },
});

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  content: {
    title: string;
    description: string;
  };
}
export const ConfirmationDialog: React.FC<Props> = (props: Props) => {
  const {isOpen, onConfirm, onCancel, content} = props;
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={isOpen} onClose={onCancel}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className={classes.background} aria-hidden="true" />

      <Dialog.Panel className={classes.dialogPanel}>
        <Dialog.Title as="div">
          <Typography className={classes.text} variant="h5">
            {content.title}
          </Typography>
        </Dialog.Title>
        <Dialog.Description as="div">
          <Typography className={classes.text} variant="body1">
            {content.description}
          </Typography>
        </Dialog.Description>
        <div className={classes.buttonBox}>
          <Button className={classes.button} onClick={onCancel}>
            CANCEL
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            CONFIRM
          </Button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

/** Hook to be used in combination with the above dialog for
 * confirming/cancelling the dialog and controlling/knowing whether or not the dialog
 * is open.
 */
export function useConfirmDialog(content: {
  title: string;
  description: string;
}): [() => Promise<boolean>, Props] {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const resolveFnRef = React.useRef<((value: boolean) => void) | null>(null);

  const openConfirmDialog = () => {
    setIsOpen(true);
    // An awaitable promise, used for pausing code execution until the dialog
    // has either been confirmed or cancelled (will return True/False respectively).
    return new Promise<boolean>((resolve) => {
      resolveFnRef.current = resolve;
    });
  };

  const onConfirm = async () => {
    setIsOpen(false);
    if (resolveFnRef.current) {
      resolveFnRef.current(true);
      resolveFnRef.current = null;
    }
  };

  const onCancel = (): void => {
    setIsOpen(false);
    if (resolveFnRef.current) {
      resolveFnRef.current(false);
      resolveFnRef.current = null;
    }
  };

  return [
    openConfirmDialog,
    {
      isOpen,
      onConfirm,
      onCancel,
      content,
    },
  ];
}
