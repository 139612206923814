import * as React from "react";
import Button from "@material-ui/core/Button";
import Launch from "@material-ui/icons/Launch";

const CLOUD_SHELL_URL = "https://shell.cloud.google.com/";

export const OpenCloudShellButton: React.FC<{authUserEmail: string}> = (
  props,
) => {
  const href = `${CLOUD_SHELL_URL}?authuser=${props.authUserEmail}`;
  return (
    <Button
      href={href}
      target="_blank"
      variant="outlined"
      endIcon={<Launch style={{fontSize: 14}} />}
    >
      Open Cloud Shell
    </Button>
  );
};
