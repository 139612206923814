import * as React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import {AppState, Interview} from "../reducers/types";
import {brand} from "../branding";
import {connect} from "react-redux";
import {getServerTime as getServerTimeFn} from "../util/time";
import {surveyUrl, interviewModuleUrl} from "../helpers/urls/routerUrls";
import {withInterview, RedirectRule} from "../components/InterviewLoader";
import {withRouter, RouteComponentProps} from "react-router-dom";
import InterviewOverview from "../components/InterviewOverview";

interface Props extends RouteComponentProps {
  atsId: string;
  interview: Interview;
  isM1Submitted: boolean;
  isM2Submitted: boolean;
  getServerTime: () => number;
}

class OverviewScreen extends React.Component<Props> {
  render(): React.ReactNode {
    const {interview, getServerTime} = this.props;
    return (
      <CenteredScreenContent size="tall">
        <InterviewOverview
          title={`Your ${brand.company} Interview`}
          getServerTime={getServerTime}
          interview={interview}
        />
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  interview: state.interview,
  isM1Submitted: Boolean(state.interview && state.interview.m1EndTime),
  isM2Submitted: Boolean(state.interview && state.interview.m2EndTime),
  getServerTime: () => getServerTimeFn(state.serverInfo.serverTimeOffset),
});

const redirectRules: RedirectRule[] = [
  {
    match: (interview: Interview) => {
      return Boolean(interview.m2EndTime);
    },
    redirect: (interview) => surveyUrl(interview.id),
  },
  {
    match: (interview: Interview) => {
      return Boolean(interview.m2OpenTime);
    },
    redirect: (interview) => interviewModuleUrl(interview, "m2"),
  },
  {
    match: (interview: Interview) => {
      const {m1OpenTime, m1EndTime} = interview;
      return Boolean(m1OpenTime && !m1EndTime);
    },
    redirect: (interview) => interviewModuleUrl(interview, "m1"),
  },
];

export default connect(mapStateToProps)(
  withInterview(redirectRules)(withRouter(OverviewScreen)),
);
