import {BBDocAction} from "../actions/actionTypes";
import {BBDocState} from "./types";
import {omit} from "lodash";
import {optimistic} from "redux-optimistic-ui";
import {sortCandidateResponsesBySelectionRange} from "../helpers/bbdoc";

const initialState: BBDocState = {
  isLoading: false,
  isLoaded: false,
  interviewDoc: null,
};

function bbdocStateBase(
  state: BBDocState = initialState,
  action: BBDocAction,
): BBDocState {
  switch (action.type) {
    case "BBDOC.IS_LOADING":
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case "BBDOC.IS_LOADED":
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    case "BBDOC.SET_INTERVIEW_DOC":
      return {
        ...state,
        interviewDoc: {
          ...action.value,
          candidateResponses: action.value.candidateResponses
            .slice()
            .sort(sortCandidateResponsesBySelectionRange),
        },
      };
    case "BBDOC.CLEAR":
      return initialState;
    case "BBDOC.CREATE_COMMENT":
      return {
        ...state,
        // @ts-ignore FIXME: strictNullChecks
        interviewDoc: {
          ...state.interviewDoc,
          candidateResponses: [
            // @ts-ignore FIXME: strictNullChecks
            ...state.interviewDoc.candidateResponses,
            {
              ...action.value,
              inFlightRequest: true,
            },
          ].sort(sortCandidateResponsesBySelectionRange),
        },
      };
    case "BBDOC.CREATE_COMMENT_SUCCESS":
      // At this point, the candidate response object is already in redux
      // (because it was added optimistically), but we need to update the
      // object after it is saved so that it stays up to date with its
      // timestamps.
      return {
        ...state,
        // @ts-ignore FIXME: strictNullChecks
        interviewDoc: {
          ...state.interviewDoc,
          // @ts-ignore FIXME: strictNullChecks
          candidateResponses: state.interviewDoc.candidateResponses.map(
            (response) => {
              return response.id === action.candidateResponse.id
                ? omit(action.candidateResponse, "inFlightRequest")
                : response;
            },
          ),
        },
      };
    case "BBDOC.DELETE_COMMENT":
      return {
        ...state,
        // @ts-ignore FIXME: strictNullChecks
        interviewDoc: {
          ...state.interviewDoc,
          // @ts-ignore FIXME: strictNullChecks
          candidateResponses: state.interviewDoc.candidateResponses.filter(
            (response) => {
              return response.id !== action.value.id;
            },
          ),
        },
      };
    case "BBDOC.UPDATE_RESPONSE":
      return {
        ...state,
        // @ts-ignore FIXME: strictNullChecks
        interviewDoc: {
          ...state.interviewDoc,
          // @ts-ignore FIXME: strictNullChecks
          candidateResponses: state.interviewDoc.candidateResponses.map(
            (response) => {
              return response.id === action.candidateResponse.id
                ? omit(action.candidateResponse, "inFlightRequest")
                : response;
            },
          ),
        },
      };
    case "BBDOC.BEGIN_SAVE_RESPONSE":
      return {
        ...state,
        // @ts-ignore FIXME: strictNullChecks
        interviewDoc: {
          ...state.interviewDoc,
          // @ts-ignore FIXME: strictNullChecks
          candidateResponses: state.interviewDoc.candidateResponses.map(
            (response) => {
              return response.id === action.candidateResponse.id
                ? {
                    ...omit(response, "failedToSave"),
                    inFlightRequest: true,
                  }
                : response;
            },
          ),
        },
      };
    case "BBDOC.FAILED_TO_SAVE_RESPONSE":
      return {
        ...state,
        // @ts-ignore FIXME: strictNullChecks
        interviewDoc: {
          ...state.interviewDoc,
          // @ts-ignore FIXME: strictNullChecks
          candidateResponses: state.interviewDoc.candidateResponses.map(
            (response) => {
              return response.id === action.candidateResponse.id
                ? {
                    ...omit(response, "inFlightRequest"),
                    failedToSave: true,
                  }
                : response;
            },
          ),
        },
      };
    default:
      return state;
  }
}

export const bbdocState = optimistic(bbdocStateBase);
