import {AnyAction} from "redux";
import {InterviewSurveyScreenAction} from "./actionTypes";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {Interview, User, AppState} from "../reducers/types";
import {saveSurvey} from "./interviewActions";
import {Gender, Race} from "../types/types";

export const SET_INTERVIEW_SURVEY_SCREEN_NUM_STARS =
  "SET_INTERVIEW_SURVEY_SCREEN_NUM_STARS";
export const IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN =
  "IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN";

export function isSubmitting(value: boolean): InterviewSurveyScreenAction {
  return {
    type: IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
    value,
  };
}

export function submitSurvey(
  interview: Interview,
  user: User,
  stars: number,
  feedback: string,
  gender: Gender,
  races: Race[],
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  // @ts-ignore FIXME: No overload matches this call
  return async (
    dispatch: ThunkDispatch<AppState, null, InterviewSurveyScreenAction>,
  ): Promise<void> => {
    dispatch({
      type: IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
      value: true,
    });
    // @ts-ignore FIXME: strictNullChecks
    await dispatch(saveSurvey(interview, user, stars, feedback, gender, races));
    dispatch({
      type: IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
      value: false,
    });
  };
}
