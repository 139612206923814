import * as React from "react";
import Button from "@material-ui/core/Button";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import autobind from "autobind-decorator";
import {AppState} from "../reducers/types";
import {AccommodationScreenAction} from "../actions/actionTypes";
import {ThunkDispatch} from "redux-thunk";
import {brand} from "../branding";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  submit,
  toggleScreenReader,
} from "../actions/accommodationScreenActions";
import {withInterview} from "../components/InterviewLoader";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

type Dispatch = ThunkDispatch<
  AppState,
  Record<string, never>,
  AccommodationScreenAction
>;

interface StateProps {
  isSubmitting: boolean;
  didSubmit: boolean;
  screenReader: boolean;
}
interface DispatchProps {
  submit: (text: string) => Promise<void>;
  toggleScreenReader: () => void;
}
type Props = StyledComponentProps & StateProps & DispatchProps;

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 650,
    },
    logo: {
      marginBottom: 30,
      marginRight: "auto",
      marginLeft: "auto",
      transform: "scale(0.5)",
    },
    logoIcon: {
      marginBottom: 30,
      marginRight: "auto",
      marginLeft: "auto",
      transform: "scale(0.5)",
    },
    box: {
      padding: "0 30px",
    },
    boxNoBorder: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      padding: "50px 0px",
    },
    headerText: {
      lineHeight: 1.3,
    },
    text: {
      margin: "0 10px 25px 10px",
      textAlign: "center",
    },
  });

class AccommodationScreen extends React.Component<Props> {
  inputElRef: React.RefObject<HTMLInputElement> = React.createRef();

  @autobind
  onInputChange() {
    this.forceUpdate();
  }

  @autobind
  onScreenReaderChange() {
    this.props.toggleScreenReader();
  }

  @autobind
  onSubmit() {
    // @ts-ignore FIXME: strictNullChecks
    const text = this.inputElRef.current.value;
    if (!text) return;
    this.props.submit(text);
  }

  renderRequestAccommodation(): React.ReactNode {
    const {classes, isSubmitting, screenReader} = this.props;
    const submitButtonLabel = isSubmitting ? "Please Wait" : "Submit";
    return (
      <CenteredScreenContent>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <img className={classes.logo} src={brand.logo} />
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.box}>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography variant="h3" className={classes.headerText}>
              {brand.company} Accommodation Request
            </Typography>
            <Typography paragraph={true}>
              If you have a disability or special need that requires
              accommodation, please let us know by completing the form below:
            </Typography>
            <FormControlLabel
              key="screenReader"
              control={
                <Checkbox
                  checked={screenReader}
                  onChange={this.onScreenReaderChange}
                  color="primary"
                />
              }
              label="I require the usage of a screen reader."
            />
            <div style={{height: 20}} />
            <TextField
              multiline={true}
              autoFocus={true}
              variant="outlined"
              fullWidth={true}
              onChange={this.onInputChange}
              inputRef={this.inputElRef}
              placeholder="Add anything you are comfortable sharing here."
              rows={4}
              maxRows={15}
            />
            <div style={{height: 20}} />
            <Button
              onClick={this.onSubmit}
              variant="contained"
              disabled={isSubmitting}
              color="primary"
            >
              {submitButtonLabel}
            </Button>
          </div>
        </div>
      </CenteredScreenContent>
    );
  }

  renderSubmitted(): React.ReactNode {
    const {classes} = this.props;
    return (
      <CenteredScreenContent>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.boxNoBorder}>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <img className={classes.logoIcon} src={brand.logoMark} />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography variant="h3" className={classes.headerText}>
              Thank you for letting us know!
            </Typography>
            <br />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.text}>
              A {brand.company} staffer will be in contact as soon as possible!
            </Typography>
          </div>
        </div>
      </CenteredScreenContent>
    );
  }

  render(): React.ReactNode {
    const {didSubmit} = this.props;
    if (didSubmit) {
      return this.renderSubmitted();
    }
    return this.renderRequestAccommodation();
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isSubmitting: state.accommodationScreenState.isSubmitting,
  didSubmit: state.accommodationScreenState.didSubmit,
  screenReader: state.accommodationScreenState.screenReader,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  // @ts-ignore FIXME: strictNullChecks
  submit: (text: string) => dispatch(submit(text)),
  toggleScreenReader: () => dispatch(toggleScreenReader()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview([], {ignoreGlobalRules: ["terms", "getStarted"]})(
      AccommodationScreen,
    ),
  ),
);
