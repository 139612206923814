import * as React from "react";
import Button from "@material-ui/core/Button";
import CheckIcon from "./CheckIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface PassedProps {
  header: string;
  text: string;
  buttonLabel: string;
  show: boolean;
  isSubmitting: boolean;
  onNextScreen: () => any;
}
type Props = PassedProps & StyledComponentProps;

const styles = () =>
  createStyles({
    submittedDialogContent: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    centerText: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
    },
  });

class SubmittedDialog extends React.Component<Props> {
  render(): React.ReactNode {
    const {
      header,
      text,
      buttonLabel,
      show,
      isSubmitting,
      onNextScreen,
      classes,
    } = this.props;
    return (
      <Dialog open={show}>
        <CheckIcon />
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <DialogTitle className={classes.centerText}>{header}</DialogTitle>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <DialogContent className={classes.submittedDialogContent}>
          <Typography>{text}</Typography>
        </DialogContent>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <DialogActions className={classes.center}>
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={onNextScreen}
          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SubmittedDialog);
