module.exports = {
  firebase: {
    apiKey: "AIzaSyDr3o13ID-hEigSpI-SPsnizlTiO8mQyto",
    authDomain: "byteboard-web.firebaseapp.com",
    databaseURL: "https://byteboard-web.firebaseio.com",
    projectId: "byteboard-web",
    storageBucket: "byteboard-web.appspot.com",
    messagingSenderId: "1082097066380",
    appId: "1:1082097066380:web:d019974ac0921ea5",
  },
  gapi: {
    apiKey: "AIzaSyDr3o13ID-hEigSpI-SPsnizlTiO8mQyto",
    clientId:
      "1082097066380-rkr1efantj6g5rmqp2ek4mlkc07abifl.apps.googleusercontent.com",
    discoveryDocs: ["https://script.googleapis.com/$discovery/rest?version=v1"],
    scopes: ["https://www.googleapis.com/auth/userinfo.email"],
  },
  languages: [
    "cpp",
    "csharp",
    "golang",
    "java",
    "js",
    "kotlin",
    "php",
    "python2",
    "python3",
    "ruby",
    "rust",
    "ts",
    "swift",
    "swiftui",
    "flutter",
    "reactjs",
    "reactts",
    "r",
    "sql",
  ],
  languageNameMap: {
    cpp: "C++",
    csharp: "C#",
    golang: "Go",
    java: "Java",
    js: "JavaScript",
    js_web: "JavaScript (No Framework)",
    kotlin: "Kotlin",
    php: "PHP",
    python2: "Python",
    python3: "Python",
    ruby: "Ruby",
    rust: "Rust",
    swift: "Swift (UIKit)",
    swiftui: "Swift (SwiftUI)",
    ts: "TypeScript",
    ts_web: "TypeScript (No Framework)",
    flutter: "Flutter",
    reactjs: "JavaScript (React)",
    reactts: "TypeScript (React)",
    r: "R",
    sql: "SQL",
    systems_design: "Systems Design",
  },
  languageNameMapWithVersions: {
    cpp: "C++",
    csharp: "C#",
    golang: "Go",
    java: "Java",
    js: "JavaScript",
    js_web: "JavaScript (No Framework)",
    php: "PHP",
    python2: "Python 2.7",
    kotlin: "Kotlin",
    python3: "Python 3",
    ruby: "Ruby",
    rust: "Rust",
    swift: "Swift (UIKit)",
    swiftui: "Swift (SwiftUI)",
    ts: "TypeScript",
    ts_web: "TypeScript (No Framework)",
    flutter: "Flutter",
    reactjs: "JavaScript (React)",
    reactts: "TypeScript (React)",
    r: "R",
    sql: "SQL Only",
    systems_design: "Systems Design",
  },
  languageDownloadUrlMap: {
    cpp: "https://cmake.org/download/",
    csharp: "https://dotnet.microsoft.com/download/visual-studio-sdks",
    golang: "https://golang.org/dl/",
    java: "https://www.oracle.com/java/technologies/downloads/#java11",
    js: "https://nodejs.org/en/download/",
    kotlin: "https://kotlinlang.org/docs/command-line.html",
    php: "https://www.php.net/downloads.php",
    python2: "https://www.python.org/downloads/",
    python3: "https://www.python.org/downloads/",
    ruby: "https://www.ruby-lang.org/en/downloads/",
    rust: "https://www.rust-lang.org/tools/install",
    ts: "https://nodejs.org/en/download/",
    swift: "https://swift.org/download/",
    swiftui: "https://swift.org/download/",
    flutter: "https://flutter.dev/docs/get-started/install",
    reactjs: "https://nodejs.org/en/download/",
    reactts: "https://nodejs.org/en/download/",
    r: "https://posit.co/download/rstudio-desktop/",
    sql: "https://sqlitebrowser.org/",
  },
  languageInstallCommandMap: {
    rust: "&& curl --proto '=https' --tlsv1.2 -sSf https://sh.rustup.rs | sh -s -- -y && source \"$HOME/.cargo/env\" ",
    kotlin:
      '&& curl -s https://get.sdkman.io | bash && source "$HOME/.sdkman/bin/sdkman-init.sh" && sdk install kotlin ',
  },
  interviewBasePath: "",
  redirectUrl: "/redirect",
  devHosts: ["env.localhost:1234", "interview.localhost:1234"],
  webInteviewPort: "8080",
};
