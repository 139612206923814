import * as React from "react";
import {ErrorAction} from "./actionTypes";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const USER_NOT_SIGNED_IN = "USER_NOT_SIGNED_IN";

export class UserNotSignedInError {
  message = "User is not signed in.";
}

export class NoEmailError {
  message = "Unable to get email address for the current user.";
}

export class UserNotAdminError {
  message = "You do not have permission to view this content.";
}

export class UnexpectedServerResponse {
  response: any;
  message: string;
  constructor(response: any) {
    this.response = response;
    this.message = `Received unexpected response: ${JSON.stringify(response)}`;
  }
}

export interface ErrorMessageOptions {
  /** Optional additional tips to show the user for the current error. */
  tips?: React.ReactNode[];
  /** Whether or not to hide all of the tips from the error screen. */
  hideTips?: boolean;
  /** Whether or not this should display as a notification. */
  isNotification?: boolean;
}

export function setErrorMessage(
  errorMessage: string,
  opts: ErrorMessageOptions = {},
): ErrorAction {
  return {
    errorMessage,
    tips: opts.tips || [],
    hideTips: opts.hideTips || false,
    isNotification: opts.isNotification || false,
    type: SET_ERROR_MESSAGE,
  };
}

export function userNotSignedIn(): ErrorAction {
  return {
    type: USER_NOT_SIGNED_IN,
  };
}
