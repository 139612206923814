import * as zipUploadActions from "../actions/zipUploadActions";
import {ZipUpload} from "./types";
import {ZipUploadAction} from "../actions/actionTypes";

const {
  CLEAR_ZIP_UPLOAD_FILE,
  SET_ZIP_IS_UPLOADED,
  SET_ZIP_UPLOADED_FILES,
  SET_ZIP_UPLOAD_FILE,
  SET_ZIP_UPLOAD_PROGRESS,
  TOGGLE_SHOW_ZIP_UPLOAD_DIALOG,
} = zipUploadActions;

const defaultState: ZipUpload = {
  isUploaded: false,
  progress: 0,
  showDialog: false,
  uploadedFiles: [],
};

export function zipUpload(
  state: ZipUpload = defaultState,
  action: ZipUploadAction,
): ZipUpload {
  switch (action.type) {
    case CLEAR_ZIP_UPLOAD_FILE:
      return Object.assign({}, state, {
        file: null,
        isUploaded: false,
        progress: 0,
        uploadedFiles: [],
      });
    case SET_ZIP_IS_UPLOADED:
      return Object.assign({}, state, {
        isUploaded: action.value,
      });
    case SET_ZIP_UPLOADED_FILES:
      return Object.assign({}, state, {
        uploadedFiles: action.value,
      });
    case SET_ZIP_UPLOAD_FILE:
      return Object.assign({}, state, {
        file: action.file,
      });
    case SET_ZIP_UPLOAD_PROGRESS:
      return Object.assign({}, state, {
        progress: action.progress,
      });
    case TOGGLE_SHOW_ZIP_UPLOAD_DIALOG:
      return Object.assign({}, state, {
        showDialog:
          typeof action.value !== "undefined"
            ? action.value
            : !state.showDialog,
      });
    default:
      return state;
  }
}
