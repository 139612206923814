import * as React from "react";
import * as m1InterviewScreenActions from "../actions/m1InterviewScreenActions";
import Button from "@material-ui/core/Button";
import ConfirmSubmitDialog from "../components/ConfirmSubmitDialog";
import Fade from "../components/Fade";
import Instructions, {InstructionItem} from "../components/Instructions";
import Note from "../components/Note";
import Spinner from "../components/Spinner";
import SubmittedDialog from "../components/SubmittedDialog";
import Typography from "@material-ui/core/Typography";
import UnorderedList from "../components/UnorderedList";
import autobind from "autobind-decorator";
import {truncate} from "lodash";
import blurryDocImg from "../img/blurry-doc.png";
import {
  AppState,
  BBDocState,
  CandidateResponse,
  Flags,
  Interview,
  M1InterviewScreenState,
  ModuleId,
  User,
} from "../reducers/types";
import {BBDoc} from "../components/BBDoc";
import {HelpTopicId} from "../components/HelpDialog";
import {
  M1InterviewScreenAction,
  HelpDialogAction,
} from "../actions/actionTypes";
import {ThunkDispatch} from "redux-thunk";
import {connect, useDispatch} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import {getIframeUrl} from "../helpers/interview";
import {getServerTime as getServerTimeFn} from "../util/time";
import {isBBDocEnabled} from "../helpers/bbdoc";
import {
  createComment,
  deleteComment,
  loadBBDoc,
  updateCandidateResponse,
} from "../actions/bbdocActions";
import {generateBBDocCommentId} from "../helpers/bbdoc";
import {
  isSecurityInterview,
  isDataAnalysisInterview,
  isStaffEngInterview,
} from "../helpers/interview";
import {logFEInfo, LogAction} from "../actions/serverInfoActions";
import {overviewUrl} from "../helpers/urls/routerUrls";
import {showHelpDialog} from "../actions/helpDialogActions";
import {submitM1} from "../actions/interviewActions";
import {withInterview, RedirectRule} from "../components/InterviewLoader";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import {useEventListener} from "../hooks/browser";

const {
  toggleScreenInstructions,
  toggleConfirmDialog,
  toggleSubmittedDialog,
  startM1,
  readyToStartM1,
} = m1InterviewScreenActions;

const endTimeRedirectBuffer = 30 * 1000; // 30 seconds;
const autoCollapseMaxWidth = 1400;

const M1_MODULE_ID: ModuleId = "m1";

interface StateProps {
  interview: Interview;
  m1InterviewScreenState: M1InterviewScreenState;
  user: User;
  flags: Flags;
  bbdocState: BBDocState;
  getServerTime: () => number;
}
interface DispatchProps {
  loadBBDoc: (moduleId: ModuleId) => Promise<void>;
  createBBDocComment: (candidateResponse: CandidateResponse) => Promise<void>;
  deleteBBDocComment: (candidateResponse: CandidateResponse) => Promise<void>;
  updateBBDocCandidateResponse: (
    candidateResponse: CandidateResponse,
  ) => Promise<void>;
  logFEInfo: (action: LogAction, data: any) => Promise<void>;
  readyToStartM1: () => any;
  startM1: () => any;
  submitM1: (interview: Interview, user: User) => any;
  toggleScreenInstructions: () => any;
  toggleConfirmDialog: () => any;
  toggleSubmittedDialog: () => any;
  showHelpDialog: (activeTopicId: string) => void;
}
interface Props
  extends StateProps,
    DispatchProps,
    StyledComponentProps,
    RouteComponentProps {
  atsId: string;
}

const styles = () =>
  createStyles({
    root: {
      bottom: 0,
      display: "flex",
      left: 0,
      overflow: "hidden",
      position: "absolute",
      right: 0,
      top: 0,
    },
    contentContainer: {
      borderRight: "solid 1px #ddd",
      flex: 1,
      height: "100%",
      overflow: "hidden",
      position: "relative",
    },
    fullScreen: {
      height: "100%",
      width: "100%",
    },
    beforeInstructionsBackground: {
      background: `#fcfcfc url(${blurryDocImg}) no-repeat left top`,
      backgroundSize: 1300,
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    beforeInstructions: {
      background: "#fff",
      border: "solid 1px #ccc",
      borderRadius: 6,
      left: "50%",
      maxWidth: "80%",
      padding: "30px 25px 25px 25px",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
    },
    startButtonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    instructionsText: {
      color: "black",
    },
    list: {
      paddingLeft: 15,
    },
  });

class M1InterviewScreen extends React.Component<Props> {
  instructionsScrollEl?: HTMLElement;
  redirectTimeoutId: any;
  autoSubmitTimeoutId: any;
  beforeInstructionsRef: React.RefObject<HTMLSpanElement> = React.createRef();

  componentDidMount() {
    this.maybeFocusBeforeInstructions();
    this.autoSubmitWhenTimeExpires();
    this.maybeLoadBBDoc();
  }

  componentDidUpdate(prevProps: Props) {
    const {interview, m1InterviewScreenState} = this.props;
    if (
      (interview.m1DocId ||
        (this.shouldUseBBDoc() && interview.isBBDocReady)) &&
      m1InterviewScreenState.isStartingM1 &&
      !interview.m1OpenTime
    ) {
      this.props.startM1();
    }
    const justStarted = interview.m1OpenTime && !prevProps.interview.m1OpenTime;
    if (
      justStarted &&
      m1InterviewScreenState.hasExpandedInstructions &&
      window.innerWidth <= autoCollapseMaxWidth
    ) {
      this.onToggleExpandInstructions();
      this.props.logFEInfo(LogAction.AUTO_COLLAPSED_M1_INSTRUCTIONS, {
        autoCollapsedM1Instructions: true,
        screenWidth: window.innerWidth,
      });
    }

    // reset the autosubmit callback timeline every time we get new props,
    // to ensure that the autosubmit always triggers at the correct time.
    // (for example, if the support tool adds time to an active interview,
    // this will ensure the autosubmit doesn't trigger until the new timeline
    // is reached).
    this.autoSubmitWhenTimeExpires();

    this.maybeLoadBBDoc();
  }

  componentWillUnmount() {
    clearTimeout(this.redirectTimeoutId);
    clearTimeout(this.autoSubmitTimeoutId);
  }

  hasStarted(): boolean {
    return Boolean(this.getExpectedEndTime());
  }

  shouldUseBBDoc(): boolean {
    const {interview, flags} = this.props;
    return isBBDocEnabled(interview, flags);
  }

  getExpectedEndTime(): number {
    const {m1OpenTime, m1Duration} = this.props.interview;
    return m1OpenTime && m1Duration ? m1OpenTime + m1Duration : 0;
  }

  getM1DurationMinutes(): number {
    const {m1Duration} = this.props.interview;
    // @ts-ignore FIXME: strictNullChecks
    return m1Duration / 1000 / 60;
  }

  getTimeRemaining(): number {
    return this.getExpectedEndTime() - this.props.getServerTime();
  }

  isTimeExpired(): boolean {
    return this.getTimeRemaining() <= 0;
  }

  isEndTimeRecorded(): boolean {
    return Boolean(this.props.interview.m1EndTime);
  }

  isModuleComplete(): boolean {
    return this.isTimeExpired() || this.isEndTimeRecorded();
  }

  maybeFocusBeforeInstructions() {
    if (!this.hasStarted()) {
      // @ts-ignore FIXME: strictNullChecks
      this.beforeInstructionsRef.current.focus();
    }
  }

  maybeLoadBBDoc() {
    const {isLoading, isLoaded} = this.props.bbdocState;
    if (this.hasStarted() && this.shouldUseBBDoc() && !isLoading && !isLoaded) {
      this.props.loadBBDoc(M1_MODULE_ID);
    }
  }

  autoSubmitWhenTimeExpires() {
    if (!this.hasStarted() || this.isModuleComplete()) {
      return;
    }

    let timeRemaining = this.getTimeRemaining();
    if (timeRemaining < 0) {
      timeRemaining = 0;
    }

    clearTimeout(this.autoSubmitTimeoutId);
    // even if timeRemaining == 0, we still set this to run in a 0-second
    // setTimeout, so the current execution context
    // (probably a componentDidUpdate) finishes before onSubmit runs.
    this.autoSubmitTimeoutId = setTimeout(() => {
      this.onSubmit();
    }, timeRemaining);
  }

  @autobind
  onToggleExpandInstructions() {
    this.props.toggleScreenInstructions();
  }

  @autobind
  onStart() {
    this.props.readyToStartM1();
  }

  @autobind
  onToggleConfirmDialog() {
    this.props.toggleConfirmDialog();
  }

  @autobind
  async onSubmit() {
    const {interview, user} = this.props;
    if (!this.isEndTimeRecorded()) {
      await this.props.submitM1(interview, user);
    }
    this.props.toggleSubmittedDialog();
  }

  @autobind
  onNextScreen() {
    const {atsId} = this.props;
    this.props.history.push(overviewUrl(atsId));
  }

  @autobind
  onOpenTips() {
    this.props.showHelpDialog(HelpTopicId.TIPS);
  }

  @autobind
  onNewComment(newComment: CandidateResponse) {
    // Generate a new id for the comment using the ats id. We need to do this
    // now instead of using the firestore auto id to ensure the animation will
    // be smooth.
    const id = generateBBDocCommentId();
    this.props.createBBDocComment({
      ...newComment,
      id,
    });
  }

  renderBeforeInstructions(): React.ReactNode {
    const {classes, m1InterviewScreenState} = this.props;
    const startButtonLabel = m1InterviewScreenState.isStartingM1
      ? "Loading"
      : "Start Part 1";
    return (
      // @ts-ignore FIXME: strictNullChecks
      <Fade in={!this.hasStarted()}>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.beforeInstructionsBackground}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.beforeInstructions}>
            <Typography variant="h2">
              <span
                tabIndex={-1}
                style={{outline: "none"}}
                ref={this.beforeInstructionsRef}
              >
                Before we begin:
              </span>
            </Typography>
            <UnorderedList
              items={[
                <Typography className={classes?.instructionsText}>
                  In the top right corner of the page, note the box that says
                  "Time has not begun." This is your timer. It can be
                  temporarily hidden by clicking on it.
                </Typography>,
                <Typography className={classes?.instructionsText}>
                  The use of copy/paste has been disabled for Part 1. Please be
                  mindful of where you are leaving your input in order to
                  minimize lost time.
                </Typography>,
                <Typography className={classes?.instructionsText}>
                  On the right are your instructions. These will be present
                  throughout Part 1 and can be collapsed using the button at the
                  bottom left of the panel. Please read them now, before
                  proceeding.
                </Typography>,
              ]}
            />
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <div className={classes.startButtonContainer}>
              <Button
                color="primary"
                variant="contained"
                disabled={m1InterviewScreenState.isStartingM1}
                onClick={this.onStart}
              >
                {startButtonLabel}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    );
  }

  renderInstructions(): React.ReactNode {
    const {interview, classes, m1InterviewScreenState} = this.props;
    const {hasExpandedInstructions} = m1InterviewScreenState;
    const instructions: InstructionItem[] = [
      {
        enabled: true,
        content: (
          <React.Fragment>
            <Typography variant="h3" align="center">
              Part 1 Instructions
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText}>
              Your teammates have started to outline the design for a new
              project and they need your help to complete it. In the next{" "}
              {this.getM1DurationMinutes()} minutes, your job is to finish the
              design document by taking the following steps:
            </Typography>
            <br />
            <Typography variant="h4">Step 1</Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText}>
              Read the document carefully to understand the goals of the project
              and its technical design. You are encouraged to leave comments
              with questions or clarifications you may have.
            </Typography>
            <br />
            <Typography variant="h4">Step 2</Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText}>
              Reply to the questions left as comments on the right side of the
              document.
            </Typography>
            <br />
            {this.shouldUseBBDoc() ? null : (
              <Note>
                {/* @ts-ignore FIXME: strictNullChecks*/}
                <Typography className={classes.instructionsText}>
                  Note: If you are having trouble viewing or accessing comments,
                  you can answer the comment prompts inline at the bottom of the
                  document. Comments are hyperlinked (blue underlined text), and
                  you can click to see the associated prompt.
                </Typography>
              </Note>
            )}
            {isDataAnalysisInterview(interview) ||
            isSecurityInterview(interview) ||
            isStaffEngInterview(interview) ? null : (
              <React.Fragment>
                <Typography variant="h4">Step 3</Typography>
                {/* @ts-ignore FIXME: strictNullChecks*/}
                <Typography className={classes.instructionsText}>
                  Write the "Conclusion" section, describing and explaining your
                  recommendation.
                </Typography>
                <UnorderedList
                  // @ts-ignore FIXME: strictNullChecks
                  className={classes.list}
                  items={[
                    // @ts-ignore FIXME: strictNullChecks
                    <Typography className={classes.instructionsText}>
                      There is no one “right answer;” however, we highly
                      recommend discussing the pros, cons, trade-offs, and
                      assumptions that motivate your choice.
                    </Typography>,
                    // @ts-ignore FIXME: strictNullChecks
                    <Typography className={classes.instructionsText}>
                      You can suggest new ideas that were not included in the
                      document, but any new ideas should be accompanied by
                      tradeoff analysis and justifications.
                    </Typography>,
                  ]}
                />
              </React.Fragment>
            )}
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.instructionsText}>
              If you run into any issues or have questions, click the{" "}
              <strong>?</strong> at the top of your screen.
            </Typography>
          </React.Fragment>
        ),
      },
    ];
    return (
      <Instructions
        instructions={instructions}
        instructionsIndex={0}
        isExpanded={hasExpandedInstructions}
        isSubmitEnabled={this.hasStarted()}
        submitButtonLabel="Submit"
        onToggleExpand={this.onToggleExpandInstructions}
        onSubmit={this.onToggleConfirmDialog}
        onNext={() => {
          /**/
        }}
        onPrev={() => {
          /**/
        }}
      />
    );
  }

  renderDoc(): React.ReactNode {
    if (this.shouldUseBBDoc()) {
      return this.renderBBDoc();
    }
    return this.renderIframe();
  }

  renderBBDoc(): React.ReactNode {
    const {interview} = this.props;
    const {interviewDoc, isLoaded, isLoading} = this.props.bbdocState;
    if (isLoaded) {
      return (
        <BBDoc
          // @ts-ignore FIXME: strictNullChecks
          interviewDoc={interviewDoc}
          readonly={this.isModuleComplete()}
          candidateName={interview.candidateName}
          onUpdateCandidateResponse={this.props.updateBBDocCandidateResponse}
          onNewComment={this.onNewComment}
          onDeleteComment={this.props.deleteBBDocComment}
        />
      );
    } else if (isLoading) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  renderIframe(): React.ReactNode {
    const {interview, m1InterviewScreenState, classes, user} = this.props;
    const {m1DocId, m1DocUrl, m1OpenTime} = interview;
    const time = m1InterviewScreenState.docUpdateTime;
    const iframeSrc = getIframeUrl(m1DocId, m1DocUrl, {
      rm: "minimal",
      time: time.toString(),
      authuser: user.email ?? "",
    });
    if (iframeSrc && Boolean(m1OpenTime)) {
      return (
        <iframe
          src={iframeSrc}
          // @ts-ignore FIXME: strictNullChecks
          className={classes.fullScreen}
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
        />
      );
    } else if (this.hasStarted()) {
      return <Spinner />;
    }
    return null;
  }

  renderConfirmSubmitDialog(): React.ReactNode {
    const {m1InterviewScreenState} = this.props;
    const {showConfirmDialog, isSubmittingM1} = m1InterviewScreenState;
    const confirmSubmitButtonLabel = isSubmittingM1
      ? "Submitting"
      : "Submit Part 1";
    return (
      <ConfirmSubmitDialog
        label={confirmSubmitButtonLabel}
        show={showConfirmDialog}
        isSubmitting={isSubmittingM1}
        onConfirmSubmit={this.onSubmit}
        onCancelSubmit={this.onToggleConfirmDialog}
      />
    );
  }

  renderSubmittedDialog(): React.ReactNode {
    const {interview, m1InterviewScreenState} = this.props;
    const {breakDuration} = interview;
    const {showSubmittedDialog, isSubmittingM1} = m1InterviewScreenState;
    // @ts-ignore FIXME: strictNullChecks
    const breakDurationMinutes = breakDuration / 1000 / 60;
    return (
      <SubmittedDialog
        header="Part 1 has been submitted!"
        text={`You will now have a ${breakDurationMinutes} minute break (see top-right timer). At the end of your break you should start Part 2 of the interview.`}
        buttonLabel="I got it!"
        show={showSubmittedDialog}
        isSubmitting={isSubmittingM1}
        onNextScreen={this.onNextScreen}
      />
    );
  }

  render(): React.ReactNode {
    const {classes} = this.props;
    return (
      <WrapClipboard>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.contentContainer}>
            {this.renderBeforeInstructions()}
            {this.renderDoc()}
          </div>
          {this.renderInstructions()}
          {this.renderConfirmSubmitDialog()}
          {this.renderSubmittedDialog()}
        </div>
      </WrapClipboard>
    );
  }
}

function WrapClipboard(props: {children: React.ReactNode}): React.ReactElement {
  const maxLength = 100; // limit how much text we put into the logs
  const dispatch = useDispatch();

  useEventListener("copy", (e: ClipboardEvent) => {
    const text = document.getSelection()?.toString();
    if (text) {
      logAction(LogAction.COPIED_M1_CONTENT, text);
    }
  });

  useEventListener("paste", (e: ClipboardEvent) => {
    const text = e.clipboardData?.getData("text");
    if (text) {
      logAction(LogAction.PASTED_M1_CONTENT, text);
    }
  });

  function logAction(action: LogAction, text: string) {
    dispatch(
      logFEInfo(action, {
        text: truncate(text, {length: maxLength}),
        length: text.length,
      }),
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

const mapStateToProps = (state: AppState): StateProps => ({
  interview: state.interview,
  m1InterviewScreenState: state.m1InterviewScreenState,
  user: state.user,
  flags: state.flags,
  bbdocState: state.bbdocState.current,
  getServerTime: () => getServerTimeFn(state.serverInfo.serverTimeOffset),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    AppState,
    null,
    M1InterviewScreenAction | HelpDialogAction
  >,
): DispatchProps => ({
  // @ts-ignore FIXME: strictNullChecks
  loadBBDoc: (moduleId: ModuleId) => dispatch(loadBBDoc(moduleId)),
  // @ts-ignore FIXME: strictNullChecks
  createBBDocComment: (candidateResponse: CandidateResponse) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(createComment(candidateResponse, M1_MODULE_ID));
  },
  // @ts-ignore FIXME: strictNullChecks
  deleteBBDocComment: (candidateResponse: CandidateResponse) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(deleteComment(candidateResponse));
  },
  // @ts-ignore FIXME: strictNullChecks
  logFEInfo: (action: LogAction, data: any) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(logFEInfo(action, data));
  },
  // @ts-ignore FIXME: strictNullChecks
  readyToStartM1: () => dispatch(readyToStartM1()),
  // @ts-ignore FIXME: strictNullChecks
  startM1: () => dispatch(startM1()),
  submitM1: (interview: Interview, user: User) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(submitM1(interview, user));
  },
  toggleScreenInstructions: () => dispatch(toggleScreenInstructions()),
  toggleConfirmDialog: () => dispatch(toggleConfirmDialog()),
  toggleSubmittedDialog: () => dispatch(toggleSubmittedDialog()),
  showHelpDialog: (activeTopicId: string) => {
    return dispatch(showHelpDialog(activeTopicId));
  },
  // @ts-ignore FIXME: strictNullChecks
  updateBBDocCandidateResponse: (candidateResponse: CandidateResponse) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(updateCandidateResponse(candidateResponse, M1_MODULE_ID));
  },
});

const redirectRules: RedirectRule[] = [
  {
    // @ts-ignore FIXME: strictNullChecks
    match: (interview: Interview, serverTime: number) => {
      return (
        interview.m1EndTime &&
        interview.m1EndTime + endTimeRedirectBuffer < serverTime
      );
    },
    redirect: (interview) => overviewUrl(interview.id),
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview(redirectRules)(withRouter(M1InterviewScreen)),
  ),
);
