import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import React, {useContext} from "react";
import {ApplicantContext} from "../context/ApplicantContext";
import {Education, Project, Role} from "../helpers/types";

const useStyles = makeStyles({
  containerBox: {
    columnGap: "8px",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "8px",
  },
});

type Props = {
  experience: Role | Education | Project;
};

export default function ExperienceSkills({experience}: Props) {
  const {skills} = useContext(ApplicantContext);
  const classes = useStyles();

  const filteredSkills = skills.filter((skill) =>
    skill.experiences.includes(experience.uuid),
  );

  if (filteredSkills.length === 0) return null;
  return (
    <Box className={classes.containerBox}>
      {filteredSkills.map((skill) => (
        <Chip size="small" color="primary" label={skill.name} />
      ))}
    </Box>
  );
}
