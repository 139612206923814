import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Stars from "../components/Stars";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {ExternalLiveCodingInterview} from "../reducers/types";
import {liveCodingThankYouUrl} from "../helpers/urls/routerUrls";
import {post} from "../util/fetch";
import {
  liveCodingInterviewUrl,
  saveSurveyUrl,
} from "../helpers/urls/interviewUrls";
import Spinner from "../components/Spinner";
import {useUser} from "../hooks/user";
import {Redirect} from "react-router-dom";
import {setErrorMessage} from "../actions/errorActions";
import {useDispatch} from "react-redux";
import {get} from "../actions/fetchActions";

const useStyles = makeStyles({
  feedback: {
    marginBottom: 35,
  },
  stars: {
    marginBottom: 35,
  },
});

interface Props {
  liveCodingInterviewId: string;
}
export default function LiveCodingSurveyScreen({liveCodingInterviewId}: Props) {
  const [numStars, setNumStars] = React.useState<number>(0);
  const [feedback, setFeedback] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const classes = useStyles();
  const user = useUser();
  const dispatch = useDispatch();
  const [liveCodingInterview, setLiveCodingInterview] =
    React.useState<ExternalLiveCodingInterview | null>(null);

  React.useEffect(() => {
    async function getLiveCodingInterview() {
      const req = get(liveCodingInterviewUrl(liveCodingInterviewId));
      const res = (await dispatch(req)) as any;
      if (!("error" in res)) {
        setLiveCodingInterview(res.liveCodingInterview);
      } else {
        console.error("Error finding interview:", res.error);
        dispatch(
          setErrorMessage(
            "Your CodeCollab interview has failed to load. Please double check your URL or contact support@byteboard.dev.",
            {
              hideTips: true,
            },
          ),
        );
      }
    }
    getLiveCodingInterview();
  }, []);

  async function submitLiveCodingSurvey() {
    if (liveCodingInterview) {
      setSubmitting(true);
      const data = {
        atsId: liveCodingInterview?.atsId,
        ...(numStars ? {stars: numStars} : {}),
        feedback,
        liveCodingInterviewId,
      };
      await post(saveSurveyUrl(liveCodingInterview?.atsId), data, {
        token: user.idToken,
      });
      // If the survey was successful, manually update the candidate's live coding interview to true
      // so we can redirect to the thank you page
      setLiveCodingInterview({...liveCodingInterview, survey: true});
      setSubmitting(false);
    }
  }

  if (!liveCodingInterview) {
    return <Spinner />;
  }

  const redirectUrl = checkForRedirect(liveCodingInterview);
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  if (!liveCodingInterview) {
    return <Spinner />;
  }

  return (
    <CenteredScreenContent>
      <Typography variant="h2">
        All done! Congratulations! How would you rate your CodeCollab interview
        experience?
      </Typography>
      <div className={classes.stars}>
        <Stars numStars={numStars} onChange={(e) => setNumStars(e)} />
      </div>

      <div className={classes.feedback}>
        <TextField
          multiline={true}
          variant="outlined"
          fullWidth={true}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder={
            "Let us know what went well and what we could improve to make this CodeCollab interview experience better!"
          }
          rows={4}
          maxRows={15}
        />
      </div>
      <Button
        color="primary"
        variant="contained"
        disabled={submitting || numStars === 0 || feedback.length === 0}
        onClick={submitLiveCodingSurvey}
      >
        Submit
      </Button>
    </CenteredScreenContent>
  );
}

function checkForRedirect(
  liveCodingInterview: ExternalLiveCodingInterview,
): string | undefined {
  /* If the candidate has already completed the survey, redirect them to the thank you screen. */
  if (Boolean(liveCodingInterview.survey)) {
    return liveCodingThankYouUrl(liveCodingInterview.id);
  }
}
