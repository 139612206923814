import * as m1InterviewScreenActions from "../actions/m1InterviewScreenActions";
import {M1InterviewScreenAction} from "../actions/actionTypes";
import {M1InterviewScreenState} from "../reducers/types";

const defaultState: M1InterviewScreenState = {
  hasExpandedInstructions: true,
  isStartingM1: false,
  isSubmittingM1: false,
  isUnresolvingComments: false,
  showConfirmDialog: false,
  showSubmittedDialog: false,
  docUpdateTime: 0,
};

export function m1InterviewScreenState(
  state: M1InterviewScreenState = defaultState,
  action: M1InterviewScreenAction,
) {
  switch (action.type) {
    case "M1_INTERVIEW_SCREEN.IS_STARTING_M1":
      return Object.assign({}, state, {isStartingM1: action.value});
    case "M1_INTERVIEW_SCREEN.IS_SUBMITTING_M1":
      return Object.assign({}, state, {isSubmittingM1: action.value});
    case "M1_INTERVIEW_SCREEN.IS_UNRESOLVING_COMMENTS":
      return Object.assign({}, state, {isUnresolvingComments: action.value});
    case "M1_INTERVIEW_SCREEN.TOGGLE_M1_INTERVIEW_SCREEN_INSTRUCTIONS":
      return Object.assign({}, state, {
        hasExpandedInstructions: !state.hasExpandedInstructions,
      });
    case "M1_INTERVIEW_SCREEN.TOGGLE_M1_INTERVIEW_SCREEN_CONFIRM_DIALOG":
      return Object.assign({}, state, {
        showConfirmDialog: !state.showConfirmDialog,
      });
    case "M1_INTERVIEW_SCREEN.TOGGLE_M1_INTERVIEW_SCREEN_SUBMITTED_DIALOG":
      return Object.assign({}, state, {
        showSubmittedDialog: !state.showSubmittedDialog,
      });
    case "M1_INTERVIEW_SCREEN.SET_DOC_UPDATE_TIME":
      return Object.assign({}, state, {docUpdateTime: action.value});
    default:
      return state;
  }
}
