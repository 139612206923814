import React, {useState, useRef, useEffect} from "react";
import {
  BBDocBody,
  isDocBodyHighlightElement,
  ResponseIdToHighlightedElementsMap,
} from "./BBDocBody";
import {BBDocCommentList} from "./BBDocCommentList";
import {InterviewDoc, CandidateResponse} from "../reducers/types";
import {getRangeForSelection} from "../util/selection";
import {isNewComment, useComponentBecameReadonly} from "../helpers/bbdoc";
import {makeStyles} from "@material-ui/core/styles";
import {useForceUpdate} from "../util/hooks";

interface Props {
  interviewDoc: InterviewDoc;
  candidateName: string;
  readonly: boolean;
  onNewComment?: (newComment: CandidateResponse) => any;
  onDeleteComment?: (candidateResponse: CandidateResponse) => any;
  onUpdateCandidateResponse?: (candidateResponse: CandidateResponse) => any;
}

const useStyles = makeStyles(
  () => ({
    root: {
      background: "#fafafa",
      bottom: 0,
      left: 0,
      overflow: "auto",
      position: "absolute",
      right: 0,
      top: 0,
    },
    content: {
      display: "flex",
      margin: "0 auto",
      maxWidth: 1200,
      minWidth: 900,
    },
  }),
  {
    name: "BBDoc",
  },
);

export const BBDoc: React.FC<Props> = (props) => {
  const classes = useStyles({});
  const [
    responseIdToHighlightedElementsMap,
    setResponseIdToHighlightedElementsMap,
  ] = useState<ResponseIdToHighlightedElementsMap>({});
  const [docTextSelection, setDocTextSelection] = useState<Selection | null>(
    null,
  );
  const [activeResponseId, setActiveResponseId] = useState<string | null>(null);
  const forceUpdate = useForceUpdate();
  const docBodyRef = useRef<HTMLDivElement>(null);
  const commentListRef = useRef<HTMLElement>(null);
  const commentBoxRefs = useRef<{
    [candidateResponseId: string]: HTMLElement | null;
  }>({});

  useEffect(() => {
    document.body.addEventListener("mousedown", maybeUnsetActiveResponseId);
    return () => {
      document.body.removeEventListener(
        "mousedown",
        maybeUnsetActiveResponseId,
      );
    };
  }, [activeResponseId]);

  const maybeUnsetActiveResponseId = (e: MouseEvent) => {
    const clickedElement = e.target as HTMLElement;
    // @ts-ignore FIXME: strictNullChecks
    const isClickWithinCommentBox = commentListRef.current.contains(
      clickedElement?.parentElement,
    );
    const isClickWithinDocHighlight = isDocBodyHighlightElement(clickedElement);
    if (
      !isClickWithinCommentBox &&
      !isClickWithinDocHighlight &&
      activeResponseId &&
      !isNewComment(activeResponseId)
    ) {
      setActiveResponseId(null);
    }
  };

  // Re-render to reposition the comments after this component becomes readonly.
  useComponentBecameReadonly(props, forceUpdate);

  const onHighlightRanges = (
    resIdToElementMap: ResponseIdToHighlightedElementsMap,
  ) => {
    setResponseIdToHighlightedElementsMap(resIdToElementMap);
  };

  const onDocTextSelectionChange = (newSelection: Selection | null) => {
    setDocTextSelection(newSelection);
    // The selection instance doesn't always change, so do a force update to
    // ensure that a re-render happens.
    if (newSelection || docTextSelection) {
      forceUpdate();
    }
  };

  const onCommentHeightChange = () => {
    // Re-render to reposition the comments.
    forceUpdate();
  };

  const onNewComment = (newCandidateResponse: CandidateResponse) => {
    const selectionRange = getRangeForSelection(
      // @ts-ignore FIXME: strictNullChecks
      docBodyRef.current,
      docTextSelection,
    );
    // @ts-ignore FIXME: strictNullChecks
    props.onNewComment({
      ...newCandidateResponse,
      // Set the selection range on the new candidate response.
      selectionRange,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <BBDocBody
          interviewDoc={props.interviewDoc}
          ref={docBodyRef}
          activeResponseId={activeResponseId}
          readonly={props.readonly}
          onHighlightRanges={onHighlightRanges}
          onSelectionChange={onDocTextSelectionChange}
          onHighlightedRangeClick={setActiveResponseId}
          commentBoxRefs={commentBoxRefs}
        />
        <BBDocCommentList
          interviewDoc={props.interviewDoc}
          ref={commentListRef}
          onNewComment={onNewComment}
          // @ts-ignore FIXME: strictNullChecks
          onDeleteComment={props.onDeleteComment}
          // @ts-ignore FIXME: strictNullChecks
          onUpdateCandidateResponse={props.onUpdateCandidateResponse}
          onCommentHeightChange={onCommentHeightChange}
          activeResponseId={activeResponseId}
          // @ts-ignore FIXME: strictNullChecks
          docBodyElement={docBodyRef.current} // TODO: passing in refs like this is bad, so fix this to take the ref
          candidateName={props.candidateName}
          readonly={props.readonly}
          onActiveResponseChange={(activeResponse) =>
            setActiveResponseId(activeResponse?.id)
          }
          responseIdToHighlightedElementsMap={
            responseIdToHighlightedElementsMap
          }
          docTextSelection={docTextSelection}
          commentBoxRefs={commentBoxRefs}
        />
      </div>
    </div>
  );
};
