import Box, {BoxProps} from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import React, {PropsWithChildren} from "react";

const useStyles = makeStyles({
  actionsBox: {
    columnGap: "12px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "12px",
  },
});

type Props = PropsWithChildren<{style?: BoxProps["style"]}>;

export default function ActionsWrapper({children, style}: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.actionsBox} style={style}>
      {children}
    </Box>
  );
}
