import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Theme, makeStyles} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import {ApplicantContext} from "../context/ApplicantContext";
import {getNextUuid} from "../helpers/entries";
import validateIcon from "../icons/validate-icon.png";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    alignItems: "center",
    columnGap: "16px",
    display: "grid",
    gridTemplateAreas: `
      "header"
      "paragraph1"
      "paragraph2"
      "actions"
      `,
    [theme.breakpoints.up("sm")]: {
      gridTemplateAreas: `
        "icon header"
        "icon paragraph1"
        ". paragraph2"
        "actions actions"
      `,
    },
    rowGap: "24px",
  },
  getStartedButton: {
    margin: "0 auto",
  },
}));

export default function GetStarted() {
  const isBiggerScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );
  const {roles, setUIState} = useContext(ApplicantContext);
  const classes = useStyles();

  const onGetStarted = () => {
    const nextUuid = getNextUuid(roles);
    if (nextUuid) {
      setUIState({step: "validateRole", uuid: nextUuid});
    } else {
      setUIState({step: "rolesEnd"});
    }
  };

  return (
    <ApplicantPaper className={classes.containerBox}>
      {isBiggerScreen && <img src={validateIcon} style={{gridArea: "icon"}} />}
      <Typography variant="h1" style={{gridArea: "header"}}>
        Validate your Byteboard Portfolio!
      </Typography>
      <Typography variant="body1" style={{gridArea: "paragraph1"}}>
        Welcome to our validation portal! To ensure the accuracy of the
        information parsed from your resume, carefully review the details of
        your professional experience at each step of the validation process.
        Feel free to make corrections and additions where needed.
      </Typography>
      <Typography variant="body1" style={{gridArea: "paragraph2"}}>
        Let's make sure your portfolio accurately showcases your professional
        journey. For each experience entry, select any relevant skills from the
        list tailored to the role you applied for. Ready to get started?
      </Typography>
      <ActionsWrapper style={{gridArea: "actions"}}>
        <Button
          className={classes.getStartedButton}
          color="primary"
          onClick={onGetStarted}
          size="large"
          variant="contained"
        >
          Get started
        </Button>
      </ActionsWrapper>
    </ApplicantPaper>
  );
}
