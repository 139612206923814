import * as React from "react";
import classNames from "classnames";
import logo from "../img/bb-logo-md.png";
import {brand} from "../branding";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";
import "./Spinner.scss";

const logoAnimationDuration = 1000;

interface Props extends StyledComponentProps {
  immediate?: boolean;
  variant?: "default" | "logo";
}

const styles = () =>
  createStyles({
    "@keyframes logo": {
      from: {
        transform: "translateY(-5px)",
      },
      "50%": {
        transform: "translateY(5px)",
      },
      to: {
        transform: "translateY(-5px)",
      },
    },
    "@keyframes shadow": {
      from: {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.5, 1.2)",
      },
      to: {
        transform: "scale(1)",
      },
    },
    root: {
      alignItems: "center",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    container: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column-reverse",
    },
    logo: {
      animation: `logo ${logoAnimationDuration}ms infinite`,
      height: 84,
      width: 62,
    },
    shadow: {
      animation: `shadow ${logoAnimationDuration}ms infinite`,
      background: "#eee",
      borderRadius: "50%",
      height: 16,
      marginTop: 0,
      width: 36,
    },
  });

function Spinner(props: Props) {
  const {classes, variant} = props;
  if (variant === "logo" && brand.spinnerLogo) {
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.root}>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.container}>
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.shadow} />
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <img src={brand.spinnerLogo} className={classes.logo} />
        </div>
      </div>
    );
  }
  return (
    <div
      className={classNames("spinner", {"spinner-immediate": props.immediate})}
    >
      <SpinnerWheel />
    </div>
  );
}

function SpinnerWheel() {
  return (
    <div className="mspin-medium">
      <div>
        <div></div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Spinner);
