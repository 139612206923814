import {useCallback, useContext} from "react";
import {ApplicantContext} from "../context/ApplicantContext";
import {
  generateEducationTemplate,
  generateProjectTemplate,
  generateRoleTemplate,
} from "../helpers/entries";

export default function useAddExperience(): {
  handleAddRole: () => void;
  handleAddEducation: () => void;
  handleAddProject: () => void;
} {
  const {setRoles, setEducation, setProjects, setUIState} =
    useContext(ApplicantContext);

  // We could abstract these into a generic handleAddExperience(experience) function,
  // but TS makes that a little tricky with the setter functions. So it's easier to
  // just have three separate functions.

  const handleAddRole = useCallback(() => {
    const newRole = generateRoleTemplate();
    setRoles((oldState) => [...oldState, newRole]);
    setUIState({step: "validateRole", uuid: newRole.uuid});
  }, [setRoles, setUIState]);

  const handleAddEducation = useCallback(() => {
    const newEducation = generateEducationTemplate();
    setEducation((oldState) => [...oldState, newEducation]);
    setUIState({step: "validateEducation", uuid: newEducation.uuid});
  }, [setEducation, setUIState]);

  const handleAddProject = useCallback(() => {
    const newProject = generateProjectTemplate();
    setProjects((oldState) => [...oldState, newProject]);
    setUIState({step: "validateProject", uuid: newProject.uuid});
  }, [setProjects, setUIState]);

  return {handleAddRole, handleAddEducation, handleAddProject};
}
