import {compact, flatten} from "lodash";

export function getRangeForSelection(
  rootEl: HTMLElement,
  selection: Selection,
): [number, number] {
  const {anchorNode, focusNode, anchorOffset, focusOffset} = selection;
  // @ts-ignore FIXME: strictNullChecks
  return getRange({rootEl, anchorNode, focusNode, anchorOffset, focusOffset});
}

export function getRangeForElement(
  rootEl: HTMLElement,
  element: Element,
): [number, number] {
  return getRange({
    rootEl,
    anchorNode: element,
    focusNode: element,
    anchorOffset: 0,
    // @ts-ignore FIXME: strictNullChecks
    focusOffset: element.textContent.length,
  });
}

function getRange({
  rootEl,
  anchorNode,
  focusNode,
  anchorOffset,
  focusOffset,
}: {
  rootEl: HTMLElement;
  anchorNode: Node;
  focusNode: Node;
  anchorOffset: number;
  focusOffset: number;
}): [number, number] {
  let startIndex = 0;
  let endIndex = 0;
  const allTextNodes = getAllTextNodes(rootEl);
  const anchorNodeIndex = allTextNodes.indexOf(
    getTextNodeFromSelection(anchorNode),
  );
  const focusNodeIndex = allTextNodes.indexOf(
    getTextNodeFromSelection(focusNode),
  );
  for (
    let textNodeIndex = 0;
    textNodeIndex <= Math.max(anchorNodeIndex, focusNodeIndex);
    textNodeIndex++
  ) {
    if (textNodeIndex < anchorNodeIndex) {
      // @ts-ignore FIXME: strictNullChecks
      startIndex += allTextNodes[textNodeIndex].textContent.length;
    } else if (textNodeIndex === anchorNodeIndex) {
      startIndex += anchorOffset;
    }
    if (textNodeIndex < focusNodeIndex) {
      // @ts-ignore FIXME: strictNullChecks
      endIndex += allTextNodes[textNodeIndex].textContent.length;
    } else if (textNodeIndex === focusNodeIndex) {
      endIndex += focusOffset;
    }
  }
  if (anchorNodeIndex === -1 || focusNodeIndex === -1) {
    // The selection went outside the root element.
    // @ts-ignore FIXME: strictNullChecks
    endIndex = rootEl.textContent.length;
  }
  return [startIndex, endIndex].sort((a, b) => a - b) as [number, number];
}

function getAllTextNodes(node: Node): Node[] {
  return compact(
    flatten(
      Array.from(node.childNodes).map((n) => {
        if (n.nodeType === Node.TEXT_NODE) {
          return n;
        } else if (n.childNodes.length) {
          return getAllTextNodes(n);
        } else {
          return null;
        }
      }),
    ),
  );
}

// The anchorNode and focusNode on a Selection object can either be a TextNode
// or an HTMLElement that contains a TextNode. This ensures that we're using
// the TextNode.
function getTextNodeFromSelection(nodeOrElement: Node | HTMLElement) {
  if (nodeOrElement instanceof HTMLElement) {
    return nodeOrElement.childNodes[0];
  }
  return nodeOrElement;
}

/** Removes the whitespace from child nodes that only contain whitespace. */
export function trimChildNodes(element: HTMLElement): HTMLElement {
  for (const childNode of element.childNodes) {
    // @ts-ignore FIXME: strictNullChecks
    if (childNode.textContent.match(/^\s*$/)) {
      childNode.textContent = "";
    }
  }
  return element;
}
