import * as React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import autobind from "autobind-decorator";
import classNames from "classnames";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {brand} from "../branding";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

const leftMarginWidth = 40;
const paddingSmall = 15;

interface Props extends StyledComponentProps {
  completed?: boolean;
  number?: number;
  heading: string;
  details: () => React.ReactNode;
  expanded: boolean;
  expandable: boolean;
  onChange: (event: object, expanded: boolean) => any;
  small?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    "@keyframes checkBounce": {
      from: {
        opacity: 0,
        transform: "scale(0.5)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      to: {
        opacity: 1,
        transform: "scale(1)",
      },
    },
    "@keyframes fadeIn": {
      from: {
        opacity: 0.5,
        transform: "translateY(3px)",
      },
      to: {
        opacity: 1,
      },
    },
    small: {},
    notExpandable: {
      cursor: "default !important",
    },
    numberContainer: {
      display: "flex",
      height: 20,
      width: leftMarginWidth,
    },
    number: {
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      color: "white",
      display: "flex",
      fontSize: 12,
      height: 20,
      justifyContent: "center",
      width: 20,
    },
    checkOutlineIcon: {
      animation: "$checkBounce 0.3s forwards",
      height: 23,
      width: 23,
    },
    accordionSummary: {
      "$small &": {
        minHeight: 20,
        paddingLeft: paddingSmall,
        paddingRight: paddingSmall,
      },
    },
    accordionSummaryContent: {
      "$small &": {
        margin: "12px 0",
      },
    },
    accordionDetails: {
      "$small &": {
        padding: `0 ${paddingSmall}px`,
      },
    },
    heading: {
      color: "black",
      fontSize: 15,
      fontWeight: 500,
    },
    completedHeading: {
      animation: "$fadeIn 0.3s forwards",
    },
    details: {
      marginLeft: leftMarginWidth,
      overflow: "hidden",
      overflowWrap: "break-word",
      "$small &": {
        marginLeft: 0,
      },
    },
  });

class Accordion extends React.Component<Props> {
  static defaultProps: any = {
    small: false,
  };

  @autobind
  onSummaryClick(event: object, expanded: boolean) {
    const {onChange, expandable} = this.props;
    if (!expandable) return;
    onChange(event, expanded);
  }

  renderNumber(): React.ReactNode {
    const {classes, completed} = this.props;
    if (!Number.isInteger(this.props.number)) return null;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.numberContainer}>
        {completed ? (
          <svg
            // @ts-ignore FIXME: strictNullChecks
            className={classes.checkOutlineIcon}
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="23"
              cy="23"
              r="20.5"
              stroke={brand.primaryColor.main}
              strokeWidth="5"
            />
            <path
              d="M13.5 25.587L20.5217 30.9565L32.5 16.5"
              stroke={brand.primaryColor.main}
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          // @ts-ignore FIXME: strictNullChecks
          <div className={classes.number}>{this.props.number}</div>
        )}
      </div>
    );
  }

  render(): React.ReactNode {
    const {classes, completed, heading, details, expanded, expandable, small} =
      this.props;
    const expandIcon = expandable ? <ExpandMoreIcon /> : null;
    // @ts-ignore FIXME: strictNullChecks
    const headingClassName = classNames(classes.heading, {
      // @ts-ignore FIXME: strictNullChecks
      [classes.completedHeading]: completed,
    });
    return (
      <MuiAccordion
        expanded={expanded}
        onChange={this.onSummaryClick}
        // @ts-ignore FIXME: strictNullChecks
        className={small ? classes.small : ""}
      >
        <AccordionSummary
          expandIcon={expandIcon}
          // @ts-ignore FIXME: strictNullChecks
          classes={{content: classes.accordionSummaryContent}}
          // @ts-ignore FIXME: strictNullChecks
          className={classNames(classes.accordionSummary, {
            // @ts-ignore FIXME: strictNullChecks
            [classes.notExpandable]: !expandable,
          })}
        >
          {this.renderNumber()}
          <Typography className={headingClassName}>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails
          // @ts-ignore FIXME: strictNullChecks
          className={classes.accordionDetails}
        >
          <div
            // @ts-ignore FIXME: strictNullChecks
            className={classes.details}
          >
            {details()}
          </div>
        </AccordionDetails>
      </MuiAccordion>
    );
  }
}

export default withStyles(styles)(Accordion);
