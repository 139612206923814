import React from "react";
import ReactDOM from "react-dom/client";
import {initializeApp} from "firebase/app";

import Routes from "./Routes";
import Theme from "./components/Theme";
import app from "./reducers/app";
import config from "./config.js";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {brand} from "./branding";
import {createLogger} from "redux-logger";
import {createStore, applyMiddleware} from "redux";

// Polyfills
import "whatwg-fetch";

const middleware: any[] = [thunk];
if (config.devHosts.includes(location.host)) {
  middleware.push(createLogger({collapsed: true}));
}
const store = createStore(app, applyMiddleware(...middleware));
// @ts-ignore
window.store = store;

// @ts-ignore
if (!window.firebase) {
  // @ts-ignore
  window.firebase = initializeApp(config.firebase);
}
// @ts-ignore
window.version = process.env.WEBPACK_NPM_PACKAGE_VERSION;

document.title = brand.title;
(document.querySelector("[data-favicon]") as HTMLLinkElement).href =
  brand.favicon;

class App extends React.Component {
  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <Theme>
          <Routes />
        </Theme>
      </Provider>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("app")!);
root.render(<App />);
