import * as React from "react";
import {AnyAction} from "redux";
import {OptimisticState} from "redux-optimistic-ui";
import {
  ThunkAction as ReduxThunkAction,
  ThunkDispatch as ReduxThunkDispatch,
} from "redux-thunk";

export interface AppError {
  errorMessage: string;
  tips: React.ReactNode[];
  hideTips: boolean;
  isNotification: boolean;
  userNotSignedIn: boolean;
}

export enum Flag {
  cloudshellEnabled = "cloudshellEnabled",
  enableBBDoc = "enableBBDoc",
}
export const possibleFlags = Object.values(Flag);

export interface Flags {
  [Flag.cloudshellEnabled]: boolean;
  [Flag.enableBBDoc]: boolean;
}

export type M2Type = "cloudshell" | "zip";

export type Language =
  | "cpp"
  | "csharp"
  | "golang"
  | "java"
  | "js"
  | "js_web"
  | "kotlin"
  | "php"
  | "python2"
  | "python3"
  | "ruby"
  | "rust"
  | "swift"
  | "swiftui"
  | "ts"
  | "ts_web"
  | "flutter"
  | "reactjs"
  | "reactts"
  | "r"
  | "sql"
  | "systems_design";

export type Role =
  | "genSwe"
  | "mobile"
  | "web"
  | "dataEng"
  | "sre"
  | "securityEng"
  | "dataAnalysis"
  | "staffEng"
  | "appliedAI";

export enum LiveCodingInterviewStatus {
  created = "created",
  invited = "invited",
  active = "active",
  performed = "performed",
  reset = "reset",
}

export enum CloudRunServiceStatus {
  notStarted = "not started",
  starting = "starting",
  created = "created",
  started = "started",
  deleted = "deleted",
  failed = "failed",
}

export type ExternalLiveCodingInterview = {
  id: string;
  atsId: string;
  configurationId: string;
  interviewerIds: string[];
  interviewName?: string;

  creationTime?: Date;
  inviteTime?: Date;
  inviteEmailSentTime?: Date;
  interviewStartTime?: Date;
  interviewEndTime?: Date;

  interviewStatus: LiveCodingInterviewStatus;
  cloudRunServiceId?: string;
  cloudRunServiceStatus?: CloudRunServiceStatus;
  codeTogetherPassword?: string;

  survey?: boolean;
  language?: Language;
};

export type ExternalLiveCodingConfiguration = {
  id: string;
  name: string;
  configurationType: "core" | "custom";
  languages: Partial<Record<Language, "blank" | "custom">>;
  candidateInstructions?: string;
};

export type CandidateOnsiteData = {
  /* List of names corresponding to the platformUsers */
  interviewerNames?: string[];
  onsiteInterviewStatus: LiveCodingInterviewStatus;
  onsiteSurvey: boolean;
  interviewStartTime: string;
  interviewId: string;
  codeTogetherPassword: string;
  assessmentType?: "blank" | "bb-assessment";
  language?: Language;
};

export interface Candidate {
  id: string;
  candidateName: string;
  companyId?: string;
  language?: Language;
  assessmentId?: string;
  m1OpenTime?: number;
  m1EndTime?: number;
  m1DocId?: string;
  m1DocUrl?: string;
  m2Type?: M2Type;
  m2OpenTime?: number;
  m2BucketId?: string;
  m2EndTime?: number;
  m2DocId?: string;
  m2DocUrl?: string;
  isSubmittingM2?: boolean;
  terms?: boolean;
  survey_stars?: number;
  role?: Role;
  repeatCandidateNoAssessments?: boolean;
  expired?: boolean;
  incomplete?: boolean;
  signupTime?: string;
  survey?: boolean;
  error?: string;
  enableBBDoc?: boolean;
  isBBDocReady?: boolean;
  bbhcStatus?: CandidateBBHCStatus;
  signupDeadline?: Date;
}

export enum CandidateBBHCStatus {
  eligible = "eligible",
  ineligible = "ineligible",
  optin = "optin",
  optout = "optout",
  notClassified = "notClassified",
}

export interface Interview extends Candidate {
  companyName?: string;
  breakDuration?: number;
  languageOptions?: Language[];
  m1Duration?: number;
  m2Duration?: number;
  modules?: InterviewModule[];
}

export interface AccommodationScreenState {
  isSubmitting: boolean;
  didSubmit: boolean;
  screenReader: boolean;
}

export interface GetStartedScreenState {
  language: Language | "none";
  m2Type: M2Type | "";
  isSubmitting: boolean;
  completedSteps: number[];
  expandedSteps: number[];
  showDocsWalkthroughDialog: boolean;
}

export interface TesterScreenState {
  isSubmitting: boolean;
  isDownloadingZip: boolean;
  showInstructions: boolean;
}

export interface InterviewModuleScreenState {
  isStarting: boolean;
  isSubmitting: boolean;
  isUnresolvingComments: boolean;
  isBBDocDisabledOrDisabling: boolean;
  hasExpandedInstructions: boolean;
  showConfirmDialog: boolean;
  showSubmittedDialog: boolean;
  docUpdateTime: number;
  isDownloadingZip: boolean;
  /**
   * Used to control if the candidate downloads the base code zip or the
   * modified code in their bucket.
   */
  zipDownloadType: ZipDownloadType;
}

export interface AssessmentConfig {
  isLoading: boolean;
  isLoaded: boolean;
  config: RoleAssessmentConfig;
}

export interface RoleAssessmentConfig {
  taskList: string;
}

export interface M1InterviewScreenState {
  isStartingM1: boolean;
  isSubmittingM1: boolean;
  isUnresolvingComments: boolean;
  hasExpandedInstructions: boolean;
  showConfirmDialog: boolean;
  showSubmittedDialog: boolean;
  docUpdateTime: number;
}

export type ZipDownloadType = "base" | "modified";

export interface M2InterviewScreenState {
  isStartingM2: boolean;
  isSubmittingM2: boolean;
  isDownloadingZip: boolean;
  isScreenOpen: boolean;
  isSubmittingM2AdditionalTimeDescription: boolean;
  hasExpandedInstructions: boolean;
  showConfirmDialog: boolean;
  showSubmittedDialog: boolean;
  instructionsIndex: number;
  projectDirectory: string;
  projectName: string;
  runShFileName: string;
  isUpdatingM2Type: boolean;
  /**
   * Whether or not the candidate has made changes to the base code in their
   * bucket.
   */
  hasModifiedM2Code: boolean;
  /**
   * Whether or not `hasModifiedM2Code` has been loaded.
   */
  hasModifiedM2CodeLoaded: boolean;
  /**
   * Used to control if the candidate downloads the base code zip or the
   * modified code in their bucket.
   */
  zipDownloadType: ZipDownloadType;
  switchedToCloudshellFromZip: boolean;
}

export interface HelpDialogState {
  showDialog: boolean;
  isSubmitting: boolean;
  didSubmit: boolean;
  activeTopicId: string;
  contactFormText: string;
}

export interface InterviewSurveyScreenState {
  numStars: number;
  isSubmitting: boolean;
}

export interface LoadingState {
  isLoadingFiles: boolean;
}

export interface User {
  id: string;
  avatar?: string;
  email?: string;
  accessToken?: string;
  idToken?: string;
}

export interface FileDiff {
  path: string;
  numLinesAdded: number;
  added?: boolean;
  removed?: boolean;
}

export interface ZipUpload {
  isUploaded: boolean;
  file?: File;
  progress: number;
  showDialog: boolean;
  uploadedFiles: FileDiff[];
}

export type InterviewScreenName =
  | "GetStarted"
  | "InterviewEnd"
  | "InterviewSurvey"
  | "M1Interview"
  | "M2Interview"
  | "Overview"
  | "";

export interface InterviewScreen {
  name: InterviewScreenName;
}

export interface Toast {
  id: string;
  message: string;
  startTime: number;
}

export interface ServerInfo {
  serverTimeOffset: number;
}

export interface AppState {
  accommodationScreenState: AccommodationScreenState;
  assessmentConfig: AssessmentConfig;
  bbdocState: OptimisticState<BBDocState>;
  error: AppError;
  flags: Flags;
  getStartedScreenState: GetStartedScreenState;
  helpDialogState: HelpDialogState;
  interview: Interview;
  interviewModuleScreenState: InterviewModuleScreenState;
  interviewScreen: InterviewScreen;
  interviewSurveyScreenState: InterviewSurveyScreenState;
  loading: LoadingState;
  m1InterviewScreenState: M1InterviewScreenState;
  m2InterviewScreenState: M2InterviewScreenState;
  testerScreenState: TesterScreenState;
  serverInfo: ServerInfo;
  toast: Toast;
  user: User;
  zipUpload: ZipUpload;
  bbhcStatus: CandidateBBHCStatus;
}

export type ThunkDispatch = ReduxThunkDispatch<{}, {}, any>;

export type ThunkAction = ReduxThunkAction<Promise<void>, {}, {}, AnyAction>;

export type ModuleType = "document" | "code" | "break";
export type ModuleId = "m1" | "m2" | "break";
export interface InterviewModule {
  id: ModuleId;
  name: string;
  type: ModuleType;
  description?: string;
}

export type CandidateResponseJSON = {
  id: string;
  moduleId: ModuleId;
  responseText: string;
  firstEditTime: Date;
  lastEditTime: Date;
  /** The first/last index of the range that is highlighted in the doc body. */
  selectionRange: [number, number];
  type: "comment" | "question";
  questionId?: string;
};
export interface CandidateResponse
  extends Omit<CandidateResponseJSON, "moduleId"> {
  failedToSave?: boolean;
  inFlightRequest?: boolean;
}

export interface InterviewDocJSON {
  /** The content of the doc, written in markdown. */
  docBody: string;
  /** The questions that are in this module. */
  questions: {
    [questionId: string]: InterviewDocQuestion;
  };
  /** The candidate responses (answers to questions and comments). */
  candidateResponses: {
    [candidateResponseId: string]: CandidateResponse;
  };
  assets: {
    [assetName: string]: string;
  };
  /** Once AV Club launches, make this mandatory */
  sections?: string[];
}

export interface InterviewDocQuestion {
  id: string;
  /** The question text that appears in the question box. */
  text: string;
  /** The name of the fake person asking the question. */
  questioner: string;
  /** The first/last index of the range that is highlighted in the doc body. */
  selectionRange: [number, number];
  /** Whether the question takes user input. */
  inputRequired: boolean;
  /** Where the input field goes. */
  inputType?: "body" | "comment";
  /** Optional placeholder text that is used if the input is empty. */
  placeholder?: string;
}

export interface InterviewDoc {
  moduleId: ModuleId;
  /** The content of the doc, in html. */
  docHTML: string;
  /** The questions that are in this module. */
  questions: {
    [questionId: string]: InterviewDocQuestion;
  };
  /** The candidate responses (answers to questions and comments). */
  candidateResponses: CandidateResponse[];
}

export interface BBDocState {
  isLoading: boolean;
  isLoaded: boolean;
  interviewDoc: InterviewDoc | null;
}

export interface LanguageMap {
  value: Language;
  label: string;
}
