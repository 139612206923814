import {AnyAction} from "redux";
import {SET_TOAST} from "../actions/toastActions";

export function toast(state = {}, action: AnyAction) {
  switch (action.type) {
    case SET_TOAST:
      return action.toast;
    default:
      return state;
  }
}
