import * as React from "react";
import {MuiThemeProvider, lighten} from "@material-ui/core/styles";
import {brand} from "../branding";
import red from "@material-ui/core/colors/red";
import {createTheme} from "@material-ui/core/styles";

const blue = {
  main: "#2b63bf",
};
export const headerHeight = 54;
const defaultFontSize = 14;

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    bbdoc: {
      palette: {
        bb: {
          main: string;
          light: string;
          lighter: string;
        };
        candidate: {
          main: string;
          light: string;
        };
      };
      gutterWidth: number;
    };
    link: {
      color: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: brand.primaryColor,
    secondary: brand.primaryColor,
    error: red,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(236,236,236,0.8)",
        top: headerHeight,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      label: {
        fontFamily: "Google Sans, Arial",
        textTransform: "none",
      },
      root: {
        borderRadius: 20,
        fontSize: defaultFontSize,
        minWidth: 150,
        paddingLeft: 36,
        paddingRight: 36,
      },
      text: {
        minWidth: 80,
        padding: "6px 16px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 500,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "space-between",
        margin: "0 20px 10px 20px",
      },
    },
    MuiDialogContent: {
      root: {
        paddingBottom: 15,
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: 14,
      },
    },
    MuiAccordion: {
      root: {
        "&:before": {
          height: 0,
        },
        "&$expanded": {
          margin: "0 0 20px 0",
        },
        border: "solid 1px #c2c2c2",
        "&:last-child": {
          borderBottomLeftRadius: "invalid",
          borderBottomRightRadius: "invalid",
        },
        boxShadow: "none",
        margin: "0 0 20px 0",
      },
      rounded: {
        borderRadius: 22,
        "&:last-child": {
          borderBottomLeftRadius: 22,
          borderBottomRightRadius: 22,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        paddingRight: 22,
        paddingTop: 4,
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 64,
        "&$focusVisible": {
          backgroundColor: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: defaultFontSize,
      },
    },
    MuiLink: {
      root: {
        color: blue.main,
      },
      underlineHover: {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
      notchedOutline: {
        borderRadius: 8,
        "div:hover > &&": {
          borderColor: brand.primaryColor.main,
        },
      },
      multiline: {
        fontSize: defaultFontSize,
        lineHeight: "1.5em",
        padding: 12,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10,
      },
      elevation24: {
        boxShadow: "none",
        border: "solid 1px #ccc",
      },
    },
    MuiSelect: {
      selectMenu: {
        fontSize: defaultFontSize,
        paddingBottom: 5,
        paddingTop: 5,
      },
      select: {
        fontSize: defaultFontSize,
        "&:focus": {
          borderRadius: 20,
        },
      },
    },
    // @ts-ignore
    MuiToggleButton: {
      label: {
        textTransform: "none",
      },
      root: {
        border: "none",
        borderRadius: 20,
        color: "#505050",
        minWidth: 90,
        padding: "4px 12px",
      },
    },
    // @ts-ignore
    MuiToggleButtonGroup: {
      root: {
        border: "solid 1px rgba(0, 0, 0, 0.23)",
        borderRadius: 20,
      },
    },
    MuiTypography: {
      body1: {
        color: "#000",
        fontFamily: "Roboto, Arial",
        fontSize: defaultFontSize,
      },
      body2: {
        color: "#000",
        fontFamily: "Roboto, Arial",
        fontSize: defaultFontSize,
      },
      h1: {
        color: "#000",
        fontFamily: "Google Sans, Arial",
        fontSize: 42,
        fontWeight: "bold",
        marginBottom: 20,
      },
      h2: {
        color: "#000",
        fontFamily: "Google Sans, Arial",
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: 0,
        marginBottom: 20,
      },
      h3: {
        color: "#000",
        fontFamily: "Google Sans, Arial",
        fontSize: 18,
        fontWeight: "bold",
        letterSpacing: 0,
        marginBottom: 20,
      },
      h4: {
        color: "#505050",
        fontFamily: "Roboto, Arial",
        fontSize: defaultFontSize,
        fontWeight: "bold",
        marginBottom: 5,
      },
      h6: {
        fontFamily: "Google Sans, Arial",
        letterSpacing: 0,
      },
    },
  },
  bbdoc: {
    gutterWidth: 10,
    palette: {
      bb: {
        light: lighten(brand.primaryColor.main, 0.8),
        lighter: lighten(brand.primaryColor.main, 0.94),
        main: brand.primaryColor.main,
      },
      candidate: {
        light: lighten("#009fc1", 0.9),
        main: "#009fc1",
      },
    },
  },
  link: {
    color: blue.main,
  },
});

export default function Theme(props: any) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}
