import * as React from "react";
import InterviewOverviewTips from "./InterviewOverviewTips";
import Spinner from "./Spinner";
import classNames from "classnames";
import {Interview} from "../reducers/types";
import {ModuleId} from "../reducers/types";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {brand} from "../branding";
import {createStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {interviewModuleUrl} from "../helpers/urls/routerUrls";
import {
  isActiveModule,
  isModuleComplete,
  getModuleDurationMs,
  getActiveModuleId,
  getEndTimeMs,
} from "../helpers/interview";
import {msToMin} from "../util/time";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface Props extends StyledComponentProps, RouteComponentProps {
  title?: string;
  interview: Interview;
  getServerTime: () => number;
  hideButton?: boolean;
}

const leftMargin = 30;
const dotSize = 14;
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    rows: {
      marginTop: 30,
    },
    row: {
      paddingBottom: 25,
      position: "relative",
      "&:not(:last-child)::before": {
        background: brand.primaryColor.main,
        bottom: 0,
        content: '""',
        left: dotSize / 2,
        position: "absolute",
        top: 18,
        width: 1,
      },
      "&:not(:first-child)::after": {
        background: brand.primaryColor.main,
        content: '""',
        height: 5,
        left: dotSize / 2,
        position: "absolute",
        top: 0,
        width: 1,
      },
    },
    title: {
      alignItems: "center",
      display: "flex",
    },
    dot: {
      background: "white",
      border: `solid 1px ${brand.primaryColor.main}`,
      borderRadius: "50%",
      marginRight: 17,
      position: "relative",
    },
    dotCenter: {
      background: "white",
      borderRadius: "50%",
      height: dotSize - 6,
      margin: 2,
      width: dotSize - 6,
      "$active &": {
        background: brand.primaryColor.main,
      },
    },
    rowTitleText: {
      color: "black",
      fontWeight: "bold",
      "$active &": {
        color: brand.primaryColor.main,
      },
    },
    tipBox: {
      marginLeft: leftMargin,
      paddingLeft: 20,
    },
    continueButton: {
      marginLeft: leftMargin,
      marginTop: 20,
    },
    active: {},
    complete: {},
  });

class InterviewOverview extends React.Component<Props> {
  refreshTimeoutId: any;

  componentDidUpdate() {
    // Refresh this component when the break is finished so that the active dot
    // moves to the next module.
    this.refreshWhenBreakIsFinished();
  }

  serverTime(): number {
    return this.props.getServerTime();
  }

  refreshWhenBreakIsFinished() {
    const {interview} = this.props;
    if (getActiveModuleId(interview, this.serverTime()) !== "break") return;
    const breakTimeRemaining =
      // @ts-ignore FIXME: strictNullChecks
      getEndTimeMs(interview, "break") - this.serverTime();
    if (!breakTimeRemaining || breakTimeRemaining < 0) return;
    clearTimeout(this.refreshTimeoutId);
    this.refreshTimeoutId = setTimeout(() => {
      this.forceUpdate();
    }, breakTimeRemaining);
  }

  goToModule(moduleId: ModuleId) {
    const {interview} = this.props;
    this.props.history.push(interviewModuleUrl(interview, moduleId));
  }

  /**
   * Returns whether or not the provided module id is the next module that the
   * candidate will take. The break module is never considered the "next"
   * module.
   */
  isNextInterviewModule(moduleId: ModuleId): boolean {
    if (moduleId === "break") return false;
    const {interview} = this.props;
    const isBreakActive = isActiveModule(interview, "break", this.serverTime());
    if (isBreakActive) {
      // The module that comes after the break is the next module.
      const activeModuleAfterBreak = "m2";
      return moduleId === activeModuleAfterBreak;
    }
    return isActiveModule(interview, moduleId, this.serverTime());
  }

  renderRows(): React.ReactNode[] {
    const {interview, classes, hideButton} = this.props;
    if (!interview.modules) return [];
    return interview.modules.map((module) => {
      const moduleDurationMin = msToMin(
        getModuleDurationMs(interview, module.id),
      );
      return (
        <div
          key={module.id}
          // @ts-ignore FIXME: strictNullChecks
          className={classNames(classes.row, {
            // @ts-ignore FIXME: strictNullChecks
            [classes.active]: isActiveModule(
              interview,
              module.id,
              this.serverTime(),
            ),
            // @ts-ignore FIXME: strictNullChecks
            [classes.complete]: isModuleComplete(
              interview,
              module.id,
              this.serverTime(),
            ),
          })}
        >
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.title}>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <div className={classes.dot}>
              {/* @ts-ignore FIXME: strictNullChecks*/}
              <div className={classes.dotCenter} />
            </div>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <Typography className={classes.rowTitleText}>
              {module.name} ({moduleDurationMin} min)
              {module.description ? `: ${module.description}` : ""}
            </Typography>
          </div>
          {this.isNextInterviewModule(module.id) ? (
            <InterviewOverviewTips
              // @ts-ignore FIXME: strictNullChecks
              className={classes.tipBox}
              interview={interview}
              moduleId={module.id}
            />
          ) : null}
          {this.isNextInterviewModule(module.id) && !hideButton ? (
            <Button
              variant="contained"
              // @ts-ignore FIXME: strictNullChecks
              className={classes.continueButton}
              onClick={() => this.goToModule(module.id)}
              color="primary"
            >
              Take me to {module.name}
            </Button>
          ) : null}
        </div>
      );
    });
  }

  render(): React.ReactNode {
    const {classes, title, interview} = this.props;
    if (!interview) {
      return <Spinner />;
    }
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.root}>
        {title ? <Typography variant="h3">{title}</Typography> : null}
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.rows}>{this.renderRows()}</div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(InterviewOverview));
