import {ToastAction} from "./actionTypes";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {Toast, AppState} from "../reducers/types";
import {uniqueId} from "lodash";

export const SET_TOAST = "SET_TOAST";

const DEFAULT_TOAST_DURATION = 5000;

function setToast(toastObj: Toast | {}): ToastAction {
  return {
    type: SET_TOAST,
    toast: toastObj,
  };
}

export function toast(
  message: string,
  duration: number = DEFAULT_TOAST_DURATION,
): ThunkAction<Promise<void>, AppState, Record<string, never>, ToastAction> {
  return async (
    dispatch: ThunkDispatch<AppState, Record<string, never>, ToastAction>,
    getState: () => AppState,
  ): Promise<void> => {
    const id = uniqueId("toast");
    dispatch(
      setToast({
        id,
        message,
        startTime: Date.now(),
      }),
    );
    await new Promise((resolve) => setTimeout(resolve, duration));
    if (getState().toast.id === id) {
      dispatch(clearToast());
    }
  };
}

export function clearToast(): ToastAction {
  return setToast({});
}
