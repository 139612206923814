import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import React, {useContext} from "react";
import {ApplicantContext} from "../context/ApplicantContext";
import {ExpereienceCategory} from "../helpers/types";

const useStyles = makeStyles({
  button: {
    gridArea: "editButton",
    height: "fit-content",
    minWidth: "unset",
    paddingLeft: 12,
    paddingRight: 12,
  },
});

type Props = {
  category: ExpereienceCategory;
  uuid: string;
};

export default function ExperienceEditButton({category, uuid}: Props) {
  const {setUIState} = useContext(ApplicantContext);
  const classes = useStyles();

  const onClick =
    category === "role"
      ? () => setUIState({step: "validateRole", uuid})
      : category === "education"
      ? () => setUIState({step: "validateEducation", uuid})
      : () => setUIState({step: "validateProject", uuid});

  return (
    <Button
      className={classes.button}
      color="primary"
      onClick={onClick}
      size="small"
      startIcon={<Edit fontSize="small" />}
      variant="outlined"
    >
      Edit
    </Button>
  );
}
