import Button from "@material-ui/core/Button";
import Dialog, {DialogProps} from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {useSharedStyles} from "../hooks/useSharedStyles";

interface Props extends Pick<DialogProps, "open"> {
  onCancel: () => void;
  onRemove: () => void;
}

export default function RemoveExperienceModal({
  onCancel,
  onRemove,
  open,
}: Props) {
  const sharedClasses = useSharedStyles();
  return (
    <Dialog open={open}>
      <DialogTitle>Remove experience</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove this experience? This action cannot be
          undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onRemove}
          className={sharedClasses.containedButtonWarningColor}
        >
          Remove experience
        </Button>
      </DialogActions>
    </Dialog>
  );
}
