import {AccommodationScreenState} from "./types";
import {AccommodationScreenAction} from "../actions/actionTypes";

const defaultState: AccommodationScreenState = {
  isSubmitting: false,
  didSubmit: false,
  screenReader: false,
};

export function accommodationScreenState(
  state: AccommodationScreenState = defaultState,
  action: AccommodationScreenAction,
): AccommodationScreenState {
  switch (action.type) {
    case "ACCOMMODATION_SCREEN.IS_SUBMITTING":
      return {...state, ...{isSubmitting: true}};
    case "ACCOMMODATION_SCREEN.DID_SUBMIT":
      return {
        ...state,
        ...{
          isSubmitting: false,
          didSubmit: true,
        },
      };
    case "ACCOMMODATION_SCREEN.TOGGLE_SCREEN_READER":
      return {...state, screenReader: !state.screenReader};
    default:
      return state;
  }
}
