import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {compact} from "lodash";
import React from "react";
import {instructionsTextStyle} from "../../../components/Instructions";
import {ExternalLink} from "../../../components/Link";
import UnorderedList from "../../../components/UnorderedList";
import {webInteviewPort} from "../../../config";
import {isWebInterview} from "../../../helpers/interview";
import commandLineIcon from "../../../img/command-line-icon.png";
import editorIcon from "../../../img/editor-icon.png";
import webPreviewIcon from "../../../img/web-preview-icon.png"; // TODO make the correct img
import {Interview} from "../../../reducers/types";

const useStyles = makeStyles({
  instructionsText: instructionsTextStyle,
  shellIcon: {
    margin: "0 2px",
    transform: "translateY(2px)",
    width: 18,
  },
});

export default function CloudShellTips({interview}: {interview: Interview}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3">Tips</Typography>
      <UnorderedList
        items={compact([
          <Typography className={classes.instructionsText}>
            If you run into any issues with the editor and would like to
            download a zip file to use your local IDE, click the{" "}
            <strong>?</strong> at the top right of your screen for instructions.
          </Typography>,

          <Typography className={classes.instructionsText}>
            If you would like additional room to work, you can resize the
            terminal panel at any time and view files side by side in the editor
            by dragging them to your desired configuration.
          </Typography>,

          <Typography className={classes.instructionsText}>
            If you are unfamiliar with the command line{" "}
            <ExternalLink
              target="_blank"
              href="https://maker.pro/linux/tutorial/basic-linux-commands-for-beginners"
            >
              here are some key Linux commands
            </ExternalLink>
            .
          </Typography>,

          <Typography className={classes.instructionsText}>
            If you close the editor, you can reopen it by clicking the{" "}
            <img src={editorIcon} className={classes.shellIcon} /> icon at the
            top-right of the editor.
          </Typography>,

          <Typography className={classes.instructionsText}>
            If you close the command line, you can reopen it by clicking the{" "}
            <img src={commandLineIcon} className={classes.shellIcon} /> icon at
            the top-right of the editor.
          </Typography>,

          isWebInterview(interview) && (
            <Typography className={classes.instructionsText}>
              You can open your web app by clicking the{" "}
              <img src={webPreviewIcon} className={classes.shellIcon} /> icon at
              the top-right of the editor and selecting "Preview on port{" "}
              {webInteviewPort}".
            </Typography>
          ),
        ])}
      />
    </>
  );
}
