import * as React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import MuiSelect, {SelectProps} from "@material-ui/core/Select";
import {findDOMNode} from "react-dom";

interface Props extends SelectProps {
  autoFocus?: boolean;
  className?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
  value: any;
  options: {label: string; value: any}[];
  ariaLabel?: string;
}

export default class Select extends React.Component<Props> {
  componentDidMount() {
    // Can't get the aria-label to work with the Select, so add it manually.
    this.addAriaLabel();
  }

  addAriaLabel() {
    const {ariaLabel} = this.props;
    if (!ariaLabel) return;
    const selectEl = findDOMNode(this) as Element;
    const buttonEl = selectEl.querySelector('[role="button"]');
    // @ts-ignore FIXME: strictNullChecks
    buttonEl.setAttribute("aria-label", ariaLabel);
  }

  renderOptions(): React.ReactNode[] {
    return this.props.options.map(({label, value}) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  }

  render(): React.ReactNode {
    const {autoFocus, className, options} = this.props;
    let value = this.props.value;
    if (!options.some((option) => option.value === value)) {
      // Material-UI doesn't like values that don't have an option.
      value = "";
    }
    return (
      <FormControl variant="outlined" className={className}>
        <MuiSelect
          autoFocus={autoFocus}
          displayEmpty={true}
          value={value}
          onChange={this.props.onChange}
          inputRef={this.props.inputRef}
          renderValue={(val: React.ReactNode) => {
            const option = val ? options.find((op) => op.value === val) : null;
            return option ? (
              option.label
            ) : (
              <span style={{color: "rgba(0,0,0,0.5)"}}>Select</span>
            );
          }}
          input={<OutlinedInput labelWidth={0} name={this.props.name} />}
        >
          {this.renderOptions()}
        </MuiSelect>
      </FormControl>
    );
  }
}
