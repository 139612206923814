import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React from "react";
import {Project} from "../helpers/types";
import {useSharedStyles} from "../hooks/useSharedStyles";
import ExperienceEditButton from "./ExperienceEditButton";
import ExperienceSkills from "./ExperienceSkills";

type Props = {
  project: Required<Project>;
};

export default function ExperienceProject({project}: Props) {
  const sharedClasses = useSharedStyles();
  return (
    <Box display="grid" gridRowGap={8}>
      <Box className={sharedClasses.experinceHeaderContainer}>
        <Typography
          className={sharedClasses.experienceTitle}
          style={{gridArea: "title"}}
          variant="h3"
        >
          {project.title}
        </Typography>
        <Typography component="p" style={{gridArea: "line1"}} variant="caption">
          {`${moment(project.startDate).format("MMM YYYY")} - ${
            project.endDate === ""
              ? "Present"
              : moment(project.endDate).format("MMM YYYY")
          }`}
        </Typography>
        <ExperienceEditButton category="project" uuid={project.uuid} />
      </Box>
      <Typography variant="body2">
        {project.accomplishmentsAndResponsibilities}
      </Typography>
      <ExperienceSkills experience={project} />
    </Box>
  );
}
