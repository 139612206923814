import {getAPIBaseUrl} from "../api";

const bbhcBaseUrl = getAPIBaseUrl() + "/bbhc/interview";

export function bbhcOptinUrl(atsId: string): string {
  return `${bbhcBaseUrl}/${atsId}/optin`;
}

export function bbhcOptoutUrl(atsId: string): string {
  return `${bbhcBaseUrl}/${atsId}/optout`;
}
