import * as React from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import CheckIcon from "./CheckIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import autobind from "autobind-decorator";
import {AppState} from "../reducers/types";
import {M2InterviewScreenAction} from "../actions/actionTypes";
import {ThunkDispatch} from "redux-thunk";
import {brand} from "../branding";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import {saveM2AdditionalTimeDescription} from "../actions/m2InterviewScreenActions";
import {surveyUrl} from "../helpers/urls/routerUrls";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

// Give candidates 15 minutes to fill out the m2-additional-time form.
const SUBMITTED_DIALOG_REDIRECT_TIME_MIN = 15;
export const SUBMITTED_DIALOG_REDIRECT_TIME_MS =
  SUBMITTED_DIALOG_REDIRECT_TIME_MIN * 1000 * 60;

interface PassedProps {
  show: boolean;
}
interface StateProps {
  atsId: string;
  isSubmitting: boolean;
}
interface DispatchProps {
  saveM2AdditionalTimeDescription: (text: string) => Promise<void>;
}
type Props = StateProps &
  PassedProps &
  DispatchProps &
  StyledComponentProps &
  RouteComponentProps;
type Dispatch = ThunkDispatch<
  AppState,
  Record<string, never>,
  M2InterviewScreenAction
>;

const styles = () =>
  createStyles({
    centerText: {
      textAlign: "center",
    },
  });

class M2SubmittedDialog extends React.Component<Props> {
  surveyRef: React.RefObject<HTMLInputElement> = React.createRef();
  redirectTimeout: any;

  componentDidMount() {
    this.maybeStartRedirectTimer();
  }

  componentDidUpdate() {
    this.maybeStartRedirectTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.redirectTimeout);
  }

  maybeStartRedirectTimer() {
    // Wait until the dialog is shown before starting the redirect timer.
    if (this.redirectTimeout || !this.props.show) return;
    this.redirectTimeout = setTimeout(
      this.onSubmit,
      SUBMITTED_DIALOG_REDIRECT_TIME_MS,
    );
  }

  getDialogText(): string {
    return (
      `Congratulations on finishing the ${brand.company} interview! As ` +
      "a reminder, we don’t expect you to finish every task, and we know " +
      "there are probably optimizations and improvements you would make " +
      "with additional time. This is an OPTIONAL step for you to tell us " +
      "what you would have done with more time. Please note, the form will " +
      `auto-submit in ${SUBMITTED_DIALOG_REDIRECT_TIME_MIN} minutes.`
    );
  }

  redirectToSurvey() {
    const {atsId} = this.props;
    this.props.history.push(surveyUrl(atsId));
  }

  @autobind
  async onSubmit() {
    const text = _.get(this.surveyRef, "current.value", "");
    if (text) {
      await this.props.saveM2AdditionalTimeDescription(text);
    }
    this.redirectToSurvey();
  }

  @autobind
  onSkip() {
    this.redirectToSurvey();
  }

  renderSurvey(): React.ReactNode {
    const placeholder =
      "What would you have done with additional time on " +
      "Part 2 of the interview?";
    return (
      <TextField
        multiline={true}
        variant="outlined"
        fullWidth={true}
        inputRef={this.surveyRef}
        placeholder={placeholder}
        rows={4}
        maxRows={15}
      />
    );
  }

  render(): React.ReactNode {
    const {show, isSubmitting, classes} = this.props;
    const buttonLabel = isSubmitting ? "Submitting" : "Submit";
    const justifyContent = "space-between";
    return (
      <Dialog open={show}>
        <CheckIcon />
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <DialogTitle className={classes.centerText}>
          Part 2 has been submitted!
        </DialogTitle>
        <DialogContent>
          <Typography paragraph={true}>{this.getDialogText()}</Typography>
          {this.renderSurvey()}
        </DialogContent>
        <DialogActions style={{justifyContent}}>
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={this.onSubmit}
          >
            {buttonLabel}
          </Button>
          <Button color="default" variant="text" onClick={this.onSkip}>
            Skip
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  atsId: state.interview.id,
  isSubmitting: state.m2InterviewScreenState.isSubmittingM2,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  // @ts-ignore FIXME: strictNullChecks
  saveM2AdditionalTimeDescription: (text: string) => {
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(saveM2AdditionalTimeDescription(text));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(M2SubmittedDialog)));
