import * as helpDialogActions from "../actions/helpDialogActions";
import {HelpDialogState} from "./types";
import {HelpDialogAction} from "../actions/actionTypes";

const {
  SHOW_HELP_DIALOG,
  HIDE_HELP_DIALOG,
  IS_SUBMITTING_HELP_FORM,
  DID_SUBMIT_HELP_FORM,
  RESET_HELP_FORM,
  SET_ACTIVE_TOPIC_ID,
  SET_CONTACT_FORM_TEXT,
} = helpDialogActions;

const defaultState: HelpDialogState = {
  isSubmitting: false,
  didSubmit: false,
  showDialog: false,
  activeTopicId: "",
  contactFormText: "",
};

export function helpDialogState(
  state: HelpDialogState = defaultState,
  action: HelpDialogAction,
): HelpDialogState {
  switch (action.type) {
    case SHOW_HELP_DIALOG:
      return {
        ...state,
        showDialog: true,
        activeTopicId: action.activeTopicId || "",
      };
    case HIDE_HELP_DIALOG:
      return {
        ...state,
        showDialog: false,
      };
    case IS_SUBMITTING_HELP_FORM:
      return {
        ...state,
        isSubmitting: true,
      };
    case DID_SUBMIT_HELP_FORM:
      return {
        ...state,
        didSubmit: true,
        isSubmitting: false,
        contactFormText: "",
      };
    case RESET_HELP_FORM:
      return {
        ...state,
        didSubmit: false,
        isSubmitting: false,
      };
    case SET_ACTIVE_TOPIC_ID:
      return {
        ...state,
        activeTopicId: action.value,
        didSubmit: false,
      };
    case SET_CONTACT_FORM_TEXT:
      return {
        ...state,
        contactFormText: action.value,
      };
    default:
      return state;
  }
}
