import {TesterScreenAction} from "../actions/actionTypes";
import {TesterScreenState} from "./types";

const defaultState: TesterScreenState = {
  isSubmitting: false,
  isDownloadingZip: false,
  showInstructions: true,
};

export function testerScreenState(
  state: TesterScreenState = defaultState,
  action: TesterScreenAction,
) {
  switch (action.type) {
    case "TESTER_SCREEN.IS_SUBMITTING":
      return {...state, ...{isSubmitting: action.value}};
    case "TESTER_SCREEN.IS_DOWNLOADING_ZIP":
      return {...state, ...{isDownloadingZip: action.value}};
    case "TESTER_SCREEN.TOGGLE_INSTRUCTIONS":
      return {...state, ...{showInstructions: !state.showInstructions}};
    default:
      return state;
  }
}
