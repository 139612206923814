export interface Option<T = string> {
  value: T;
  label: string;
  description?: string;
}

export const genderOptions: Option[] = [
  {
    label: "Man",
    value: "man",
  },
  {
    label: "Woman",
    value: "woman",
  },
  {
    label: "Genderqueer / Non-Binary",
    value: "nonBinary",
  },
  {
    label: "None of these apply",
    value: "noneApply",
  },
  {
    label: "Prefer not to say",
    value: "preferNotToSay",
  },
];

export const raceOptions: (Option & {shortLabel: string})[] = [
  {
    label: "Asian",
    shortLabel: "Asian",
    value: "asian",
  },
  {
    label: "Black / African descent",
    shortLabel: "Black",
    value: "black",
  },
  {
    label:
      "Hispanic / Latino / Latinx (e.g. identify as Hispanic or Latino " +
      "with ties in Latin America, and can be of any race)",
    shortLabel: "Hispanic",
    value: "latinx",
  },
  {
    label:
      "Indigenous (Aborginal Australian, Alaska Native, First Nations, " +
      "Native American, Native Hawaiian, Samoan)",
    shortLabel: "Indigenous",
    value: "indigenous",
  },
  {
    label: "Middle Eastern",
    shortLabel: "Middle Eastern",
    value: "middleEastern",
  },
  {
    label: "White / European descent",
    shortLabel: "White",
    value: "white",
  },
];
