import * as React from "react";
import CopyButton from "./CopyButton";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
  style?: any;
}

const useStyles = makeStyles({
  wrap: {
    marginBottom: 20,
    overflow: "hidden",
    position: "relative",
  },
  code: {
    border: "solid 1px #ccc",
    borderRadius: 10,
    display: "block",
    fontSize: 12,
    padding: "8px 50px 8px 12px",
    whiteSpace: "pre-wrap",
  },
  copyButton: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
});

const CodeSnippet = (props: React.PropsWithChildren<Props>) => {
  const classes = useStyles();
  const {children, style} = props;
  return (
    <div className={classNames(classes.wrap)} style={style || {}}>
      <code className={classes.code}>{children}</code>
      {/* @ts-ignore FIXME: strictNullChecks*/}
      <Tooltip title="Copy">
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <CopyButton text={children.toString()} className={classes.copyButton} />
      </Tooltip>
    </div>
  );
};

export default CodeSnippet;
