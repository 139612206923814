import * as interviewActions from "../actions/interviewActions";
import {InterviewAction} from "../actions/actionTypes";
import {Interview} from "./types";

const {
  IS_SUBMITTING_M2,
  SET_TERMS,
  SET_INTERVIEW,
  SET_LANGUAGE,
  SET_M1_OPEN_TIME,
  SET_M1_END_TIME,
  SET_M2_OPEN_TIME,
  SET_M2_END_TIME,
  SET_M2_TYPE,
  SET_SURVEY_STARS,
  UPDATE_CANDIDATE,
} = interviewActions;

export function interview(
  state: Interview | undefined | null,
  action: InterviewAction,
): Interview | null {
  // Need to set the interview first
  if (!state && action.type !== SET_INTERVIEW) return null;

  switch (action.type) {
    case IS_SUBMITTING_M2:
      return Object.assign({}, state, {isSubmittingM2: true});
    case SET_TERMS:
      return Object.assign({}, state, {terms: true});
    case SET_INTERVIEW:
      return action.interview;
    case SET_LANGUAGE:
      return Object.assign({}, state, {language: action.language});
    case SET_M1_OPEN_TIME:
      return Object.assign({}, state, {
        m1OpenTime: action.value,
        isStartingM1: false,
      });
    case SET_M1_END_TIME:
      return Object.assign({}, state, {
        m1EndTime: action.value,
        isSubmittingM1: false,
      });
    case SET_M2_OPEN_TIME:
      return Object.assign({}, state, {
        m2OpenTime: action.value,
      });
    case SET_M2_END_TIME:
      return Object.assign({}, state, {
        m2EndTime: action.value,
        isSubmittingM2: false,
      });
    case SET_M2_TYPE:
      return Object.assign({}, state, {m2Type: action.value});
    case SET_SURVEY_STARS:
      return Object.assign({}, state, {
        survey_stars: action.value,
      });
    case UPDATE_CANDIDATE:
      // Note `candidate` does not include `companyName`, so merge it into the
      // state instead of replacing it.
      return Object.assign({}, state, action.candidate);
    default:
      return state || null;
  }
}
