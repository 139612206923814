import * as getStartedScreenActions from "../actions/getStartedScreenActions";
import {GetStartedScreenAction} from "../actions/actionTypes";
import {GetStartedScreenState} from "../reducers/types";
import {uniq} from "lodash";

const {OVERVIEW_STEP, LANGUAGE_STEP, M2_TYPE_STEP} = getStartedScreenActions;

const defaultState: GetStartedScreenState = {
  isSubmitting: false,
  language: "none",
  m2Type: "",
  completedSteps: [],
  expandedSteps: [],
  showDocsWalkthroughDialog: false,
};

export function getStartedScreenState(
  state: GetStartedScreenState = defaultState,
  action: GetStartedScreenAction,
) {
  let completedSteps: number[];
  let expandedSteps: number[];
  switch (action.type) {
    case "GET_STARTED_SCREEN.IS_SUBMITTING":
      return Object.assign({}, state, {isSubmitting: action.value});
    case "GET_STARTED_SCREEN.SET_OVERVIEW":
      completedSteps = state.completedSteps.includes(OVERVIEW_STEP)
        ? state.completedSteps.slice()
        : [OVERVIEW_STEP, ...state.completedSteps];
      expandedSteps = state.expandedSteps.filter((step) => {
        return step !== OVERVIEW_STEP;
      });
      if (
        !expandedSteps.includes(LANGUAGE_STEP) &&
        !completedSteps.includes(LANGUAGE_STEP)
      ) {
        expandedSteps.push(LANGUAGE_STEP);
      }
      return Object.assign({}, state, {
        completedSteps,
        expandedSteps,
      });
    case "GET_STARTED_SCREEN.SET_LANGUAGE":
      completedSteps = state.completedSteps.includes(LANGUAGE_STEP)
        ? state.completedSteps.slice()
        : [...state.completedSteps, LANGUAGE_STEP];
      expandedSteps = state.expandedSteps.filter((step) => {
        return step !== LANGUAGE_STEP;
      });
      if (
        !expandedSteps.includes(M2_TYPE_STEP) &&
        !completedSteps.includes(M2_TYPE_STEP)
      ) {
        expandedSteps.push(M2_TYPE_STEP);
      }
      return Object.assign({}, state, {
        language: action.language,
        completedSteps,
        expandedSteps,
      });
    case "GET_STARTED_SCREEN.SET_M2_TYPE":
      completedSteps = state.completedSteps.includes(M2_TYPE_STEP)
        ? state.completedSteps.slice()
        : [...state.completedSteps, M2_TYPE_STEP];
      expandedSteps = state.expandedSteps.filter((step) => {
        return step !== M2_TYPE_STEP;
      });
      return Object.assign({}, state, {
        m2Type: action.value,
        completedSteps,
        expandedSteps,
      });
    case "GET_STARTED_SCREEN.TOGGLE_EXPAND_OVERVIEW_STEP":
      const expandOverviewStep =
        typeof action.value === "boolean"
          ? action.value
          : !state.expandedSteps.includes(OVERVIEW_STEP);
      expandedSteps = expandOverviewStep
        ? uniq([OVERVIEW_STEP, ...state.expandedSteps])
        : state.expandedSteps.filter((step) => step !== OVERVIEW_STEP);
      return Object.assign({}, state, {expandedSteps});
    case "GET_STARTED_SCREEN.TOGGLE_EXPAND_LANGUAGE_STEP":
      const expandLanguageStep =
        typeof action.value === "boolean"
          ? action.value
          : !state.expandedSteps.includes(LANGUAGE_STEP);
      expandedSteps = expandLanguageStep
        ? uniq([...state.expandedSteps, LANGUAGE_STEP])
        : state.expandedSteps.filter((step) => step !== LANGUAGE_STEP);
      return Object.assign({}, state, {expandedSteps});
    case "GET_STARTED_SCREEN.TOGGLE_EXPAND_M2_TYPE_STEP":
      const expandM2TypeStep =
        typeof action.value === "boolean"
          ? action.value
          : !state.expandedSteps.includes(M2_TYPE_STEP);
      expandedSteps = expandM2TypeStep
        ? uniq([...state.expandedSteps, M2_TYPE_STEP])
        : state.expandedSteps.filter((step) => step !== M2_TYPE_STEP);
      return Object.assign({}, state, {expandedSteps});
    case "GET_STARTED_SCREEN.TOGGLE_COMPLETED_STEP":
      return Object.assign({}, state, {
        completedSteps: state.completedSteps.includes(action.value)
          ? state.completedSteps.filter((step) => step !== action.value)
          : [...state.completedSteps, action.value],
      });
    case "GET_STARTED_SCREEN.TOGGLE_DOCS_WALKTHROUGH_DIALOG":
      return {
        ...state,
        ...{
          showDocsWalkthroughDialog: !state.showDocsWalkthroughDialog,
        },
      };
    default:
      return state;
  }
}
