/**
 * Warns the user that they have unsaved changes before they reload the page
 * or navigate away.
 * @returns Function that cancels the unsaved changes warning.
 */
export function displayUnsavedChangesWarningBeforeUnload(): () => void {
  addEventListener("beforeunload", onBeforeUnload);
  return () => removeEventListener("beforeunload", onBeforeUnload);
}

function onBeforeUnload(e: BeforeUnloadEvent) {
  // Show the unsaved changes dialog.
  e.preventDefault();
  // Chrome requires returnValue to be set.
  e.returnValue = "";
}
