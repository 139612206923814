import {AppAction} from "../actions/actionTypes";
import {SET_USER} from "../actions/userActions";

export function user(state = {}, action: AppAction) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    default:
      return state;
  }
}
