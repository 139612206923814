import {AppState} from "../reducers/types";
import {ServerInfoAction} from "./actionTypes";
import {ThunkDispatch, ThunkAction} from "redux-thunk";
import {serverTimeUrl, logFEInfoUrl} from "../helpers/urls/interviewUrls";
import {get, post} from "./fetchActions";

export enum LogAction {
  AUTO_COLLAPSED_M1_INSTRUCTIONS = "AUTO_COLLAPSED_M1_INSTRUCTIONS",
  CREDENTIALS_REQUESTED = "CREDENTIALS_REQUESTED",
  DISABLED_BBDOC_FOR_DICTATION = "DISABLED_BBDOC_FOR_DICTATION",
  HELP_DIALOG_OPENED = "HELP_DIALOG_OPENED",
  INTERVIEW_HUB_FAQ_PAGE_VIEWED = "INTERVIEW_HUB_FAQ_PAGE_VIEWED",
  INTERVIEW_HUB_GUIDE_PAGE_VIEWED = "INTERVIEW_HUB_GUIDE_PAGE_VIEWED",
  INTERVIEW_HUB_HELP_PAGE_VIEWED = "INTERVIEW_HUB_HELP_PAGE_VIEWED",
  INTERVIEW_HUB_PAGE_VIEWED = "INTERVIEW_HUB_PAGE_VIEWED",
  PREP_GUIDE_PAGE_VIEWED = "PREP_GUIDE_PAGE_VIEWED",
  REQUEST_CREDENTIALS_PAGE_VIEWED = "REQUEST_CREDENTIALS_PAGE_VIEWED",
  SUPPORT_REQUESTED = "SUPPORT_REQUESTED",
  SWITCHED_TO_CLOUDSHELL = "SWITCHED_TO_CLOUDSHELL",
  SWITCHED_TO_ZIP = "SWITCHED_TO_ZIP",
  TIMER_OUT_OF_SYNC = "TIMER_OUT_OF_SYNC",
  COPIED_M1_CONTENT = "COPIED_M1_CONTENT",
  PASTED_M1_CONTENT = "PASTED_M1_CONTENT",
}
type Action = ServerInfoAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

function setServerTimeOffset(value: number): ServerInfoAction {
  return {
    type: "SERVER_INFO.SET_SERVER_TIME_OFFSET",
    value,
  };
}

export function fetchServerTimeOffset(): AsyncAction {
  return async (dispatch: Dispatch): Promise<void> => {
    const request = get(serverTimeUrl(), {
      errorMessage: "Failed to load server info.",
    });
    // @ts-ignore FIXME: strictNullChecks
    const json = await dispatch(request);
    if (!json) return; // Stop here (error screen will be visible).
    // In the case that the server time is exactly the same as the client time
    // we'll set the offset to 1ms so that the value is truthy after it is set.
    // @ts-ignore FIXME: strictNullChecks
    const offset = Date.now() - json.time || 1;
    dispatch(setServerTimeOffset(offset));
  };
}

export function logFEInfo(action: LogAction, data?: object): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const request = post(
      logFEInfoUrl(),
      {
        log: {
          action,
          ...data,
        },
        atsId: getState().interview.id,
      },
      {
        errorMessage: "Unknown interview error.",
      },
    );
    // @ts-ignore FIXME: strictNullChecks
    return dispatch(request);
  };
}
