import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import {ThemeProvider, createTheme, makeStyles} from "@material-ui/core/styles";
import merge from "lodash/merge";
import React, {useContext, useMemo} from "react";
import {
  ApplicantContext,
  ApplicantContextProvider,
} from "./context/ApplicantContext";
import applicantTheme from "./helpers/applicantTheme";
import EndEducation from "./views/EndEducation";
import EndProjects from "./views/EndProjects";
import EndRoles from "./views/EndRoles";
import FinalReview from "./views/FinalReview";
import GetStarted from "./views/GetStarted";
import Loading from "./views/Loading";
import ThankYou from "./views/ThankYou";
import ValidateEducation from "./views/ValidateEducation";
import ValidateProject from "./views/ValidateProject";
import ValidateRole from "./views/ValidateRole";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: theme.palette.background.default,
    fallbacks: [{height: "100vh"}],
    height: "100svh",
    overflow: "auto",
  },
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(3),
  },
}));

function ApplicantScreenMain() {
  const {uiState} = useContext(ApplicantContext);
  const classes = useStyles();

  const view = useMemo(() => {
    switch (uiState.step) {
      case "loading":
        return <Loading />;
      case "getStarted":
        return <GetStarted />;
      case "validateRole":
        return <ValidateRole />;
      case "rolesEnd":
        return <EndRoles />;
      case "validateEducation":
        return <ValidateEducation />;
      case "educationEnd":
        return <EndEducation />;
      case "validateProject":
        return <ValidateProject />;
      case "projectsEnd":
        return <EndProjects />;
      case "finalReview":
        return <FinalReview />;
      case "thankYou":
        return <ThankYou />;
    }
  }, [uiState.step]);

  return (
    <Box className={classes.page}>
      <Container className={classes.container} maxWidth="md">
        {view}
      </Container>
    </Box>
  );
}

export default function ApplicantScreen() {
  return (
    <ThemeProvider theme={(theme) => createTheme(merge(theme, applicantTheme))}>
      <ApplicantContextProvider>
        <ApplicantScreenMain />
      </ApplicantContextProvider>
    </ThemeProvider>
  );
}
