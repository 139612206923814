import React, {useEffect, useRef, useState, forwardRef} from "react";
import {
  BBDocCommentBox,
  INPUT_DATA_ATTR,
  COMMENT_BOX_MARGIN_PX,
} from "./BBDocCommentBox";
import Fade from "@material-ui/core/Fade";
import {
  CandidateResponse,
  InterviewDoc,
  InterviewDocQuestion,
} from "../reducers/types";
import {
  ResponseIdToHighlightedElementsMap,
  INLINE_QUESTION_DATA_ATTR,
} from "./BBDocBody";
import {
  getNewCandidateResponse,
  NEW_COMMENT,
  isNewComment,
} from "../helpers/bbdoc";
import {getRangeForSelection} from "../util/selection";
import {makeStyles} from "@material-ui/core/styles";
import {throttle, differenceBy} from "lodash";
import {useCombinedRefs, useForceUpdate, usePrevious} from "../util/hooks";

const WINDOW_RESIZE_THROTTLE_WAIT_MS = 500;
const ADJUST_COMMENTS_UP_PX = 5;

interface Props {
  interviewDoc: InterviewDoc;
  responseIdToHighlightedElementsMap: ResponseIdToHighlightedElementsMap;
  docTextSelection: Selection | null;
  activeResponseId: string | null;
  docBodyElement: HTMLElement;
  candidateName: string;
  readonly: boolean;
  onActiveResponseChange: (activeResponse: CandidateResponse) => any;
  onNewComment: (newCandidateResponse: CandidateResponse) => any;
  onDeleteComment: (newCandidateResponse: CandidateResponse) => any;
  onUpdateCandidateResponse: (candidateResponse: CandidateResponse) => any;
  onCommentHeightChange: () => any;
  commentBoxRefs: React.MutableRefObject<{
    [candidateResponseId: string]: HTMLElement | null;
  }>;
}

type CommentBoxTopOffsetMap = {
  [candidateResponseId: string]: number;
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginRight: theme.bbdoc.gutterWidth,
      marginTop: 10,
      position: "relative",
      width: 300,
    },
  }),
  {
    name: "BBDocCommentList",
  },
);

export const BBDocCommentList = forwardRef<HTMLElement, Props>((props, ref) => {
  const classes = useStyles({});
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, innerRef);
  const forceUpdate = useForceUpdate();
  const throttledForceUpdate = throttle(
    forceUpdate,
    WINDOW_RESIZE_THROTTLE_WAIT_MS,
  );
  const commentBoxRefs = props.commentBoxRefs;
  const [newlyCreatedResponseId, setNewlyCreatedResponseId] = useState<
    string | null
  >(null);

  // @ts-ignore FIXME: strictNullChecks
  const prevActiveResponseId = usePrevious(props.activeResponseId);

  // Set the activeResponseId to NEW_COMMENT whenever text is selected in the
  // doc body.
  useEffect(() => {
    // @ts-ignore FIXME: strictNullChecks
    if (props.docTextSelection && !isNewComment(prevActiveResponseId)) {
      props.onActiveResponseChange(getNewCandidateResponse());
    }
  }, [props.activeResponseId, props.docTextSelection]);

  // Delete comments when the user moves focus and the input field is empty.
  useEffect(() => {
    if (props.readonly) return;
    const prevActiveResponse = prevActiveResponseId
      ? props.interviewDoc.candidateResponses.find(
          (response) => response.id === prevActiveResponseId,
        )
      : null;
    // Don't delete the comment if it is linked to a question.
    // @ts-ignore FIXME: strictNullChecks
    const question = getQuestionForCandidateResponse(prevActiveResponse);
    if (question) return;
    // This is a little hacky, but it's easier than storing the display value
    // state here instead of in the BBDocCommentBox component.
    // @ts-ignore FIXME: strictNullChecks
    const commentBoxRef = commentBoxRefs.current[prevActiveResponse?.id];
    const prevActiveResponseDisplayText = (
      commentBoxRef?.querySelector(
        `[${INPUT_DATA_ATTR}]`,
      ) as HTMLTextAreaElement
    )?.value;
    if (prevActiveResponse && !prevActiveResponseDisplayText) {
      props.onDeleteComment(prevActiveResponse);
    }
  }, [props.activeResponseId]);

  // Once activeResponseId changes (the user moves focus from the newly
  // created response) then unset newlyCreatedResponseId.
  useEffect(() => {
    if (
      newlyCreatedResponseId &&
      newlyCreatedResponseId !== props.activeResponseId
    ) {
      setNewlyCreatedResponseId(null);
    }
  }, [newlyCreatedResponseId, props.activeResponseId]);

  // Set activeResponseId and newlyCreatedResponseId whenever a new comment is
  // added.
  useEffect(() => {
    const newlyCreatedCandidateResponse = getNewlyCreatedCandidateResponse();
    if (!newlyCreatedCandidateResponse) return;
    setNewlyCreatedResponseId(newlyCreatedCandidateResponse.id);
    props.onActiveResponseChange(newlyCreatedCandidateResponse);
  }, [props.interviewDoc.candidateResponses.length]);

  // Force the component to re-render when the window resizes because the
  // top-offsets for the comments might have changed.
  useEffect(() => {
    window.addEventListener("resize", throttledForceUpdate);
    return () => {
      window.removeEventListener("resize", throttledForceUpdate);
    };
  }, []);

  useEffect(() => {
    commentBoxRefs.current = props.interviewDoc.candidateResponses.reduce(
      (refs, candidateResponse) => ({
        ...refs,
        [candidateResponse.id]:
          commentBoxRefs.current[candidateResponse.id] || null,
      }),
      {},
    );
  }, [props.interviewDoc.candidateResponses]);

  // Prevent the comment boxes from sliding in when the doc initially loads.
  const [preventCommentPositionAnimation, setPreventCommentPositionAnimation] =
    useState(true);
  useEffect(() => {
    const animationFrameId = requestAnimationFrame(() => {
      setPreventCommentPositionAnimation(false);
    });
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const prevCandidateResponses = usePrevious(
    props.interviewDoc.candidateResponses,
  );
  const numCandidateResponses = props.interviewDoc.candidateResponses.length;

  // Returns the newly created candidate response (whenever a new response is
  // added to props.doc.candidateResponses).
  const getNewlyCreatedCandidateResponse = (): CandidateResponse | null => {
    if (
      !prevCandidateResponses ||
      numCandidateResponses !== prevCandidateResponses.length + 1
    ) {
      return null;
    }
    return differenceBy(
      props.interviewDoc.candidateResponses,
      prevCandidateResponses,
      "id",
    )[0];
  };

  // We cache the newly created response in this component's state
  // (newlyCreatedResponseId), however, we also need to know if a response is
  // new on its initial render so the animation is smooth, so we also check
  // getNewlyCreatedCandidateResponse().
  const isResponseNewlyCreated = (
    candidateResponse: CandidateResponse,
  ): boolean => {
    return (
      candidateResponse.id === getNewlyCreatedCandidateResponse()?.id ||
      candidateResponse.id === newlyCreatedResponseId
    );
  };

  const getQuestionForCandidateResponse = (
    candidateResponse: CandidateResponse,
  ): InterviewDocQuestion => {
    const {questionId} = candidateResponse || {};
    // @ts-ignore FIXME: strictNullChecks
    return props.interviewDoc.questions[questionId];
  };

  const showInlineResponseTextField = (
    candidateResponse: CandidateResponse,
  ): boolean => {
    const question = getQuestionForCandidateResponse(candidateResponse);
    return question?.inputType === "body";
  };

  const unsetActiveResponseId = () => {
    // @ts-ignore FIXME: strictNullChecks
    props.onActiveResponseChange(null);
  };

  const renderNewCommentButton = (): React.ReactNode => {
    const offsetTop = getSelectionTopOffset();
    const show = Boolean(props.docTextSelection && offsetTop);
    return (
      <Fade in={show} timeout={{enter: 250, exit: 0}} key={NEW_COMMENT}>
        <div>
          {show ? (
            <BBDocCommentBox
              type="newCommentCollapsed"
              readonly={props.readonly}
              candidateResponse={getNewCandidateResponse()}
              active={true}
              ref={(el) => (commentBoxRefs.current[NEW_COMMENT] = el)}
              topOffset={offsetTop}
              onFocus={(newCandidateResponse) => {
                props.onNewComment(newCandidateResponse);
                requestAnimationFrame(() => forceUpdate());
              }}
            />
          ) : null}
        </div>
      </Fade>
    );
  };

  const getDocHighlightTopForResponse = (
    candidateResponseId: string,
  ): number => {
    if (isNewComment(candidateResponseId)) return getSelectionTopOffset();
    const highlightedElements =
      props.responseIdToHighlightedElementsMap[candidateResponseId] || [];
    return getMinimumTopOffset(highlightedElements);
  };

  const getCommentBoxHeight = (candidateResponseId: string): number => {
    if (!candidateResponseId) return 0;
    return commentBoxRefs.current[candidateResponseId]?.offsetHeight || 0;
  };

  const getSelectionTopOffset = (): number => {
    if (!props.docTextSelection) return 0;
    const range = props.docTextSelection.getRangeAt(0);
    const {top: selectionTop} = range.getBoundingClientRect();
    // @ts-ignore FIXME: strictNullChecks
    const {top: rootTop} = combinedRef.current.getBoundingClientRect();
    return Math.max(selectionTop - rootTop - ADJUST_COMMENTS_UP_PX, 0);
  };

  // If the text field for this candidate response is in the doc body then this
  // will return the parent element for the text field.
  const getTextFieldParentElement = (
    candidateResponse: CandidateResponse,
    question: InterviewDocQuestion,
  ): HTMLElement => {
    // @ts-ignore FIXME: strictNullChecks
    return showInlineResponseTextField(candidateResponse)
      ? getInlineResponseParentElement(question)
      : null;
  };

  // Inserts the new comment (as a CandidateResponse) in the list of candidate
  // responses, which is useful for positioning the new comment element in
  // between existing comments.
  const getCandidateResponsesIncludingNewComment = (): CandidateResponse[] => {
    const {candidateResponses} = props.interviewDoc;
    const selectionTopOffset = getSelectionTopOffset();
    if (!props.docTextSelection || !selectionTopOffset) {
      return candidateResponses;
    }
    const [selectionStartIndex, selectionEndIndex] = getRangeForSelection(
      props.docBodyElement,
      props.docTextSelection,
    );
    const firstCandidateResponseBelowNewCommentIndex =
      candidateResponses.findIndex((response) => {
        const [responseStartIndex, responseEndIndex] = response.selectionRange;
        return selectionStartIndex === responseStartIndex
          ? responseEndIndex > selectionEndIndex
          : responseStartIndex > selectionStartIndex;
      });
    const newCommentInCandidateResponsesIndex =
      firstCandidateResponseBelowNewCommentIndex >= 0
        ? firstCandidateResponseBelowNewCommentIndex
        : candidateResponses.length;
    return [
      ...candidateResponses.slice(0, newCommentInCandidateResponsesIndex),
      getNewCandidateResponse(),
      ...candidateResponses.slice(newCommentInCandidateResponsesIndex),
    ];
  };

  // Determine the top position for each comment box. By default, comment boxes
  // line up with the top of their corresponding highlighted region. Comment
  // boxes will be pushed down if the previous box is too close, and pushed up
  // if they precede the active comment box. See go/bbdoc for more details.
  const commentBoxTopOffsetMap = ((): CommentBoxTopOffsetMap => {
    const curCommentBoxTopOffsetMap: CommentBoxTopOffsetMap = {};
    const candidateResponses = getCandidateResponsesIncludingNewComment();
    let activeResponseIndex = -1;
    // Generate curCommentBoxTopOffsetMap, first by pushing overlapping boxes
    // down.
    candidateResponses.forEach((response, responseIndex) => {
      const isActiveResponse = props.activeResponseId === response.id;
      if (isActiveResponse) {
        activeResponseIndex = responseIndex;
      }
      const docHighlightTopForResponse = getDocHighlightTopForResponse(
        response.id,
      );
      const {id: prevResponseId} = candidateResponses[responseIndex - 1] || {};
      const prevResponseTop = curCommentBoxTopOffsetMap[prevResponseId] || 0;
      const prevResponseBottom =
        prevResponseTop + getCommentBoxHeight(prevResponseId);
      const responseTopOffset = isActiveResponse
        ? docHighlightTopForResponse
        : Math.max(
            docHighlightTopForResponse,
            prevResponseBottom + COMMENT_BOX_MARGIN_PX,
          );
      curCommentBoxTopOffsetMap[response.id] = responseTopOffset;
    });
    // Next, start with the active comment box and push preceding boxes up if
    // they overlap.
    for (
      let responseIndex = activeResponseIndex - 1;
      responseIndex >= 0;
      responseIndex--
    ) {
      const response = candidateResponses[responseIndex];
      const responseHeight = getCommentBoxHeight(response.id);
      const originalResponseTopPosition =
        curCommentBoxTopOffsetMap[response.id];
      const nextResponse = candidateResponses[responseIndex + 1];
      const nextResponseTop = curCommentBoxTopOffsetMap[nextResponse.id];
      const responseTopOffset = Math.min(
        originalResponseTopPosition,
        nextResponseTop - responseHeight - COMMENT_BOX_MARGIN_PX,
      );
      curCommentBoxTopOffsetMap[response.id] = responseTopOffset;
    }
    return curCommentBoxTopOffsetMap;
  })();

  const findRelativeCandidateResponse = (
    commentId: string,
    offset: number,
  ): CandidateResponse | null => {
    const currentCommentIdx = props.interviewDoc.candidateResponses.findIndex(
      (response) => response.id === commentId,
    );
    const nextCommentIdx = currentCommentIdx + offset;
    if (
      nextCommentIdx > props.interviewDoc.candidateResponses.length ||
      nextCommentIdx < 0
    ) {
      return null;
    }

    return props.interviewDoc.candidateResponses[nextCommentIdx];
  };

  const goToNextComment = (currentComment: string): void => {
    const nextResponse = findRelativeCandidateResponse(currentComment, 1);
    if (nextResponse) {
      props.onActiveResponseChange(nextResponse);
    }
  };
  const goToPreviousComment = (currentComment: string): void => {
    const nextResponse = findRelativeCandidateResponse(currentComment, -1);
    if (nextResponse) {
      props.onActiveResponseChange(nextResponse);
    }
  };

  return (
    // @ts-ignore FIXME: strictNullChecks
    <div className={classes.root} ref={combinedRef}>
      {renderNewCommentButton()}
      {props.interviewDoc.candidateResponses.map((candidateResponse) => {
        const topOffset = commentBoxTopOffsetMap[candidateResponse.id];
        const type = isResponseNewlyCreated(candidateResponse)
          ? "newCommentExpanded"
          : "default";
        const question = getQuestionForCandidateResponse(candidateResponse);
        return (
          <BBDocCommentBox
            type={type}
            key={candidateResponse.id}
            candidateResponse={candidateResponse}
            active={candidateResponse.id === props.activeResponseId}
            ref={(el) => (commentBoxRefs.current[candidateResponse.id] = el)}
            topOffset={topOffset}
            textFieldParentElement={getTextFieldParentElement(
              candidateResponse,
              question,
            )}
            goToNextComment={() => goToNextComment(candidateResponse.id)}
            goToPreviousComment={() =>
              goToPreviousComment(candidateResponse.id)
            }
            preventPositionAnimation={preventCommentPositionAnimation}
            readonly={props.readonly}
            onFocus={props.onActiveResponseChange}
            onResponseChange={(responseText) => {
              props.onUpdateCandidateResponse({
                ...candidateResponse,
                responseText,
              });
            }}
            onInputHeightChange={props.onCommentHeightChange}
            onEscapeKeyPress={unsetActiveResponseId}
            highlightedElements={
              props.responseIdToHighlightedElementsMap[candidateResponse.id]
            }
            candidateName={props.candidateName}
            {...(question ? {question} : {})}
          />
        );
      })}
    </div>
  );
});

function getMinimumTopOffset(elements: HTMLElement[]): number {
  if (!elements.length) return 0;
  return Math.min(...elements.map((el) => el.offsetTop));
}

function getInlineResponseParentElement(
  question: InterviewDocQuestion,
): HTMLElement {
  // @ts-ignore FIXME: strictNullChecks
  return document.querySelector(
    `[${INLINE_QUESTION_DATA_ATTR}="${question.id}"]`,
  );
}
