import moment from "moment";

export function getServerTime(serverOffset: number): number {
  return Date.now() - serverOffset;
}

// [@mason] TODO: This function is now just a wrapper and is not necessary anymore.
export function msToMin(ms: number): number {
  return moment.duration(ms).asMinutes();
}
