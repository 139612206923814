import * as React from "react";
import MuiFade, {FadeProps} from "@material-ui/core/Fade";

const hideDelay = 300;

interface State {
  isDisplayNone: boolean;
}

export default class Fade extends React.Component<FadeProps, State> {
  timeoutId: any = null;

  constructor(props: FadeProps) {
    super(props);
    this.state = {
      isDisplayNone: !props.in,
    };
  }

  componentDidUpdate(prevProps: FadeProps) {
    if (prevProps.in && !this.props.in) {
      this.timeoutId = setTimeout(() => {
        this.setState({isDisplayNone: true});
      }, hideDelay);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const style: any = {};
    if (this.state.isDisplayNone) {
      style.display = "none";
    }
    return (
      <MuiFade {...this.props} style={style}>
        {this.props.children}
      </MuiFade>
    );
  }
}
