import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import FormField, {FormFieldType} from "../components/FormField";
import RemoveExperienceModal from "../components/RemoveExperienceModal";
import ReturnToFinalReviewButton from "../components/ReturnToFinalReviewButton";
import Skills from "../components/Skills";
import {ApplicantContext} from "../context/ApplicantContext";
import {getNextUuid, getPrevUuid} from "../helpers/entries";
import {Project} from "../helpers/types";
import useRemoveExperienceModalState from "../hooks/useRemoveExperienceModalState";
import {useSharedStyles} from "../hooks/useSharedStyles";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    display: "grid",
    gridAutoFlow: "row",
    rowGap: "24px",
  },
  formBox: {
    columnGap: "24px",
    display: "grid",
    gridTemplateAreas: `
      "name"
      "startDate"
      "endDate"
      "present"
      "description"
    `,
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateAreas: `
        "name name"
        "startDate ."
        "endDate present"
        "description description"
      `,
      gridTemplateColumns: "1fr 1fr",
    },
    rowGap: "16px",
  },
}));

export default function ValidateProject() {
  const {uiState, projects, setUIState, setProjects, hasReachedFinalReview} =
    useContext(ApplicantContext);
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  // There should always be a uuid in the uiState, but we need to check for TS
  const currentUuid = "uuid" in uiState ? uiState.uuid : undefined;
  const projectIdx = projects.findIndex((p) => p.uuid === currentUuid);
  const project = projectIdx > -1 ? projects[projectIdx] : null;

  const [removeModalOpen, setRemoveModalOpen] =
    useRemoveExperienceModalState(currentUuid);

  const onNext = () => {
    const nextUuid = getNextUuid(projects, currentUuid);
    if (nextUuid) {
      setUIState({step: "validateProject", uuid: nextUuid});
    } else {
      setUIState({step: "projectsEnd"});
    }
  };

  const onBack = () => {
    const prevUuid = getPrevUuid(projects, currentUuid);
    if (prevUuid) {
      setUIState({step: "validateProject", uuid: prevUuid});
    } else {
      setUIState({step: "educationEnd"});
    }
  };

  const onRemove = () => {
    setProjects((oldState) => oldState.filter((p) => p.uuid !== currentUuid));
    if (hasReachedFinalReview) {
      setUIState({step: "finalReview"});
    } else {
      onNext();
    }
  };

  const handleUpdateProject = <K extends keyof Project>(
    field: K,
    value: Project[K],
  ) => {
    setProjects((oldState) =>
      oldState.map((proj) => {
        if (proj.uuid === currentUuid) {
          return {
            ...proj,
            [field]: value,
          };
        }
        return proj;
      }),
    );
  };

  if (!project) return null;

  const formFields: Record<keyof Omit<Project, "uuid">, FormFieldType> = {
    title: {
      value: project.title,
      label: "Title",
      onChange: (value) => handleUpdateProject("title", value),
      error: !project.title,
      required: true,
    },
    startDate: {
      value:
        project.startDate && moment(project.startDate).format("YYYY-MM-DD"),
      label: "Start Date",
      onChange: (value) => {
        const newStartDateMoment = moment(value);
        handleUpdateProject(
          "startDate",
          newStartDateMoment.isValid() ? newStartDateMoment.toISOString() : "",
        );
      },
      error: project.startDate === "" || !moment(project.startDate).isValid(),
      required: true,
    },
    endDate: {
      value: project.endDate && moment(project.endDate).format("YYYY-MM-DD"),
      label: "End Date",
      onChange: (value) => {
        const newEndDateMoment = moment(value);
        handleUpdateProject(
          "endDate",
          newEndDateMoment.isValid() ? newEndDateMoment.toISOString() : "",
        );
      },
      error: project.endDate !== "" && !moment(project.endDate).isValid(),
      disabled: project.endDate === "",
    },
    accomplishmentsAndResponsibilities: {
      value: project.accomplishmentsAndResponsibilities,
      label: "Description",
      onChange: (value) =>
        handleUpdateProject("accomplishmentsAndResponsibilities", value),
      error: !project.accomplishmentsAndResponsibilities,
      required: true,
    },
  };

  const formIsValid = Object.values(formFields).every((field) => !field.error);

  return (
    <ApplicantPaper>
      <Box className={sharedClasses.gridBoxRowGap24}>
        <Box display="grid" gridRowGap={8}>
          <Typography color="primary" variant="h2">
            Projects
          </Typography>
          <Typography variant="caption">
            {`${projectIdx + 1} out of ${projects.length}`}
          </Typography>
        </Box>
        <Box className={classes.formBox}>
          <FormField
            {...formFields.title}
            TextFieldProps={{style: {gridArea: "name"}}}
          />
          <FormField
            {...formFields.startDate}
            TextFieldProps={{
              InputLabelProps: {shrink: true},
              inputProps: {max: moment().format("YYYY-MM-DD")},
              style: {gridArea: "startDate"},
              type: "date",
            }}
          />
          <FormField
            {...formFields.endDate}
            TextFieldProps={{
              InputLabelProps: {shrink: true},
              inputProps: {max: moment().format("YYYY-MM-DD")},
              style: {gridArea: "endDate"},
              type: "date",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={project.endDate === ""}
                onChange={(e) =>
                  handleUpdateProject(
                    "endDate",
                    e.target.checked ? "" : moment().toISOString(),
                  )
                }
              />
            }
            label="I am currently working on this project"
            style={{gridArea: "present"}}
          />
          <FormField
            {...formFields.accomplishmentsAndResponsibilities}
            TextFieldProps={{
              minRows: 5,
              multiline: true,
              style: {gridArea: "description"},
            }}
          />
        </Box>
        <Skills />
        <ActionsWrapper>
          <Button
            className={clsx(
              sharedClasses.marginRightAuto,
              sharedClasses.outlinedButtonWarningColor,
            )}
            onClick={() => setRemoveModalOpen(true)}
            variant="outlined"
          >
            Remove experience
          </Button>
          {hasReachedFinalReview ? (
            <ReturnToFinalReviewButton disabled={!formIsValid} />
          ) : (
            <>
              <Button onClick={onBack}>Back</Button>
              <Button
                color="primary"
                disabled={!formIsValid}
                onClick={onNext}
                variant="contained"
              >
                Next
              </Button>
            </>
          )}
        </ActionsWrapper>
        <RemoveExperienceModal
          onCancel={() => setRemoveModalOpen(false)}
          onRemove={onRemove}
          open={removeModalOpen}
        />
      </Box>
    </ApplicantPaper>
  );
}
