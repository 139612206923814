import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Timer from "@material-ui/icons/Timer";
import React from "react";
import {primaryColor, secondaryColors} from "../../../branding";

const useStyles = makeStyles({
  outer: {
    backgroundColor: secondaryColors.light,
    border: `1px solid ${secondaryColors.dark}`,
    borderRadius: 8,
    color: primaryColor.main,
    position: "relative",
    top: -32,
  },
  inner: {
    alignItems: "center",
    columnGap: 16,
    display: "grid",
    gridTemplateAreas: `
        "icon title"
        "icon message"
    `,
    margin: "0 auto",
    maxWidth: 720,
    padding: 16,
    rowGap: 4,
  },
});

export default function EarlySubmitBanner() {
  const classes = useStyles();
  return (
    <Box className={classes.outer}>
      <Box className={classes.inner}>
        <Timer
          color="inherit"
          fontSize="large"
          style={{gridArea: "icon", justifySelf: "flex-end"}}
        />
        <Typography color="inherit" variant="body1" style={{gridArea: "title"}}>
          <b>Finished with Part 2 early?</b>
        </Typography>
        <Typography
          color="inherit"
          variant="body1"
          style={{gridArea: "message"}}
        >
          Be sure to follow the early submission instructions in Step 5.
        </Typography>
      </Box>
    </Box>
  );
}
