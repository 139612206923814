import {InterviewSurveyScreenAction} from "../actions/actionTypes";
import {InterviewSurveyScreenState} from "./types";
import {
  SET_INTERVIEW_SURVEY_SCREEN_NUM_STARS,
  IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN,
} from "../actions/interviewSurveyScreenActions";

const defaultState: InterviewSurveyScreenState = {
  numStars: 0,
  isSubmitting: false,
};

export function interviewSurveyScreenState(
  state: InterviewSurveyScreenState = defaultState,
  action: InterviewSurveyScreenAction,
) {
  switch (action.type) {
    case SET_INTERVIEW_SURVEY_SCREEN_NUM_STARS:
      return Object.assign({}, state, {
        numStars: action.numStars,
      });
    case IS_SUBMITTING_INTERVIEW_SURVEY_SCREEN:
      return Object.assign({}, state, {
        isSubmitting: action.value,
      });
    default:
      return state;
  }
}
