import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

interface Props {
  label: string;
  isSubmitting: boolean;
  show: boolean;
  onConfirmSubmit: () => any;
  onCancelSubmit: () => any;
}

export default class ConfirmSubmitDialog extends React.Component<Props> {
  render(): React.ReactNode {
    const {label, show, isSubmitting, onConfirmSubmit, onCancelSubmit} =
      this.props;
    return (
      <Dialog open={show}>
        <DialogTitle>Are you sure you want to submit?</DialogTitle>
        <DialogContent>
          <Typography>This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={onConfirmSubmit}
          >
            {label}
          </Button>
          <Button disabled={isSubmitting} onClick={onCancelSubmit}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
