import * as React from "react";
import classNames from "classnames";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface Props extends StyledComponentProps {
  items: any[];
  className?: string;
}

const styles = () =>
  createStyles({
    ul: {
      marginBottom: 16,
    },
    li: {
      marginBottom: 10,
    },
  });

class UnorderedList extends React.Component<Props> {
  render() {
    const {className} = this.props;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <ul className={classNames(this.props.classes.ul, className)}>
        {this.props.items.map((item, index) => (
          // @ts-ignore FIXME: strictNullChecks
          <li className={this.props.classes.li} key={index}>
            {item}
          </li>
        ))}
      </ul>
    );
  }
}

export default withStyles(styles)(UnorderedList);
