import {ServerInfo} from "./types";
import {ServerInfoAction} from "../actions/actionTypes";

const defaultState: ServerInfo = {
  serverTimeOffset: 0,
};

export function serverInfo(
  state: ServerInfo = defaultState,
  action: ServerInfoAction,
): ServerInfo {
  switch (action.type) {
    case "SERVER_INFO.SET_SERVER_TIME_OFFSET":
      return {
        ...state,
        ...{
          serverTimeOffset: action.value,
        },
      };
    default:
      return state;
  }
}
