import * as React from "react";
import classNames from "classnames";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

type Size = "medium" | "tall" | "normal";

interface Props extends StyledComponentProps {
  size?: Size;
}

const styles = () =>
  createStyles({
    "@keyframes fadeIn": {
      from: {opacity: 0},
      to: {opacity: 1},
    },
    root: {
      bottom: 0,
      left: 0,
      overflowY: "auto",
      position: "absolute",
      right: 0,
      top: 0,
    },
    screen: {
      animation: "$fadeIn 0.3s 0.2s forwards",
      margin: "50px auto 60px auto",
      maxWidth: 720,
      opacity: 0,
      padding: "0 20px",
      position: "relative",
      transition: "margin 0.3s",
    },
    helpButton: {
      bottom: 6,
      position: "fixed",
      right: 16,
    },
    normal: {
      margin: "64px auto",
      maxWidth: 900,
    },
    "@media (min-height: 600px)": {
      screen: {
        marginTop: 100,
      },
      tall: {
        marginTop: 30,
      },
      normal: {
        marginTop: 32,
      },
    },
    "@media (min-height: 780px)": {
      helpButton: {
        right: 6,
      },
      screen: {
        marginTop: 180,
      },
      tall: {
        marginTop: 60,
      },
      normal: {
        marginTop: 32,
      },
    },
  });

class CenteredScreenContent extends React.Component<Props> {
  static defaultProps = {
    size: "medium" as Size,
  };

  render(): React.ReactNode {
    const {classes, size} = this.props;
    return (
      // @ts-ignore FIXME: strictNullChecks
      <div className={classes.root}>
        <div
          // @ts-ignore FIXME: strictNullChecks
          className={classNames(classes.screen, {
            // @ts-ignore FIXME: strictNullChecks
            [classes.tall]: size === "tall",
            // @ts-ignore FIXME: strictNullChecks
            [classes.normal]: size === "normal",
          })}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CenteredScreenContent);
