import {InterviewModuleScreenAction} from "../actions/actionTypes";
import {InterviewModuleScreenState} from "../reducers/types";

const defaultState: InterviewModuleScreenState = {
  hasExpandedInstructions: true,
  isStarting: false,
  isSubmitting: false,
  isUnresolvingComments: false,
  isBBDocDisabledOrDisabling: false,
  showConfirmDialog: false,
  showSubmittedDialog: false,
  docUpdateTime: 0,
  isDownloadingZip: false,
  zipDownloadType: "base",
};

export function interviewModuleScreenState(
  state: InterviewModuleScreenState = defaultState,
  action: InterviewModuleScreenAction,
): InterviewModuleScreenState {
  switch (action.type) {
    case "INTERVIEW_MODULE_SCREEN.IS_STARTING":
      return Object.assign({}, state, {isStarting: action.value});
    case "INTERVIEW_MODULE_SCREEN.IS_SUBMITTING":
      return Object.assign({}, state, {isSubmitting: action.value});
    case "INTERVIEW_MODULE_SCREEN.IS_UNRESOLVING_COMMENTS":
      return Object.assign({}, state, {isUnresolvingComments: action.value});
    case "INTERVIEW_MODULE_SCREEN.TOGGLE_INSTRUCTIONS":
      return Object.assign({}, state, {
        hasExpandedInstructions: !state.hasExpandedInstructions,
      });
    case "INTERVIEW_MODULE_SCREEN.TOGGLE_CONFIRM_DIALOG":
      return Object.assign({}, state, {
        showConfirmDialog: !state.showConfirmDialog,
      });
    case "INTERVIEW_MODULE_SCREEN.TOGGLE_SUBMITTED_DIALOG":
      return Object.assign({}, state, {
        showSubmittedDialog: !state.showSubmittedDialog,
      });
    case "INTERVIEW_MODULE_SCREEN.SET_DOC_UPDATE_TIME":
      return Object.assign({}, state, {docUpdateTime: action.value});
    case "INTERVIEW_MODULE_SCREEN.IS_BBDOC_DISABLED_OR_DISABLING":
      return Object.assign({}, state, {
        isBBDocDisabledOrDisabling: action.value,
      });
    default:
      return state;
  }
}
