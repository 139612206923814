import {AppState, Flags, possibleFlags} from "../reducers/types";
import {FlagsAction, ErrorAction} from "./actionTypes";
import {ThunkDispatch, ThunkAction} from "redux-thunk";
import {flagsUrl} from "../helpers/urls/interviewUrls";
import {get} from "../util/fetch";
import {setErrorMessage} from "./errorActions";

type Action = FlagsAction<any> | ErrorAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;

export function setFlags(flags: Flags): FlagsAction<Flags> {
  return {
    type: "SET_FLAGS",
    possibleValues: possibleFlags,
    flags,
  };
}

export function fetchFlags(): AsyncAction {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const res = await get(flagsUrl());
      const json = await res.json();
      if (json.error) {
        console.error("Error loading flags", json.error);
        dispatch(setErrorMessage("Unable to set up environment."));
      } else {
        dispatch(setFlags(json));
      }
    } catch (err) {
      dispatch(setErrorMessage("Failed to connect to the back end."));
    }
  };
}
