import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
  container: {
    display: "grid",
    rowGap: "4px",
  },
  overline: {
    lineHeight: "1em",
  },
});

type Props = {
  labelText: string;
  overlineText: string;
};

export default function LabelWithOverline({labelText, overlineText}: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.overline} variant="overline">
        {overlineText}
      </Typography>
      <Typography variant="body2">{labelText}</Typography>
    </Box>
  );
}
