import {useRef, useEffect} from "react";

// functionality a la https://github.com/donavon/use-event-listener
// and types a la https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-window-event/use-window-event.ts
export function useEventListener<K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: (ev: HTMLElementEventMap[K]) => void,
  element: any = global,
  options: boolean | AddEventListenerOptions = false,
) {
  const savedHandler = useRef<(ev: HTMLElementEventMap[K]) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event: HTMLElementEventMap[K]) => {
      return savedHandler.current && savedHandler.current(event);
    };

    element.addEventListener(eventName, eventListener, options);
    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, element, options]);
}
