import Button from "@material-ui/core/Button";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, {useContext} from "react";
import {ApplicantContext} from "../context/ApplicantContext";

type Props = {
  disabled?: boolean;
};

export default function ReturnToFinalReviewButton({disabled}: Props) {
  const {setUIState} = useContext(ApplicantContext);
  return (
    <Button
      color="primary"
      disabled={disabled}
      endIcon={<KeyboardArrowRight />}
      onClick={() => setUIState({step: "finalReview"})}
      variant="contained"
    >
      Return To Final Review
    </Button>
  );
}
