import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React from "react";
import {Education} from "../helpers/types";
import {useSharedStyles} from "../hooks/useSharedStyles";
import ExperienceEditButton from "./ExperienceEditButton";
import ExperienceSkills from "./ExperienceSkills";

type Props = {
  education: Required<Education>;
};

export default function ExperienceEducation({education: education}: Props) {
  const sharedClasses = useSharedStyles();
  return (
    <Box display="grid" gridRowGap={8}>
      <Box className={sharedClasses.experinceHeaderContainer}>
        <Typography
          className={sharedClasses.experienceTitle}
          style={{gridArea: "title"}}
          variant="h3"
        >
          {`${education.degreeOrCertificateName} - ${education.degreeOrCertificateLevel}`}
        </Typography>
        <Typography component="p" style={{gridArea: "line1"}} variant="caption">
          {education.completionDate === ""
            ? "Present"
            : moment(education.completionDate).format("MMMM YYYY")}
        </Typography>
        <Typography component="p" style={{gridArea: "line2"}} variant="caption">
          {education.institution}
        </Typography>
        <ExperienceEditButton category="education" uuid={education.uuid} />
      </Box>
      <ExperienceSkills experience={education} />
    </Box>
  );
}
