import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import MuiExpandIcon from "@material-ui/icons/ChevronLeft";
import Tooltip from "@material-ui/core/Tooltip";

const updateTitleDelay = 400;

interface Props {
  isExpanded: boolean;
  isVertical?: boolean;
  collapseLabel?: string;
  expandLabel?: string;
  onChange: (isExpanded: boolean) => any;
}

interface State {
  title: string;
}

export default class ExpandIcon extends React.Component<Props, State> {
  static defaultProps = {
    isVertical: false,
    collapseLabel: "Collapse",
    expandLabel: "Expand",
  };

  timeoutId?: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      // @ts-ignore FIXME: strictNullChecks
      title: this.getTitle(),
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  getTitle() {
    return this.props.isExpanded
      ? this.props.collapseLabel
      : this.props.expandLabel;
  }

  getRotateDegrees() {
    const {isVertical, isExpanded} = this.props;
    if (isVertical) {
      return isExpanded ? -90 : 90;
    }
    return isExpanded ? 180 : 0;
  }

  onChange(isExpanded: boolean) {
    this.props.onChange(isExpanded);
    // Update the title when the animation is complete so that the user doesn't
    // immediately see the new title after clicking the icon.
    this.timeoutId = setTimeout(() => {
      this.setState({
        // @ts-ignore FIXME: strictNullChecks
        title: this.getTitle(),
      });
    }, updateTitleDelay);
  }

  render() {
    const {isExpanded} = this.props;
    const expandIconStyle = {
      transform: `rotate(${this.getRotateDegrees()}deg)`,
      transition: "transform 0.3s",
    };
    return (
      <Tooltip title={this.state.title}>
        <IconButton
          style={expandIconStyle}
          onClick={() => this.onChange(!isExpanded)}
        >
          <MuiExpandIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    );
  }
}
