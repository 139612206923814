import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import FormField, {FormFieldType} from "../components/FormField";
import RemoveExperienceModal from "../components/RemoveExperienceModal";
import ReturnToFinalReviewButton from "../components/ReturnToFinalReviewButton";
import Skills from "../components/Skills";
import {ApplicantContext} from "../context/ApplicantContext";
import {getNextUuid, getPrevUuid} from "../helpers/entries";
import {Role} from "../helpers/types";
import useRemoveExperienceModalState from "../hooks/useRemoveExperienceModalState";
import {useSharedStyles} from "../hooks/useSharedStyles";

const useStyles = makeStyles((theme) => ({
  experienceBox: {
    columnGap: "24px",
    display: "grid",
    gridTemplateAreas: `
      "title"
      "organization"
      "location"
      "startDate"
      "endDate"
      "present"
      "summary"
    `,
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateAreas: `
        "title title"
        "organization location"
        "startDate ."
        "endDate present"
        "summary summary"
      `,
      gridTemplateColumns: "1fr 1fr",
    },
    rowGap: "16px",
  },
}));

export default function ValidateRole() {
  const {roles, setRoles, uiState, setUIState, hasReachedFinalReview} =
    useContext(ApplicantContext);
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  // There should always be a uuid in the uiState, but we need to check for TS
  const currentUuid = "uuid" in uiState ? uiState.uuid : undefined;
  const roleIdx = roles.findIndex((r) => r.uuid === currentUuid);
  const role = roleIdx > -1 ? roles[roleIdx] : null;

  const [removeModalOpen, setRemoveModalOpen] =
    useRemoveExperienceModalState(currentUuid);

  const onNext = () => {
    const nextUuid = getNextUuid(roles, currentUuid);
    if (nextUuid) {
      setUIState({step: "validateRole", uuid: nextUuid});
    } else {
      setUIState({step: "rolesEnd"});
    }
  };

  const onBack = () => {
    const prevUuid = getPrevUuid(roles, currentUuid);
    if (prevUuid) {
      setUIState({step: "validateRole", uuid: prevUuid});
    } else {
      setUIState({step: "getStarted"});
    }
  };

  const onRemove = () => {
    setRoles((oldRoles) => oldRoles.filter((r) => r.uuid !== currentUuid));
    if (hasReachedFinalReview) {
      setUIState({step: "finalReview"});
    } else {
      onNext();
    }
  };

  const handleUpdateRole = <K extends keyof Role>(field: K, value: Role[K]) => {
    setRoles((oldRoles) =>
      oldRoles.map((r) => {
        if (r.uuid === currentUuid) {
          return {
            ...r,
            [field]: value,
          };
        }
        return r;
      }),
    );
  };

  if (!role) return null;

  const formFields: Record<keyof Omit<Role, "uuid">, FormFieldType> = {
    title: {
      value: role.title,
      label: "Job Title",
      onChange: (value) => handleUpdateRole("title", value),
      error: !role.title,
      required: true,
    },
    organization: {
      value: role.organization,
      label: "Employer",
      onChange: (value) => handleUpdateRole("organization", value),
      error: !role.organization,
      required: true,
    },
    location: {
      value: role.location,
      label: "Location",
      onChange: (value) => handleUpdateRole("location", value),
      error: !role.location,
      required: true,
    },
    startDate: {
      value: role.startDate && moment(role.startDate).format("YYYY-MM-DD"),
      label: "Start Date",
      onChange: (value) => {
        const newStartDateMoment = moment(value);
        handleUpdateRole(
          "startDate",
          newStartDateMoment.isValid() ? newStartDateMoment.toISOString() : "",
        );
      },
      error: role.startDate === "" || !moment(role.startDate).isValid(),
      required: true,
    },
    endDate: {
      value: role.endDate && moment(role.endDate).format("YYYY-MM-DD"),
      label: "End Date",
      onChange: (value) => {
        const newEndDateMoment = moment(value);
        handleUpdateRole(
          "endDate",
          newEndDateMoment.isValid() ? newEndDateMoment.toISOString() : "",
        );
      },
      error: role.endDate !== "" && !moment(role.endDate).isValid(),
      disabled: role.endDate === "",
    },
    accomplishmentsAndResponsibilities: {
      value: role.accomplishmentsAndResponsibilities,
      label: "Summary of work",
      onChange: (value) =>
        handleUpdateRole("accomplishmentsAndResponsibilities", value),
    },
  };

  const formIsValid = Object.values(formFields).every((field) => !field.error);

  return (
    <ApplicantPaper>
      <Box className={sharedClasses.gridBoxRowGap24}>
        <Box display="grid" gridRowGap={8}>
          <Typography color="primary" variant="h2">
            Work Experience
          </Typography>
          <Typography variant="caption">
            {`${roleIdx + 1} out of ${roles.length}`}
          </Typography>
        </Box>
        <Box className={classes.experienceBox}>
          <FormField
            {...formFields.title}
            TextFieldProps={{style: {gridArea: "title"}}}
          />
          <FormField
            {...formFields.organization}
            TextFieldProps={{style: {gridArea: "organization"}}}
          />
          <FormField
            {...formFields.location}
            TextFieldProps={{style: {gridArea: "location"}}}
          />
          <FormField
            {...formFields.startDate}
            TextFieldProps={{
              InputLabelProps: {shrink: true},
              inputProps: {max: moment().format("YYYY-MM-DD")},
              style: {gridArea: "startDate"},
              type: "date",
            }}
          />
          <FormField
            {...formFields.endDate}
            TextFieldProps={{
              InputLabelProps: {shrink: true},
              inputProps: {max: moment().format("YYYY-MM-DD")},
              style: {gridArea: "endDate"},
              type: "date",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={role.endDate === ""}
                onChange={(e) =>
                  handleUpdateRole(
                    "endDate",
                    e.target.checked ? "" : moment().toISOString(),
                  )
                }
              />
            }
            label="I am currently working in this role"
            style={{gridArea: "present"}}
          />
          <FormField
            {...formFields.accomplishmentsAndResponsibilities}
            TextFieldProps={{
              minRows: 5,
              multiline: true,
              style: {gridArea: "summary"},
            }}
          />
        </Box>
        <Skills />
        <ActionsWrapper>
          <Button
            className={clsx(
              sharedClasses.marginRightAuto,
              sharedClasses.outlinedButtonWarningColor,
            )}
            onClick={() => setRemoveModalOpen(true)}
            variant="outlined"
          >
            Remove experience
          </Button>
          {hasReachedFinalReview ? (
            <ReturnToFinalReviewButton disabled={!formIsValid} />
          ) : (
            <>
              <Button onClick={onBack} variant="text">
                Back
              </Button>
              <Button
                color="primary"
                disabled={!formIsValid}
                onClick={onNext}
                variant="contained"
              >
                Next
              </Button>
            </>
          )}
        </ActionsWrapper>
        <RemoveExperienceModal
          onCancel={() => setRemoveModalOpen(false)}
          onRemove={onRemove}
          open={removeModalOpen}
        />
      </Box>
    </ApplicantPaper>
  );
}
