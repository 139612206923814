import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import autobind from "autobind-decorator";
import {AppState, Toast} from "../reducers/types";
import {ThunkDispatch} from "redux-thunk";
import {ToastAction} from "../actions/actionTypes";
import {clearToast} from "../actions/toastActions";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

interface Props extends StyledComponentProps {
  clearToast: () => void;
  toast: Toast;
}

const styles = () =>
  createStyles({
    message: {
      display: "flex",
      alignItems: "center",
    },
  });

class ToastComponent extends React.Component<Props> {
  @autobind
  onClose(e: React.SyntheticEvent, reason: string) {
    if (reason === "clickaway") return;
    this.props.clearToast();
  }

  @autobind
  onCloseButtonPress(e: React.MouseEvent) {
    this.props.clearToast();
  }

  renderMessage() {
    const {classes, toast} = this.props;
    // @ts-ignore FIXME: strictNullChecks
    return <div className={classes.message}>{toast.message}</div>;
  }

  render() {
    const {toast} = this.props;
    const action = [
      <IconButton key="close" color="inherit" onClick={this.onCloseButtonPress}>
        <CloseIcon />
      </IconButton>,
    ];
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(toast.id)}
        onClose={this.onClose}
        message={this.renderMessage()}
        action={action}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  toast: state.toast,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, ToastAction>,
) => ({
  clearToast: () => dispatch(clearToast()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ToastComponent));
