export function getItem(name: string): any {
  // @ts-ignore FIXME: strictNullChecks
  return JSON.parse(localStorage.getItem(name));
}

export function setItem(name: string, value: any) {
  localStorage.setItem(name, JSON.stringify(value));
}

export function removeItem(name: string) {
  localStorage.removeItem(name);
}
