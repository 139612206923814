export type BrowserName =
  | "opera"
  | "firefox"
  | "safari"
  | "ie"
  | "edge"
  | "chrome"
  | "blink"
  | "unknown"
  | "electron";

type OS = "mac" | "ios" | "windows" | "android" | "linux" | "unknown";

export function isChromeBrowser(): boolean {
  return getBrowserName() === "chrome";
}

export function getBrowserName(): BrowserName {
  const win = window as any;
  const {userAgent} = win.navigator;
  if (
    (!!win.opr && !!win.opr.addons) ||
    !!win.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0
  ) {
    return "opera";
  } else if (typeof win.InstallTrigger !== "undefined") {
    return "firefox";
  } else if (
    /constructor/i.test(win.HTMLElement) ||
    ((p) => p.toString() === "[object SafariRemoteNotification]")(
      !win.safari ||
        (typeof win.safari !== "undefined" && win.safari.pushNotification),
    )
  ) {
    return "safari";
  } else if (!!(document as any).documentMode) {
    return "ie";
  } else if (!!win.StyleMedia) {
    return "edge";
  } else if (userAgent.includes("Chrome") && !userAgent.includes("Chromium")) {
    return "chrome";
  } else if (userAgent.toLowerCase().includes(" electron/")) {
    return "electron";
  } else {
    return "unknown";
  }
}

export function getOS(): OS {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  if (macosPlatforms.indexOf(platform) !== -1) {
    return "mac";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return "ios";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return "windows";
  } else if (/Android/.test(userAgent)) {
    return "android";
  } else if (/Linux/.test(platform)) {
    return "linux";
  } else {
    return "unknown";
  }
}
