import Typography from "@material-ui/core/Typography";
import HearingOutlinedIcon from "@mui/icons-material/HearingOutlined";
import React from "react";
import Link from "@material-ui/core/Link";
import {
  interviewGuideAccessibilityUrl,
  interviewGuideBreatherUrl,
} from "../../../helpers/urls/routerUrls";
import classNames from "classnames";
import {useStyles} from "../InterviewHubScreen.styles";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type Props = {
  interviewId: string;
};
export const AccommodationCallout = ({interviewId}: Props) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.callout, classes.calloutWithIcon)}>
      <HearingOutlinedIcon />
      <Typography>
        Before you begin, inform your recruiter about any{" "}
        <strong>accommodation requests</strong> if applicable. For more details
        see{" "}
        <Link href={interviewGuideAccessibilityUrl(interviewId)}>
          Accommodations
        </Link>
        .
      </Typography>
    </div>
  );
};

export const DestressCallout = ({interviewId}: Props) => {
  const classes = useStyles();
  return (
    <Link
      className={classNames(
        classes.callout,
        classes.calloutWithIcon,
        classes.calloutWithInline,
        classes.calloutWithBorder,
        classes.blockAsLink,
      )}
      underline="none"
      href={interviewGuideBreatherUrl(interviewId)}
    >
      <SpaOutlinedIcon />
      <Typography variant="h3">
        <strong>
          Need to de-stress? Take a quick breather before you start your
          interview
        </strong>
        <ArrowForwardIcon className={classes.arrowForwardIcon} />
      </Typography>
    </Link>
  );
};
