import {createStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {StyledComponentProps, withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import classNames from "classnames";
import * as React from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {HelpDialogAction} from "../actions/actionTypes";
import {showHelpDialog} from "../actions/helpDialogActions";
import {AppState} from "../reducers/types";

interface Props extends StyledComponentProps {
  className?: string;
  showHelpDialog: () => void;
}

const styles = () =>
  createStyles({
    iconButton: {
      background: "white",
      padding: 0,
    },
    icon: {
      alignItems: "center",
      border: "solid 1px rgba(0, 0, 0, 0.23)",
      borderRadius: "50%",
      color: "#aaa",
      display: "flex",
      fontFamily: "Google Sans, roboto",
      height: 36,
      justifyContent: "center",
      width: 36,
      "&:hover": {
        color: "#555",
      },
    },
    questionIcon: {
      fontSize: 20,
    },
  });

class HelpDialogButtonBase extends React.Component<Props> {
  render() {
    const {className, classes} = this.props;
    return (
      <Tooltip title="Help">
        <IconButton
          aria-label="Help button"
          // @ts-ignore FIXME: strictNullChecks
          className={classNames(classes.iconButton, className)}
          onClick={this.props.showHelpDialog}
        >
          <span
            // @ts-ignore FIXME: strictNullChecks
            className={classes.icon}
          >
            <HelpOutlineIcon // @ts-ignore FIXME: strictNullChecks
              className={classes.questionIcon}
            />
          </span>
        </IconButton>
      </Tooltip>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, HelpDialogAction>,
) => ({
  showHelpDialog: () => dispatch(showHelpDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(HelpDialogButtonBase));
