import * as m2InterviewScreenActions from "../actions/m2InterviewScreenActions";
import {M2InterviewScreenAction} from "../actions/actionTypes";
import {M2InterviewScreenState} from "../reducers/types";

const {
  IS_STARTING_M2,
  IS_SUBMITTING_M2,
  IS_DOWNLOADING_ZIP,
  IS_SCREEN_OPEN,
  IS_SUBMITTING_M2_ADDITIONAL_TIME_DESCRIPTION,
  TOGGLE_M2_INTERVIEW_SCREEN_INSTRUCTIONS,
  TOGGLE_M2_INTERVIEW_SCREEN_CONFIRM_DIALOG,
  TOGGLE_M2_INTERVIEW_SCREEN_SUBMITTED_DIALOG,
  SET_M2_INTERVIEW_SCREEN_INSTRUCTIONS_INDEX,
  SET_M2_INTERVIEW_SCREEN_TASK_INDEX,
  SET_M2_TASK_LIST,
  SET_M2_RUN_SH_FILE_NAME,
  SET_M2_PROJECT_DIRECTORY,
  SET_M2_PROJECT_NAME,
  IS_UPDATING_M2_TYPE,
  SET_HAS_MODIFIED_M2_CODE,
  SET_ZIP_DOWNLOAD_TYPE,
  SET_SWITCHED_TO_CLOUDSHELL_FROM_ZIP,
} = m2InterviewScreenActions;

const defaultState: M2InterviewScreenState = {
  hasExpandedInstructions: true,
  isStartingM2: false,
  isSubmittingM2: false,
  isDownloadingZip: false,
  isScreenOpen: false,
  isSubmittingM2AdditionalTimeDescription: false,
  showConfirmDialog: false,
  showSubmittedDialog: false,
  instructionsIndex: 0,
  runShFileName: "",
  projectDirectory: "",
  projectName: "",
  isUpdatingM2Type: false,
  hasModifiedM2Code: false,
  hasModifiedM2CodeLoaded: false,
  zipDownloadType: "base",
  switchedToCloudshellFromZip: false,
};

export function m2InterviewScreenState(
  state: M2InterviewScreenState = defaultState,
  action: M2InterviewScreenAction,
) {
  switch (action.type) {
    case IS_STARTING_M2:
      return Object.assign({}, state, {isStartingM2: action.value});
    case IS_SUBMITTING_M2:
      return Object.assign({}, state, {isSubmittingM2: action.value});
    case IS_DOWNLOADING_ZIP:
      return Object.assign({}, state, {isDownloadingZip: action.value});
    case IS_SCREEN_OPEN:
      return Object.assign({}, state, {isScreenOpen: action.value});
    case IS_SUBMITTING_M2_ADDITIONAL_TIME_DESCRIPTION:
      return {
        ...state,
        isSubmittingM2AdditionalTimeDescription: action.value,
      };
    case TOGGLE_M2_INTERVIEW_SCREEN_INSTRUCTIONS:
      return Object.assign({}, state, {
        hasExpandedInstructions: !state.hasExpandedInstructions,
      });
    case TOGGLE_M2_INTERVIEW_SCREEN_CONFIRM_DIALOG:
      return Object.assign({}, state, {
        showConfirmDialog: !state.showConfirmDialog,
      });
    case TOGGLE_M2_INTERVIEW_SCREEN_SUBMITTED_DIALOG:
      return Object.assign({}, state, {
        showSubmittedDialog: !state.showSubmittedDialog,
      });
    case SET_M2_INTERVIEW_SCREEN_INSTRUCTIONS_INDEX:
      return Object.assign({}, state, {
        instructionsIndex: action.value,
      });
    case SET_M2_RUN_SH_FILE_NAME:
      return Object.assign({}, state, {
        runShFileName: action.value,
      });
    case SET_M2_PROJECT_DIRECTORY:
      return Object.assign({}, state, {
        projectDirectory: action.value,
      });
    case SET_M2_PROJECT_NAME:
      return Object.assign({}, state, {
        projectName: action.value,
      });
    case IS_UPDATING_M2_TYPE:
      return {
        ...state,
        isUpdatingM2Type: action.value,
      };
    case SET_HAS_MODIFIED_M2_CODE:
      return {
        ...state,
        hasModifiedM2Code: action.value,
        hasModifiedM2CodeLoaded: true,
        zipDownloadType: action.value ? "modified" : "base",
      };
    case SET_ZIP_DOWNLOAD_TYPE:
      return {
        ...state,
        zipDownloadType: action.value,
      };
    case SET_SWITCHED_TO_CLOUDSHELL_FROM_ZIP:
      return {
        ...state,
        switchedToCloudshellFromZip: true,
      };
    default:
      return state;
  }
}
