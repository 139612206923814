import bbIcon from "./img/bb-icon.png";
import bbLogo from "./img/bb-logo.png";
import bbLogoLocal from "./img/bb-logo-local.png";
import bbLogoMark from "./img/bb-logo-md.png";
import bbSpinnerLogo from "./img/bb-logo-md.png";
import {getItem, setItem} from "./util/localStorage";

type Environment = "default" | "local";

export const primaryColor = {
  light: "#7f65ff",
  main: "#4038de",
  dark: "#000cab",
};

export const secondaryColors = {
  light: "#F8F5FF",
  main: "#EBE3FF",
  dark: "#DCCAFF",
};

interface BrandData {
  title: string;
  company: string;
  logo: string;
  logoMark: string;
  logoMarkDimensions: {
    height: number;
    width: number;
  };
  favicon: string;
  spinnerLogo?: string;
  primaryColor: {
    light: string;
    main: string;
    dark: string;
  };
}
interface EnvToBrand {
  default: BrandData;
  local: BrandData;
}

const mapEnvironmentToBrand: EnvToBrand = {
  default: {
    title: "Byteboard",
    company: "Byteboard",
    logo: bbLogo,
    logoMark: bbLogoMark,
    logoMarkDimensions: {
      height: 84,
      width: 62,
    },
    favicon: bbIcon,
    spinnerLogo: bbSpinnerLogo,
    /** Purple */
    primaryColor: {
      light: primaryColor.light,
      main: primaryColor.main,
      dark: primaryColor.dark,
    },
  },
  local: {
    title: "Byteboard Local",
    company: "Byteboard",
    logo: bbLogoLocal,
    logoMark: bbLogoMark,
    logoMarkDimensions: {
      height: 84,
      width: 62,
    },
    favicon: bbIcon,
    spinnerLogo: bbSpinnerLogo,
    /** Green */
    primaryColor: {
      light: primaryColor.light,
      main: primaryColor.main,
      dark: primaryColor.dark,
    },
  },
};

export function getEnvironment(): Environment {
  const envOverride = getItem("envOverride");
  if (envOverride) {
    return envOverride;
  }
  switch (location.host) {
    case "interview.localhost:1234":
      return "local";
    default:
      return "default";
  }
}

// @ts-ignore
window.setEnv = (env) => {
  setItem("envOverride", env);
};

export const brand = mapEnvironmentToBrand[getEnvironment()];
