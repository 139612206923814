let announcerEl: HTMLDivElement;

export function say(message: string) {
  const el = getAnnouncerEl();
  // Resets text content to force a DOM mutation (so that the setTextContent
  // post-timeout function will be noticed by the screen reader). This is to
  // avoid the problem of when the same message is "said" twice, which doesn't
  // trigger a DOM mutation.
  el.textContent = "";
  // Uses non-zero timer to make VoiceOver and NVDA work.
  setTimeout(() => {
    el.textContent = message;
  }, 1);
}

function getAnnouncerEl(): HTMLDivElement {
  if (!announcerEl) {
    announcerEl = document.createElement("div");
    announcerEl.style.position = "absolute";
    announcerEl.style.top = "-1000px";
    announcerEl.style.height = "1px";
    announcerEl.style.overflow = "hidden";
    announcerEl.setAttribute("aria-live", "polite");
    announcerEl.setAttribute("aria-atomic", "true");
    document.body.appendChild(announcerEl);
  }
  return announcerEl;
}
