import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import FormField, {FormFieldType} from "../components/FormField";
import RemoveExperienceModal from "../components/RemoveExperienceModal";
import ReturnToFinalReviewButton from "../components/ReturnToFinalReviewButton";
import Skills from "../components/Skills";
import {ApplicantContext} from "../context/ApplicantContext";
import {getNextUuid, getPrevUuid} from "../helpers/entries";
import {Education} from "../helpers/types";
import useRemoveExperienceModalState from "../hooks/useRemoveExperienceModalState";
import {useSharedStyles} from "../hooks/useSharedStyles";

const useStyles = makeStyles((theme) => ({
  conatinerBox: {
    display: "grid",
    gridAutoFlow: "row",
    rowGap: "24px",
  },
  formBox: {
    columnGap: "24px",
    display: "grid",
    gridTemplateAreas: `
      "degreeType"
      "degreeLevel"
      "institution"
      "completionDate"
      "present"
      "description"
    `,
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateAreas: `
        "degreeType degreeType"
        "degreeLevel institution"
        "completionDate present"
        "description description"
      `,
      gridTemplateColumns: "1fr 1fr",
    },
    rowGap: "16px",
  },
}));

export default function ValidateEducation() {
  const {uiState, education, setUIState, setEducation, hasReachedFinalReview} =
    useContext(ApplicantContext);
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  // There should always be a uuid in the uiState, but we need to check for TS
  const currentUuid = "uuid" in uiState ? uiState.uuid : undefined;
  const eduIdx = education.findIndex((e) => e.uuid === currentUuid);
  const edu = eduIdx > -1 ? education[eduIdx] : null;

  const [removeModalOpen, setRemoveModalOpen] =
    useRemoveExperienceModalState(currentUuid);

  const onNext = () => {
    const nextUuid = getNextUuid(education, currentUuid);
    if (nextUuid) {
      setUIState({step: "validateEducation", uuid: nextUuid});
    } else {
      setUIState({step: "educationEnd"});
    }
  };

  const onBack = () => {
    const prevUuid = getPrevUuid(education, currentUuid);
    if (prevUuid) {
      setUIState({step: "validateEducation", uuid: prevUuid});
    } else {
      setUIState({step: "rolesEnd"});
    }
  };

  const onRemove = () => {
    setEducation((oldState) => oldState.filter((e) => e.uuid !== currentUuid));
    if (hasReachedFinalReview) {
      setUIState({step: "finalReview"});
    } else {
      onNext();
    }
  };

  const handleUpdateEducation = <K extends keyof Education>(
    field: K,
    value: Education[K],
  ) => {
    setEducation((oldState) =>
      oldState.map((e) => {
        if (e.uuid === currentUuid) {
          return {
            ...e,
            [field]: value,
          };
        }
        return e;
      }),
    );
  };

  if (!edu) return null;

  const formFields: Record<keyof Omit<Education, "uuid">, FormFieldType> = {
    degreeOrCertificateName: {
      value: edu.degreeOrCertificateName,
      label: "Degree Type",
      onChange: (value) =>
        handleUpdateEducation("degreeOrCertificateName", value),
      error: !edu.degreeOrCertificateName,
      required: true,
    },
    degreeOrCertificateLevel: {
      value: edu.degreeOrCertificateLevel,
      label: "Degree Level",
      onChange: (value) =>
        handleUpdateEducation("degreeOrCertificateLevel", value),
      error: !edu.degreeOrCertificateLevel,
      required: true,
    },
    institution: {
      value: edu.institution,
      label: "Institution",
      onChange: (value) => handleUpdateEducation("institution", value),
      error: !edu.institution,
      required: true,
    },
    completionDate: {
      value:
        edu.completionDate && moment(edu.completionDate).format("YYYY-MM-DD"),
      label: "Completion Date",
      onChange: (value) => {
        const newCompletionDateMoment = moment(value);
        handleUpdateEducation(
          "completionDate",
          newCompletionDateMoment.isValid()
            ? newCompletionDateMoment.toISOString()
            : "",
        );
      },
      error: edu.completionDate !== "" && !moment(edu.completionDate).isValid(),
      disabled: edu.completionDate === "",
    },
  };

  const formIsValid = Object.values(formFields).every((field) => !field.error);

  return (
    <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
      <Box display="grid" gridRowGap={8}>
        <Typography color="primary" variant="h2">
          Education
        </Typography>
        <Typography variant="caption">
          {`${eduIdx + 1} out of ${education.length}`}
        </Typography>
      </Box>
      <Box className={classes.formBox}>
        <FormField
          {...formFields.degreeOrCertificateName}
          TextFieldProps={{style: {gridArea: "degreeType"}}}
        />
        <FormField
          {...formFields.degreeOrCertificateLevel}
          TextFieldProps={{style: {gridArea: "degreeLevel"}}}
        />
        <FormField
          {...formFields.institution}
          TextFieldProps={{style: {gridArea: "institution"}}}
        />
        <FormField
          {...formFields.completionDate}
          TextFieldProps={{
            InputLabelProps: {shrink: true},
            inputProps: {max: moment().format("YYYY-MM-DD")},
            style: {gridArea: "completionDate"},
            type: "date",
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={edu.completionDate === ""}
              onChange={(e) =>
                handleUpdateEducation(
                  "completionDate",
                  e.target.checked ? "" : moment().toISOString(),
                )
              }
            />
          }
          label="I am currently completing this degree"
          style={{gridArea: "present"}}
        />
      </Box>
      <Skills />
      <ActionsWrapper>
        <Button
          className={clsx(
            sharedClasses.marginRightAuto,
            sharedClasses.outlinedButtonWarningColor,
          )}
          onClick={() => setRemoveModalOpen(true)}
          variant="outlined"
        >
          Remove experience
        </Button>
        {hasReachedFinalReview ? (
          <ReturnToFinalReviewButton disabled={!formIsValid} />
        ) : (
          <>
            <Button onClick={onBack}>Back</Button>
            <Button
              color="primary"
              disabled={!formIsValid}
              onClick={onNext}
              variant="contained"
            >
              Next
            </Button>
          </>
        )}
      </ActionsWrapper>
      <RemoveExperienceModal
        onCancel={() => setRemoveModalOpen(false)}
        onRemove={onRemove}
        open={removeModalOpen}
      />
    </ApplicantPaper>
  );
}
