import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, {useContext} from "react";
import ActionsWrapper from "../components/ActionsWrapper";
import ApplicantPaper from "../components/ApplicantPaper";
import ExperienceAddButton from "../components/ExperienceAddButton";
import {ApplicantContext} from "../context/ApplicantContext";
import {getNextUuid, getPrevUuid} from "../helpers/entries";
import {useSharedStyles} from "../hooks/useSharedStyles";

export default function EndEducation() {
  const {education, projects, setUIState} = useContext(ApplicantContext);
  const sharedClasses = useSharedStyles();

  const onProceed = () => {
    const nextUuid = getNextUuid(projects);
    if (nextUuid) {
      setUIState({step: "validateProject", uuid: nextUuid});
    } else {
      setUIState({step: "projectsEnd"});
    }
  };

  const onBack = () => {
    const prevUuid = getPrevUuid(education);
    if (prevUuid) {
      setUIState({step: "validateEducation", uuid: prevUuid});
    } else {
      setUIState({step: "rolesEnd"});
    }
  };

  return (
    <ApplicantPaper className={sharedClasses.gridBoxRowGap24}>
      <Typography color="primary" variant="h2">
        Education
      </Typography>
      {education.length ? (
        <Typography variant="body1">
          You have completed the validation for your education entries! You can
          now add any entries we might have missed. Otherwise, please continue
          on with validating your project entries.
        </Typography>
      ) : (
        <Typography variant="body1">
          There are currently no education entries associated with your
          portfolio. Feel free to add any that we might have missed. Otherwise,
          please continue on with validating your project entries.
        </Typography>
      )}
      <ActionsWrapper>
        <ExperienceAddButton category="education" />
        <Button onClick={onBack}>Back</Button>
        <Button color="primary" onClick={onProceed} variant="contained">
          Proceed
        </Button>
      </ActionsWrapper>
    </ApplicantPaper>
  );
}
