import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import {
  StyledComponentProps,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import type {Theme} from "@material-ui/core/styles/createTheme";
import CloseIcon from "@material-ui/icons/Close";
import autobind from "autobind-decorator";
import * as React from "react";
import {findDOMNode} from "react-dom";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import * as getStartedScreenActions from "../actions/getStartedScreenActions";
import {showHelpDialog} from "../actions/helpDialogActions";
import {brand} from "../branding";
import Accordion from "../components/Accordion";
import CenteredScreenContent from "../components/CenteredScreenContent";
import {RedirectRule, withInterview} from "../components/InterviewLoader";
import Select from "../components/Select";
import {languageNameMap, languageNameMapWithVersions} from "../config.js";
import {
  canSwitchM2Type,
  getDisplayInterviewType,
  hasM2CloudShellOption,
  hasM2ZipOption,
  isInterviewGenerated,
  isWebInterview,
} from "../helpers/interview";
import {overviewUrl, testerUrl} from "../helpers/urls/routerUrls";
import {
  AppState,
  Flags,
  GetStartedScreenState,
  Interview,
  Language,
  M2Type,
  ThunkDispatch,
} from "../reducers/types";
import {say} from "../util/announcer";
import {getBrowserName, isChromeBrowser} from "../util/browser";
import {msToMin} from "../util/time";

const {
  setOverview,
  setLanguage,
  setM2Type,
  submit,
  toggleExpandOverviewStep,
  toggleExpandLanguageStep,
  toggleExpandM2TypeStep,
  toggleCompletedStep,
  toggleDocsWalkthroughDialog,
  OVERVIEW_STEP,
  LANGUAGE_STEP,
  M2_TYPE_STEP,
} = getStartedScreenActions;

const initialExpandDelay = 200;

interface Props extends StyledComponentProps, RouteComponentProps {
  atsId: string;
  flags: Flags;
  interview: Interview;
  getStartedScreen: GetStartedScreenState;
  setOverview: () => any;
  setLanguage: (language: Language) => any;
  setM2Type: (value: M2Type | "") => any;
  submit: (atsId: string, language?: Language, m2Type?: M2Type) => any;
  toggleExpandOverviewStep: (value?: boolean) => any;
  toggleExpandLanguageStep: () => any;
  toggleExpandM2TypeStep: () => any;
  toggleCompletedStep: (value: number) => void;
  toggleDocsWalkthroughDialog: () => void;
  showHelpDialog: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    screen: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 600,
      padding: "50px 20px 40px 20px",
    },
    codingLanguage: {
      alignItems: "center",
      display: "flex",
      marginBottom: 10,
    },
    codingLanguageSelect: {
      marginLeft: 20,
    },
    radioForm: {
      marginLeft: 40,
    },
    continueContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 40,
    },
    dialogVideoHeader: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "5px 5px 0 5px",
    },
    dialogVideoContainer: {
      padding: "5px 10px 10px 10px",
    },
  });

class GetStartedScreen extends React.Component<Props> {
  languageSelectEl?: HTMLInputElement;
  initialExpandTimeoutId: any;
  panelRefs: React.RefObject<HTMLDivElement>[] = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  componentDidMount() {
    this.expandOverviewStep();
    if (!isChromeBrowser()) {
      say(
        "If you plan to take the interview using a screen reader, we " +
          "recommend you use the Google Chrome browser.",
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.getStartedScreen.expandedSteps.includes(LANGUAGE_STEP) &&
      this.props.getStartedScreen.expandedSteps.includes(LANGUAGE_STEP) &&
      this.languageSelectEl
    ) {
      this.languageSelectEl.focus();
    }
    if (
      !prevProps.getStartedScreen.expandedSteps.includes(M2_TYPE_STEP) &&
      this.props.getStartedScreen.expandedSteps.includes(M2_TYPE_STEP)
    ) {
      say(
        "If you plan to take the interview using a screen reader, we " +
          "recommend you use your personal IDE for the coding section.",
      );
    }
    if (
      this.props.getStartedScreen.m2Type === "cloudshell" &&
      !hasM2CloudShellOption(this.props.interview, this.props.flags)
    ) {
      this.props.setM2Type("");
      this.props.toggleCompletedStep(M2_TYPE_STEP);
      this.props.toggleExpandM2TypeStep();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.initialExpandTimeoutId);
  }

  getM2Type(): M2Type | "" {
    const {getStartedScreen, flags} = this.props;
    if (!flags.cloudshellEnabled) {
      return "zip";
    }
    return getStartedScreen.m2Type;
  }

  getPersonalIDEOptionLabel(): string {
    return (
      "My personal IDE: This will require you to set up your IDE " +
      "environment, download the code onto your machine, and upload the " +
      "code using a zip file."
    );
  }

  getOnlineEditorOptionLabel(): string {
    const label =
      "Google Cloud Shell: Byteboard offers an integration with Google " +
      "Cloud Shell, a browser based editor that will allow you to download " +
      "the code, work directly in your browser, and will auto-submit the " +
      "code for you.";
    return label + this.getOnlineEditorOptionDescription();
  }

  getOnlineEditorOptionDescription(): string {
    const {interview, flags, getStartedScreen} = this.props;
    let description;
    if (hasM2CloudShellOption(interview, flags)) {
      description = "";
    } else if (
      hasM2CloudShellOption(interview, flags, {ignoreUserBrowserType: true})
    ) {
      // Google Cloud Shell is not available; however, if the candidate switches
      // to a different browser then it will be available.
      const browserCapitalized =
        getBrowserName()[0].toUpperCase() + getBrowserName().slice(1);
      description =
        `Not available in ${browserCapitalized}, please switch ` +
        "to Google Chrome if you would like to use the online " +
        "editor";
    } else {
      description =
        "Not available for " +
        (languageNameMap as any)[getStartedScreen.language];
    }
    return description ? ` (${description})` : "";
  }

  getM2TypeDescription(): string {
    const {getStartedScreen} = this.props;
    if (getStartedScreen.m2Type === "cloudshell") {
      return "Google Cloud Shell";
    } else {
      return "your personal IDE";
    }
  }

  expandOverviewStep() {
    this.initialExpandTimeoutId = setTimeout(() => {
      this.props.toggleExpandOverviewStep(true);
      const panelEl = findDOMNode(this.panelRefs[0].current) as Element;
      const panelButtonEl = panelEl.querySelector(
        '[role="button"]',
      ) as HTMLButtonElement;
      panelButtonEl.focus();
    }, initialExpandDelay);
  }

  isOverviewCompleted(): boolean {
    return this.props.getStartedScreen.completedSteps.includes(OVERVIEW_STEP);
  }

  canProceed(): boolean {
    const {getStartedScreen, interview} = this.props;
    return (
      Boolean(getStartedScreen.language) &&
      getStartedScreen.language !== "none" &&
      Boolean(this.getM2Type())
    );
  }

  showIdeOption(): boolean {
    const {flags, interview} = this.props;
    return hasM2ZipOption(interview) || hasM2CloudShellOption(interview, flags);
  }

  @autobind
  onLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const language = event.target.value as Language;
    this.props.setLanguage(language);
  }

  @autobind
  onIDEChange(event: React.ChangeEvent<HTMLInputElement>) {
    const m2Type = event.target.value as M2Type;
    this.props.setM2Type(m2Type);
  }
  @autobind
  onCloseDocsWalkthrough() {
    this.props.toggleDocsWalkthroughDialog();
  }

  @autobind
  async onNext() {
    const {atsId, getStartedScreen} = this.props;
    const {language} = getStartedScreen;
    const m2Type = this.getM2Type() as M2Type;
    // The atsId might be different than the one currently in the URL.
    const nextAtsId = atsId;
    this.props.history.push(testerUrl(nextAtsId, m2Type, language as Language));
  }

  @autobind
  showHelpDialog() {
    this.props.showHelpDialog();
  }

  renderDocsWalkthroughDialog(): React.ReactNode {
    const {getStartedScreen, classes} = this.props;
    const src =
      "https://drive.google.com/file/d/1LMxAREUf78fjBF0zS-RxE8cCAN-FIlyF/preview";
    return (
      <Dialog
        open={getStartedScreen.showDocsWalkthroughDialog}
        maxWidth="md"
        onClose={this.onCloseDocsWalkthrough}
      >
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.dialogVideoHeader}>
          <IconButton onClick={this.onCloseDocsWalkthrough}>
            <CloseIcon />
          </IconButton>
        </div>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.dialogVideoContainer}>
          <iframe
            src={src}
            frameBorder={0}
            width="800"
            height="480"
            allowFullScreen
          />
        </div>
      </Dialog>
    );
  }

  renderOverviewPanelDetails(): React.ReactNode {
    const {interview} = this.props;
    // @ts-ignore FIXME: strictNullChecks
    const m1Min = msToMin(interview.m1Duration);
    // @ts-ignore FIXME: strictNullChecks
    const m2Min = msToMin(interview.m2Duration);
    // @ts-ignore FIXME: strictNullChecks
    const breakMin = msToMin(interview.breakDuration);
    const totalMin = m1Min + m2Min + breakMin;
    return (
      <React.Fragment>
        <Typography paragraph={true}>
          This is a {totalMin} minute interview split into two parts that must
          be completed in one continuous block of time. You will have a{" "}
          {breakMin} minute break between Part 1 and Part 2. Significantly
          delaying Part 2 may result in your interview being marked as
          incomplete.
        </Typography>
        <Typography variant="h4" component="h3">
          Part 1: Technical Reasoning Exercise ({m1Min} minutes)
        </Typography>
        <Typography paragraph={true}>
          You will work on a document outlining potential implementation
          decisions for a product. You will be tasked with completing the
          document by filling in unfinished sections and answering any questions
          left by other members of the product team.
        </Typography>
        <Typography variant="h4" component="h3">
          Part 2: Code Implementation Exercise ({m2Min} minutes)
        </Typography>
        <Typography>
          You will implement a few aspects of the product described in Part 1.
          You will need to navigate a small multi-file codebase and write clean
          and functional code.
        </Typography>
      </React.Fragment>
    );
  }

  renderOverviewPanel(): React.ReactNode {
    const {getStartedScreen} = this.props;
    return (
      <Accordion
        number={1}
        ref={this.panelRefs[0]}
        completed={this.isOverviewCompleted()}
        heading={
          this.isOverviewCompleted()
            ? `You reviewed the ${brand.company} interview overview`
            : `Review ${brand.company}’s interview overview`
        }
        details={() => (
          <div>
            {this.renderOverviewPanelDetails()}
            <div style={{height: 32}} />
            <Button
              color="primary"
              aria-required={true}
              onClick={() => this.props.setOverview()}
              variant="contained"
            >
              I got it!
            </Button>
            {this.renderDocsWalkthroughDialog()}
          </div>
        )}
        onChange={() => this.props.toggleExpandOverviewStep()}
        expandable={this.isOverviewCompleted()}
        expanded={getStartedScreen.expandedSteps.includes(OVERVIEW_STEP)}
      />
    );
  }

  renderLanguagePanel(): React.ReactNode {
    const {classes, getStartedScreen, interview} = this.props;
    const languageName = (languageNameMapWithVersions as any)[
      getStartedScreen.language
    ];
    const isLanguageCompleted =
      getStartedScreen.completedSteps.includes(LANGUAGE_STEP);
    return (
      <Accordion
        number={2}
        ref={this.panelRefs[1]}
        completed={isLanguageCompleted}
        heading={
          isLanguageCompleted
            ? `You chose ${languageName}`
            : "Choose your coding language"
        }
        details={() => (
          <>
            <Typography paragraph={true}>
              You will be working on a project in a multi-file system. Choose
              the language you are most comfortable reading and writing code in.
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <div className={classes.codingLanguage}>
              <Typography>Select your coding language</Typography>
              {this.renderLanguageSelect()}
            </div>
          </>
        )}
        onChange={() => this.props.toggleExpandLanguageStep()}
        expanded={getStartedScreen.expandedSteps.includes(LANGUAGE_STEP)}
        expandable={isLanguageCompleted}
      />
    );
  }

  renderLanguageSelect(): React.ReactNode {
    const {interview, classes, getStartedScreen} = this.props;
    const availableLanguages = (interview.languageOptions || [])
      .map((language) => ({
        value: language,
        label: (languageNameMapWithVersions as any)[language],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (isWebInterview(interview)) {
      languageNameMapWithVersions.js = languageNameMapWithVersions.js_web;
      languageNameMapWithVersions.ts = languageNameMapWithVersions.ts_web;
    }

    return (
      <Select
        onChange={this.onLanguageChange}
        // @ts-ignore FIXME: strictNullChecks
        className={classes.codingLanguageSelect}
        value={getStartedScreen.language}
        options={availableLanguages}
        ariaLabel="Select your coding language"
        name="language"
        inputRef={(ref) => (this.languageSelectEl = ref)}
      />
    );
  }

  renderM2TypePanel(): React.ReactNode {
    const {classes, getStartedScreen, interview, flags} = this.props;
    const isM2TypeCompleted =
      getStartedScreen.completedSteps.includes(M2_TYPE_STEP);
    if (!this.showIdeOption()) return null;
    return (
      <Accordion
        number={3}
        ref={this.panelRefs[2]}
        completed={isM2TypeCompleted}
        heading={
          isM2TypeCompleted
            ? `You chose to use ${this.getM2TypeDescription()}`
            : "Choose your coding environment"
        }
        details={() => (
          <div>
            <Typography style={{marginBottom: 5}}>
              I would like to do the coding section of the interview using:
            </Typography>
            {/* @ts-ignore FIXME: strictNullChecks*/}
            <FormControl className={classes.radioForm}>
              <RadioGroup
                name="m2Type"
                aria-label="Coding environment"
                value={this.getM2Type()}
                onChange={this.onIDEChange}
              >
                <FormControlLabel
                  value="zip"
                  control={<Radio inputProps={{["aria-label"]: "zip"}} />}
                  style={{marginBottom: 10}}
                  label={this.getPersonalIDEOptionLabel()}
                />
                <FormControlLabel
                  value="cloudshell"
                  disabled={!hasM2CloudShellOption(interview, flags)}
                  control={
                    <Radio
                      inputProps={{
                        ["aria-label"]: "cloud shell",
                      }}
                    />
                  }
                  label={this.getOnlineEditorOptionLabel()}
                />
              </RadioGroup>
            </FormControl>
            <br />
            <br />
            <Typography>
              You will have the chance to test your chosen coding environment
              before you start your interview.
            </Typography>
            {canSwitchM2Type(interview, flags) ? (
              <Typography>
                You will be able to switch environments before and during the
                interview.
              </Typography>
            ) : null}
          </div>
        )}
        onChange={() => this.props.toggleExpandM2TypeStep()}
        expanded={getStartedScreen.expandedSteps.includes(M2_TYPE_STEP)}
        expandable={isM2TypeCompleted}
      />
    );
  }

  render(): React.ReactNode {
    const {classes, getStartedScreen, interview} = this.props;
    const nextButtonText = getStartedScreen.isSubmitting
      ? "Loading"
      : "Continue";
    const interviewTypeDisplay = getDisplayInterviewType(interview);
    return (
      <CenteredScreenContent size="tall">
        <Typography variant="h2">Let's get you set up!</Typography>
        <Typography paragraph={true}>
          Before you start your {interviewTypeDisplay} interview, we'll walk you
          through setting up and testing your environment.
        </Typography>
        {this.renderOverviewPanel()}
        {this.renderLanguagePanel()}
        {this.renderM2TypePanel()}
        {this.canProceed() ? (
          // @ts-ignore FIXME: strictNullChecks
          <div className={classes.continueContainer}>
            <Button
              color="primary"
              disabled={getStartedScreen.isSubmitting}
              onClick={this.onNext}
              variant="contained"
            >
              {nextButtonText}
            </Button>
          </div>
        ) : null}
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState): Partial<Props> => ({
  flags: state.flags,
  getStartedScreen: state.getStartedScreenState,
  interview: state.interview,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): Partial<Props> => ({
  setOverview: () => dispatch(setOverview()),
  setLanguage: (language: Language) => dispatch(setLanguage(language)),
  setM2Type: (value: M2Type | "") => dispatch(setM2Type(value)),
  submit: (atsId: string, language: Language, m2Type: M2Type) => {
    return dispatch(submit(atsId, language, m2Type));
  },
  toggleExpandOverviewStep: (value?: boolean) => {
    return dispatch(toggleExpandOverviewStep(value));
  },
  toggleExpandLanguageStep: () => dispatch(toggleExpandLanguageStep()),
  toggleExpandM2TypeStep: () => dispatch(toggleExpandM2TypeStep()),
  toggleCompletedStep: (value: number) => dispatch(toggleCompletedStep(value)),
  toggleDocsWalkthroughDialog: () => dispatch(toggleDocsWalkthroughDialog()),
  showHelpDialog: () => dispatch(showHelpDialog()),
});

const redirectRules: RedirectRule[] = [
  {
    match: (interview: Interview) => isInterviewGenerated(interview),
    redirect: (interview) => overviewUrl(interview.id),
  },
];

const redirectOptions = {
  ignoreGlobalRules: ["getStarted"],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(styles)(
    withInterview(redirectRules, redirectOptions)(withRouter(GetStartedScreen)),
  ),
);
