import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import React from "react";
import {ExpereienceCategory} from "../helpers/types";
import useAddExperience from "../hooks/useAddExperience";
import {useSharedStyles} from "../hooks/useSharedStyles";

type Props = {
  category: ExpereienceCategory;
};

export default function ExperienceAddButton({category}: Props) {
  const {handleAddRole, handleAddEducation, handleAddProject} =
    useAddExperience();
  const sharedClasses = useSharedStyles();

  const handleAddExperience =
    category === "role"
      ? handleAddRole
      : category === "education"
      ? handleAddEducation
      : handleAddProject;

  return (
    <Button
      className={sharedClasses.marginRightAuto}
      color="primary"
      onClick={handleAddExperience}
      startIcon={<Add />}
      variant="outlined"
    >
      {`Add ${category}`}
    </Button>
  );
}
