import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState, Interview, User} from "../reducers/types";

import {fetchInterview, streamCandidate} from "../actions/interviewActions";

export function useInterview(atsId: string, user: User): Interview {
  const dispatch = useDispatch();
  const interview = useSelector((state: AppState) => state.interview);

  useEffect(() => {
    if (!interview) {
      dispatch(fetchInterview(atsId, user));
      // Don't stream the candidate in testing environments.
      if (!(window as any).disableCandidateStreamForTests) {
        dispatch(streamCandidate(atsId));
      }
    }
  }, [atsId, user]);

  return interview;
}
