import * as React from "react";
import Spinner from "../components/Spinner";
import {AppState, ThunkDispatch, User} from "../reducers/types";
import {Interview} from "../reducers/types";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {fetchInterview, streamCandidate} from "../actions/interviewActions";
import {getServerTime as getServerTimeFn} from "../util/time";
import {isInterviewGenerated} from "../helpers/interview";
import {termsUrl, getStartedUrl} from "../helpers/urls/routerUrls";

interface Props {
  fetchInterview: (atsId: string, user: User) => any;
  streamCandidate: (atsId: string) => any;
  getServerTime: () => number;
  interview?: Interview;
  atsId: string;
  user: User;
}

export interface RedirectRule {
  /** Function that determines if the interview matches this redirect rule.  */
  match: (interview: Interview, serverTime: number) => boolean;

  /** Path to redirect to. */
  redirect: (interview: Interview) => string;
}

export interface RedirectOptions {
  /** Names of the global redirect rules to ignore. */
  ignoreGlobalRules?: string[];
}

const globalRedirectRules: Map<string, RedirectRule> = new Map([
  [
    "terms",
    {
      match: (interview: Interview) => !interview.terms,
      redirect: (interview: Interview) => termsUrl(interview.id),
    },
  ],
  [
    "getStarted",
    {
      match: (interview: Interview) => !isInterviewGenerated(interview),
      redirect: (interview: Interview) => getStartedUrl(interview.id),
    },
  ],
]);

/** Loads the `interview` object before the wrapped component is rendered. */
export function withInterview(
  redirectRules: RedirectRule[],
  options: RedirectOptions = {},
) {
  return (WrappedComponent: React.ComponentType): React.ComponentType<any> => {
    class WithInterviewComponent extends React.Component<Props> {
      static displayName = `WithInterview(${getDisplayName(WrappedComponent)})`;

      componentDidMount() {
        const {interview, atsId, user} = this.props;
        if (!interview) {
          this.props.fetchInterview(atsId, user);
          // Don't stream the candidate in testing environments.
          if (!(window as any).disableCandidateStreamForTests) {
            this.props.streamCandidate(atsId);
          }
        }
      }

      getRedirectUrl(): string {
        const {interview, getServerTime} = this.props;
        const ignoreGlobalRules = options.ignoreGlobalRules || [];
        const globalRules: RedirectRule[] = Array.from(globalRedirectRules)
          .filter(([name, redirectRule]) => !ignoreGlobalRules.includes(name))
          .map(([name, redirectRule]) => redirectRule);
        const rules: RedirectRule[] = [...globalRules, ...redirectRules];
        const redirectRuleMatch = rules.find((rule: RedirectRule) => {
          // @ts-ignore FIXME: strictNullChecks
          return rule.match(interview, getServerTime());
        });
        const redirectUrl = redirectRuleMatch
          ? // @ts-ignore FIXME: strictNullChecks
            redirectRuleMatch.redirect(interview)
          : "";
        if (redirectUrl) {
          console.log(`Redirecting to ${redirectUrl}`);
        }
        return redirectUrl;
      }

      render() {
        const {interview} = this.props;
        const redirectUrl = interview ? this.getRedirectUrl() : "";
        if (interview) {
          if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
          }
          return <WrappedComponent {...this.props} />;
        }
        return <Spinner />;
      }
    }
    return connect(
      (state: AppState) => ({
        interview: state.interview,
        user: state.user,
        getServerTime: () => getServerTimeFn(state.serverInfo.serverTimeOffset),
      }),
      (dispatch: ThunkDispatch) => ({
        fetchInterview: (atsId: string, user: User) => {
          return dispatch(fetchInterview(atsId, user));
        },
        streamCandidate: (atsId: string) => dispatch(streamCandidate(atsId)),
      }),
    )(WithInterviewComponent);
  };
}

function getDisplayName(WrappedComponent: React.ComponentType) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
