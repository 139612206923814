import {v4 as uuidv4} from "uuid";
import {convertFirestoreTimestampToDate} from "../../../helpers/firestore";
import {
  Education,
  Portfolio,
  Project,
  ResponsePortfolio,
  Role,
  Skill,
} from "./types";

// getNextUuid and getPrevUuid help us navigate between experiences in the collection.
// They return the uuid of the next or previous experience in the collection based on the
// provided currentUuid. If the currentUuid is not provided or found within the collection,
// it returns the first or last uuid in the collection. If the next or previous experiences
// do not exist, they return null.

export function getNextUuid(
  collection: Role[] | Education[] | Project[],
  currentUuid?: string,
): string | null {
  const currentIndex = collection.findIndex(
    (experience) => experience.uuid === currentUuid,
  );
  if (collection.length > 0 && currentIndex === -1) {
    return collection[0]?.uuid ?? null;
  }
  return collection[currentIndex + 1]?.uuid ?? null;
}

export function getPrevUuid(
  collection: Role[] | Education[] | Project[],
  currentUuid?: string,
): string | null {
  const currentIndex = collection.findIndex(
    (experience) => experience.uuid === currentUuid,
  );
  if (collection.length > 0 && currentIndex === -1) {
    return collection[collection.length - 1]?.uuid ?? null;
  }
  return collection[currentIndex - 1]?.uuid ?? null;
}

// Since it's easier to deal with empty strings than undefined values in input fields,
// we normalize experience objects to have empty strings instead of undefined values.

export function normalizeRole(
  role: ResponsePortfolio["roles"][number],
): Required<Role> {
  const {startDate, endDate, ...restOfRole} = role;
  return {
    ...generateRoleTemplate(),
    ...restOfRole,
    startDate: convertFirestoreTimestampToDate(startDate).toISOString(),
    endDate: endDate
      ? convertFirestoreTimestampToDate(endDate).toISOString()
      : "",
  };
}

export function generateRoleTemplate(): Required<Role> {
  const uuid = uuidv4();
  return {
    uuid,
    title: "",
    organization: "",
    location: "",
    startDate: "",
    endDate: "",
    accomplishmentsAndResponsibilities: "",
  };
}

export function normalizeEducation(
  education: ResponsePortfolio["education"][number],
): Required<Education> {
  const {completionDate, ...restOfEducation} = education;
  return {
    ...generateEducationTemplate(),
    ...restOfEducation,
    completionDate: completionDate
      ? convertFirestoreTimestampToDate(completionDate).toISOString()
      : "",
  };
}

export function generateEducationTemplate(): Required<Education> {
  const uuid = uuidv4();
  return {
    uuid,
    degreeOrCertificateName: "",
    degreeOrCertificateLevel: "",
    institution: "",
    completionDate: "",
  };
}

export function normalizeProject(
  project: ResponsePortfolio["projects"][number],
): Required<Project> {
  const {startDate, endDate, ...restOfProject} = project;
  return {
    ...generateProjectTemplate(),
    ...restOfProject,
    startDate: startDate
      ? convertFirestoreTimestampToDate(startDate).toISOString()
      : "",
    endDate: endDate
      ? convertFirestoreTimestampToDate(endDate).toISOString()
      : "",
  };
}

export function generateProjectTemplate(): Required<Project> {
  const uuid = uuidv4();
  return {
    uuid,
    title: "",
    startDate: "",
    endDate: "",
    accomplishmentsAndResponsibilities: "",
  };
}

/**
 * This doesn't do any validation, it just removes empty fields.
 */
export function createValidatedPortfolio({
  bio,
  roles,
  education,
  projects,
  skills,
}: {
  bio: ResponsePortfolio["bio"];
  roles: Required<Role>[];
  education: Required<Education>[];
  projects: Required<Project>[];
  skills: Skill[];
}): Portfolio {
  return {
    bio,
    roles: roles.map(removeEmptyFields),
    education: education.map(removeEmptyFields),
    projects: projects.map(removeEmptyFields),
    skills,
  };
}

function removeEmptyFields<T extends Record<string, unknown>>(object: T): T {
  const entries = Object.entries(object);
  const filteredEntries = entries.filter(([_key, value]) => value !== "");
  return Object.fromEntries(filteredEntries) as T;
}
