import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import {useStyles} from "./InterviewHubScreen.styles";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import codeTogetherSidePanel from "../../img/code-together-side-panel.png";
import codeTogetherCursors from "../../img/code-together-cursors.png";
import cloudRunTerminal from "../../img/cloud-run-terminal.png";
import Container from "@material-ui/core/Container";

export const LiveCodingInterviewHubExpectations = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography>
        The interview is a live coding interview that will take place in a
        browser-based IDE similar to Visual Studio Code that contains a
        multi-file codebase. The interview is intended to be collaborative and
        your interviewer will be able to modify the code and see your edits in
        realtime during the interview.
      </Typography>
      <Container className={classes.codeTogetherInstructionsContainer}>
        <Typography className={classes.codeTogetherInstructions}>
          Each participant's cursor is represented by a little rectangle with
          their first initial in it. The IDE is configured to dynamically switch
          drivers of the session such that participants are set to automatically
          follow whoever is editing.
        </Typography>
        <img
          src={codeTogetherCursors}
          alt="IDE Side Panel"
          className={classes.codeTogetherSidePanelIcon}
        />
      </Container>
      <Container className={classes.codeTogetherInstructionsContainer}>
        <Typography className={classes.codeTogetherInstructions}>
          At the side of your IDE you can click the 'CodeTogether Live' icon to
          open a panel containing information about the participants of your
          coding session. From there you can force other participants to follow
          you as you navigate the codebase.{" "}
        </Typography>
        <img
          src={codeTogetherSidePanel}
          alt="IDE Side Panel"
          className={classes.codeTogetherSidePanelIcon}
        />
      </Container>
      <Container className={classes.codeTogetherInstructionsContainer}>
        <Typography className={classes.codeTogetherInstructions}>
          If you close the terminal, you can open it again by clicking Menu &gt;
          View &gt; Terminal.{" "}
        </Typography>
        <img
          src={cloudRunTerminal}
          alt="Open a terminal"
          className={classes.codeTogetherSidePanelIcon}
        />
      </Container>

      <Typography variant="h4">Instructions</Typography>
      <ol>
        <li>
          <strong>Read:</strong> Click through the files in the codebase and
          build an understanding of how the code fits together.
        </li>
        <li>
          <strong>Plan:</strong> Identify what algorithm / data structure you
          are going to use.
        </li>
        <li>
          <strong>Think out loud:</strong> Describe how you are approaching the
          problem and explain the solution that you have in mind. Ask for
          clarifications on questions and work with the interviewer to
          collaborate on the plan.
        </li>
        <li>
          <strong>Implement:</strong> Start with the simplest way to complete
          each task. Think through all edge cases and do your best to implement
          a complete solution.
        </li>
      </ol>
      <div
        className={classNames(
          classes.interviewSectionHeading,
          classes.interviewSectionSubHeading,
          classes.removeTypographyMargin,
        )}
      >
        <EmojiObjectsOutlinedIcon
          className={classNames(
            classes.interviewSectionIcon,
            classes.interviewSectionIconSubHeading,
          )}
        />
        <Typography variant="h4">
          Tips while communicating with your interviewer
        </Typography>
      </div>
      <ol>
        <li>
          Spend some time reading through the codebase and describe your mental
          model of how the code fits together.
        </li>
        <li>
          Run your code often to detect bugs early on. Call out the bugs you are
          seeing and describe how you would approach fixing them.
        </li>
        <li>Pay attention to edge cases.</li>
        <li>
          Always state your assumptions, questions, or thoughts in clear
          comments.
        </li>
        <li>
          If you do not finish all your tasks, or have additional ideas for
          optimizations, describe what you would do with more time.
        </li>
      </ol>
      <Typography>
        If you forget syntax or encounter a concept you are not familiar with,
        ask for help or look it up!
      </Typography>
      <div style={{height: 20}} />
    </React.Fragment>
  );
};
