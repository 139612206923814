import * as React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Link from "../components/Link";
import Typography from "@material-ui/core/Typography";
import UnorderedList from "../components/UnorderedList";
import autobind from "autobind-decorator";
import {AppState, AppError} from "../reducers/types";
import {HelpDialogAction} from "../actions/actionTypes";
import type {Theme} from "@material-ui/core/styles/createTheme";
import {ThunkDispatch} from "redux-thunk";
import {brand} from "../branding";
import {connect} from "react-redux";
import {createStyles} from "@material-ui/core/styles";
import {isChromeBrowser} from "../util/browser";
import {getSupportEmail} from "../actions/interviewActions";
import {showHelpDialog} from "../actions/helpDialogActions";
import {withStyles, StyledComponentProps} from "@material-ui/core/styles";

const genericTips: React.ReactNode[] = [
  <span>
    Third party cookies may be blocked in your browser. If you are using Google
    Chrome, open <strong>chrome://settings/cookies</strong> in another tab and
    select "Allow all cookies".
  </span>,
  <span>
    Some browser extensions may prevent the cookies we use to authenticate (i.e.
    Ad Blockers). Please disable your extensions, or try opening the interview
    in an incognito window.
    <br />
    <strong>Note: </strong> You will likely have to allow cookies separately in
    incognito mode.
  </span>,
  "Try reloading the page.",
  "If all of the above fails, try switching browsers. We support Google Chrome, Safari, or Firefox.",
];

interface Props extends StyledComponentProps {
  error: AppError;
  supportEmail: string;
  showHelpDialog: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logo: {
      flexShrink: 0,
      height: brand.logoMarkDimensions.height,
      width: brand.logoMarkDimensions.width,
    },
    error: {
      fontSize: 18,
      fontWeight: 300,
    },
    errorBold: {
      fontWeight: 400,
    },
    content: {
      marginLeft: 30,
    },
  });

class ErrorScreen extends React.Component<Props> {
  @autobind
  onSendMessage() {
    this.props.showHelpDialog();
  }

  renderMessage(): React.ReactNode {
    const {classes, error} = this.props;
    const messagePrefix = error.isNotification ? "" : "An error occurred: ";
    return (
      // @ts-ignore FIXME: strictNullChecks
      <Typography className={classes.error}>
        {messagePrefix}
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <span className={classes.errorBold}>{error.errorMessage}</span>
      </Typography>
    );
  }

  renderTips(): React.ReactNode {
    const {classes, error, supportEmail} = this.props;
    const tips: React.ReactNode[] = [...genericTips, ...error.tips].map(
      (tip, index) => <Typography key={index}>{tip}</Typography>,
    );
    if (error.hideTips) return null;
    return (
      <React.Fragment>
        <br />
        <br />
        <Typography paragraph={true}>
          The following may help fix the issue:
        </Typography>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <UnorderedList className={classes.list} items={tips} />
        <br />
        <Typography paragraph={true}>
          If the problem persists,{" "}
          <Link to={document.location} onClick={this.onSendMessage}>
            send us a message
          </Link>{" "}
          or send an email to {supportEmail} and a team member will assist you.
        </Typography>
      </React.Fragment>
    );
  }

  render(): React.ReactNode {
    const {classes} = this.props;
    return (
      <CenteredScreenContent>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <div className={classes.root} aria-live="assertive">
          <img
            src={brand.logoMark}
            // @ts-ignore FIXME: strictNullChecks
            className={classes.logo}
            aria-hidden={true}
          />
          {/* @ts-ignore FIXME: strictNullChecks*/}
          <div className={classes.content}>
            <Typography variant="h1">Oh no!</Typography>
            {this.renderMessage()}
            {this.renderTips()}
          </div>
        </div>
      </CenteredScreenContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error,
  supportEmail: getSupportEmail(state.interview),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, null, HelpDialogAction>,
) => ({
  showHelpDialog: () => dispatch(showHelpDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ErrorScreen));
