import {AppState} from "../reducers/types";
import {M1InterviewScreenAction, ErrorAction} from "./actionTypes";
import {ThunkDispatch, ThunkAction} from "redux-thunk";
import {m1OpenTimeUrl} from "../helpers/urls/interviewUrls";
import {post} from "../util/fetch";
import {setErrorMessage} from "./errorActions";
import {toast} from "./toastActions";
import {unresolveCommentsUrl} from "../helpers/urls/interviewUrls";

type Action = M1InterviewScreenAction | ErrorAction;
type AsyncAction = ThunkAction<Promise<void>, {}, {}, Action>;
type Dispatch = ThunkDispatch<AppState, Record<string, never>, Action>;
type GetState = () => AppState;

export function isStartingM1(value: boolean): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.IS_STARTING_M1",
    value,
  };
}

export function isSubmittingM1(value: boolean): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.IS_SUBMITTING_M1",
    value,
  };
}

export function toggleScreenInstructions(): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.TOGGLE_M1_INTERVIEW_SCREEN_INSTRUCTIONS",
  };
}

export function toggleConfirmDialog(): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.TOGGLE_M1_INTERVIEW_SCREEN_CONFIRM_DIALOG",
  };
}

export function toggleSubmittedDialog(): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.TOGGLE_M1_INTERVIEW_SCREEN_SUBMITTED_DIALOG",
  };
}

function isUnresolvingComments(value: boolean): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.IS_UNRESOLVING_COMMENTS",
    value,
  };
}

function setDocUpdateTime(value: number): M1InterviewScreenAction {
  return {
    type: "M1_INTERVIEW_SCREEN.SET_DOC_UPDATE_TIME",
    value,
  };
}

export function readyToStartM1(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {interview} = getState();
    if (interview.m1OpenTime) {
      console.info("M1 is already open");
      return;
    }
    dispatch(isStartingM1(true));
  };
}

export function startM1(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview, user} = getState();
      if (interview.m1OpenTime) {
        console.info("M1 is already open");
        dispatch(isStartingM1(false));
        return;
      }
      const data = {atsId: interview.id};
      const response = await post(m1OpenTimeUrl(), data, {token: user.idToken});
      const {error} = await response.json();
      if (error) {
        console.error("Error starting m1:", error);
        dispatch(setErrorMessage("Unable to start interview."));
      } else {
        // Wait before changing the state so the button doesn't flash.
        await new Promise((resolve) => setTimeout(resolve, 1000));
        dispatch(isStartingM1(false));
      }
    } catch (err) {
      console.error("Error starting m1", err);
      dispatch(setErrorMessage("Unable to start interview."));
    }
  };
}

export function unresolveComments(): AsyncAction {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const {interview} = getState();
      const data = {atsId: interview.id};
      dispatch(isUnresolvingComments(true));
      const response = await post(unresolveCommentsUrl(), data);
      const {error} = await response.json();
      if (error) {
        throw new Error(error);
      } else {
        dispatch(toast("Re-opened all comments."));
        dispatch(setDocUpdateTime(Date.now()));
      }
    } catch (err) {
      dispatch(toast("Unable to unresolve comments.", 5000));
    }
    dispatch(isUnresolvingComments(false));
  };
}
