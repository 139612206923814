import {isLocalhost, isStaging} from "../util/env";

const devApiBaseUrl = "http://api.localhost:5000";
const prodApiBaseUrl = "https://api.byteboard.dev";
const stagingApiBaseUrl = "https://api-staging.byteboard.dev";

export function getAPIBaseUrl(): string {
  return isLocalhost()
    ? devApiBaseUrl
    : isStaging()
    ? stagingApiBaseUrl
    : prodApiBaseUrl;
}
