import React, {useReducer, useEffect, useRef} from "react";
import {displayUnsavedChangesWarningBeforeUnload} from "../util/unload";

/** Forces a component to re-render. */
export function useForceUpdate() {
  // See https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  return forceUpdate;
}

export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useCombinedRefs<T>(
  ...refs: (React.ForwardedRef<T> | React.MutableRefObject<T>)[]
): React.RefObject<T> {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "function") {
        // @ts-ignore FIXME: strictNullChecks
        ref(targetRef.current);
      } else {
        // @ts-ignore FIXME: strictNullChecks
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  // @ts-ignore FIXME: strictNullChecks
  return targetRef;
}

// Warn the user before they reload or leave the page if they have unsaved
// changes.
export function useUnsavedChangesWarning(showWarning: boolean): void {
  const cancelUnsavedChangesDialogRef = useRef<(() => void) | null>(null);
  const cancelUnsavedChangesDialog = () => {
    cancelUnsavedChangesDialogRef.current &&
      cancelUnsavedChangesDialogRef.current();
  };

  useEffect(() => {
    cancelUnsavedChangesDialogRef.current = showWarning
      ? displayUnsavedChangesWarningBeforeUnload()
      : null;
    return () => cancelUnsavedChangesDialog();
  });
}
