import Paper, {PaperProps} from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React, {PropsWithChildren} from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "32px 24px",
    [theme.breakpoints.up("md")]: {
      padding: "32px 24px",
    },
  },
}));

type Props = PropsWithChildren<{className?: PaperProps["className"]}>;

export default function ApplicantPaper({children, className}: Props) {
  const classes = useStyles();
  return (
    <Paper
      className={clsx(classes.paper, className)}
      elevation={0}
      variant="outlined"
    >
      {children}
    </Paper>
  );
}
