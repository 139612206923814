import {AppError} from "../reducers/types";
import {ErrorAction} from "../actions/actionTypes";
import {SET_ERROR_MESSAGE, USER_NOT_SIGNED_IN} from "../actions/errorActions";

const defaultState: AppError = {
  errorMessage: "",
  tips: [],
  hideTips: false,
  isNotification: false,
  userNotSignedIn: false,
};

export function error(state = defaultState, action: ErrorAction): AppError {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return Object.assign({}, state, {
        tips: action.tips,
        hideTips: action.hideTips,
        isNotification: action.isNotification,
        errorMessage: action.errorMessage,
      });
    case USER_NOT_SIGNED_IN:
      return Object.assign({}, state, {
        userNotSignedIn: true,
      });
    default:
      return state;
  }
}
