import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Typography from "@material-ui/core/Typography";
import React from "react";

export interface AccordionItemInterface {
  title: string;
  content: React.ReactFragment;
}

export interface AccordionItemsInterface {
  data: AccordionItemInterface[];
}

export const AccordionItem = (props: AccordionItemInterface) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowRightIcon />}>
        <Typography variant="h3">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.content}</AccordionDetails>
    </Accordion>
  );
};

export const AccordionItems = (props: AccordionItemsInterface) => {
  const data = props.data;
  return (
    <>
      {data.map((item, index) => (
        <AccordionItem key={index} {...item} />
      ))}
    </>
  );
};
