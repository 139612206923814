export interface FetchOptions {
  token?: string;
}
export type GetOptions = FetchOptions;
export interface PostOptions extends FetchOptions {
  contentType?: string;
  noContentType?: boolean;
}
export interface PutOptions extends FetchOptions {
  contentType?: string;
}
export type DeleteOptions = FetchOptions;

const defaultGetOptions: GetOptions = {
  token: "",
};
const defaultPostOptions: PostOptions = {
  token: "",
  contentType: "application/json",
  noContentType: false,
};
const defaultPutOptions: PutOptions = {
  token: "",
  contentType: "application/json",
};
const defaultDeleteOptions: DeleteOptions = {
  token: "",
};

enum StatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

export class PermissionError extends Error {
  name = "Permission Error";
  message = "You do not have permission to perform this action.";
  stack = new Error().stack;
  data: any;
  constructor(data: any) {
    super();
    this.data = data;
  }
}
export class NetworkError extends Error {
  name = "Network Error";
  message = "Failed to connect.";
  stack = new Error().stack;
}
export class FetchError extends Error {
  name = "Fetch Error";
  message = "The request failed.";
  stack = new Error().stack;
  data: any;
  constructor(data: any) {
    super();
    this.data = data;
  }
}

export async function get(
  url: string,
  options: GetOptions = defaultGetOptions,
): Promise<Response> {
  try {
    const optsWithDefaults = {...defaultGetOptions, ...options};
    const headers = optsWithDefaults.token
      ? {authorization: `Bearer ${optsWithDefaults.token}`}
      : {};
    const response = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      // @ts-ignore FIXME: strictNullChecks
      headers,
    });
    return handleResponse(response);
  } catch (err) {
    throw new NetworkError();
  }
}

export async function post(
  url: string,
  data: Record<string, any> = {},
  options: PostOptions = defaultPostOptions,
): Promise<Response> {
  try {
    const optsWithDefaults = {...defaultPostOptions, ...options};
    const headers = optsWithDefaults.token
      ? {authorization: `Bearer ${optsWithDefaults.token}`}
      : {};
    const body = data instanceof FormData ? data : JSON.stringify(data);
    const contentType = optsWithDefaults.noContentType
      ? {}
      : {"Content-Type": optsWithDefaults.contentType};
    const response = await fetch(url, {
      method: "POST",
      body,
      // @ts-ignore FIXME: strictNullChecks
      headers: {
        ...headers,
        ...contentType,
      },
    });
    return handleResponse(response);
  } catch (err) {
    throw new NetworkError();
  }
}

export async function put(
  url: string,
  data: any = {},
  options: PutOptions = defaultPutOptions,
): Promise<Response> {
  try {
    const optsWithDefaults = {...defaultPutOptions, ...options};
    const headers = optsWithDefaults.token
      ? {authorization: `Bearer ${optsWithDefaults.token}`}
      : {};
    const body = data instanceof FormData ? data : JSON.stringify(data);
    const contentType = {"Content-Type": optsWithDefaults.contentType};
    const response = await fetch(url, {
      method: "PUT",
      body,
      // @ts-ignore FIXME: strictNullChecks
      headers: {
        ...headers,
        ...contentType,
      },
    });
    return handleResponse(response);
  } catch (err) {
    throw new NetworkError();
  }
}

export async function deleteRequest(
  url: string,
  options: DeleteOptions = defaultGetOptions,
): Promise<Response> {
  try {
    const optsWithDefaults = {...defaultDeleteOptions, ...options};
    const headers = optsWithDefaults.token
      ? {authorization: `Bearer ${optsWithDefaults.token}`}
      : {};
    const response = await fetch(url, {
      method: "DELETE",
      // @ts-ignore FIXME: strictNullChecks
      headers,
    });
    return handleResponse(response);
  } catch (err) {
    throw new NetworkError();
  }
}

async function handleResponse(response: Response): Promise<Response> {
  let json: any;
  try {
    json = await response.clone().json();
  } catch (err) {
    // Response doesn't have a json body, ok to swallow the error.
    json = {};
  }
  if (
    response.status === StatusCode.UNAUTHORIZED ||
    response.status === StatusCode.FORBIDDEN
  ) {
    throw new PermissionError(json);
  }
  if (!response.ok) {
    throw new FetchError(json);
  }
  return response;
}
