import * as React from "react";
import CenteredScreenContent from "../components/CenteredScreenContent";
import Typography from "@material-ui/core/Typography";
import {brand} from "../branding";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
  },
  logo: {
    height: brand.logoMarkDimensions.height,
    marginBottom: 40,
    width: brand.logoMarkDimensions.width,
  },
  elbowRoom: {
    margin: 15,
  },
}));

interface Props {
  liveCodingInterviewId: string;
}
export const PostLiveCodingSurveyScreen: React.FC<Props> = ({
  liveCodingInterviewId,
}: Props) => {
  const classes = useStyles();
  return (
    <CenteredScreenContent>
      <div className={classes.root}>
        <img className={classes.logo} src={brand.logoMark} />
        <Typography variant="h2" paragraph={true} align="center">
          Thank you for your feedback on CodeCollab!
        </Typography>
        <Typography align="center">
          You can close this window at any time.
        </Typography>
      </div>
    </CenteredScreenContent>
  );
};
