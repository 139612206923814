/** Rejects with `error` if `maxTime`ms elapses before `promise` resolves. */
export async function rejectLongRunningPromise(
  promise: Promise<any>,
  maxTime: number,
  error: Error,
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    let timeoutId;
    try {
      timeoutId = setTimeout(() => {
        reject(error);
      }, maxTime);
      const result = await promise;
      resolve(result);
    } catch (err) {
      reject(err);
    }
    // @ts-ignore FIXME: strictNullChecks
    clearTimeout(timeoutId);
  });
}
