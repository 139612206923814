import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React from "react";
import {Role} from "../helpers/types";
import {useSharedStyles} from "../hooks/useSharedStyles";
import ExperienceEditButton from "./ExperienceEditButton";
import ExperienceSkills from "./ExperienceSkills";

type Props = {
  role: Required<Role>;
};

export default function ExperienceRole({role}: Props) {
  const sharedClasses = useSharedStyles();
  return (
    <Box display="grid" gridRowGap={8}>
      <Box className={sharedClasses.experinceHeaderContainer}>
        <Typography
          className={sharedClasses.experienceTitle}
          style={{gridArea: "title"}}
          variant="h3"
        >
          {`${role.title} - ${role.organization}`}
        </Typography>
        <Typography component="p" style={{gridArea: "line1"}} variant="caption">
          {`${moment(role.startDate).format("MMM YYYY")} - ${
            role.endDate === ""
              ? "Present"
              : moment(role.endDate).format("MMM YYYY")
          }`}
        </Typography>
        <Typography component="p" style={{gridArea: "line2"}} variant="caption">
          {role.location}
        </Typography>
        <ExperienceEditButton category="role" uuid={role.uuid} />
      </Box>
      <Typography variant="body2">
        {role.accomplishmentsAndResponsibilities}
      </Typography>
      <ExperienceSkills experience={role} />
    </Box>
  );
}
