import TextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import React from "react";

export type FormFieldType = {
  label: string;
  onChange: (value: string) => void;
  value: string;
} & Pick<MuiTextFieldProps, "error" | "required" | "disabled">;

type FormFieldProps = FormFieldType & {
  TextFieldProps: Omit<MuiTextFieldProps, keyof FormFieldType>;
};

export default function FormField({
  onChange,
  TextFieldProps,
  ...restOfFormFieldData
}: FormFieldProps) {
  return (
    <TextField
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      {...restOfFormFieldData}
      {...TextFieldProps}
    />
  );
}
